/*jslint nomen:true, indent:4*/
/*global define, console, alert, $, FormData, location */

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "views/daterange",
    "templates/settings.html",
    "dict/isocountries.json",
    "views/pureSelect",
    "libs/copyToClipboard",
    "views/profile"
], function (
    Backbone,
    registry,
    ApiRequest,
    DateRange,
    settingsTemplate,
    isoCountries,
    PureSelect,
    copyToClipboard,
    ProfileView
) {

    var SettingsView = Backbone.View.extend({

        // Constants
        KEY_LABEL_TEXT: "Password",
        KEY_COPIED_LABEL_TEXT: "API Key (Copied!)",
        SAVE_LABEL_DEFAULT: "Save Settings",

        // Properties
        _subViews: [],
        $avatar: null,
        $nameText: null,
        $nickInput: null,
        $globalPostBackInput: null,
        $subscriptionPostBackInput: null,
        $saveButton: null,
        $logoutButton: null,

        $fileInput: null,
        $uploadForm: null,

        exportLeadsDateRange: null,
        pushPostbackMacros: ['clickID','tag','subscriptionid','amount'],
        postbackMacros: ['campaignID', 'keyword1', 'keyword2', 'keyword3', 'keyword4', 'keyword5', 'clickID', 'amount', 'offer', 'transactionID', 'advertiserID','clickid','cat','uid'],


        // Events
        events: {
            "click .settings_saveButton": "saveSettings",
            "click .settings_logoutButton": "clickLogout",
            "click #changePassword": "clickChangePass",
            "click #exportLeads": "clickExportLeads",
            "click .settings_avatarOverlay": "clickAvatarUpload",
            "change #uploadAvatarInput": "uploadAvatar",
            "click .clickMacro": "clickMacro",
            "click .clickSubMacro": "clickSubMacro",
            "click #doChangePassword": "clickSavePassword",
            "click .copyAccessTokenBtn": "copyAccessToken",
            "click #doChangeAccessToken": "changeAccessToken",
            'click #createWpPluginAccessToken': 'createWpPluginAccessToken',
            'click #doDeleteWpPluginAccessToken': 'deleteWpPluginAccessToken',
            'click .copyWpPluginAccessTokenBtn': 'copyWpPluginAccessToken'
        },


        // Methods
        initialize: function () {
        },

        render: function () {
            var self = this,
                req = new ApiRequest(),
                url = "data/partner-revenue.php";

            this.setElement($(document.createElement('div')));

            this.$el.addClass("settingsPane")
                .addClass("mainPane")
                .attr('id', 'settingsContainer');

            this.$el.append(_.template(settingsTemplate)({
                access: registry.user.getAccessLevel()
            }));
            $('#appContainer').append(this.$el);

            this.avatarWrapper = this.$el.find('.avatarWrapper');
            this.$nickInput = this.$el.find('.nickInput');
            this.$globalPostBackInput = this.$el.find('.postbackInput');
            this.$subscriptionPostbackInput = this.$el.find('.subscriptionPostbackInput');
            this.$avatar = this.avatarWrapper.find('.avatar');
            this.allTimeRevenuePlaceholder = this.$el.find('.allTimeWrapper strong');
            this.$paymentNetActual = this.$el.find('.paymentWrapper strong');
            this.$revSharePlaceholder = this.$el.find('.revShareWrapper strong');
            this.$partnerIdText = this.$el.find('.idWrapper strong');
            this.$passwordButton = this.$el.find('#changePassword');
            this.$nameText = this.$el.find('.nameText');
            this.$usernameText = this.$el.find('.avatarWrapper span');
            this.$fileInput = this.$el.find('#uploadAvatarInput');
            this.$saveButton = this.$el.find('.settings_saveButton');
            this.$tokenInput = this.$el.find('.passwordToken');
            this.$newPassInput = this.$el.find('.newPassword');
            this.$passwordModal = this.$el.find('#changePasswordModal');
            this.$exportLeadsButton = this.$el.find('#exportLeads');

            this.$notificationTopOffers = this.$el.find('.notificationTopOffers');
            this.$notificationMessages = this.$el.find('.notificationMessages');
            this.$notificationNews = this.$el.find('.notificationNews');
            this.$accessTokenInput = this.$el.find('.accessTokenInput');

            this.exportLeadsDateRange = new DateRange({
                minDate: undefined,
                element: this.$el.find('.exportDateRange')
            });
            this._subViews.push(this.exportLeadsDateRange);

            this.exportLeadsDateRange.render();

            this.$revSharePlaceholder.text(registry.user.share+'%');

            this.profileView = new ProfileView();
            this._subViews.push(this.profileView);
            this.$el.find('.profileDetails').html(this.profileView.render().el)

            this.on("post-render", this.postRender, this);
            this.on("update:avatar", this.avatarUpdated, this);

            function updateRevenue(data) {
                if (!data || !data.amount) {
                    return;
                }

                var amount = parseFloat(data.amount);
                var display = amount > 1000000 ? (amount / 1000000).toFixed(1) + 'M' : amount > 1000 ? (amount / 1000).toFixed(1) + 'K' : amount.toFixed(1);

                self.allTimeRevenuePlaceholder.text('$' + display);
            }

            req.request(url)
                .done(updateRevenue);


            $.when(
                registry.ready,
                req.request('v3/publisherWpPluginAccessToken.php')
            )
                .then(function(registryResponse, wpPluginAccessTokenResponse) {
                    self.wpPluginAccessToken = wpPluginAccessTokenResponse[0].token;
                    self.trigger("post-render");
                });

            return this.$el;
        },

        postRender: function () {
            var user = registry.user;

            this.$nickInput.val(user.nick);
            this.$usernameText.text(user.nick);
            this.$globalPostBackInput.val(user.postbackURL);
            this.$subscriptionPostbackInput.val(user.push_subscription_postback_url);
            this.$passwordButton.val(user.token);
            this.$paymentNetActual.text('NET ' + user.paymentNet);

            if(parseInt(user.notification_top_offers) === 1) {
                this.$notificationTopOffers.attr('checked','checked');
            }
            if(parseInt(user.notification_messages) === 1) {
                this.$notificationMessages.attr('checked','checked');
            }
            if(parseInt(user.notification_news) === 1) {
                this.$notificationNews.attr('checked','checked');
            }

            this.$nameText.text(user.name);

            this.$partnerIdText.text(user.id);

            this.$avatar.css("background-image", 'url('+registry.user.getGravatar()+')');

            this.$accessTokenInput.val(user.token || 'N/A');

            this.$wpPluginAccessTokenInput = this.$el.find('.wpPluginAccessTokenInput');
            this.updateUIwpPluginAccessToken();
        },

        updateUIwpPluginAccessToken: function () {
            if (this.wpPluginAccessToken) {
                this.$wpPluginAccessTokenInput.val(this.wpPluginAccessToken);
                this.$el.find('.wpPluginAccessTokenExists').show();
                this.$el.find('.wpPluginAccessTokenCreate').hide();
            } else {
                this.$el.find('.wpPluginAccessTokenExists').hide();
                this.$el.find('.wpPluginAccessTokenCreate').show();
            }
        },

        _checkMacroUsage: function (string, macroAr) {
            var macroRegex = /{(.*?)}/gm,
                valid = true;

            while((m = macroRegex.exec(string)) !== null) {
                if (m.index === macroRegex.lastIndex) {
                    macroRegex.lastIndex++;
                }
                // The result can be accessed through the `m`-variable.
                m.forEach(function(match, groupIndex) {
                    if(groupIndex === 1) {

                        if(macroAr.indexOf(match) == -1) {
                            valid = false;
                            return;
                        }

                    }
                });
            }
            return valid;
        },

        saveSettings: function () {
            var self = this,
                req = new ApiRequest(),
                url = "data/partner.php",
                notificationTopOffers = 0,
                notificationMessages = 0,
                notificationNews = 0,
                error = false;

            this.$saveButton.text("Saving...");

            if(!this._checkMacroUsage(this.$subscriptionPostbackInput.val(), this.pushPostbackMacros)) {
                error = true;
                this.$subscriptionPostbackInput.addClass('input-error');
                alert("Unsupported macro in 'Push Subscription Postback'.");
            } else {
                this.$subscriptionPostbackInput.removeClass('input-error');
            }

            if(!this._checkMacroUsage(this.$globalPostBackInput.val(), this.postbackMacros)) {
                error = true;
                this.$globalPostBackInput.addClass('input-error');
                alert("Unsupported macro in 'Global Postback'.");
            } else {
                this.$globalPostBackInput.removeClass('input-error');
            }


            if(error) {
                this.$saveButton.text('Failed!');
                window.setTimeout(function () {
                    self.$saveButton.text(self.SAVE_LABEL_DEFAULT);
                }, 2000);
            } else {

                if(this.$notificationTopOffers.is(':checked')) {
                    notificationTopOffers = 1;
                }
                if(this.$notificationMessages.is(':checked')) {
                    notificationMessages = 1;
                }
                if(this.$notificationNews.is(':checked')) {
                    notificationNews = 1;
                }

                $.when(req.request(url, {
                    action: 'changeSettings',
                    nick: this.$nickInput.val(),
                    gpb: this.$globalPostBackInput.val(),
                    spb: this.$subscriptionPostbackInput.val(),
                    notification_top_offers: notificationTopOffers,
                    notification_messages: notificationMessages,
                    notification_news: notificationNews
                })).done(function (result) {
                    var saveLabel = "Saved!";

                    if (result === false) {
                        saveLabel = "Failed!";
                        console.error(result, "error");
                    } else {
                        registry.user.postbackURL = self.$globalPostBackInput.val();
                        registry.user.push_subscription_postback_url = self.$subscriptionPostbackInput.val();
                    }

                    self.$saveButton.text(saveLabel);
                    window.setTimeout(function () {
                        self.$saveButton.text(self.SAVE_LABEL_DEFAULT);
                    }, 2000);

                });

            }

        },

        clickLogout: function() {
            location.reload();
        },


        clickChangePass: function() {
            var req = new ApiRequest();


            req.request("data/partner-pass-change.php")
            .fail(function (err) {
                console.log("ERROR", err);
            });

        },

        clickExportLeads: function () {
            var self = this,
                req = new ApiRequest(),
                range = this.exportLeadsDateRange.get(),
                url;

            url = "data/partner-leads.php" +
                '?start_ts=' + range.start +
                '&end_ts=' + range.end;

            var finishWaiting = (function () {
                var saved = self.$exportLeadsButton.text();
                self.$exportLeadsButton.text('Generating...');

                return function () {
                    self.$exportLeadsButton.text(saved);
                };
            }());

            req.request(url)
                .done(function (body) {
                    finishWaiting();

                    var blob = new Blob(
                        [ body ],
                        { type: 'text/csv;charset=utf-8' }
                    );
                    var url = URL.createObjectURL(blob);


                    $('<a>', { href: url, download: 'leads.csv' })
                        .get(0)
                        .click();

                    URL.revokeObjectURL(url);
                })
                .fail(function () {
                    finishWaiting();
                    alert('Error');
                });
        },

        uploadAvatar: function() {
            var url = API_URL + "data/partner-avatar.php",
                options = {},
                headers = null,
                fd = null,
                dfd = $.Deferred(),
                self = this;

            if (this.$fileInput[0].files[0].size > 1e6) {
                alert("Theres a 1MB limit on the file");
            } else {

                fd = new FormData();
                fd.append('file', this.$fileInput[0].files[0]);


                // Refactor
                headers = {
                    "X_AFFLOW_API_TOKEN": registry.user.token,
                    "X_AFFLOW_CLIENT_VERSION": registry.versionStr
                };

                options.url = url;
                options.headers = headers;
                options.crossDomain = true;
                options.type = "POST";
                options.enctype = 'multipart/form-data';
                options.data = fd;
                options.processData = false;
                options.contentType = false;

                this.requestObject = $.ajax(options)
                .done(function (body) {
                    dfd.resolve(body.url);
                    self.trigger("update:avatar", body.url);
                })
                .fail(function () {
                    console.log("UPLOAD FAILED");
                    dfd.reject();
                });


            }

        },

        avatarUpdated: function(url) {
            var secUrl = url.replace('http:','https:');

            registry.user.nick_img = secUrl;
            $('.userbar .userAvatar').css("background-image", 'url("'+secUrl+'")');
            this.$avatar.css("background-image", 'url('+secUrl+')');
        },

        clickAvatarUpload: function() {
            this.$fileInput.trigger("click");

        },

        clickMacro: function(e) {
            var caretPos = this.$globalPostBackInput[0].selectionStart,
                value = this.$globalPostBackInput.val(),
                macro = $(e.currentTarget).html();
            this.$globalPostBackInput.val(value.substring(0, caretPos) + macro + value.substring(caretPos));

        },

        clickSubMacro: function(e) {
            var caretPos = this.$subscriptionPostbackInput[0].selectionStart,
                value = this.$subscriptionPostbackInput.val(),
                macro = $(e.currentTarget).html();
            this.$subscriptionPostbackInput.val(value.substring(0, caretPos) + macro + value.substring(caretPos));

        },

        clickSavePassword: function() {
            var self = this,
                token = this.$tokenInput.val(),
                password = this.$newPassInput.val(),
                req = new ApiRequest();

            // Lets sanity check the token, it should be 8 hex digits
            if (token.match(/^[a-f0-9]{8}$/i) !== null) {
                console.log("token looks ok");
            } else {
                this.$tokenInput.addClass("error");
                return false;
            }

            if (password.length > 7) {
                console.log("Pass looks ok");
                this.$newPassInput.removeClass("error");
            } else {
                this.$newPassInput.addClass("error");
                console.log("shitty password");
                return false;
            }

            req.request("data/partner-pass-change.php", {
                "new_pass": password,
                "t": token
            })
            .done(function () {
                alert("Your password was changed.");
                self.$passwordModal.fadeOut('fast');
                self.$tokenInput.val("");
                self.$newPassInput.val("");
            })
            .fail(function (err) {
                console.error("CP FAIL", err);
                self.$tokenInput.addClass("error");
                self.$newPassInput.addClass("error");
            });

        },

        copyAccessToken: function(ev) {
            copyToClipboard(this.$accessTokenInput.val());
        },

        changeAccessToken: function(ev) {
            var self = this;
            var req = new ApiRequest();

            req.post('v3/publisherChangeApiKey.php', {token: localStorage.apiToken})
                .done(function(response) {
                    if (response.status === 'success' && response.access_token) {
                        registry.user.token = response.access_token;
                        self.$accessTokenInput.val(response.access_token);
                    }
                })
                .fail(function (err) {
                    console.error(err.responseJSON);
                    if (err.responseJSON && err.responseJSON.message) {
                        alert(err.responseJSON.message);
                    }
                })
                .always(function () {
                    self.$el.find('#changeAccessTokenModal').hide();
                });
        },

        createWpPluginAccessToken: function (ev) {
            var self = this;
            var req = new ApiRequest();

            req.post('v3/publisherWpPluginAccessToken.php', {
                token: localStorage.apiToken,
                action: 'create'
            })
                .done(function(response) {
                    if (response && response.token) {
                        self.wpPluginAccessToken = response.token;
                        self.updateUIwpPluginAccessToken();
                    }
                })
                .fail(function (err) {
                    console.error(err.responseJSON);
                    if (err.responseJSON && err.responseJSON.message) {
                        alert(err.responseJSON.message);
                    }
                });
        },

        deleteWpPluginAccessToken: function (ev) {
            var self = this;
            var req = new ApiRequest();

            req.post('v3/publisherWpPluginAccessToken.php', {
                token: localStorage.apiToken,
                action: 'delete'
            })
                .done(function(response) {
                    if (response && response.status === 'OK') {
                        self.wpPluginAccessToken = null;
                        self.updateUIwpPluginAccessToken();
                    }
                })
                .fail(function (err) {
                    console.error(err.responseJSON);
                    if (err.responseJSON && err.responseJSON.message) {
                        alert(err.responseJSON.message);
                    }
                })
                .always(function () {
                    self.$el.find('#deleteWpPluginAccessTokenModal').hide();
                });
        },

        copyWpPluginAccessToken: function(ev) {
            copyToClipboard(this.$wpPluginAccessTokenInput.val());
        },

        showError: function (msg) {
            var $panel = $('#globalErrorMsg');

            $panel.html(msg);

            $panel.fadeIn('fast', function() {
                setTimeout(function() {
                    $panel.fadeOut('fast');
                }, 5000)
            });
        },

        wakeUp: function() {
        },

        sleep: function () {
        },

    });


    return new SettingsView();
});

