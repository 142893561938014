/*jslint nomen:true, indent:4, bitwise:true*/
/*jshint -W079 */
/*global define, _, $, alert, confirm, console*/

// TODO editLeagueSelectWrapper

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "moment-timezone",
    "views/bigselect",
    "views/directLink",
    "views/pureSelect",
    "dict/isocountries.json",
    "dict/networkInfo.json",
    "dict/verticals.json",
    "dict/static-verticals.json",
    "dict/language-codes.json",
    "templates/linkGen.html",
    "templates/linkGenRowTemplate.html",
    "templates/linkGenNoLinks.html",
    "templates/linkEdit.html"
], function(
    Backbone,
    registry,
    ApiRequest,
    moment,
    BigSelectView,
    DirectLinkView,
    PureSelect,
    geoDataSrc,
    networkDataSrc,
    verticalDataSrc,
    staticVerticalDataSrc,
    languagesDataSrc,
    mainTemplate,
    rowTemplate,
    noLinksTemplate,
    editTemplate
) {

    var geoData = geoDataSrc,
        networkData = networkDataSrc,
        verticalData = verticalDataSrc,
        languagesData = _.reduce(languagesDataSrc, function (acc, v) {
            acc[v.alpha2] = v.English;
            return acc;
        }, {}),
        staticVerticalData = staticVerticalDataSrc;

    var LinkManagementView = Backbone.View.extend({
        // Constants
        MODE_EDIT: 1,
        MODE_ADD: 2,
        MODE_COPY: 3,
        EDIT_HEADER_TEMPLATE: "Edit Link",
        CREATE_HEADER: "Create Link",
        DONT_MATCH_TEXT: "(Any Value)",
        DEFAULT_REDIRECT_TIME: 10,

        // Properties
        _subViews: [],
        _currentRid: null,
        _isAsleep: null,
        _linkList: null,
        _onPage: 1,
        _pageMaxReached: false,
        _carrierTimeout: null,
        _cityTimeout: null,
        _offerTimeout: null,
        _modalOnPage: 1,
        _modalPageMaxReached: false,

        _currentlyEditing: null,
        _mode: null,

        // Events
        events: {
            "keyup #linkListSearchInput": "searchKeyUp",
            "click #linkListNextButton": "clickNext",
            "click #linkListPrevButton": "clickPrev",
            "click #editFormBackButton": "clickEditBack",
            "click .linkGenEditShowLinksButton": "clickShowLinks",
            "click #linksEditSaveButton": "clickSaveEdit",
            "click #linksEditCreateButton": "clickCreate",
            "change #editFormRcidInput, #editFormKw1Input, #editFormKw2Input, #editFormKw3Input, #editFormKw4Input, #editFormKw5Input, #editFormClickIdInput":
                "outputChanged",
            "change .linkFormInteract": "triggerUnsaved",
            "click .filterClick": "clickFilterLink",
            "click .expandable": "toggleActive",
            "click #editHashInput": "selectInput",
            "click .routeLink": "routeLink",
            "click .copyLink": '_copyLink',
            "click .togglePanelBtn": '_togglePanel',
            "click .getDirectLink": "getDirectLink",
        },

        // Methods
        initialize: function() {},

        render: function() {
            var self = this;

            this.setElement(
                $(document.createElement("div"))
                    .addClass("mainPane")
                    .addClass("linksPane")
                    .attr("id", "linkManagementContainer")
            );

            // Set up UI
            this.$el.append(
                _.template(mainTemplate)({
                    access: registry.user.getAccessLevel()
                })
            );
            $("#appContainer").append(this.$el);

            // Set Mode
            this._mode = this.MODE_EDIT;
            this._unsavedChanged = false;

            this.$linkListContainer = this.$el.find("#listContainer");
            this.$linkList = this.$el.find("#linkList");
            this.$linkListSearchInput = this.$el.find("#linkListSearchInput");
            this.$linkListPrevButton = this.$el.find("#linkListPrevButton");
            this.$linkListNextButton = this.$el.find("#linkListNextButton");

            this.$showFilteredSelect = new PureSelect()
                .attr("id", "linkListShowFilteredSelect")
                .addClass("white")
                .updateData({
                    "0": "Hide Filtered",
                    "1": "Show Filtered"
                })
                .appendTo(this.$el.find("#linkListShowFilteredSelectContainer"))
                .on("change", this.getLinks, this);
            this._subViews.push(this.$showFilteredSelect);

            this.$showBounceSelect = new PureSelect()
                .attr("id", "linkListShowBounceSelect")
                .addClass("white")
                .updateData({
                    "1": "Show Bounce",
                    "0": "Hide Bounce"
                })
                .val("1")
                .appendTo(this.$el.find("#linkListShowBounceSelectContainer"))
                .on("change", this.getLinks, this);
            this._subViews.push(this.$showBounceSelect);

            // Events
            this.on("has:links", this.renderLinkList, this);

            // Init Data
            this._linkList = [];
            this.domains = [];

            this.getLinks();
            this.getDomains();

            return this.$el;
        },

        renderLinkList: function() {
            var self = this;

            this.$linkList.empty();

            if (_.size(this._linkList) === 0) {
                this.renderNoLinks();
            } else {
                _.each(this._linkList, function(linkItem) {
                    var nidInfo = registry.user.getInfoForNid(linkItem.nid);

                    self.$linkList.append(
                        _.template(rowTemplate)({
                            access: registry.user.getAccessLevel(),
                            linkLeagueText: nidInfo.name,
                            linkHash: linkItem.hash,
                            linkNameText: linkItem.name === null ? "Unnamed" : linkItem.name,
                            linkVertical: linkItem.vertical,
                            linkCreated:
                                linkItem.created_ts === null
                                    ? "Historic"
                                    : moment.unix(linkItem.created_ts).format("MMM Do YYYY"),
                            linkFilterIcon:
                                linkItem.is_filtered === "0" ? "eye-slash" : "eye",
                            linkFilterText: linkItem.is_filtered === "0" ? "Hide" : "Show",
                            linkFiltered: linkItem.is_filtered,
                            hideActions: false,
                            highlight: false,
                            smartlinkFallback: !+linkItem.hard_offer_whitelist
                        })
                    );
                });
            }
        },

        renderNoLinks: function() {
            this.$linkList.html(noLinksTemplate);
        },

        getLinks: function() {
            var self = this,
                dfd = $.Deferred(),
                hashRegX = /[0-9a-f]{40}/,
                req = new ApiRequest(),
                searchString = null,
                optionsString = null;

            searchString = this.$linkListSearchInput.val().trim();
            if (searchString) {
                if (hashRegX.test(searchString)) {
                    searchString = searchString.match(hashRegX)[0];
                }

                searchString = "&search=" + searchString;
            } else {
                searchString = "";
            }

            optionsString = "&bbr=" + this.$showBounceSelect.val();
            optionsString += "&filtered=" + this.$showFilteredSelect.val();

            req
                .request(
                    "data/partner-link.php?page=" +
                    this._onPage +
                    searchString +
                    optionsString
                )
                .done(function(linkList) {
                    if (self._onPage > 1 && _.size(linkList) === 0) {
                        self._pageMaxReached = true;
                    } else {
                        self._pageMaxReached = false;
                    }

                    self._linkList = linkList;
                    self.trigger("has:links");
                })
                .fail(function(xhr) {
                    alert(
                        "There was an error fetching your links, please restart Monetizer and if the problem persists contact Support."
                    );
                    console.error("Error fetching links", xhr.responseJSON);
                });

            return dfd.promise();
        },

        searchKeyUp: function(e) {
            if (e.which === 13) {
                this._onPage = 1;
                this.getLinks();
            }
        },

        clickNext: function() {
            if (!this._pageMaxReached) {
                this._onPage += 1;
                this.getLinks();
            }
        },

        clickPrev: function() {
            if (this._onPage - 1 !== 0) {
                this._onPage -= 1;
                this.getLinks();
            }
        },

        showEditLink: function(type, cid) {
            var self = this;

            this.setElement(
                $(document.createElement("div"))
                    .addClass("mainPane")
                    .addClass("newLinkPane")
                    .attr("id", "newLinkContainer")
            );

            // Set up UI
            this.$el.append(
                _.template(editTemplate)({
                    access: registry.user.getAccessLevel()
                })
            );
            $("#appContainer").append(this.$el);

            // Parse External JSON
            geoData = geoDataSrc;
            networkData = networkDataSrc;
            verticalData = verticalDataSrc;

            staticVerticalData = staticVerticalDataSrc;

            this.$editForm = this.$el.find("#editFormContainer");
            this.$unsavedWrapper = this.$el.find("#linksUnsavedWrapper").hide();
            this.$linkOutputWrapperOld = this.$el.find("#linkOutputWrapper");

            // Edit form cache
            this.$editFormHeader = this.$el.find("#formHeader");
            this.$editFormLeague = this.$el.find("#editFormLeague");
            this.$editFormHash = this.$el.find("#editHashInput");
            this.$editFormNameInput = this.$el.find("#editFormNameInput");
            this.$editFormVerticalSelectContainer = this.$el.find("#editFormVerticalSelectContainer");
            this.$editFormVerticalSelect = new PureSelect()
                .addClass("white")
                .attr("id", "editFormVerticalSelect")
                .appendTo(this.$editFormVerticalSelectContainer)
                .on("change", this.verticalChanged, this);
            this._subViews.push(this.$editFormVerticalSelect);

            this.$editFormSaveButton = this.$el.find("#linksEditSaveButton");
            this.$editFormCreateButton = this.$el.find("#linksEditCreateButton");

            this.$editFormNetworkSelectContainer = this.$el.find("#editFormNetworkSelectContainer");
            this.$editFormNetworkSelect = new PureSelect()
                .addClass("white")
                .attr("id", "editFormNetworkSelect")
                .placeholder("Loading")
                .appendTo(this.$editFormNetworkSelectContainer)
                .on("change", this.changeNetworkSlugs, this);
            this._subViews.push(this.$editFormNetworkSelect);

            this.$editFormRcidInput = this.$el.find("#editFormRcidInput");
            this.$editFormKw1Input = this.$el.find("#editFormKw1Input");
            this.$editFormKw2Input = this.$el.find("#editFormKw2Input");
            this.$editFormKw3Input = this.$el.find("#editFormKw3Input");
            this.$editFormKw4Input = this.$el.find("#editFormKw4Input");
            this.$editFormKw5Input = this.$el.find("#editFormKw5Input");
            this.$editFormClickIdInput = this.$el.find("#editFormClickIdInput");

            this.$postbackThresholdInput = this.$el.find("#postbackThresholdInput");

            this.$editLeagueSelectWrapper = this.$el.find("#editLeagueSelectWrapper");

            this.$editLeagueInfoWrapper = this.$el.find("#editLeagueInfoWrapper");
            this.$editFormHashInfoContainer = this.$el.find("#editFormHashInfoContainer");

            this.$editFormNidSelect = new PureSelect()
                .addClass("white")
                .attr("id", "editFormNidSelect")
                .appendTo(this.$editLeagueSelectWrapper)
                .on("change", this.changeNidSelect, this);
            this._subViews.push(this.$editFormNidSelect);

            this.$exitPopWrapper = this.$el.find("#exitPopWrapper");
            this.$exitPopEnabledSelect = new PureSelect()
                .addClass("white")
                .attr("id", "exitPopEnabledSelect")
                .updateData({
                    "0": "Disabled",
                    "1": "Enabled"
                })
                .appendTo(this.$exitPopWrapper);
            this._subViews.push(this.$exitPopEnabledSelect);

            this.$vibrateWrapper = this.$el.find("#vibrateWrapper");
            this.$vibrateEnabledSelect = new PureSelect()
                .addClass("white")
                .attr("id", "vibrateEnabledSelect")
                .updateData({
                    "0": "Disabled",
                    "1": "Enabled"
                })
                .appendTo(this.$vibrateWrapper);
            this._subViews.push(this.$vibrateEnabledSelect);

            this.$entryPopWrapper = this.$el.find("#entryPopWrapper");
            this.$entryPopEnabledSelect = new PureSelect()
                .addClass("white")
                .attr("id", "entryPopEnabledSelect")
                .updateData({
                    "0": "Disabled",
                    "1": "Enabled"
                })
                .appendTo(this.$entryPopWrapper);
            this._subViews.push(this.$entryPopEnabledSelect);

            this.$soundWrapper = this.$el.find("#soundWrapper");
            this.$soundEnabledSelect = new PureSelect()
                .addClass("white")
                .attr("id", "soundEnabledSelect")
                .updateData({
                    "0": "Disabled",
                    "1": "Enabled"
                })
                .appendTo(this.$soundWrapper);
            this._subViews.push(this.$soundEnabledSelect);

            this.$redirectTimeWrapper = this.$el.find("#redirectTimeWrapper");
            this.$redirectTimeSelect = new PureSelect()
                .addClass("white")
                .attr("id", "redirectTimeSelect")
                .updateData(
                    {
                        "259200": "Disabled",
                        "20": "20 Seconds",
                        "10": "10 Seconds",
                        "5": "5 Seconds",
                        "0": "0 Seconds"
                    },
                    "259200"
                )
                .appendTo(this.$redirectTimeWrapper);
            this._subViews.push(this.$redirectTimeSelect);

            this.$desktopRedirectWrapper = this.$el.find("#desktopRedirectWrapper");
            this.$desktopRedirectEnabledSelect = new PureSelect()
                .addClass("white")
                .attr("id", "desktopRedirectEnabledSelect")
                .updateData(
                    {
                        "0": "No",
                        "1": "Yes"
                    },
                    "0"
                )
                .appendTo(this.$desktopRedirectWrapper);
            this._subViews.push(this.$desktopRedirectEnabledSelect);


            this.$vautoOptionsWrapper = this.$el.find("#vautoOptionsWrapper");
            this.$vautoVerticalsSelect = this.$el.find("#vautoVerticalsSelect");
            this.$vautoAllowAggressiveCheck = this.$el.find(
                "#vautoAllowAggressiveCheck"
            );
            this.$vautoAdultSelectContainer = this.$el.find(
                "#vautoAdultSelectContainer"
            );
            this.$vautoAdultSelect = new PureSelect()
                .attr("id", "vautoAdultSelect")
                .updateData(
                    {
                        0: "Mainstream",
                        1: "Adult"
                    },
                    "whitelist"
                )
                .on("change", this.populateVautoVerticals, this)
                .appendTo(this.$vautoAdultSelectContainer);
            this._subViews.push(this.$vautoAdultSelect);

            this.$editFormOfferSelectWrapper = this.$el.find(
                "#editFormOfferSelectWrapper"
            );

            // For Offer Selection
            this.offerWhitelistInput = this.$el.find('#offerWhitelist');

            this.$smartlinkFallbackCheck = this.$el.find("#smartlinkFallbackCheck");
            this.$smartlinkFallbackContainer = this.$el.find("#smartlinkFallbackContainer");

            languagesData['do_not_match'] = self.DONT_MATCH_TEXT;

            // Create the BigSelect view for VAuto Verticals
            this.vautoVerticalSelect = new BigSelectView({
                searchLabel: "Allowed Verticals:",
                allText: "All Verticals Allowed"
            });
            this._subViews.push(this.vautoVerticalSelect);
            this.vautoVerticalSelect.on(
                "change",
                function() {
                    this.trigger("unsaved");
                },
                this
            );
            this.$vautoVerticalsSelect.append(this.vautoVerticalSelect.render());

            this.populateVerticals(type);

            var networkHT = {};
            _.each(networkData, function(row, index) {
                networkHT[index] = row.displayName;
            });
            networkHT.other = "Other";
            this.$editFormNetworkSelect.updateData(networkHT);

            switch (type) {
                case "new":
                    // Null currently editing as a safe guard
                    // (will cause thrown errors if mode is overlooked)
                    this._currentlyEditing = null;
                    this._mode = this.MODE_ADD;

                    this.$editLeagueInfoWrapper.hide();
                    this.$editFormHashInfoContainer.hide();
                    this.$editFormCreateButton.show();
                    this.$editFormSaveButton.hide();
                    this.$editLeagueSelectWrapper.show();
                    this.$linkOutputWrapperOld.hide();

                    this.$vibrateEnabledSelect.val(1);
                    this.$exitPopEnabledSelect.val(0);
                    this.$entryPopEnabledSelect.val(1);
                    this.$soundEnabledSelect.val(1);
                    this.$desktopRedirectEnabledSelect.val(1);
                    this.$postbackThresholdInput.val(0);

                    this.$editFormNetworkSelect.enable();

                    this.resetForm();

                    if (!_.isNull(cid)) {
                        this.$editFormVerticalSelect.val(cid);
                    } else {
                        this.$editFormVerticalSelect.val("vauto");
                    }
                    this.verticalChanged();

                    // Jumbo link handling (url that sends traffic to specified list of private offers)
                    if (registry.jumboLinkOffers && registry.jumboLinkOffers.length > 0) {
                        var jumboLinkOffers = registry.jumboLinkOffers;
                        registry.jumboLinkOffers = undefined;

                        var linkNameParts = _.map(jumboLinkOffers, function(offer) {
                            return offer.aff_campaign_geo + '_' + offer.offerIdDep;
                        }, this);
                        var linkName = 'Target_' + linkNameParts.join('__');
                        this.$editFormNameInput.val(linkName);

                        var offerHashes = _.map(jumboLinkOffers, function(offer) {
                            return offer.offerIdDep;
                        }, this);
                        this.offerWhitelistInput.val(this.offerIdsToString(offerHashes));

                        this.$smartlinkFallbackCheck.prop("checked", false);

                        // show Offer Targeting section use existing handler for this
                        this.$editFormOfferSelectWrapper.find('.togglePanelBtn').click();
                    }

                    break;
                case "edit":
                case "copy":
                    if (!cid) {
                        console.error("no cid for that link?!");
                    } else {
                        var req = new ApiRequest(),
                            hash = cid;

                        if(type === 'edit') {
                            this._mode = this.MODE_EDIT;

                            this.$editLeagueSelectWrapper.hide();
                            this.$editFormCreateButton.hide();
                            this.$editFormSaveButton.show();
                            this.$editLeagueInfoWrapper.show();
                            this.$editFormHashInfoContainer.show();
                            this.$linkOutputWrapperOld.show();

                        } else {
                            this._mode = this.MODE_ADD;

                            this.$editLeagueSelectWrapper.show();
                            this.$editFormCreateButton.show();
                            this.$editFormSaveButton.hide();
                            this.$editLeagueInfoWrapper.hide();
                            this.$editFormHashInfoContainer.show();
                            this.$linkOutputWrapperOld.hide();

                        }

                        this.resetForm();

                        // Get the data
                        req
                            .request("data/partner-link.php?hash=" + hash)
                            .done(function(linkResult) {
                                var linkInfo = linkResult.main,
                                    nidInfo = registry.user.getInfoForNid(linkInfo.nid),
                                    allowAggressive = (linkInfo.allow_aggressive & 1) === 1,
                                    smartlinkFallback = (linkInfo.hard_offer_whitelist & 1) === 0,
                                    allowedVerticals = null,
                                    optionsData = null;



                                // Make sure we keep a reference to what we're editing for saving it.
                                self._currentlyEditing = linkResult;

                                // Populate it
                                self.$editFormHash.val(linkInfo.hash);
                                self.$editFormLeague.text(nidInfo.name);
                                self.$editFormNameInput.val(
                                    linkInfo.name === null
                                        ? self.$editFormNameInput.data("default")
                                        : linkInfo.name
                                );

                                if(type === 'copy') {
                                    self.$editFormNameInput.val(self.$editFormNameInput.val() + ' (copy)');
                                    self.$editFormNidSelect.val(linkInfo.nid);
                                }

                                self.$editFormVerticalSelect.val(linkInfo.vertical);
                                self.verticalChanged();

                                if (linkInfo.vertical === "vauto") {
                                    self.$vautoAdultSelect.val(linkInfo.adult);
                                    self.populateVautoVerticals();
                                    allowedVerticals = JSON.parse(
                                        linkInfo.allowed_verticals_data
                                    );
                                    self.vautoVerticalSelect.val(allowedVerticals);
                                }

                                if (nidInfo.network !== undefined && nidInfo.network !== null) {
                                    self.$editFormNetworkSelect.val(nidInfo.network);
                                    self.$editFormNetworkSelect.disable();
                                } else {
                                    self.$editFormNetworkSelect.enable();
                                    self.$editFormNetworkSelect.val("other");
                                }

                                self.$desktopRedirectEnabledSelect.val(
                                    linkResult.main.desktop_redirect
                                );

                                self.$postbackThresholdInput.val(
                                    linkResult.main.postback_threshold
                                );

                                if (
                                    linkResult.main.options_data !== undefined &&
                                    linkResult.main.options_data !== null
                                ) {
                                    optionsData = JSON.parse(linkResult.main.options_data);

                                    if (optionsData.vibrate !== undefined) {
                                        self.$vibrateEnabledSelect.val(optionsData.vibrate);
                                    }

                                    if (optionsData.exit_popup !== undefined) {
                                        self.$exitPopEnabledSelect.val(optionsData.exit_popup);
                                    }

                                    if (optionsData.entry_popup !== undefined) {
                                        self.$entryPopEnabledSelect.val(optionsData.entry_popup);
                                    }

                                    if (optionsData.sound !== undefined) {
                                        self.$soundEnabledSelect.val(optionsData.sound);
                                    }

                                    if (optionsData.redirect_time !== undefined) {
                                        self.$redirectTimeSelect.val(optionsData.redirect_time);
                                    }

                                    if (optionsData.rcid !== undefined) {
                                        self.$editFormRcidInput.val(optionsData.rcid);
                                    }

                                    if (optionsData.kw1 !== undefined) {
                                        self.$editFormKw1Input.val(optionsData.kw1);
                                    }

                                    if (optionsData.kw2 !== undefined) {
                                        self.$editFormKw2Input.val(optionsData.kw2);
                                    }

                                    if (optionsData.kw3 !== undefined) {
                                        self.$editFormKw3Input.val(optionsData.kw3);
                                    }

                                    if (optionsData.kw4 !== undefined) {
                                        self.$editFormKw4Input.val(optionsData.kw4);
                                    }

                                    if (optionsData.kw5 !== undefined) {
                                        self.$editFormKw5Input.val(optionsData.kw5);
                                    }

                                    if (optionsData.s4 !== undefined) {
                                        self.$editFormClickIdInput.val(optionsData.s4);
                                    }
                                } else {
                                    // This is an old link with no data so use the defaults
                                    self.changeNetworkSlugs();
                                }

                                if (linkInfo.offer_whitelist_data) {
                                    var whitelistedOfferIds = JSON.parse(linkInfo.offer_whitelist_data);
                                    self.offerWhitelistInput.val(self.offerIdsToString(whitelistedOfferIds));
                                }

                                self.$vautoAllowAggressiveCheck.prop(
                                    "checked",
                                    allowAggressive
                                );


                                self.$smartlinkFallbackCheck.prop(
                                    "checked",
                                    smartlinkFallback
                                );

                            })
                            .fail(function(xhr) {
                                console.error("Error fetching link", xhr.responseJSON);
                                alert(
                                    "There was an error fetching your link, please restart Monetizer and if the problem persists contact Support."
                                );
                            });
                    }

                    break;
            }

            this.on(
                "unsaved",
                function() {
                    if (!this.$unsavedWrapper.is(":visible")) {
                        this.$unsavedWrapper.fadeIn("fast");
                    }
                },
                this
            );
        },

        old_showEditLink: function(cid) {
            if (!cid) {
                console.error("no cid for that link?!");
            } else {
                this.showEditForm(cid);
            }
        },

        offerIdsToString: function(ids) {
            if (ids && _.isArray(ids)) {
                return ids.map(id => '#' + id).join(' ');
            } else {
                return '';
            }
        },

        offerIdsStringToArray: function(idsString) {
            if (typeof idsString === 'string' && idsString.length > 0) {
                return idsString.split(/\s+/).reduce((arr, part) => {
                    var matched = part.match('#?([a-f0-9]{6})');
                    if (matched && matched[1]) {
                        arr.push(matched[1])
                    }
                    return arr;
                }, []);
            } else {
                return [];
            }
        },

        clickEditBack: function() {
            $("body").removeClass("overflow-hidden");
            Backbone.history.navigate("links", { trigger: true });
        },

        clickShowLinks: function() {
            var link = this._currentlyEditing.main;
            this.showDirectLink(link);
        },

        showLinks: function(link) {
            this.showDirectLink(link, this.clickEditBack, this.clickEditBack);
        },

        verticalChanged: function() {
            var currentVertical = this.$editFormVerticalSelect.val();

            if (currentVertical === "vauto") {
                this.$vautoOptionsWrapper.show();
            } else {
                this.$vautoOptionsWrapper.hide();
            }
        },

        resetForm: function() {
            this.$vautoOptionsWrapper.hide();

            this.seenAdultWarning = false;

            this.$editFormLeague.text("");
            this.$editFormNameInput.val("");
            this.$editFormVerticalSelect.reset();

            this.populateNidSelect();
            this.changeNidSelect();

            this.vautoVerticalSelect.val([]);
            this.offerWhitelistInput.val('');

            this.$vautoAdultSelect.val("0");
            this.populateVautoVerticals();
            this.$vautoAllowAggressiveCheck.prop("checked", true);
            this.$smartlinkFallbackCheck.prop("checked", true);

            this.$redirectTimeSelect.val(this.DEFAULT_REDIRECT_TIME);
        },

        populateNidSelect: function() {
            var leagues = {},
                rNidInfo = null,
                filterMap = null,
                self = this;

            registry.user.hasPreferences.always(function() {
                filterMap = registry.user.getLeagueFilterMap();

                registry.user.nidAssignmentArr.forEach(function(nid) {
                    if (filterMap[nid] === undefined) {
                        rNidInfo = registry.user.getInfoForNid(nid);
                        leagues[nid] = rNidInfo.name;
                    }
                });

                self.$editFormNidSelect.updateData(leagues);
            });
        },

        changeNidSelect: function() {
            var nidSelected = this.$editFormNidSelect.val(),
                nidInfo = registry.user.getInfoForNid(nidSelected);

            if (
                nidInfo.network !== undefined &&
                nidInfo.network !== null &&
                networkData[nidInfo.network] !== undefined
            ) {
                this.$editFormNetworkSelect.disable();
            } else {
                this.$editFormNetworkSelect.enable();
            }

            this.$editFormNetworkSelect.val(nidInfo.network);
            if (this.$editFormNetworkSelect.val() !== nidInfo.network) {
                this.$editFormNetworkSelect.val("other");
            }

            this.changeNetworkSlugs();
        },

        populateVerticals: function(type) {
            var pureSelectData = _.clone(verticalData);

            _.each(pureSelectData, function(cat, index) {
                _.each(cat, function(vert, key) {
                    if (
                        !registry.user.hasVerticalAccess(key) ||
                        type === 'new' && (vert === "aggressive" || vert === "aggressive_desktop")
                    ) {
                        delete pureSelectData[index][key];
                    }
                });
            });

            pureSelectData.Automatic = {
                vauto: "vAuto (All)"
            };
            this.$editFormVerticalSelect.updateData(pureSelectData);
        },

        populateVautoVerticals: function() {
            var isAdult = this.$vautoAdultSelect.val() & 1,
                bigData = [];

            _.each(verticalData, function(group, groupName) {
                if (
                    (groupName === "mainstream" && isAdult) ||
                    (groupName === "adult" && !isAdult)
                ) {
                    return;
                }

                _.each(group, function(name, index) {
                    if (!staticVerticalData[index]) {
                        bigData.push({ text: name, value: index });
                    }
                });
            });

            this.vautoVerticalSelect.updateData([]);
            this.vautoVerticalSelect.val([]);
            this.vautoVerticalSelect.updateData(bigData);
        },

        createOptionObj: function(geo, meat) {
            var meatText = meat + " (" + geo + ")";
            return {
                value: geo + "_" + meat,
                text: meatText
            };
        },

        clickSaveEdit: function() {
            var self = this,
                vertical = this.$editFormVerticalSelect.val(),
                allowedVerticals = this.vautoVerticalSelect.val(),
                desktopRedirect = this.$desktopRedirectEnabledSelect.val(),
                postbackThreshold = this.$postbackThresholdInput.val(),
                name = this.$editFormNameInput.val().trim(),
                optionsBlob = this.buildOptionsBlob(),
                postData = {},
                req = new ApiRequest(),
                url = "data/partner-link.php";

            postData.disable_bbr = 0;

            postData.disable_popunder = 0;

            // Name Logic
            if (name === "") {
                name = "null";
            }

            // Whitelist Blob
            postData.options_data = JSON.stringify(optionsBlob);

            var offerWhitelist = this.offerIdsStringToArray(this.offerWhitelistInput.val());
            if (offerWhitelist.length > 0) {
                postData.offer_whitelist_data = JSON.stringify(offerWhitelist)
            }

            postData.vertical = vertical;

            if (vertical === "vauto") {
                if (allowedVerticals.length) {
                    postData.allowed_verticals_data = JSON.stringify(allowedVerticals);
                }
                postData.adult = this.$vautoAdultSelect.val();
                postData.allow_aggressive =
                    this.$vautoAllowAggressiveCheck.prop("checked") & 1;
            }

            // Normal Stuff
            postData.name = name;
            postData.desktop_redirect = desktopRedirect;
            postData.postback_threshold = postbackThreshold;
            postData.hash = this._currentlyEditing.main.hash;

            // Prevent wild Clicking
            this.$editFormSaveButton.text("Saving...").addClass("busy");

            if(this.$smartlinkFallbackCheck.is(':checked')) {
                postData.hard_offer_whitelist = 0;
            } else {
                postData.hard_offer_whitelist = 1;
            }


            // Send the request
            req
                .request(url, postData)
                .done(function() {
                    setTimeout(function() {
                        self.$editFormSaveButton.removeClass("busy").text("Saved!");

                        self.$unsavedWrapper.hide();
                    }, 1000);

                    setTimeout(function() {
                        self.$editFormSaveButton.text("Save Changes");
                    }, 3000);

                    if (self.linkRequiresReSubmission()) {
                        self.showLinks(postData);
                    }
                })
                .fail(function(xhr) {
                    console.error(xhr.responseJSON);
                    self.$editFormSaveButton.text("Failed!").removeClass("busy");

                    alert(xhr.responseJSON.message);
                });
        },

        clickCreate: function() {
            var self = this,
                vertical = this.$editFormVerticalSelect.val(),
                allowedVerticals = this.vautoVerticalSelect.val(),
                desktopRedirect = this.$desktopRedirectEnabledSelect.val(),
                postbackThreshold = this.$postbackThresholdInput.val(),
                name = this.$editFormNameInput.val().trim(),
                nid = this.$editFormNidSelect.val(),
                postData = {},
                optionsBlob = this.buildOptionsBlob(),
                req = new ApiRequest(),
                url = "data/partner-link.php";

            postData.disable_bbr = 0;

            postData.disable_popunder = 0;

            // Name Logic
            if (name === "") {
                name = "null";
            }

            // Whitelist Blob
            postData.options_data = JSON.stringify(optionsBlob);

            var offerWhitelist = this.offerIdsStringToArray(this.offerWhitelistInput.val());
            if (offerWhitelist.length > 0) {
                postData.offer_whitelist_data = JSON.stringify(offerWhitelist)
            }

            postData.vertical = vertical;

            if (vertical === "vauto") {
                if (allowedVerticals.length) {
                    postData.allowed_verticals_data = JSON.stringify(allowedVerticals);
                }
                postData.adult = this.$vautoAdultSelect.val();
                postData.allow_aggressive =
                    this.$vautoAllowAggressiveCheck.prop("checked") & 1;
            }

            // Normal Stuff
            postData.name = name;
            postData.nid = nid;
            postData.desktop_redirect = desktopRedirect;
            postData.postback_threshold = postbackThreshold;

            if(this.$smartlinkFallbackCheck.is(':checked')) {
                postData.hard_offer_whitelist = 0;
            } else {
                postData.hard_offer_whitelist = 1;
            }

            // Prevent wild Clicking
            this.$editFormCreateButton.text("Creating...").addClass("busy");

            // Send the request
            req
                .request(url, postData)
                .done(function(response) {
                    self.$editFormCreateButton.text("Created").removeClass("busy");

                    self.$unsavedWrapper.hide();

                    setTimeout(function() {
                        self.$editFormCreateButton.text("Create");
                    }, 3000);

                    // create link like object
                    var linkData = _.extend(postData, response);
                    self.showLinks(linkData);
                })
                .fail(function(xhr) {
                    console.error(xhr.responseJSON);
                    self.$editFormCreateButton.text("Failed").removeClass("busy");

                    alert(xhr.responseJSON.message);
                });
        },

        buildOptionsBlob: function() {
            var blobPayload = {};

            blobPayload.entry_popup = this.$entryPopEnabledSelect.val() | 0;
            blobPayload.exit_popup = this.$exitPopEnabledSelect.val() | 0;
            blobPayload.vibrate = this.$vibrateEnabledSelect.val() | 0;
            blobPayload.sound = this.$soundEnabledSelect.val() | 0;
            blobPayload.redirect_time = this.$redirectTimeSelect.val() | 0;
            blobPayload.rcid = this.$editFormRcidInput.val();
            blobPayload.kw1 = this.$editFormKw1Input.val();
            blobPayload.kw2 = this.$editFormKw2Input.val();
            blobPayload.kw3 = this.$editFormKw3Input.val();
            blobPayload.kw4 = this.$editFormKw4Input.val();
            blobPayload.kw5 = this.$editFormKw5Input.val();
            blobPayload.s4 = this.$editFormClickIdInput.val();

            return blobPayload;
        },

        getDomains: function () {
            var dfd = $.Deferred(),
                self = this,
                req = new ApiRequest();

            if (this.domains && this.domains.length > 0) {
                dfd.resolve(this.domains);
            } else {
                req
                    .request("data/domains.php", { action: "list" })
                    .done(function(data) {
                        self.domains = data.domains || [];
                        dfd.resolve(self.domains);
                    })
                    .fail(function(err) {
                        console.error("FAILED TO FETCH PARTNER DOMAINS", err.responseJSON);
                        dfd.resolve([]);
                    });
            }

            return dfd.promise();
        },

        changeNetworkSlugs: function() {
            var curVal = this.$editFormNetworkSelect.val(),
                networkInfo = null,
                rcidVal = null,
                clickIDVal = null,
                kw1Val = null,
                kw2Val = null,
                kw3Val = null,
                kw4Val = null,
                kw5Val = null;

            if (networkData[curVal] === undefined) {
                console.warn("Unsupported Network Value");
            } else {
                networkInfo = networkData[curVal];
                rcidVal = networkInfo.rcidArg !== undefined ? networkInfo.rcidArg : "";
                clickIDVal =
                    networkInfo.convTrackingArg !== undefined
                        ? networkInfo.convTrackingArg
                        : "";
                kw1Val = networkInfo.sidArg !== undefined ? networkInfo.sidArg : "";
                kw2Val = networkInfo.catArg !== undefined ? networkInfo.catArg : "";
                kw3Val = networkInfo.tsArg !== undefined ? networkInfo.tsArg : "";
                kw4Val = networkInfo.kw4Arg !== undefined ? networkInfo.kw4Arg : "";
                kw5Val = networkInfo.kw5Arg !== undefined ? networkInfo.kw5Arg : "";
            }

            this.$editFormRcidInput.val(rcidVal);
            this.$editFormKw1Input.val(kw1Val);
            this.$editFormKw2Input.val(kw2Val);
            this.$editFormKw3Input.val(kw3Val);
            this.$editFormKw4Input.val(kw4Val);
            this.$editFormKw5Input.val(kw5Val);
            this.$editFormClickIdInput.val(clickIDVal);

            return true;
        },

        encodeSpaces: function(text) {
            return text.replace(/ /g, "%20");
        },

        linkRequiresReSubmission: function() {
            var optionsData,
                requiresUpdate = false;

            if (this._mode !== this.MODE_EDIT) {
                console.warn(
                    "Trying to check if a link needs re-submitting in create mode."
                );
            } else if (
                this._currentlyEditing.main.options_data !== undefined &&
                this._currentlyEditing.main.options_data !== null
            ) {
                optionsData = JSON.parse(this._currentlyEditing.main.options_data);

                if (this.$editFormRcidInput.val() !== optionsData.rcid) {
                    requiresUpdate = true;
                }

                if (this.$editFormKw1Input.val() !== optionsData.kw1) {
                    requiresUpdate = true;
                }

                if (this.$editFormKw2Input.val() !== optionsData.kw2) {
                    requiresUpdate = true;
                }

                if (this.$editFormKw3Input.val() !== optionsData.kw3) {
                    requiresUpdate = true;
                }

                if (this.$editFormKw4Input.val() !== optionsData.kw4) {
                    requiresUpdate = true;
                }

                if (this.$editFormKw5Input.val() !== optionsData.kw5) {
                    requiresUpdate = true;
                }

                if (this.$editFormClickIdInput.val() !== optionsData.s4) {
                    requiresUpdate = true;
                }
            } else {
                console.log(
                    "Can't check for re-submission on a link that doesnt have options data"
                );
            }

            return requiresUpdate;
        },

        triggerUnsaved: function() {
            this.trigger("unsaved");
        },

        clickFilterLink: function(e) {
            var self = this,
                $target = $(e.currentTarget),
                req = new ApiRequest(),
                filtered = 0;

            e.stopPropagation();

            if ($target.data("filtered") === 1) {
                filtered = 0;
            } else {
                filtered = 1;
            }

            req
                .request("data/partner-link.php", {
                    hash: $target.data("cid"),
                    is_filtered: filtered
                })
                .done(function() {
                    self.getLinks();
                })
                .fail(function(err) {
                    console.error(err.responseJSON);
                });
        },

        toggleActive: function(event) {
            if (event.target.tagName === "H2") {
                $(event.currentTarget).toggleClass("active");
            }
        },

        selectInput: function(event) {
            event.target.select();
        },

        _copyLink: function (ev) {
            var self = this,
                $link = $(ev.currentTarget),
                srcHash =  $link.data('cid'),
                postData = {'hash':srcHash,'copy':true},
                req = new ApiRequest(),
                url = "data/partner-link.php";

            req
                .request(url, postData)
                .done(function() {
                    document.body.scrollTop = 0; // For Safari
                    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
                    self._onPage = 1;
                    self.getLinks();
                })
                .fail(function(xhr) {
                    console.error(xhr.responseJSON);

                    alert(xhr.responseJSON.message);
                });


        },

        routeLink: function(event) {
            var route = $(event.currentTarget).data("route");
            Backbone.history.navigate(route, { trigger: true });
        },

        _togglePanel: function(ev) {
            var $btn = $(ev.currentTarget),
                panelId = $btn.data('panel'),
                $panelContent = this.$el.find('#'+panelId+' > .panel-content');

            if($panelContent.is(':visible')) {
                $btn.find('.fa').removeClass('fa-minus').addClass('fa-plus');

            } else {
                $btn.find('.fa').removeClass('fa-plus').addClass('fa-minus');

            }

            $panelContent.slideToggle('fast');


        },

        getDirectLink: function (ev) {
            var hash = $(ev.currentTarget).data('cid');
            var link = _.findWhere(this._linkList, {hash: hash});
            this.showDirectLink(link);
        },

        showDirectLink: function (link, onCloseCallback, noDomainsCallback) {
            if (!link) {
                return;
            }
            var self = this;
            this.getDomains()
                .then(function (domains) {
                    var sslDomains = _.where(domains, {ssl_enabled: '1'});
                    if (sslDomains && sslDomains.length > 0) {
                        var directLinkView = new DirectLinkView({parent: self, link: link, domains: sslDomains});
                        if (onCloseCallback) {
                            self.listenToOnce(directLinkView, 'close', onCloseCallback);
                        }
                        directLinkView.render();
                    } else {
                        if (noDomainsCallback) {
                            noDomainsCallback.call(self);
                        } else {
                            alert(
                                "Please add a domain name to your " +
                                "account and wait until SSL cert generated before generating a direct link"
                            );
                            Backbone.history.navigate("domains", { trigger: true });
                        }
                    }
                });
        },

        sleep: function() {},

        wakeUp: function() {}
    });

    return new LinkManagementView();
});
