define([
    "Backbone",
    "workers/apirequest",
], function(
    Backbone,
    ApiRequest,
) {
    return Backbone.View.extend({
        _subViews: [],
        id: 'tableView',
        collectionName: 'data',
        template: '',
        rowTemplate: '',
        dataEndpointUrl: '',
        filtration: 'local',
        sorting: 'local',
        pagination: 'local',

        events: {
            'change .filterInput': 'filterChanged',
            'click .applyFiltersBtn': 'applyFilters',
            'click .resetFiltersBtn': 'resetFilters',
            'click th.sort': 'sortChanged',
            'click .pageBtn': 'pageChanged',
        },

        initialize: function() {
            this[this.collectionName] = [];
            this.page = 1;
            this.perPage = 25;
            this.filters = {};
            this.sortBy = undefined;
            this.sortDirection = 1;
        },

        wakeUp: function() {
            this.off();
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", this.id);
            this.$el.append(this.template);

            // setup element references
            this.$prevBtns = this.$el.find('.pageBtn[data-id="prev"]');
            this.$nextBtns = this.$el.find('.pageBtn[data-id="next"]');
            this.$pageNumbers = this.$el.find('.paginationPageNumber');
            this.$tableBody = this.$el.find('.tableBody');
            this.$dataLoadingMsg = this.$el.find('.dataLoadingMsg');
            this.$noDataMsg = this.$el.find('.noDataMsg');

            this.initFilters();

            this.renderSubViews();

            $("#appContainer").append(this.$el);

            // load data and render rows
            this.loadData();
        },

        initFilters: function () {
            for (const [field, value] of Object.entries(this.filters)) {
                this.$el.find(`.filterInput[data-field=${field}]`).val(value);
            }
        },

        renderSubViews: function () {
        },

        loadData: function () {
            const self = this;
            const api = new ApiRequest();
            const params = this.getLoadDataParams();
            const url = params ? this.dataEndpointUrl + '?' + $.param(params) : this.dataEndpointUrl;

            this.$dataLoadingMsg.show();
            api.request(url)
                .then(function (response) {
                    self[self.collectionName] = _.map(response, self.castDataRecord.bind(self));
                    self.onDataStored();
                    self.filterData();
                    self.sortData();
                    self.paginate();
                    self.renderRows();
                })
                .always(function () {
                    self.$dataLoadingMsg.hide();
                });
        },

        getLoadDataParams: function () {
            return null;
        },

        castDataRecord: function (record) {
            return record;
        },

        onDataStored: function () {
        },

        filterData: function () {
            if (this.filtration === 'local' && Object.keys(this.filters).length > 0) {
                this.filteredData = this[this.collectionName].filter((record) => {
                    for (const [field, value] of Object.entries(this.filters)) {
                        if (
                            record[field] &&
                            (_.isNumber(record[field]) && record[field] !== +value) ||
                            (typeof record[field] === 'string' && !record[field].includes(value))
                        ) {
                            return false
                        }
                    }
                    return true;
                });
            } else {
                this.filteredData = this[this.collectionName];
            }
        },

        sortData: function () {
            if (this.sorting !== 'local' || !this.sortBy || !this.sortDirection) {
                return;
            }

            const field = this.sortBy;
            const dir = this.sortDirection;
            this.filteredData = this.filteredData.sort((a, b) => {
                if (a[field] < b[field]) {
                    return dir;
                }

                if (a[field] > b[field]) {
                    return -dir;
                }

                return 0;
            });
        },

        paginate: function () {
            if (this.pagination === 'local') {
                const start = (this.page - 1) * this.perPage;
                const end = start + this.perPage;
                this.pageData = this.filteredData.slice(start, end);
                this.pagesCount = Math.ceil(this.filteredData.length / this.perPage);
            } else {
                this.pageData = this.filteredData;
            }
            this.updatePagination();
        },

        updatePagination: function(ev) {
            if (this.page <= 1) {
                this.$prevBtns.prop('disabled', true);
            } else {
                this.$prevBtns.prop('disabled', false);
            }
            if ((this.pagesCount && this.page === this.pagesCount) ||
                (this.pageData && this.pageData.length < this.perPage)) {
                this.$nextBtns.prop('disabled', true);
            } else {
                this.$nextBtns.prop('disabled', false);
            }
            this.$pageNumbers.text('Page ' + this.page);
        },

        renderRows: function() {
            this.$tableBody.empty();
            this.$noDataMsg.hide();
            if (this.pageData && this.pageData.length > 0) {
                _.each(this.pageData, function (row) {
                    this.$tableBody.append(this.rowTemplate(row))
                }, this);
            } else {
                this.$noDataMsg.show();
            }
        },

        filterChanged: function (ev) {
            const $filterInput = $(ev.currentTarget);
            const field = $filterInput.data('field');
            const value = $filterInput.val();
            if (value) {
                this.filters[field] = $filterInput.val();
            } else {
                delete this.filters[field];
            }
            this.page = 1;

            this.applyFilter();
        },

        applyFilter: function () {
            if (this.filtration === 'local') {
                this.filterData();
                this.sortData();
                this.paginate();
                this.renderRows();
            } else {
                this.loadData();
            }
        },

        resetFilters: function (ev) {
            this.$el.find(`.filterInput[data-field]`).val('');
            this.filters = {};
            this.page = 1;
            this.applyFilter();
        },

        sortChanged: function (ev) {
            const field = $(ev.currentTarget).data('field');
            if (this.sortBy === field) {
                this.sortDirection = -this.sortDirection;
            }
            this.sortBy = field;
            this.page = 1;

            if (this.sorting === 'local') {
                this.sortData();
                this.paginate();
                this.renderRows();
            } else {
                this.loadData();
            }
        },

        pageChanged: function(ev) {
            var $btn = $(ev.currentTarget);
            var id = $btn.data('id');
            if (id === 'next') {
                this.page += 1;
            } else if (id === 'prev' && this.page > 1) {
                this.page -= 1
            }

            if (this.pagination === 'local') {
                this.paginate()
                this.renderRows();
            } else {
                this.loadData();
            }
        },

        sleep: function() {
            // clean up
        },
    });
});
