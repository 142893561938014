/*jslint nomen:true, indent:4*/
/*global define, console, _, confirm, alert */


define([
	"Backbone",
	"registry",
	"workers/apirequest",
	"templates/domains.html",
	"templates/domainsEmpty.html",
	"templates/domainsRowTemplate.html"
], function (Backbone, registry, ApiRequest, mainTemplate, noDataTemplate, rowTemplate) {

	var DomainsView = Backbone.View.extend({
		// testing deployment timing.
		// Constants

		// Properties
		hasInitialData: null,
		isAsleep: true,

		// Events
		events: {
			"click .domainDelete": "clickDelete",
			"click #doAddDomainButton": "doAdd",
			"focus .domainInputWrapper > input": "focusInput",
			"blur .domainInputWrapper > input": "blurInput",
            "click .routeLink": "routeLink",
			"change input[name='preferredDomain']": "preferredDomainChanged"
		},


		// Methods
		initialize: function () {

		},

		getData: function() {
			var self = this,
				dfd = $.Deferred(),
				domainReq = new ApiRequest();

			domainReq.request("data/domains.php", {action: "list"})
			.done(function (data) {
				if (data.success !== undefined) {
					self.data = data.domains;
				}
				else {
					self.data = [];
					console.error("Error getting domain list for partner");
				}

                self.checkDomains();

				self.trigger("has:data");
			});

			dfd.resolve();
			return dfd.promise;
		},

		render: function () {
			var self = this;


            this.setElement($(document.createElement('div')));
			this.$el.addClass("settingsPane")
                .addClass("mainPane")
                .attr('id', 'domainsContainer');
            this.$el.append(_.template(mainTemplate)());

            $('#appContainer').append(this.$el);

            this.$domainListContainer = this.$el.find("#domainsListContainer");
            this.$domainList = this.$el.find("#domainsList tbody");


            this.$errorContainer = this.$el.find(".addErrorMsg");
            this.$domainInput = this.$el.find("#domainInput");
            this.$subdomainInput = this.$el.find("#subdomainInput");
            this.$newDomainModal = this.$el.find("#newDomainModal");

            this.alertedDomains = {};
			this.hasInitialData = this.getData();
            this.on("has:data", this.update, this);

			if (registry.user && registry.user.allowDomains === 0) {
				this.$el.find('.allowDomains').hide();
				this.$el.find('.domainsManagingDisabledWarning').show();
			} else {
				this.$el.find('.allowDomains').show();
				this.$el.find('.domainsManagingDisabledWarning').hide();
			}

			return this.$el;
		},

		update: function () {
			var self = this;
			this.$domainList.empty();

			if (_.size(this.data) > 0) {
				_.each(this.data, function (row) {
					self.$domainList.append(_.template(rowTemplate)({
						domain: row.domain,
						ip: row.ip,
						flagged: parseInt(row.flagged),
						ssl_enabled: parseInt(row.ssl_enabled),
						extraClasses: parseInt(row.flagged) ? ' flagged' : '',
						preferred: parseInt(row.preferred)
					}));
				});
			} else {
				this.$domainList.html(noDataTemplate);
			}
		},

		showForm: function() {

            this.setElement($(document.createElement('div')));
			this.$el.addClass("settingsPane")
                .addClass("mainPane")
                .attr('id', 'domainsContainer');
            this.$el.append(_.template(newTemplate)());

            $('#appContainer').append(this.$el);
		},

		hideForm: function () {
			this.$formContainer.hide();
			this.$domainListContainer.show();
		},

		clickDelete: function(e) {
			var self = this,
				$el = $(e.currentTarget),
				req = new ApiRequest(),
				theDomain = null;

			theDomain = $el.data("cid");

			if (confirm("Are you sure you want to remove this domain? Make sure you have no active links using it.")) {
				req.request("data/domains.php", {action: "del", domain: theDomain})
				.done(function (response) {
					if (response.success !== undefined && response.success === true) {
						self.getData();
					} else {
						console.log(response);
						alert("There was an error when deleting the domain, please refresh and try again.");
					}
				})
				.fail(function (response) {
					var trueResponse = response.responseJSON;
					if (trueResponse.message !== undefined) {
						alert("Sorry, " + trueResponse.message);
					}
					console.error("Error deleting domain", trueResponse);
				});
			}
		},


		doAdd: function() {
			var self = this,
				theDomain = null,
				isError = false,
				addReq = new ApiRequest();

			if (this.$subdomainInput.val() === this.$subdomainInput.data("default") ||
				this.$subdomainInput.val().trim() === "")
			{
				isError = true;
				this.$subdomainInput.addClass("error");
			}

			if (this.$domainInput.val() === this.$domainInput.data("default") ||
				this.$domainInput.val() === "")
			{
				isError = true;
				this.$domainInput.addClass("error");
			}

			if (isError) {
				return false;
			} else {
				this.$errorContainer.hide();
				this.$domainInput.removeClass("error");
				this.$subdomainInput.removeClass("error");
			}


			theDomain = this.$subdomainInput.val() + '.' + this.$domainInput.val();

			addReq.request("data/domains.php", {action: "add", domain: theDomain})
			.done(function () {
				self.getData();
                self.$newDomainModal.fadeOut('fast');
                $('html, body').removeClass('overflow-hidden');
			})
			.fail(function (response) {
				self.$domainInput.addClass("error");
				self.$subdomainInput.addClass("error");
				self.$errorContainer.find('.panel-content').text(response.responseJSON.message);
				self.$errorContainer.show();
			});
		},

        checkDomains: function () {
            var self = this;

            _.each(this.data, function (row) {
                if (!parseInt(row.flagged)) {
                    return;
                }
                if (self.alertedDomains[row.domain]) {
                    return;
                }

                alert("Your domain " + row.domain +
                      " is reported as phishing/malware" +
                      " by Google Safe Browsing service.\n\n" +
                      "Please remove " + row.domain + " from our system" +
                      " (don't forget to remove A record!)" +
                      " and replace it with a clean one.\n\n" +
                      "This flag may be induced by receiving traffic" +
                      " from bad traffic sources.\n" +
                      "If you recently started getting traffic from a" +
                      " new traffic source, consider stopping using that" +
                      " source, otherwise new domain may be flagged again.");

                self.alertedDomains[row.domain] = 1;
            });
        },

		focusInput: function(e) {
			var $target = $(e.currentTarget);
			if ($target.val() === $target.data("default")) {
				$target.val("");
			}
		},

		blurInput: function(e) {
			var $target = $(e.currentTarget);
			if ($target.val() === "") {
				$target.val($target.data("default"));
			}
		},




		sleep: function () {
			this.isAsleep = true;
		},

		wakeUp: function() {
			this.isAsleep = false;
		},

        routeLink: function (event) {
            var route = $(event.currentTarget).data("route");
            Backbone.history.navigate(route, {trigger: true});

        },

		preferredDomainChanged: function (ev) {
			const preferredDomain = ev.currentTarget.value;
			const apiRequest = new ApiRequest();
			apiRequest.request("data/domains.php", {action: "preferred", domain: preferredDomain})
				.done((response) => {
					// ignore
				});
		}
	});

	return new DomainsView();
});
