define([
    "Backbone",
    "workers/apirequest",
    "templates/apps/linkForm.html",
    "views/apps/appDetails"
], function (
    Backbone,
    Api,
    template,
    AppDetailsView
) {
    return Backbone.View.extend({
        events: {
            'click .view-close': 'destroy',
            'click .saveLinkBtn': 'save',
            "click .clickMacro": "clickMacro",
            "change #appLinkPackageIdInput": "packageChanged",
        },

        initialize: function(inputs) {
            this.parent = inputs.parent;
            this.appDetailsView = null;
            if (inputs && inputs.link) {
                this.link = inputs.link;
                this.isEditMode = true;
            } else {
                this.link = {};
                this.isEditMode = false;
            }
            this.apps = [];
            // render immediately without waiting of loaded data
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "appLinkForm");
            this.$el.append(template);
            if (this.isEditMode) {
                this.$el.find('#headerTitleType').text('Edit')
            }
            $("#appContainer").append(this.$el);
            this.initInputs();
            this.$el.find('.overlay').fadeIn('fast');
        },

        initInputs: function() {
            const self = this;

            this.$nameInput = this.$el.find('#appLinkNameInput');
            if (this.link.name) {
                this.$nameInput.val(this.link.name);
            }

            this.$postbackUrlInput = this.$el.find('#appLinkPostbackUrlInput');
            if (this.link.postback_url) {
                this.$postbackUrlInput.val(this.link.postback_url);
            }

            this.$packageIdInput = this.$el.find('#appLinkPackageIdInput');
            this.parent.getApps().then(function(apps) {
                self.populatePackageIdInput(apps);
                if (self.link.package_id) {
                    self.$packageIdInput.val(self.link.package_id);
                    self.packageChanged();
                }
            });
        },

        populatePackageIdInput: function(apps) {
            if (!apps) {
                return;
            }
            _.each(apps, function(app, id) {
                this.$packageIdInput.append($("<option>", {value: id, text: id}));
            }, this);
        },

        packageChanged: function(e) {
            const self = this;
            const packageId = this.$packageIdInput.val();
            this.parent.getApps().then(function(apps) {
                self.removeSubviews();
                self.appDetailsView = new AppDetailsView({app: apps[packageId]});
                self.$el.find('.appLinkPackageInfo').html(self.appDetailsView.render().el);
            });
        },

        removeSubviews: function() {
            if (this.appDetailsView) {
                this.appDetailsView.remove();
                this.appDetailsView = null;
            }
        },

        destroy: function() {
            this.removeSubviews();
            this.$el.find('.overlay').fadeOut('fast');
            this.remove();
        },

        save: function(ev) {
            const self = this;
            const api = new Api();
            const $btn = $(ev.currentTarget);
            const data = this.getFormData();

            if (this.isEditMode) {
                data.action = 'update';
                data.id = this.link.id;
            } else {
                data.action = 'create';
            }

            $btn.prop('disabled', true);
            api.request('v3/publisherAppsLinks.php', data)
                .then(function(response) {
                    if (response) {
                        if (self.isEditMode) {
                            self.parent.trigger('appLink:update', response);
                        } else {
                            self.parent.trigger('appLink:new', response);
                        }
                    }
                    self.destroy();
                })
                .fail(function (error) {
                    if (error.responseJSON && error.responseJSON.message) {
                        alert(error.responseJSON.message);
                    } else {
                        alert('Server error');
                    }
                })
                .always(function() {
                    $btn.prop('disabled', false);
                });
        },

        getFormData: function() {
            const data = {};

            data.name = this.$nameInput.val();
            data.postback_url = this.$postbackUrlInput.val();
            data.package_id = this.$packageIdInput.val();

            return data;
        },

        clickMacro: function(e) {
            const caretPos = this.$postbackUrlInput[0].selectionStart,
                value = this.$postbackUrlInput.val(),
                macro = $(e.currentTarget).text();
            this.$postbackUrlInput.val(value.substring(0, caretPos) + macro + value.substring(caretPos));
        },
    });
});

