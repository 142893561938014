define(['Backbone', 'chart.js'], function (Backbone, Chart) {
	
	return Backbone.View.extend({
	
		// Constants
		CHART_WIDTH: 100,
		CHART_HEIGHT: 100,
	
		// Properties
		$chartContainer: null,
		$chart: null,
		$widthCalculated: 0,
		$heightCalculated: 0,

		type: null,
		context: null,
		chart: null,

		
		// Events
		events: {
	
		},
	
		
		// Methods
		initialize: function (options) {
			var self = this;

			this.setElement($(document.createElement("div")).addClass("graphContainer"));
			this.$chartContainer = $(document.createElement("div")).addClass("chartWrapper").appendTo(this.$el);

			this.$containerWidth = this.$chartContainer.width();
            this.$containerHeight = this.$chartContainer.height();

			this.$chart = $(document.createElement("canvas"));
			this.$chart.width(this.$containerWidth);
            this.$chart.height(this.$containerHeight);

            this.$chartContainer.append(this.$chart);

			this.context = this.$chart.get(0).getContext("2d");

			// Chart type
			if (options === undefined || options.chartType === undefined || options.chartType === "bar") {
				this.type = "bar";
			} else if (options.chartType === "line") {
				this.type = "line";
			} else if (options.chartType === "linesets") {
			    //todo: doubt this is needed
				this.type = "LineSets";
			}

			return this.$el;
		},
	
		render: function (initialLabels, initialData) {

			if (this.type === "bar") {
				this.renderBar(initialLabels, initialData);
			} else if (this.type === "line") {
				this.renderLine(initialLabels, initialData);
			} else if (this.type === "LineSets") {
			    //todo not sure this is needed anymore
				this.renderLineWithDatasets(initialLabels, initialData);
			}
		},



		renderBar: function(initialLabels, initialData) {
			var chartOptions = {
                responsive: true,
                defaultFontFamily: "arial, sans-serif",
                defaultFontSize: 10,
                gridLines: {
                    color: "#e1e1e1"
                },
                animation: false,
                legend: {
                    display: false
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                }

			};


			if (this.chart !== null) {
			    this.chart.data.labels = initialLabels;
                this.chart.data.datasets[0].data = initialData;
                this.chart.update();
			} else {
                this.chart = new Chart(this.context,{
                    type: 'bar',
                    data: {
                        labels: initialLabels,
                        datasets: [{
                            backgroundColor: "rgba(51, 204, 102, 1)",
                            borderColor: "rgba(51, 204, 102, 1)",
                            hoverBackgroundColor: "rgba(51, 204, 102, 1)",
                            hoverBorderColor: "rgba(51, 204, 102, 1)",
                            data: initialData
                        }]
                    },
                    options: chartOptions
                });

            }

		},


		renderLine: function(initialLabels, initialData) {
			var chartOptions = {
                responsive: true,
				 scaleFontFamily: "arial, sans-serif",
				 tooltipFontFamily:"arial, sans-serif",
				 scaleFontSize: 10,
				 tooltipFontSize: 10,
				 scaleLineColor: "#e1e1e1",
				 scaleShowGridLines : true,
				 scaleGridLineWidth : 1,
				 bezierCurve : false,
				 bezierCurveTension : 0.4,
				 pointDot : true,
				 pointDotRadius : 7,
				 pointDotStrokeWidth : 3,
				 pointHitDetectionRadius : 20,
				 datasetStroke : true,
				 datasetStrokeWidth : 4,
				 datasetFill : false,
				 animation: false


			};

			if (this.chart !== null) {
				this.chart.clear();
				this.chart.destroy();
				this.chart = null;
			}
			
			this.$chart
				.attr("width", this.$widthCalculated)
				.attr("height", this.$heightCalculated);

			this.chart = new Chart(this.context).Line({
				labels: initialLabels,
				datasets: [{
					label: "Some Title",
					fillColor: "#FFFFFF",
            		strokeColor: "#32ad38",
            		pointColor: "#ffffff",
            		pointStrokeColor: "#32ad38",
            		pointHighlightFill: "#ffffff",
            		pointHighlightStroke: "#32ad38",
					data: initialData
				}]
			}, chartOptions);
		},

		renderLineWithDatasets: function (initialLabels, datasets) {
			var chartOptions = {
                responsive: true,
                defaultFontFamily: "arial, sans-serif",
                defaultFontSize: 10,
                gridLines: {
                    color: "#e1e1e1",
                },
                scales: {
                    yAxes: [{
                        ticks: {
                            beginAtZero: true
                        }
                    }]
                },
                elements: {
                    line: {
                        tension: 0 // disables bezier curves
                    }
                },
                scaleBeginAtZero : true,
			};


            if (this.chart !== null) {

                this.chart.data.labels = initialLabels;
                this.chart.data.datasets = datasets;
                this.chart.update();
			} else {
                this.chart = new Chart(this.context,{
                    type: 'line',
                    data: {
                        labels: initialLabels,
                        datasets: datasets

                    },
                    options: chartOptions

                });

            }


		},

		clear: function() {
			if (this.chart !== null) {
				this.chart.clear();
				this.chart.destroy();	
			}
		}
		
		
		

	});



});

