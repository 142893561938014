define([
    'Backbone',
    'registry',
    'workers/apirequest',
    'dict/isocountries.json',
    'views/pureSelect',
    'templates/profile.html',
    'views/profilePreview'
], function (
    Backbone,
    registry,
    ApiRequest,
    isoCountries,
    PureSelect,
    template,
    ProfilePreviewView
) {
    return Backbone.View.extend({
        events: {
            'click .saveProfileDetailsButton': 'saveWithPreview',
            'click .changeEmailBtn': 'showChangeEmailModal',
            'click .changeEmailSaveBtn': 'saveNewEmail',
            'click .changeEmailConfirmBtn': 'confirmEmailChange',
            'click .showPendingDetailsBtn': 'showPendingDetails',
            'click .showRejectedDetailsBtn': 'showRejectedDetails'
        },

        initialize: function () {
            this._subViews = [];
            this.countryByCode = isoCountries.reduce((acc, r) => {
                acc[r.alpha_2_code] = r.country;
                return acc;
            }, {});
            this.user = null;
            this.lastProfileUpdate = null;
            this.api = new ApiRequest();
        },

        render: function () {
            this.$el.html(template);

            this.$email = this.$el.find('.email');
            this.$changeEmailModal = this.$el.find('.changeEmailModal');
            this.$newAccountEmailInput = this.$changeEmailModal.find('.newAccountEmailInput');
            this.$verificationCodeInput = this.$changeEmailModal.find('.verificationCodeInput');

            this.profileFieldInputs = {};
            this.$el.find('.profileFieldInput').each((i, el) => this.profileFieldInputs[el.name] = $(el));

            this.$addressCountryInputHolder = this.$el.find('#addressCountryInputHolder');
            this.profileFieldInputs['address_country'] = new PureSelect()
                .attr("id", "addressCountryInput")
                .addClass("white")
                .updateData(this.countryByCode)
                .appendTo(this.$addressCountryInputHolder);
            this._subViews.push(this.profileFieldInputs['address_country']);

            this.on('post-render', this.postRender, this);
            $.when(
                registry.ready,
                this.api.request("data/partner.php?action=getLastProfileDetailsUpdate")
            ).then((_, lastProfileUpdateResponse) => {
                this.lastProfileUpdate = lastProfileUpdateResponse[0];
                this.user = registry.user;
                this.trigger("post-render");
            });

            return this;
        },

        postRender: function () {
            this.populateFieldsInputs(this.user);
            this.renderLockedAlert();
            this.renderNotApprovedAlert();
            if (this.isProfileLocked()) {
                this.lockProfileFields();
            }
        },

        populateFieldsInputs: function (profile) {
            this.$email.text(profile.email);
            for (const [field, input] of Object.entries(this.profileFieldInputs)) {
                input.val(profile[field]);
            }
        },

        renderLockedAlert: function () {
            if (this.isProfileLocked()) {
                this.$el.find('.lockedAlert').show();
            } else {
                this.$el.find('.lockedAlert').hide();
            }
        },

        isProfileLocked: function () {
            return this.user.profileComplete && !this.user.unlockProfile;
        },

        renderNotApprovedAlert: function () {
            if (this.lastProfileUpdate && +this.lastProfileUpdate.status === 2) {
                this.$el.find('.pendingAlert').show();
                this.$el.find('.rejectAlert').hide();
            } else if (this.lastProfileUpdate && +this.lastProfileUpdate.status === 0) {
                this.$el.find('.pendingAlert').hide();
                this.$el.find('.rejectAlert').show();
                this.$el.find('#profileRejectionReason').text(this.lastProfileUpdate.rejection_reason || '');
            } else {
                this.$el.find('.pendingAlert').hide();
                this.$el.find('.rejectAlert').hide();
            }
        },

        lockProfileFields: function () {
            this.$el.find('.profileFieldInput').prop('disabled', true);
            this.profileFieldInputs['address_country'].disable();
            this.$el.find('.saveProfileDetailsButton').hide();
        },

        saveWithPreview: function (ev) {
            const $btn = $(ev.currentTarget);
            const btnText = $btn.text();
            if (!this.validateInputs()) {
                $btn.text('Failed');
                window.setTimeout(() => $btn.text(btnText), 2000);
                return;
            }

            const profileDetails = this.getProfileFieldInputValues();
            const profileDetailsView = new ProfilePreviewView(profileDetails);
            this.listenToOnce(profileDetailsView, 'profilePreview:confirmed', (confirmed) => {
                if (confirmed) {
                    this.saveProfile(ev);
                }
            });
        },

        getProfileFieldInputValues: function () {
            const result = {};
            for (const [field, $input] of Object.entries(this.profileFieldInputs)) {
                result[field] = $input.val();
            }
            return result;
        },

        saveProfile: function (ev) {
            const $btn = $(ev.currentTarget);
            const btnText = $btn.text();

            const self = this;
            const req = new ApiRequest();
            const url = "data/partner.php";
            const profileDetails = this.getProfileFieldInputValues();
            const params = {
                action: 'changeDetails',
                ...profileDetails
            };

            $btn.text("Saving...").prop('disabled', true);
            $.when(req.request(url, params)).done(function (result) {
                if (result.status === 'success') {
                    registry.user.tel = profileDetails.tel;
                    registry.user.profileComplete = true;
                    $btn.text('Saved');
                    self.lastProfileUpdate = profileDetails;
                    self.lastProfileUpdate.status = 2;
                    self.renderNotApprovedAlert();
                }
            }).fail(function (err) {
                console.error(err.responseJSON);
                if (err.responseJSON && err.responseJSON.message) {
                    alert(err.responseJSON.message);
                }
                $btn.text('Failed');
            }).always(function () {
                $btn.prop('disabled', false);
                window.setTimeout(() => $btn.text(btnText), 2000);
            });
        },

        validateInputs: function () {
            let valid = true;
            const requiredFields = ['name', 'tel', 'address_1', 'address_zip', 'address_country'];
            for (const field of requiredFields) {
                const $input = this.profileFieldInputs[field];
                const inputValid = this.validateInput($input, input => input.val() !== '');
                valid = valid && inputValid;
            }
            return valid;
        },

        validateInput: function ($input, condition) {
            const valid = condition($input);
            this.showHideInputError($input, valid)
            return valid;
        },

        showHideInputError: function ($input, valid) {
            if (valid) {
                $input.removeClass('input-error');
            } else {
                $input.addClass('input-error');
            }
        },

        showChangeEmailModal: function (ev) {
            this.$newAccountEmailInput.val('');
            this.$verificationCodeInput.val('');
            this.$changeEmailModal.find('.step-2').hide();
            this.$changeEmailModal.find('.step-1').show();
            this.$changeEmailModal.show();
        },

        saveNewEmail: function (ev) {
            const self = this,
                req = new ApiRequest(),
                url = "data/partner.php",
                $btn = $(ev.currentTarget);

            $btn.prop('disabled', true);
            req.request(url, {
                'action': 'changeEmail',
                'email': this.$newAccountEmailInput.val()
            })
                .done(function (response) {
                    if ('success' === response.status) {
                        self.$changeEmailModal.find('.step-1').hide();
                        self.$changeEmailModal.find('.step-2').show();
                    }
                })
                .fail(function (response) {
                    self.showError(response.responseJSON.message);
                })
                .always(function () {
                    $btn.prop('disabled', false);
                })
        },

        confirmEmailChange: function (ev) {
            var self = this,
                req = new ApiRequest(),
                url = "data/partner.php",
                $btn = $(ev.currentTarget);

            $btn.prop('disabled', true);
            req.request(url, {
                'action': 'confirmEmailChange',
                'verification_code': this.$verificationCodeInput.val().trim()
            })
                .done(function (response) {
                    self.$email.text(self.$newAccountEmailInput.val());
                    registry.user.email = self.$newAccountEmailInput.val();
                    self.$changeEmailModal.hide();
                })
                .fail(function (response) {
                    self.showError(response.responseJSON.message);
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        showPendingDetails: function (ev) {
            const profileDetailsView = new ProfilePreviewView(this.lastProfileUpdate, 'pending');
            this.listenToOnce(profileDetailsView, 'profilePreview:edit', () => {
                this.populateFieldsInputs(this.lastProfileUpdate);
            });
        },

        showRejectedDetails: function (ev) {
            const profileDetailsView = new ProfilePreviewView(this.lastProfileUpdate, 'rejected');
            this.listenToOnce(profileDetailsView, 'profilePreview:edit', () => {
                this.populateFieldsInputs(this.lastProfileUpdate);
            });
        }
    });
});
