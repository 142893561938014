/*jslint nomen:true, indent:4*/
/*global define, alert, console*/

define(['Backbone', "workers/apirequest", "workers/user", "registry"], function (Backbone, ApiRequest, userWorker, registry) {

	var View = Backbone.View.extend({

		// Constants
		DEFAULT_TITLE: "Please Login",
		DEFAULT_LOGIN_BUTTON: "Login",
		FORGOT_TEXT: "Forgot Password",

		// Properties
		$passInput: null,
		$userInput: null,

		// Events
		events: {
			"click .loginButton": "doLogin",
			"click #forgotPassword": "clickForgot",
            "keyup .loginInput": "keyUpLogin"
		},


		// Methods
		initialize: function () {

			this.setElement("#keyEntryContainer");
			this.$userInput = this.$el.find("#username");
			this.$passInput = this.$el.find("#password");
			this.$loginButton = this.$el.find(".loginButton");
			this.$titleText = this.$el.find("#loginText");
			this.$forgotInput = this.$el.find("#forgotPassword");
            this.$rememberMeContainer = this.$el.find(".rememberMeContainer");
            this.$rememberMeInput = this.$el.find(".rememberMeInput");
            this.$errorMsg = this.$el.find('.loginErrorMsg');
			this.reset();

		},

		render: function () {
			return this.$el;
		},

		hide: function () {
			var self = this,
				dfd = $.Deferred();

			this.$el.transition({opacity: 0}, 333, function () {
				self.$el.hide();
				dfd.resolve();
			});

			return dfd.promise();
		},

		focus: function () {
			this.$userInput.focus();
		},

		position: function () {
			this.$el.css({
				position: "absolute",
				top: (window.innerHeight / 2) - (this.$el.height() / 2) - (window.innerHeight * 0.05),
				left: (window.innerWidth / 2) - (this.$el.width() / 2)
			});
		},

		reset: function () {
			this.forgotMode = false;
			this.$forgotInput.text("Return to Login");
			this.$titleText.text(this.DEFAULT_TITLE);
			this.$passInput.show();
            this.$rememberMeContainer.show();
			this.$loginButton.val(this.DEFAULT_LOGIN_BUTTON);
			this.$forgotInput.text(this.FORGOT_TEXT);
			this.$userInput.removeClass("error");
            this.$errorMsg.text('');
		},

		clickForgot: function () {

			if (this.forgotMode === true) {
				this.reset();
			}
			else {
				this.forgotMode = true;
				this.$titleText.text("Send a new password");
				this.$passInput.hide();
                this.$rememberMeContainer.hide();
				this.$loginButton.val("Send");
				this.$forgotInput.text("Return to Login");
                this.$errorMsg.text('');
			}

		},

		doLogin: function () {
			var self = this,
				user = this.$userInput.val(),
				pass = this.$passInput.val(),
                remember = false;

			if(this.$rememberMeInput.is(':checked') || typeof localStorage.remember !== 'undefined') {
			    remember = true;
            }



			if (this.forgotMode === true) {
				this.requestNewPassword(user);
			}
			else {
				this.$el.find("form").submit();
				self.$userInput.removeClass("input-error");
				self.$passInput.removeClass("input-error");

				userWorker.authenticate(user, pass, false, remember)
                    .done(function() {
                        if(self.accessedRoute.startsWith('login')) {
                            self.accessedRoute = '';
                        }
                        $('.visitorView').hide();
                        $('.userView').show();
                        Backbone.history.navigate(self.accessedRoute,{trigger:true});
                    })
                    .fail(function (error) {
                        if (error.status === 406) {
                            self.$userInput.addClass("input-error");
                            self.$passInput.addClass("input-error");
                        } else if (error.status === 429) {
                            self.$errorMsg.text("Login temporary blocked. Try again in 30 minutes.");
                        } else if (error.status === 403 && error.responseJSON) {
                            var attemptsLeft = error.responseJSON.attemptsLeft;
                            if (attemptsLeft > 0) {
                                self.$errorMsg.text("You have " + error.responseJSON.attemptsLeft + " more attempts remaining before being temporarily blocked.");
                            } else {
                                self.$errorMsg.text("Login temporary blocked. Try again in 30 minutes.");
                            }
                        }
                    });
			}

		},

		requestNewPassword: function (userEmail) {
			var self = this;

			$.ajax({
                url: API_URL + "password.php",
                type: "POST",
                crossDomain: true,
                data: {
                	email: userEmail,
                }
            }).done(function () {

               alert("New password sent, please check your inbox.");
               self.reset();

            }).fail(function (err) {

                console.error(err);
                self.$userInput.addClass("error");

            });
		},

		keyUpLogin: function(event) {
            if(event.keyCode == 13){
                this.doLogin();
            }
        },

        _showLogin: function (route) {
            var appendUrl = '';
            var match;
            // hack to navigate to public page after login
            if (route && (match = route.match(/login\?next=([^=&]+)/))) {
                route = match[1];
            } else {
                //todo redfadeOutirect url
                // Check to see if a pid is specified

                queryString = window.location.href.split("?");
                if (queryString[1] !== undefined) {
                    appendUrl = '?'+queryString[1];
                }

                Backbone.history.navigate('#login'+appendUrl);
            }

            $("#splash").hide();
            $(".appWrapper").hide();
            this.$el.show();
            this.accessedRoute = route;
        },

        _showUI: function () {
		    var self = this;

            $("#splash").hide();
            this.$el.hide();

            registry.user.apiRequestModule = ApiRequest;
            registry.user.pollUserPrefs();

            define(
                [
                    "views/user",
                    "views/reports",
                    "views/platformreports",
                    "views/newleague",
                    "views/filtered",
                    "views/filteredleagues",
                    "views/notifications",
                    "views/messages"
                ],
                function (
                    viewUser,
                    viewReports,
                    viewPlatformReports,
                    viewCreateLeague,
                    viewFiltered,
                    viewFilteredLeagues,
                    viewNotifications,
                    viewMessages
                ) {
                    registry.filteredView = viewFiltered; // only used in league
                    registry.filteredLeaguesView = viewFilteredLeagues; // only used in leagues and league
                    registry.reportsView = viewReports; // only used in league
                    registry.platformStatsView = viewPlatformReports; // snapshotbasic, home and offermarketplace
                    registry.createLeagueView = viewCreateLeague; // leagues and league
                    registry.userView = viewUser; // not used by other views (self contained)
                    registry.notificationView = viewNotifications; // not used by other views (self contained)
                    registry.messagesView = viewMessages; // not used by other views (self contained)

                    viewUser.render();
                    viewReports.render();
                    viewPlatformReports.render();
                    viewFiltered.render();
                    viewFilteredLeagues.render();

                    registry.ready.resolve();

                    $('.appWrapper').show();



                    self.uiMessage();

                    viewUser.hasData.done(function (monthFigure) {
                        var wtUserData = {
                            user_id: registry.user.id,
                            id_hash: registry.user.walkytalkyUserHash,
                            name: registry.user.name, // Full name
                            email: registry.user.email, // Email address
                            pid: registry.user.id, // User ID
                            platform: 'Monetizer V2 (' + registry.user.getAccessLevel() + ')',
                            monetizer: registry.user.monetizer,
                            revenue: monthFigure
                        }

                        if(registry.user.payment_method !== null) {
                            wtUserData.payment_method = registry.user.payment_method;

                        }

                        if(registry.user.ioRequired === 0) {
                            wtUserData.insertion_order = 'not required';
                        } else if (registry.user.docusignIoId) {
                            wtUserData.insertion_order = 'signed';
                        } else {
                            wtUserData.insertion_order = 'not signed';
                        }

                        // Init WalkyTalky
                        window.chatSettings = {
                            siteId: 13,
                            userData: wtUserData

                        };
                        window.walkyTalkyChat.load();
                    });

                });
        },

        uiMessage: function() {
		    if (!registry.user || !registry.user.id) {
		       return;
            }
            var api = new ApiRequest();
            api.request("v3/uiMessage.php")
                .done(function(response) {
                    if(registry.user.hide_ui_message == 0 && response.publisher_message != '') {
                        $('.uiMessageMsg').html(response.publisher_message);
                        $('.uiMessage').show()
                    }
                });
        }

});

    return new View();
});
