/*jslint nomen:true, indent:4*/
/*global define, console, alert, $, FormData, location */

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/support.html"
], function (Backbone, registry, ApiRequest, template) {
    
    var supportView = Backbone.View.extend({

    
        events: {
            'keyup .faqSearch': 'search'
        },
    
        render: function () {
            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("mainPane")
                .attr("id", "supportContainer");


            this.$el.append(_.template(template)({
                access: registry.user.getAccessLevel()
            }));
            $('#appContainer').append(this.$el);

            this.$faqContainer = this.$el.find('.faqContainer');

        },

        search: function(ev) {
            var search = $(ev.currentTarget).val();

            if (search === '') {
                this.$el.find('.faq').show();

            } else {
                this.$el.find('.faq').each(function() {
                    var keyword = ' ' + $(this).data('keyword').toLowerCase();
                    if (keyword.indexOf(search) === -1) {
                        $(this).hide();
                    } else {
                        $(this).show();
                    }

                });
            }
        },
    
        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        }
    });

    return new supportView();
});

