/* eslint-disable no-implicit-globals, no-console, no-empty-function */

var DEBUG_ENABLED = true;
if (!DEBUG_ENABLED) {
    console.log = function() {};
}
/* eslint-enable no-implicit-globals, no-console, no-empty-function */

define(['Backbone',
    'jQuery',
    'slimscroll',
    'registry',
    "workers/user",
    "workers/apirequest",
    "router",
    'moment',
    'views/menu',
    '../sass/afflow.scss',
    '../sass/media.scss',
], function (Backbone, jQuery, slimscroll, registry, userWorker, ApiRequest, Router,  moment, menuView, afflowSass, mediaSass) {

    reloadOnRoute = false;

    // Initialize the registry
    registry.ready = $.Deferred();
    registry.hasUserData = $.Deferred();
    registry.user.hasPreferences = $.Deferred();
    registry.userWorker = userWorker;


    $(function () {
        startUpgradePoll();

        function startUpgradePoll() {
            var nowTs = moment().format("X"); // Just a random string to prevent caching

            function checkIt() {
                $.getJSON("https://app.monetizer.com/version.php?req_ts=" + nowTs, function (data) {
                    if (parseInt(data.last_upgrade_ts, 10)-180 > APP_VER) {
                        reloadOnRoute = true;
                        $('.updateAvailable').css('opacity',1).show();
                    }

                    setTimeout(function () {
                        checkIt();
                    }, 5000);
                });
            }

            checkIt();
        }

        menuView.listenToOnce(userWorker, 'authenticated', menuView.render);

        $(document).on('click','.hideUiMessageBtn',function(ev) {
            var api = new ApiRequest();

            ev.stopImmediatePropagation();
            $('.uiMessage').hide();
            api.request('v3/uiMessage.php',{
                'action': 'hide'
            });

        });


        /*y
         * Push state doesn't work when you're using the file://
         * protocol, revert to using hash bangs in development
         * (local) mode.
         */
        var BackboneHistoryStarted = Backbone.History.started;
        if (!BackboneHistoryStarted) {

            Backbone.history.start({
                pushState: (document.location.protocol !== 'file:')
            });

            $(document).on("click", "a", function (e) {
                var appendUrl = '';
                    queryString = window.location.href.split("?");

                var href = $(e.currentTarget).attr('href');

                if(href !== 'javascript:;' && href.substring(0,4) !== 'http' && href.substr(0,4) !== 'data') {


                    if (queryString[1] !== undefined) {
                        appendUrl +='?'+queryString[1]
                    }
                    var res = Backbone.history.navigate(href+appendUrl,true);
                    //if we have an internal route don't call the server
                    if (res)
                        e.preventDefault();

                }

            });
        }

        $(document).on('click', '.click-to-delete-parent', function (event) {
            $(event.currentTarget).parent()
                .remove();
        });

        $('.nav-toggle, .nav-overlay').click(function(){
            $('.nav').toggleClass('nav-show');
            $('.nav-overlay').fadeToggle('fast');
            $('html, body').toggleClass('overflow-hidden');

        });

        $(document).on('click','.menu  > li > .dd', function(ev) {
            ev.stopImmediatePropagation();
            $(this).parent().find('ul').slideToggle('fast');
            $(this).toggleClass('closed');

        });

        $('.navScroll').slimScroll({
            position: 'left',
            height: "auto",
            size: "5px"
        });



        $(document).on('click', '.btn-modal', function(){
            $('#'+$(this).data('modal')).fadeIn('fast');
            $('html, body').addClass('overflow-hidden');

        });

        $(document).on('click', '.modal-destroy, .modal-close', function(){
            if ($(this).hasClass('destroy-modal')) {
                $(this)
                    .closest('.overlay')
                    .fadeOut('fast', function () {
                        $('html, body').removeClass('overflow-hidden');

                    })
                    .remove();

            } else {
                $(this)
                    .closest('.overlay')
                    .fadeOut('fast', function () {
                        $('html, body').removeClass('overflow-hidden');

                    });
            }

        });
        $(document).on('click', '.modal', function(ev) {
            ev.stopImmediatePropagation();
        })

        $('.big-select input').keyup(function(){
            $(this).attr('size', $(this).val().length + 1);
        })
            .on('focus', function() {
                $('.big-select-options').show();

            })
            .focusout(function() {
                $('.big-select-options').hide();
                $(this).val('').attr('size','10');
            });

        $(document).on('click', '.dropdown-toggle', function(){
            $(this).parent().toggleClass('over');
        });

        $(document).on('click', '.dropdown-menu a', function(){
            $(this).closest('.btn-group').toggleClass('over');
        });

        $(document).on('click', '.ticker .btn-group .routeLink', function(){
            $(this).closest('.btn-group').toggleClass('over');
        });
        $(document).on('click', '.logoutLink', function() {
            localStorage.removeItem('apiToken');
            localStorage.removeItem('remember');
            location.reload();
        });
        $(document).on('click', '.tabs>li>a', function() {
            var $tabs = $(this).closest('ul'),
                $active = $tabs.find('.active'),
                $activeTab = $('#'+$active.find('a').data('tab'));

            $active.removeClass('active');
            $(this).parent().addClass('active');

            $activeTab.hide();
            $('#' + $(this).data('tab')).show();
        });

    });
});
