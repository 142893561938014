define([
    "Backbone",
    "workers/apirequest",
], function (Backbone, ApiRequest) {

    return Backbone.Collection.extend({
        maxAge: 300,

        initialize: function() {
            this.fetchParams = {};
            this.updatedAt = 0;
        },

        hasData: function(params) {
            return !this.expired() && !this.paramsChanged(params);
        },

        refresh: function (options) {
            if (this.expired() || this.paramsChanged(options.params)) {
                this.fetch(options);
            }
        },

        expired: function() {
            var currentTimestamp = Math.round((new Date()).getTime() / 1000);
            return currentTimestamp > this.updatedAt + this.maxAge;
        },

        paramsChanged: function(newParams) {
            return !_.isEqual(this.fetchParams, newParams);
        },

        fetch: function (options = {}) {
            this.fetchParams = options.params || {};
            options = _.extend({parse: true}, options);
            var params = _.extend({action: this.getAllAction}, this.fetchParams);
            var self = this;

            this.trigger('request');
            var api = new ApiRequest();
            return api.request('v3/offerMarket.php?' + $.param(params))
                .then(function (response) {
                    self.updatedAt = Math.round((new Date()).getTime() / 1000);
                    self.reset(response, options);
                    self.trigger('sync', self, response, options);
                })
                .fail(function () {
                    self.trigger('error');
                    self.updatedAt = 0;
                    self.fetchParams = {};
                    self.reset([], {silent: true});
                });
        },

        parse: function (response, options) {
            if (!response) return response;
            return _.map(response, this.castDataRecord.bind(this));
        },

        sync: function () {
            console.log('RequestsCollection::sync is not implemented');
        },
    })
});
