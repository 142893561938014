define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/subscriptionsTemplate.html",
    "templates/subscriptionsRowTemplate.html",
    "templates/subscriptionsDeleteConfirmationTemplate.html"
], function(
    Backbone,
    registry,
    ApiRequest,
    subscriptionsTemplate,
    subscriptionsRowTemplate,
    subscriptionsDeleteModalTemplate
) {
    var SubscriptionsView = Backbone.View.extend({
        events: {
            "click .addSubBtn": "_newSubscription",
            "click .subscribeBtn": "_subscribeToOffer",
            "click .unsubscribeBtn": "_unsubscribeToOffer",
            "click .deleteSubscriptionBtn": "_showDeletionModal",
            "click .confirmSubscriptionDeletion": "_deleteSubscription",
            "change .subscriptionCheckAll": "_toggleCheckAll",
            "click .bulkBtn": "_showBulkModal",
            "click .confirmBulkBtn": "_processBulkAction"
        },

        showSubscriptions: function() {
            this.template = _.template(subscriptionsTemplate);
            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("subscriptionsPane")
                .addClass("mainPane");

            this.$el.append(this.template);

            $("#appContainer").append(this.$el);

            this._getSubscriptions();

        },
        _getSubscriptions: function() {
            var req = new ApiRequest(),
                template = _.template(subscriptionsRowTemplate),
                self = this;

            req.request("v2/PublisherOfferEventSubscription")
                .done(function (subscriptions) {
                    if (_.isArray(subscriptions) && subscriptions.length > 0) {
                        _.forEach(subscriptions, function (subscription) {
                            var row = template(subscription);
                            $('.subscriptionsContainer').append(row);
                        });
                    } else {
                        self.$el.find('.noSubscriptionsResults').show();
                    }
                })
                .always(function () {
                    $('.loading--container').remove();
                });


        },
        _newSubscription: function() {
            var self = this,
                req = new ApiRequest();


            return req.request("v2/PublisherOfferEventSubscription", {
                'offerId': this.$el.find('.addSubOfferIdInput').val(),
                'subscribed': 1

            }).then(function(res) {
                $('.subscriptionsContainer').empty();
                self._getSubscriptions();
                self.$el.find('.addSubOfferIdInput').val('');
                self.$el.find('.addSubModal').hide();

            })
        },
        _subscribeToOffer: function(ev) {
            var id = ev.currentTarget.dataset.id;

            return this._handleSubscription(id, 1)
        },
        _unsubscribeToOffer: function(ev) {
            var id = ev.currentTarget.dataset.id;

            return this._handleSubscription(id, 0);
        },
        _handleSubscription: function(id, status) {
            var self = this;
            var req = new ApiRequest();
            var formData = new FormData();
            formData.append('id', id);
            formData.append('subscribed', status);

            return req.put("v2/PublisherOfferEventSubscription", formData).then(function(res) {
                $('.subscriptionsContainer').empty();
                self._getSubscriptions();
            })
        },
        _showDeletionModal: function(ev) {
            this.subscriptionId = ev.currentTarget.dataset.id;
            this.offerId = ev.currentTarget.dataset.offerid;
            var offerData = {
                id: this.subscriptionId,
                offerId : this.offerId
            };

            var deletionModalTemplate = _.template(subscriptionsDeleteModalTemplate)(offerData);

            this.$el.append(deletionModalTemplate);
        },
        _deleteSubscription: function() {
            var self = this;
            var req = new ApiRequest();
            var url = "v2/PublisherOfferEventSubscription&id=" + this.subscriptionId;

            return req.deleteRequest(url).then(function(res) {
                $('.deleteSubscription').remove();
                $('.subscriptionsContainer').empty();
                self._getSubscriptions();
            })
        },

        _toggleCheckAll: function(ev) {
            if(ev.currentTarget.checked){
                this.$el.find('.subscriptionInput').prop('checked',true);
            } else {
                this.$el.find('.subscriptionInput').prop('checked',false);
            }
        },

        _showBulkModal: function(ev) {
            var $btn = $(ev.currentTarget),
                action = $btn.data('action');

            this.$el.find('.bulkLabel').text(action);
            this.$el.find('.confirmBulkBtn').text(action);

            this.$el.find('#bulkModal').show();

            this.bulkAction = action;

        },

        _processBulkAction: function(ev) {
            var self = this,
                $btn = $(ev.currentTarget),
                $checkboxes = this.$el.find('.subscriptionInput:checked'),
                promises = [];

            $btn.text('Processing...').attr('disabled','disabled');

            _.each($checkboxes, function(row) {
                var id = $(row).val();

                switch(self.bulkAction) {
                    case 'Unsubscribe':
                        promises.push(self._handleSubscription(id, 0));

                        break;

                    case 'Subscribe':

                        promises.push(self._handleSubscription(id, 1));
                        break;

                    case 'Delete':
                        self.subscriptionId = id;
                        promises.push(self._deleteSubscription());

                        break;
                }

            });


            Promise.all(promises)
                .then(function(responseList) {
                    self.$el.find('#bulkModal').hide();
                    $('.subscriptionsContainer').empty();
                    self._getSubscriptions();
                    $btn.text('').removeAttr('disabled');
                });


        },

        sleep: function() {
            this.isAsleep = true;
        },
        wakeUp: function() {
            this.isAsleep = false;
        }
    });

    return new SubscriptionsView();
});
