define([
    "Backbone",
    "workers/apirequest",
    "registry",
    "moment-timezone",
    "views/academySidebar",
    "views/comments",
    "templates/academy/post.html"
], function(
    backbone,
    api,
    app,
    moment,
    sidebarView,
    commentsView,
    postTemplate
) {
    var postView = Backbone.View.extend({

        // Events
        events:{
        },

        initialize: function() {

        },

        render: function (slug) {
            var self = this,
                req = new api(),
                slugAr = slug.split('-');
                postId = slugAr[slugAr.length-1];

            this.setElement($(document.createElement("div")));
            this.$el.addClass("mainPane").attr("id", "postContainer");

            self.$el.append(postTemplate);
            $("#appContainer").append(self.$el);

            this.sidebar = new sidebarView(this.$el.find('.sidebarContainer'));
            this.comments = new commentsView(this.$el.find('.commentsContainer'),{objectType:1,objectId:postId});
            this.$postTitle = this.$el.find('.postTitle');
            this.$postContent = this.$el.find('.postContent');
            this.$postDescription = this.$el.find('.postDescription');

            req.request('v3/posts.php?action=post&website=monetizer&id='+postId)
                .then(function(response) {
                    document.title = response.meta_title;
                    $('meta[name="description"]').text(response.meta_title);
                    $('meta[name="keywords"]').text(response.meta_keywords);

                    self.$postTitle.html(response.title);
                    self.$postDescription.html(response.description);
                    self.$postContent.html(response.content);

                    self.$postContent.find('.ql-video').wrap('<div class="postVideoContainer"></div>');

                });





        },
        sleep: function() {},

        wakeUp: function() {}



    });

    return new postView();
});
