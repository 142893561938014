/*jslint nomen:true, indent:4*/
/*global define, _, alert, console */

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "moment-timezone",
    "views/message",
    "templates/inbox.html",
    "templates/inboxRow.html"
], function (Backbone, registry, ApiWorker, moment, messageView, template, rowTemplate) {

    var View = Backbone.View.extend({

        events:  {
            'click .pageBtn': '_changePage',
            'click .inboxRow': '_showMessage',
            'click .newMessageBtn': '_newMessage',
            'click .archivedFilter': '_updateArchivedFilter',
            'click .archiveBtn': '_archiveMessages',
            "keyup .searchInput": "searchKeyUpHandler"
        },

        limit: 25,
        page: 0,
        searchTerm: '',

        initialize: function () {
            this.rowTemplate = _.template(rowTemplate);
            this.archived = 0;
        },

        render: function () {
            if(registry.user.allowMessages !== 1) {
                return;
            }
            this.setElement($(document.createElement("div")));
            this.$el.addClass("mainPage").attr("id", "inboxContainer");
            this.$el.append(template);
            $("#appContainer").append(this.$el);
            this.$el.find('.archivedFilter[data-value="' + this.archived + '"]').addClass('active');

            this.$table = this.$el.find('.resultsTable > tbody');

            this.listenTo(this, 'newMessage', this.onNewMessage);

            this._getResults();
        },

        searchKeyUpHandler: function(ev) {
            this.searchTerm = ev.currentTarget.value;
            if (ev.keyCode === 13) {
                this.page = 0;
                this._getResults();
            }
        },

        _getResults: function () {
            var self = this,
                apiRequest = new ApiWorker(),
                apiData = {
                    limit: this.limit,
                    page: this.page,
                    archived: this.archived
                },
                qString = '';


            if(this.page === 0) {
                self.$el.find('.pageBtn[data-direction="back"]').attr('disabled',true);
            } else {
                self.$el.find('.pageBtn[data-direction="back"]').removeAttr('disabled');
            }

            if(this.searchTerm.length > 0) {
                apiData.search = this.searchTerm;
            }

            qString = new URLSearchParams(apiData);

            apiRequest.request("v3/messages.php?"+qString)
                .then(function(response) {
                    self.resultData = _.map(response, self._castDataRecord.bind(self));
                    self._showResults();
                });
        },

        _castDataRecord: function(record) {
            record.id = +record.id;
            record.sender_type = +record.sender_type;
            record.publisher_id = +record.publisher_id;
            record.network_id = +record.network_id;
            record.is_approved = +record.is_approved;
            record.archived = +record.archived;
            record.formattedDate = moment
                .tz(record.send_ts,'America/New_York')
                .format('ddd, MMM Do YYYY HH:mm:ss');

            return record;
        },

        _showResults: function () {
            var self = this;

            this.$table.empty();
            if (this.resultData.length > 0) {
                _.each(this.resultData, function(row) {
                    self.$table.append(self.rowTemplate({row: row, archived: self.archived}));
                });
            } else {
                self.$table.append('<tr><td colspan="4" class="text-center">No Messages Found</td></tr>');
            }

            if(self.resultData.length < self.limit) {
                self.$el.find('.pageBtn[data-direction="forward"]').attr('disabled',true);
            } else {
                self.$el.find('.pageBtn[data-direction="forward"]').removeAttr('disabled');
            }
        },


        _changePage: function(ev) {
            switch(ev.currentTarget.dataset.direction) {
                case 'forward':
                    this.page++;
                    break;
                case 'back':
                    this.page--;
                    if(this.page < 0) {
                        this.page = 0;
                    }
                    break;
            }
            this._getResults();
        },


        _updateArchivedFilter: function(ev) {
            this.archived = +ev.currentTarget.dataset.value;
            this.$el.find('.archivedFilter').removeClass('active');
            this.$el.find('.archivedFilter[data-value="' + this.archived + '"]').addClass('active');

            this._getResults();
        },

        _archiveMessages: function(ev) {
            ev.stopPropagation();
            // archive operation is allowed only on the active messages tab
            if (this.archived !== 0) {
                return;
            }
            var self = this;
            var $btn = $(ev.currentTarget);
            var nid = $btn.data('nid');
            var apiRequest = new ApiWorker();

            $btn.prop('disabled', true);
            apiRequest.request('v3/messages.php',{
                'action': 'archive',
                'network_id': nid,
            })
                .then(function(response) {
                    if(response.status === 'success') {
                        $btn.closest('.inboxRow').remove();
                    }
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        _showMessage: function(ev) {
            var id = +ev.currentTarget.dataset.id,
                messageData = _.findWhere(this.resultData,{id: id}),
                $row = $(ev.currentTarget);

            $row.find('.inboxDate i.fa').removeClass('fa-envelope').addClass('fa-envelope-o');
            $row.find('.inboxMessage').removeClass('text-bold');

            new messageView({
                parent: this,
                network_id: messageData.network_id,
            });
        },

        _newMessage: function() {
            new messageView({
                parent: this,
            });
        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        },

        onNewMessage: function(record) {
            record = this._castDataRecord(record);
            var index = _.findIndex(this.resultData, function(r) {
                return r.network_id === record.network_id;
            }, this);
            if (index > -1) {
                this.resultData[index] = record;
            } else {
                this.resultData.unshift(record);
                this.resultData = _.first(this.resultData, this.limit);
            }

            this.resultData = _.sortBy(this.resultData, function(r) { return -1 * r.id;}, this);

            this._showResults();
        },

    });

    return new View();
});
