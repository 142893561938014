/*global define, jstz */
/*jshint nomen:true, indent:4*/

define(
    ["Backbone", "jQuery", "moment-timezone",
     "pikaday", "Underscore", "php-timezone", "views/pureSelect", 'jstimezonedetect'],
    function (Backbone, $, moment, Pikaday, _, phpList, PureSelect, jstz) {
        "use strict";


        return Backbone.View.extend({
            events: {
                'keyup .timezoneInput': 'tzInput',
                'focus .timezoneInput': 'tzFocus',
                'blur .timezoneInput': 'tzBlur',
                'click .timezone': 'clickTz'
            },

            timezoneList: [],

            initialize: function (options) {
                var self = this;


                if (typeof options !== 'undefined' && typeof options.element !== 'undefined') {
                    this.$el = options.element;
                    this.$el.addClass('dateRange');
                } else {
                    this.$el = $('<div>', { class: 'dateRange' });
                }

                this._currentTimezone = jstz.determine().name();

                this.$timezoneInput = $('<input>', {
                    type: 'text'

                })
                    .addClass("timezoneInput")
                    .val(this._currentTimezone)
                    .appendTo(this.$el);


                this.$timezoneInput.focus(function(e) {
                    self.tzFocus(e);
                });
                this.$timezoneInput.blur(function(e) {
                    self.tzBlur(e);
                });
                this.$timezoneInput.keyup(function(e) {
                    self.tzInput(e);
                });


                this.$el.on('click','.timezone', function(e) {
                    self.clickTz(e);
                });

                this.$timezoneSelectContainer = $('<div>')
                    .addClass("timezoneSelectContainer")
                    .appendTo(this.$el)
                    .css({opacity: 0})
                    .hide();

                this.$startDate = $('<input>', {
                    type: 'text'

                })
                    .addClass("textButton startDate")
                    .appendTo(this.$el);

                this.$endDate = $('<input>', {
                    type: 'text'

                })
                    .addClass("textButton endDate")
                    .appendTo(this.$el);

                this.$presets = new PureSelect()
                    .addClass("presets")
                    .updateData({
                        "today": "Today",
                        "yesterday": "Yesterday",
                        "three": "3 Days",
                        "seven": "7 Days",
                        "fourteen": "14 Days",
                        "thirty": "30 Days",
                        "custom": "Custom"
                    }, "today")
                    .appendTo(this.$el)
                    .on("change", this._presetChanged, this);

                var minDate = moment().subtract(30, 'days');

                var defaults = {
                    format: 'D MMM YYYY',
                    defaultDate: moment().toDate(),
                    minDate: minDate.toDate(),
                    setDefaultDate: true
                };

                _.extend(defaults, options);

                var startParams = _.extend(
                    {},
                    defaults,
                    {
                        field: this.$startDate.get(0),
                        onSelect: this._startDateChanged.bind(this)
                    }
                );

                var endParams = _.extend(
                    {},
                    defaults,
                    {
                        field: this.$endDate.get(0),
                        onSelect: this._endDateChanged.bind(this)
                    }
                );

                if(this.timezoneList.length == 0) {
                    _.each(phpList, function(row) {
                        var zone = {
                            'value': row,
                            'label': moment.tz(row).zoneAbbr() + ' - ' + row
                        };

                        self.timezoneList.push(zone);

                    });

                }


                this._padStart = new Pikaday(startParams);

                this._padEnd = new Pikaday(endParams);

                this._presetChanged();
            },

            render: function () {
                return this.$el;
            },

            get: function () {
                var range = this._getRange(),
                    timeZone = this._currentTimezone,
                    startDate = range.start.format('YYYY-MM-DD'),
                    endDate = range.end.format('YYYY-MM-DD'),
                    startMoment = moment.tz(startDate, timeZone),
                    endMoment = moment.tz(endDate, timeZone);

                endMoment
                    .add(1, 'days')
                    .subtract(1, 'second');

                return {
                    start: startMoment.unix(),
                    end: endMoment.unix(),
                    tz: timeZone
                };
            },

            getSql: function () {
                var range = this._getRange();

                return {
                    start: range.start.format('YYYY-MM-DD'),
                    end: range.end.format('YYYY-MM-DD'),
                    tz: this._currentTimezone
                };
            },

            isTodaySelected: function () {
                var range = this._getRange(),
                    now = moment.tz(this._currentTimezone),
                    today = now.format('YYYY-MM-DD'),
                    startDate = range.start.format('YYYY-MM-DD'),
                    endDate = range.end.format('YYYY-MM-DD');

                return today === startDate && today == endDate;
            },

            setTimezone: function (timezone) {
                this._currentTimezone = timezone;
                this.$timezoneInput.val(timezone);
                this.$timezoneSelectContainer.hide();
                this._presetChanged();
            },

            _getRange: function () {
                return {
                    start: this._padStart.getMoment(),
                    end: this._padEnd.getMoment()
                };
            },

            _startDateChanged: function () {
                var range = this._getRange();

                if (range.start.isAfter(range.end)) {
                    this._padEnd.setMoment(range.start, true);
                }

                this._updateSelect();
                this._triggerEvent();
            },

            _endDateChanged: function () {
                var range = this._getRange();

                if (range.end.isBefore(range.start)) {
                    this._padStart.setMoment(range.end, true);
                }

                this._updateSelect();
                this._triggerEvent();
            },

            _updateSelect: function () {
                var timezone = this._currentTimezone;

                function rangeToPreset(start, end) {
                    var today = moment.tz(timezone).startOf('day'),
                        yesterday = today.clone().subtract(1, 'day'),
                        three = today.clone().subtract(3, 'day'),
                        seven = today.clone().subtract(7, 'day'),
                        fourteen = today.clone().subtract(14, 'day'),
                        thirty = today.clone().subtract(30, 'day');

                    function sameDay(m1, m2) {
                        var s1 = m1.format('YYYY-MM-DD'),
                            s2 = m2.format('YYYY-MM-DD');

                        return s1 === s2;
                    }

                    if (sameDay(end, today)) {
                        if (sameDay(start, today)) {
                            return 'today';
                        }
                        if (sameDay(start, three)) {
                            return 'three';
                        }
                        if (sameDay(start, seven)) {
                            return 'seven';
                        }
                        if (sameDay(start, fourteen)) {
                            return 'fourteen';
                        }
                        if (sameDay(start, thirty)) {
                            return 'thirty';
                        }
                        return 'custom';
                    }

                    if (sameDay(start, yesterday) &&
                        sameDay(end, yesterday)) {
                        return 'yesterday';
                    }

                    return 'custom';
                }

                var range = this._getRange();
                this.$presets.val(rangeToPreset(range.start, range.end));
            },

            _presetChanged: function () {
                var preset = this.$presets.val(),
                    today = moment.tz(this._currentTimezone).startOf('day'),
                    yesterday = today.clone().subtract(1, 'day'),
                    start,
                    end;

                switch (preset) {
                case 'today':
                    start = end = today;
                    break;
                case 'yesterday':
                    start = end = yesterday;
                    break;
                case 'three':
                    end = today;
                    start = end.clone().subtract(3, 'day');
                    break;
                case 'seven':
                    end = today;
                    start = end.clone().subtract(7, 'day');
                    break;
                case 'fourteen':
                    end = today;
                    start = end.clone().subtract(14, 'day');
                    break;
                case 'thirty':
                    end = today;
                    start = end.clone().subtract(30, 'day');
                    break;
                default:
                    return;
                }

                // Ugly way to convert moment.tz to plain moment
                start = moment(start.format('YYYY-MM-DD'));
                end = moment(end.format('YYYY-MM-DD'));

                var range = this._getRange();

                if (!start.isSame(range.start)) {
                    this._padStart.setMoment(start, true);
                }
                if (!end.isSame(range.end)) {
                    this._padEnd.setMoment(end, true);
                }

                this._triggerEvent();
            },

            _triggerEvent: function () {
                this.trigger('change');
            },

            tzInput: function () {
                this.searchForTz();
            },

            tzFocus: function (e) {
                $(e.currentTarget).val('');
            },

            tzBlur: function(e) {
                $(e.currentTarget).val(this._currentTimezone);
            },

            searchForTz: function () {
                var self = this,
                    regex = new RegExp(this.$timezoneInput.val(), 'gi'),
                    tzInputPos = this.$timezoneInput.position();

                this.$timezoneSelectContainer
                    .empty()
                    .css({
                        position: 'absolute',
                        top: tzInputPos.top + this.$timezoneInput.height(),
                        left: tzInputPos.left
                    });

                _.each(this.timezoneList, function (row) {
                    if(regex.test(row.label)) {
                        $('<div>', { class: 'timezone' })
                            .text(row.value)
                            .data('value',row.value)
                            .appendTo(self.$timezoneSelectContainer);
                    }
                });

                this.$timezoneSelectContainer.show().css('opacity',1);
                /*
                if (this.$timezoneSelectContainer.css('opacity') === '0') {
                    this.$timezoneSelectContainer.transition({opacity: 1});
                }

                 */
            },

            clickTz: function(e) {
                var el = $(e.currentTarget),
                    self = this;

                this.setTimezone(el.data('value'));
                this._triggerEvent();
                this.trigger('timezoneChange');

            }
        });
    }
);
