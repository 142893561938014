define([
    "Backbone",
    "moment-timezone",
    "workers/apirequest",
    "registry",
    "templates/selectLink.html",
    "templates/selectLinkRow.html"
], function (
    Backbone,
    moment,
    Api,
    registry,
    template,
    rowTemplate
) {
    return Backbone.View.extend({
        events: {
            'click .view-close': 'destroy',
            'input #sl_nameInput': 'showLinksDebounced',
            'change #sl_leagueInput': 'showLinks',
            'click .pageBtn': 'changePage',
            'click .linkRow': 'selectLink'
        },

        initialize: function(options) {
            this.page = 1;
            this.perPage = 10;
            this._subViews = [];
            this.links = options.links || [];
            this.rowTemplate = _.template(rowTemplate);
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "linkSelect");
            this.$el.append(template);

            this.$nameInput = this.$el.find('#sl_nameInput');
            this.$leagueInput = this.$el.find('#sl_leagueInput');
            _.each(registry.user.nidInfoArr, function (league) {
                this.$leagueInput.append($('<option>', {value: league.nid, text: league.name}));
            }, this);
            this.$prevPageBtn = this.$el.find('.pageBtn[data-id="' + 'prev' + '"]');
            this.$nextPageBtn = this.$el.find('.pageBtn[data-id="' + 'next' + '"]');

            this.showLinks();

            $("#appContainer").append(this.$el);
            this.$el.find('.overlay').fadeIn('fast');
        },

        showLinksDebounced: function () {
            if (this.showLinksTimeout) {
                clearTimeout(this.showLinksTimeout);
            }
            this.showLinksTimeout = setTimeout(this.showLinks.bind(this), 300);
        },

        showLinks: function () {
            this.page = 1;
            this.filteredLinks = this.getFilteredLinks();
            this.renderLinksPage();
        },

        getFilteredLinks: function () {
            var name = this.$nameInput.val();
            var leagueId = this.$leagueInput.val();

            return _.filter(this.links, function (link) {
                if (name && (!link.name || !link.name.toLowerCase().includes(name.toLowerCase()))) {
                    return false;
                }
                if (leagueId && link.nid != leagueId) {
                    return false;
                }
                return true;
            });
        },

        paginate: function (links) {
            var start = (this.page - 1) * this.perPage;
            var end = start + this.perPage;

            if (this.page > 1) {
                this.$prevPageBtn.prop('disabled', false);
            } else {
                this.$prevPageBtn.prop('disabled', true);
            }
            if (end < links.length) {
                this.$nextPageBtn.prop('disabled', false);
            } else {
                this.$nextPageBtn.prop('disabled', true);
            }

            return links.slice(start, end);
        },

        renderLinksPage: function () {
            this.pageLinks = this.paginate(this.filteredLinks);
            var links = this.castLinkRecords(this.pageLinks);
            var $tableBody = this.$el.find('#sl_linksTableBody');
            $tableBody.empty();
            if (links.length > 0) {
                _.each(links, function (link) {
                    $tableBody.append(this.rowTemplate(link));
                }, this);
            } else {
                $tableBody.append('<td colspan="5">No Links</td>');
            }
        },

        castLinkRecords: function(links) {
            return _.map(links, function (link) {
                link.name = link.name || 'Unnamed';
                if (link.nid) {
                    var league = registry.user.getInfoForNid(link.nid)
                    link.leagueName = league.name || '';
                } else {
                    link.leagueName = '';
                }
                if (link.created_ts) {
                    link.createdDate = moment.unix(link.created_ts).format("MMM Do YYYY");
                } else {
                    link.createdDate = 'Historic';
                }
                return link;
            });
        },

        destroy: function() {
            if (this._subViews.length > 0) {
                _.each(this._subViews, function(subView) {
                    subView.remove();
                }, this);

                this._subViews = [];
            }
            this.$el.find('.overlay').fadeOut('fast');
            this.remove();
        },

        changePage: function (ev) {
            var $btn = $(ev.currentTarget);
            var id = $btn.data('id');
            if (id === 'prev') {
                this.page = Math.max(this.page - 1, 1);
            } else if (id === 'next') {
                this.page += 1;
            } else {
                return;
            }
            this.renderLinksPage();
        },

        selectLink: function (ev) {
            var hash = $(ev.currentTarget).data('hash');
            var link = _.findWhere(this.pageLinks, {hash: hash});
            this.trigger('select', link);
            this.destroy();
        }
    });
});

