define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/advertiserSubscriptions.html",
    "templates/advertiserSubscriptionsRow.html"
], function(
    Backbone,
    registry,
    ApiRequest,
    template,
    rowTemplate
) {
    var View = Backbone.View.extend({
        events: {
            'click .addSubBtn': 'createSubscription',
            'click .subscribeBtn': 'subscribe',
            'click .unsubscribeBtn': 'unsubscribe',
            'click .deleteSubscriptionBtn': 'showDeleteSubscriptionModal',
            'click .confirmSubscriptionDeletion': 'deleteSubscription',
            'change .subscriptionCheckAll': 'toggleCheckAll',
            'change .subscriptionInput': 'selectionUpdated',
            'click .bulkBtn': 'showBulkModal',
            'click .confirmBulkBtn': 'performBulkAction',
            'click .helpBtn': 'showHelpModal'
        },

        show: function() {
            this.template = _.template(template);
            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("advertiserSubscriptionsPane")
                .addClass("mainPane");

            this.$el.append(this.template);

            $("#appContainer").append(this.$el);

            this.$loadingContainer = this.$el.find('.loading--container');
            this.$subscriptionsContainer = this.$el.find('.subscriptionsContainer');
            this.$noResults = this.$el.find('.noSubscriptionsResults');
            this.$addSubModal = this.$el.find('#addSubModal');
            this.$networkIdInput = this.$el.find('.networkIdInput');
            this.$deleteSubModal = this.$el.find('#deleteSubModal');
            this.$bulkModal = this.$el.find('#bulkModal');
            this.$bulkBtns = this.$el.find('.bulkBtnsGroup');
            this.$checkAllInput = this.$el.find('.subscriptionCheckAll');

            this.getSubscriptions();
        },

        getSubscriptions: function () {
            var req = new ApiRequest(),
                template = _.template(rowTemplate),
                self = this;

            this.subscriptions = [];
            this.$subscriptionsContainer.empty();
            this.$loadingContainer.show();
            this.$noResults.hide();
            req.request("v2/PublisherAdvertiserEventSubscription")
                .done(function (subscriptions) {
                    if (_.isArray(subscriptions) && subscriptions.length > 0) {
                        self.subscriptions = subscriptions;
                        _.forEach(subscriptions, function (subscription) {
                            var row = template(subscription);
                            self.$subscriptionsContainer.append(row);
                        });
                    } else {
                        self.$noResults.show();
                    }
                })
                .always(function () {
                   self.$loadingContainer.hide();
                   self.updateBulkBtns();
                });
        },

        createSubscription: function (ev) {
            var self = this,
                $btn = $(ev.currentTarget),
                req = new ApiRequest();

            $btn.prop('disabled', true);
            return req.request("v2/PublisherAdvertiserEventSubscription", {
                'networkId': this.$networkIdInput.val(),
                'subscribed': 1
            })
                .then(function(res) {
                    self.getSubscriptions();
                    self.$addSubModal.hide();
                    self.$networkIdInput.val('');
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        subscribe: function (ev) {
            var id = ev.currentTarget.dataset.id;

            this.updateSubscription(id, 1);
        },

        unsubscribe: function (ev) {
            var id = ev.currentTarget.dataset.id;

            this.updateSubscription(id, 0);
        },

        updateSubscription: function (id, subscribed) {
            var self = this;
            var req = new ApiRequest();
            var formData = new FormData();
            formData.append('id', id);
            formData.append('subscribed', subscribed);

            return req.put("v2/PublisherAdvertiserEventSubscription", formData)
                .then(function(res) {
                    self.getSubscriptions();
                });
        },

        showDeleteSubscriptionModal: function (ev) {
            var data = ev.currentTarget.dataset;
            this.id = data.id;
            this.networkId = data.networkid;
            this.$deleteSubModal.find('.networkId').text(this.networkId);
            this.$deleteSubModal.show();
        },

        deleteSubscription: function (ev) {
            var self = this;
            var $btn = $(ev.currentTarget);
            var req = new ApiRequest();
            var url = "v2/PublisherAdvertiserEventSubscription?id=" + this.id;

            $btn.prop('disabled', true);
            req.deleteRequest(url)
                .then(function(res) {
                    self.$deleteSubModal.hide();
                    self.getSubscriptions();
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        toggleCheckAll: function (ev) {
            var checked = ev.currentTarget.checked;
            this.$el.find('.subscriptionInput').prop('checked', checked);
            this.updateBulkBtns();
        },

        updateBulkBtns: function (ids) {
            ids = ids || this.getSelectedIds();
            if (ids.length > 0) {
                this.$bulkBtns.show();
            } else {
                this.$bulkBtns.hide();
            }
        },

        selectionUpdated: function () {
            var ids = this.getSelectedIds();
            this.updateBulkBtns(ids);
            if (ids.length > 0 && ids.length === this.subscriptions.length) {
                this.$checkAllInput.prop('checked', true);
            } else {
                this.$checkAllInput.prop('checked', false);
            }
        },

        showBulkModal: function (ev) {
            var $btn = $(ev.currentTarget),
                action = $btn.data('action');

            this.bulkAction = action;

            this.$bulkModal.find('.bulkLabel').text(action);
            this.$bulkModal.find('.confirmBulkBtn').text(action);

            this.$bulkModal.show();
        },

        performBulkAction: function (ev) {
            var self = this;
            var $btn = $(ev.currentTarget);
            var ids = this.getSelectedIds();
            if (!ids || ids.length === 0) {
                this.$bulkModal.hide();
                return;
            }
            var req = new ApiRequest();
            var data = {
                ids: JSON.stringify(ids),
            };

            switch (this.bulkAction) {
                case 'Subscribe':
                    data.action = 'bulkUpdate';
                    data.subscribed = 1;
                    break;
                case 'Unsubscribe':
                    data.action = 'bulkUpdate';
                    data.subscribed = 0;
                    break;
                case 'Delete':
                    data.action = 'bulkDelete';
                    break;
                default:
                    this.$bulkModal.hide();
                    return;
            }

            $btn.prop('disabled', true);
            req.request("v2/PublisherAdvertiserEventSubscription", data)
                .then(function(res) {
                    self.$checkAllInput.prop('checked', false);
                    self.getSubscriptions();
                    self.$bulkModal.hide();
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        getSelectedIds: function () {
            var ids = [];
            this.$el.find('.subscriptionInput:checked').each(function () {
                ids.push($(this).val());
            });
            return ids;
        },

        showHelpModal: function () {
            this.$el.find('#helpModal').show();
        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        }
    });

    return new View();
});
