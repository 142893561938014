define([
    'backbone',
    'registry',
    'views/offerMarket/requestDetails',
    'templates/offerMarket/publisherRequestDetails.html',
], function (
    Backbone,
    registry,
    RequestDetailsView,
    template,
) {
    var PublisherRequestDetailsView =  RequestDetailsView.extend({
        template: _.template(template),
        collection: registry.publishersRequests,
        requestType: 'publisherRequest',

        events: function() {
            return _.extend({}, RequestDetailsView.prototype.events, {
            });
        },

    });

    return new PublisherRequestDetailsView();
});
