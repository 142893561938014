/*jslint nomen:true, indent:4*/
/*global define, _, Backbone, console*/

var preferencesTimeout = null;
var notificationsTimeout = null;

define([
    'Backbone',
], function (
    Backbone,
) {
    var registry = {
        versionStr: "WEB_0.1.0",

        request: null,
        apiRequestModule: null,
        zlib: null,
        gui: null,

        mainView: null,
        menuView: null,
        linkGenView: null,
        userView: null,
        reportsView: null,
        filteredView: null,

        userWorker: null,

        ready: null,

        user: {
            token: null,
            id: null,
            name: null,
            nick: null,
            postbackURL: null,
            nick_img: null,
            email: null,
            nidAssignmentArr: null,
            nidInfoArr: null,
            adjustmentType: null,
            preferences: null,
            hasPreferences: null,
            preferencesLoaded: false,
            walkytalkyUserHash: null,
            address_1: null,
            address_2: null,
            address_city: null,
            address_zip: null,
            address_country: null,
            tel: null,
            logo_img: null,
            payment_request_date: null,
            allow_premium: 0,
            share: 0,
            earnings: {},
            verticalCache: [],
            unreadOfferMarket: null,
            payment_method: null,
            idVerified: null,
            bypassVerification: null,
            profileComplete: null,
            allowMessages: 0,
            allowDomains: 0,
            ioRequired: null,
            docusignIoId: null,
            unlockProfile: false,

            getGravatar: function () {
                if ((this.nick_img === null || this.nick_img === '') && this.logo_img === '') {
                    return 'https://api.afflow.rocks/202-img/default.png';

                }
                else if (this.logo_img !== '') {
                    return 'https://api.afflow.rocks/202-img/company_logos/' + this.logo_img;
                }
                else {
                    return this.nick_img;

                }
            },

            hasVerticalAccess: function(vertical) {
                if(this.allow_premium === 1 || vertical === 'monetizer') {
                    return true;
                }
                else {
                    if(_.find(this.verticalCache, {'vertical': vertical,'premium':0})) {
                        return true;
                    }
                    else {
                        return false;
                    }
                }
            },

            getAccessLevel: function () {
                if (this.private === 1) {
                    return 'vip';
                }
                else if (this.monetizer !== '1'){
                    return 'plus';

                } else {
                    return 'basic';

                }

            },

            getInfoForNid: function (nid) {
                var toReturn = false;

                this.nidInfoArr.forEach(function (entry) {
                    if(parseInt(entry.nid, 10) === parseInt(nid, 10)) {
                        toReturn = entry;
                    }
                });

                return toReturn;
            },

            getLeagueFilterMap: function () {
                var filterMap,
                    self = this;

                // Check we have the proper thing
                if (self.preferences.nidFilterArr === undefined) {
                    self.preferences.nidFilterArr = {};
                }

                filterMap = self.preferences.nidFilterArr;
                filterMap = _.filter(filterMap, function (entry) {
                    return _.indexOf(self.nidAssignmentArr, entry.nid) > -1;
                });

                filterMap = _.indexBy(filterMap, 'nid');
                return filterMap;
            },

            getFilterMapForNid: function (nid) {
                var filterMap,
                    self = this;

                // Check we have the proper thing
                if (self.preferences.leagueFilterArr === undefined) {
                    self.preferences.leagueFilterArr = {};
                }

                filterMap = self.preferences.leagueFilterArr;

                if (filterMap[nid] !== undefined) {
                    return filterMap[nid];
                } else {
                    return {};
                }

            },

            hasFilteredCampaign: function (nid, rcid) {
                var filterMap,
                    self = this;

                // Check we have the proper thing
                if (self.preferences.leagueFilterArr === undefined) {
                    self.preferences.leagueFilterArr = {};
                }

                filterMap = self.preferences.leagueFilterArr;

                // Check if the nid is in there
                if (filterMap[nid] === undefined) {
                    return false;
                } else {
                    if (filterMap[nid][rcid] === undefined) {
                        return false;
                    } else {
                        return true;
                    }
                }
            },

            getNumFilteredFor: function (nid) {
                var filterMap,
                    self = this;

                if (self.preferences.leagueFilterArr === undefined) {
                    self.preferences.leagueFilterArr = {};
                }

                filterMap = self.preferences.leagueFilterArr;

                if (filterMap[nid] !== undefined) {
                    return _.keys(filterMap[nid]).length;
                } else {
                    return 0;
                }

            },

            filterCampaign: function (nid, rcid, name) {
                var filterMap,
                    self = this;

                if (name === undefined) {
                    name = rcid;
                }

                // Check we have the proper thing
                if (self.preferences.leagueFilterArr === undefined) {
                    self.preferences.leagueFilterArr = {};
                }

                filterMap = self.preferences.leagueFilterArr;

                // Create the object if its not there
                if (filterMap[nid] === undefined) {
                    filterMap[nid] = {};
                }

                // Create the filter
                filterMap[nid][rcid] = {
                    name: name,
                    id: rcid
                };

                self.preferences.leagueFilterArr = filterMap;
                registry.trigger("updated:preferences");
            },

            unfilterCampaign: function (nid, rcid) {
                var filterMap,
                    self = this;

                // Check we have the proper thing
                if (self.preferences.leagueFilterArr === undefined) {
                    self.preferences.leagueFilterArr = {};
                }

                filterMap = self.preferences.leagueFilterArr;

                // Create the object if its not there
                if (filterMap[nid] === undefined) {
                    filterMap[nid] = {};
                }

                // remove the filter
                delete filterMap[nid][rcid];

                self.preferences.leagueFilterArr = filterMap;
                registry.trigger("updated:preferences");
            },


            filterLeague: function (nid) {
                var filterMap,
                    self = this;

                // Check we have the proper thing
                if (self.preferences.nidFilterArr === undefined) {
                    self.preferences.nidFilterArr = {};
                }

                filterMap = self.preferences.nidFilterArr;

                // Create the filter
                filterMap[nid] = {
                    nid: nid
                };

                self.preferences.nidFilterArr = filterMap;
                registry.trigger("updated:preferences");
            },

            unfilterLeague: function (nid) {
                var filterMap,
                    self = this;

                // Check we have the proper thing
                if (self.preferences.nidFilterArr === undefined) {
                    self.preferences.nidFilterArr = {};
                }

                filterMap = self.preferences.nidFilterArr;

                // remove the filter
                delete filterMap[nid];

                self.preferences.nidFilterArr = filterMap;
                registry.trigger("updated:preferences");
            },

            getLastReadBuzz: function () {
                var self = this;

                if (self.preferences.lastReadBuzz === undefined) {
                    return 0;
                } else {
                    return self.preferences.lastReadBuzz;
                }
            },

            getLastReadPaused: function () {
                var self = this;

                if (self.preferences.lastReadPaused === undefined) {
                    return 0;
                } else {
                    return self.preferences.lastReadPaused;
                }
            },

            getLastReadAdded: function () {
                var self = this;

                if (self.preferences.lastReadAdded === undefined) {
                    return 0;
                } else {
                    return self.preferences.lastReadAdded;
                }
            },


            setLastRead: function (options) {
                var self = this;

                self.preferences.lastReadBuzz = options.buzz;
                self.preferences.lastReadAdded = options.added;
                self.preferences.lastReadPaused = options.paused;
                registry.trigger("updated:preferences");
            },

            getLastReadDirectOffers: function() {
                var self = this;

                if(self.preferences === null) {
                    return false;
                }else if (self.preferences.lastReadDirectOffers === undefined) {
                    return 0;
                } else {
                    return self.preferences.lastReadDirectOffers;
                }

            },

            setLastReadDirectOffers: function() {
                var self = this;

                if(self.preferences !== null) {
                    self.preferences.lastReadDirectOffers =  Math.round(+new Date()/1000);
                    registry.trigger("updated:preferences");

                }

            },

            pollUserPrefs: function () {
                if (preferencesTimeout) {
                    clearTimeout(preferencesTimeout);
                }
                var self = this,
                    doPoll = function() {
                        if (!self.preferencesLoaded && self.id) {
                            registry.on("updated:preferences", self.saveUserPreferences, self);
                            self.loadPreferences();
                        } else {
                            self.importLocalPreferences();
                        }
                        preferencesTimeout = setTimeout(function () {
                            doPoll();
                        }, 5000);
                    };
                doPoll();
            },

            loadPreferences: function() {
                var self = this;
                var apiRequest = new self.apiRequestModule();
                apiRequest.request("data/partner-preferences.php")
                    .done(function (data) {
                        try {
                            var prefData = JSON.parse(data.data);
                            localStorage.setItem('userPreferences', JSON.stringify(prefData));
                            self.preferences = prefData;
                            self.preferencesLoaded = true;
                            self.hasPreferences.resolve();
                        }
                        catch (e) {
                            console.error("Preferences Data could not be decoded", e);
                            self.hasPreferences.reject();
                        }
                    })
                    .fail(function (err) {
                        self.importLocalPreferences();
                    });
            },

            saveUserPreferences: function () {
                var self = this,
                    req = new self.apiRequestModule();

                if (JSON.stringify(self.preferences) !== null) {
                    req.request("data/partner-preferences.php", {data: JSON.stringify(self.preferences)})
                    .done(function () {
                        localStorage.setItem('userPreferences', JSON.stringify(self.preferences));
                    })
                    .fail(function (err) {
                        console.error("Could not store Preferences", err);
                    });
                }
            },

            importLocalPreferences: function () {
                var self = this;
                if (localStorage.hasOwnProperty('userPreferences')) {
                    self.preferences = JSON.parse(localStorage.getItem('userPreferences'));
                } else {
                    self.preferences = {};
                }
                registry.trigger("updated:preferences");
            },

            updateUnreadOfferMarketTs: function() {
                var self = this,
                    req = new self.apiRequestModule();

                    req.request("v3/publisherUnreadOfferMarketUpdateTs.php", {})
                        .done(function () {
                        })
                        .fail(function (err) {
                        });
                $('.menuOfferMarket').hide();

            }

        },

        hasUserData: null,

    };

    _.extend(registry, Backbone.Events);
    return registry;
});
