// Imports
var ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___ = require("../../node_modules/html-loader/dist/runtime/getUrl.js");
var ___HTML_LOADER_IMPORT_0___ = require("./../../images/totals/clicks.png");
var ___HTML_LOADER_IMPORT_1___ = require("./../../images/totals/leads.png");
var ___HTML_LOADER_IMPORT_2___ = require("./../../images/totals/revenue.png");
var ___HTML_LOADER_IMPORT_3___ = require("./../../images/totals/afflow.png");
var ___HTML_LOADER_IMPORT_4___ = require("./../../images/totals/monetizer.png");
// Module
var ___HTML_LOADER_REPLACER_0___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_0___);
var ___HTML_LOADER_REPLACER_1___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_1___);
var ___HTML_LOADER_REPLACER_2___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_2___);
var ___HTML_LOADER_REPLACER_3___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_3___);
var ___HTML_LOADER_REPLACER_4___ = ___HTML_LOADER_GET_SOURCE_FROM_IMPORT___(___HTML_LOADER_IMPORT_4___);
var code = "<div class=dateSelect></div> <div class=\"panel totals\" style=display:none> <div class=panel-content> <div class=row> <div class=\"col-sm-20 text-center totalClicks\"> <img src=" + ___HTML_LOADER_REPLACER_0___ + " /><br/> <span class=currentLabel>Today</span> Clicks <h4 class=\"value text-success\"></h4> </div> <div class=\"col-sm-20 text-center totalLeads\"> <img src=" + ___HTML_LOADER_REPLACER_1___ + " /><br/> <span class=currentLabel>Today</span> Leads <h4 class=\"value text-success\"></h4> </div> <div class=\"col-sm-20 text-center totalRev\"> <img src=" + ___HTML_LOADER_REPLACER_2___ + " /><br/> <span class=currentLabel>Today</span> Revenue <h4 class=\"value text-success\"></h4> </div> <div class=\"col-sm-20 text-center totalRevAfflow\"> <img src=" + ___HTML_LOADER_REPLACER_3___ + " /><br/> Monetizer <h4 class=\"value text-success\"></h4> </div> <div class=\"col-sm-20 text-center totalRevMonetizer\"> <img src=" + ___HTML_LOADER_REPLACER_4___ + " /><br/> Referrals <h4 class=\"value text-success\"></h4> </div> </div> </div> </div> <div class=\"panel tableContainer leaderboard\"> <table class=\"table leaderboardTable\"> <thead> <tr> <th class=\"afflowRank text-left\">Rank</th> <th title=\"The monetizer user\" class=text-left>Nickname</th> <th class=\"lbYear text-right\">This Year</th> <th class=\"lbAll text-right\">All Time</th> </tr> </thead> <tbody> </tbody> </table> </div> ";
// Exports
module.exports = code;