define([
    "Backbone",
    "registry",
    "workers/apirequest",
    'views/pushLinkModal',
    'views/directLink',
    "templates/pushCollection.html",
], function(Backbone, registry, ApiRequest, pushLinkModalView, DirectLinkView, mainTemplate) {
    var view = Backbone.View.extend({
        step: 1,
        script: '<script>__ALLOW____DENY____TAG____PROMPT__var pm_pid = "__PID__";</script><script src="//__DOMAIN__/js/pub.min.js" async></script>',
        swScript: 'importScripts("https://__DOMAIN__/sw.js");',

        events: {
            'click .nextStepBtn': '_nextStep',
            'click .downloadBtn': '_download',
            'click .copyBtn': '_copy',
            "click .createPushLinkBtn": "_showPushLinkModal",
            "change .promptEnableInput": "_togglePromptDetails"
        },


        render: function() {
            var self = this,
                req = new ApiRequest();
            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("pushCollectionPane")
                .addClass("mainPane")
                .attr("id", "pushCollectionContainer");

            this.$el.html(_.template(mainTemplate));
            $("#appContainer").append(this.$el);

            this.step = 1;

            this.$nextStepBtn = this.$el.find('.nextStepBtn');
            this.$domainInput = this.$el.find('.domainInput');
            this.$scriptInput = this.$el.find('.scriptInput');
            this.$allowRedirectInput = this.$el.find('.allowRedirectInput');
            this.$denyRedirectInput = this.$el.find('.denyRedirectInput');
            this.$tagInput = this.$el.find('.tagInput');
            this.$viewPushStatsBtn = this.$el.find('.viewPushStatsBtn');
            this.$promptEnableInput = this.$el.find('.promptEnableInput');
            this.$promptMsgInput = this.$el.find('.promptMsgInput');
            this.$promptAcceptInput = this.$el.find('.promptAcceptInput');
            this.$promptDenyInput = this.$el.find('.promptDenyInput');
            this.$promptHideDenyInput = this.$el.find('.promptHideDenyInput');

            req.request("data/domains.php", { action: "list" })
                .done(function(data) {
                    self.domains = data.domains || [];
                    var sslDomains = _.where(data.domains, {ssl_enabled: '1'});

                    if(typeof sslDomains !== 'undefined' && sslDomains.length > 0) {
                        _.each(sslDomains, function(domain) {
                            self.$domainInput.append($('<option>', {
                                text: domain.domain,
                                value: domain.domain
                            }));

                        });

                    } else {
                        self.$domainInput.append($('<option>', {
                            text: 'No domains with SSL found.',
                            value: ''
                        }));
                        self.$domainInput.attr('disabled','disabled');
                        self.$nextStepBtn.attr('disabled','disabled');

                    }

                });

            this.pushLinkModal = new pushLinkModalView();
            this._subViews = [];
            this._subViews.push(this.pushLinkModal);
            this.listenTo(this.pushLinkModal, 'created', this.showDirectLink)

        },

        _showPushLinkModal: function() {
            var sslDomains = _.where(this.domains, {ssl_enabled: '1'});
            if (sslDomains && sslDomains.length > 0) {
                this.pushLinkModal.show();
            } else {
                alert(
                    "Please add a domain name to your " +
                    "account and wait until SSL cert generated before generating a link"
                );
                Backbone.history.navigate("domains", { trigger: true });
            }
        },

        showDirectLink: function (linkData) {
            var directLinkView = new DirectLinkView({parent: this, link: linkData, domains: this.domains})
            directLinkView.render();
        },

        _nextStep: function() {
            var self = this,
                allow = '',
                deny = '',
                tag = '',
                prompt = '';

            if (this.step >= 6) {
                return false;
            }

            if (this.step === 3) {
                if(this.$tagInput.val() !== '' && this.$tagInput.val().match("^[A-z0-9_-]+$") === null) {
                    this.$tagInput.addClass('input-error');

                    return false;
                }
            } else if (this.step === 4) {

                if (this.$allowRedirectInput.val() !== '') {
                    allow = "var pm_allowAction = function () { location.href = '"+this.$allowRedirectInput.val()+"'; };";
                }
                if (this.$denyRedirectInput.val() !== '') {
                    deny = "var pm_denyAction = function () { location.href = '"+this.$denyRedirectInput.val()+"'; };";
                }
                if (this.$tagInput.val() !== '') {
                    tag = "var pm_tag = '"+this.$tagInput.val()+"';";
                }

                if (this.$promptEnableInput.is(':checked')) {
                    prompt += 'var pm_enablePrompt = true;';

                    if (this.$promptMsgInput.val() !== '') {
                        prompt += "var pm_promptText = '"+this.$promptMsgInput.val().replace(/(['])/g,"\\$1")+"';";
                    }

                    if (this.$promptAcceptInput.val() !== '') {
                        prompt += "var pm_promptAcceptBtnText = '"+this.$promptAcceptInput.val().replace(/(['])/g,"\\$1")+"';";
                    }

                    if (this.$promptDenyInput.val() !== '') {
                        prompt += "var pm_promptDenyBtnText = '"+this.$promptDenyInput.val().replace(/(['])/g,"\\$1")+"';";
                    }

                    if (this.$promptHideDenyInput.is(':checked')) {
                        prompt += "var pm_promptHideDeny = true;";
                    }
                }

                this.$scriptInput.val(this.script
                    .replace('__ALLOW__', allow)
                    .replace('__DENY__', deny)
                    .replace('__TAG__', tag)
                    .replace('__PROMPT__', prompt)
                    .replace('__PID__',registry.user.id+'-'+this.makeRand(8))
                    .replace('__DOMAIN__',this.$domainInput.val()));

            }

            this.$el.find('.step[data-step="'+this.step+'"]').fadeOut('fast',function() {
                self.step += 1;
                self.$el.find('.step[data-step="'+self.step+'"]').fadeIn('fast');
                if(self.step === 6) {
                    self.$nextStepBtn.html('<i class="fa fa-check"></i> Completed').prop('disabled', true);
                    self.$viewPushStatsBtn.show();
                }
            });

        },

        makeRand: function(length) {
            return Math.trunc((Math.random() * 0x100000000 + 0x100000000)).toString(16).substr(-8);
        },

        _download: function() {
            var element = document.createElement('a');
            element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(this.swScript.replace('__DOMAIN__',this.$domainInput.val())));
            element.setAttribute('download', 'sw.js');

            element.style.display = 'none';
            document.body.appendChild(element);

            element.click();

            document.body.removeChild(element);

        },

        _copy: function() {
            /* Get the text field */
            var copyText = document.getElementsByClassName('scriptInput')[0];

            /* Select the text field */
            copyText.select();
            copyText.setSelectionRange(0, 99999); /*For mobile devices*/

            /* Copy the text inside the text field */
            document.execCommand("copy");

            /* Alert the copied text */
            alert("Script copied to clipboard.");


        },

        _togglePromptDetails: function() {
            this.$el.find('.promptDetails').slideToggle('fast');

        },


        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        }
    });

    return new view();
});
