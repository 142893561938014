/*jslint nomen:true, indent:4*/
/*jshint -W079 */
/*global define, Backbone, alert, console*/

define([
	"workers/apirequest",
	"templates/tosmodal.html",
	"templates/tos.html"
	], function (ApiRequest, tosModalTemplate, tosTemplate) {
	
	var View = Backbone.View.extend({
	
		// Constants
	
		// Properties
		
		// Events
		events: {
			"click #tos_agreeButton": "clickAgree",
			"click #tos_disagreeButton": "clickDisagree"
		},


		// Methods
		initialize: function () {

            this.setElement($(document.createElement("div")));
            this.$el.append(_.template(tosModalTemplate)());
            $("body").append(this.$el);

            this.$overlay = this.$el.find('.overlay');
			this.$el.find("#tos").append($(tosTemplate).html());


		},

		show: function() {
            $('html, body').addClass('overflow-hidden');
			this.$overlay.fadeIn("fast");
		},

		hide: function() {
            $('html, body').removeClass('overflow-hidden');
			this.$overlay.fadeOut('fast');
		},

		clickAgree: function() {
			var self = this,
				req = null;

			req = new ApiRequest().request("data/partner.php", {
			    action: 'tos',
				tos_accepted: 1
			})
			.done(function () {
				self.$overlay.fadeOut("fast", function () {
					self.trigger("accepted");
					self.$el.remove();
				});
			})
			.fail(function (err, res, body) {
				alert("Something went wrong, please try again or contact the system administrator");
				console.error(err, res, body);
			});

		},

		clickDisagree: function() {
			this.$overlay.fadeOut("fast");
		},


	
	});


	return new View();
});

