define([
    "backbone",
    "templates/tos.html"
], function(
    backbone,
    mainTemplate
) {
    var tosView = Backbone.View.extend({


        initialize: function() {

        },

        render: function () {

            this.setElement($(document.createElement("div")));
            this.$el.addClass("mainPane").attr("id", "tosContainer");
            this.$el.append(mainTemplate);
            $("#appContainer").append(this.$el);
        },

    });

    return new tosView();
});
