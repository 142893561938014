/*jslint nomen:true, indent:4*/
/*global define, console, _*/

define([
    "Backbone",
    "workers/apirequest",
    "registry",
    "polyfills/formatk",
    "views/pureSelect",
    "templates/snapshotTemplate.html",
    "templates/snapshotPrepRowTemplate.html",
    "templates/buzzLeadTemplate.html"
], function (Backbone, ApiRequest, registry, formatThousands, PureSelect, snapshotTemplate, prepTemplate, buzzLeadTemplate) {

    var View = Backbone.View.extend({

        _subViews: [],

        // Constants
        REFRESH_INTERVAL: (10000),
        LEAD_REFRESH_INTERVAL: (5000),
        PAYOUT_STR_STEM: "Payout: $",
        SEARCH_DEFAULT_TEXT: "Search for a country or vertical",
        SNAPSHOT_TEXT: "Snapshot",
        EP_MODIFIER_POST_TEXT: "hr ",

        // Properties
        epcMap: null,
        hasInitialData: null,
        timeoutInstance: null,
        leadTimeoutInstance: null,
        payoutData: null,
        topBarHtml: null,
        paneDims: {},
        lastSeenListId: null,
        $splitListLeft: null,
        $splitListRight: null,
        $splitListLeftInner: null,
        $splitListRightInner: null,
        $smartLegend: null,
        $header: null,
        $dropdownContainer: null,
        splitArr: null,
        sortingSelect: null,
        $limitSelect: null,
        $timeCheck: null,
        $searchCheck: null,
        $tickerContainer: null,
        $rightLegend: null,
        $loader: null,

        isAsleep: false,
        initialLoad: false,

        // Events
        events: {
            "click .splitName": "clickMakeLink",
            "click .hotBarGEO a": "clickMakeLink",
            "click .offerPlanner": "clickStats",
            "click .flagSettings": "clickStats",
            "focus .searchCheck": "focusSearch",
            "blur .searchCheck": "blurSearch",
            "keyup .searchCheck": "keyupSearch"
        },


        // Methods
        initialize: function () {
        },

        getData: function () {
            var self = this,
                req = new ApiRequest(),
                dfd = $.Deferred(),
                timescale = this.$timeCheck.val() || "applied";

            this.showLoading();
            $.when(req.request("data/partner-rid-report.php?type=1&timescale=" + timescale)).done(function (data) {
                self.hideLoading();
                if (data !== false) {
                    self.splitRows = data;
                    dfd.resolve();
                }
                else {
                    dfd.reject();
                    // alert("There was an error getting the overview data, please close and re-open Afflow.");
                    console.log("error");
                }
            });

            return dfd.promise();
        },

        getLeads: function() {
            var req = new ApiRequest(),
                self = this;

            $.when(req.request("data/partner-lead-update.php")).done(function (data) {
                var docFragment = null;

                if (data === false) {
                    console.error("Error pulling lead data");
                } else {
                    if (_.isArray(data) && data.length > 0) {

                        if (self.lastSeenListId !== parseInt(data[0].lead_id, 10)) {
                            docFragment = $(document.createDocumentFragment());
                            self.lastSeenListId = data[0].lead_id;
                            self.$tickerContainer.find(".marquee").find("li").remove();

                            _.each(data, function (row) {
                                if (registry.user.hasVerticalAccess(row.vertical.toLowerCase())) {

                                    docFragment.append(_.template(buzzLeadTemplate)({
                                        flagPath: require('./../../images/flags/'+row.country_code+'.png').default,
                                        theGeo: row.country_code,
                                        vertical: "#" + row.vertical.toLowerCase(),
                                        amount: parseFloat(row.amount).toFixed(2)
                                    })
                                    );
                                }
                            });
                            self.$tickerContainer.find(".marquee").append(docFragment);
                        }
                    } else {
                        // console.log("nothing new", data);
                    }
                }
            });
        },

        showSnapshot: function () {
            var self = this,
                docFragment = document.createDocumentFragment();

            if (registry.user.getAccessLevel() === 'basic') {
                return;
            }

            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("mainPane")
                .attr("id", "homeContainer");
            this.$el.append(_.template(snapshotTemplate)());
            $('#appContainer').append(this.$el);

            this.$header = $(document.createElement("h1"))
                .text(this.SNAPSHOT_TEXT)
                .appendTo(docFragment);



            this.$timeCheck = new PureSelect()
                .placeholder("Please Select")
                .addClass("snapshotTimeSelect")
                .updateData({
                    "applied": "Sort by Applied",
                    "24": "Sort by 24 Hours",
                    "48": "Sort by 48 Hours",
                    "72": "Sort by 72 Hours",
                    "168": "Sort by 7 Days",
                    "336": "Sort by 14 Days"
                }, "168")
                .appendTo(this.$el.find('.snapshotTimeSelectWrapper'))
                .on("change", this.changeTime, this);
            this._subViews.push(this.$timeCheck);


            this.$sortingSelect = new PureSelect()
                .placeholder("Please Select")
                .addClass("snapshotSortingSelect")
                .updateData({
                    "3": "Sort by Clicks",
                    "2": "Sort by Revenue"
                }, "2")
                .appendTo(this.$el.find('.snapshotSortingSelectWrapper'))
                .on("change", this.changeSort, this);
            this._subViews.push(this.$sortingSelect);

            this.$dlCheck = new PureSelect()
                .placeholder("Please Select")
                .addClass("snapshotSmartLinkSelect")
                .updateData({
                    "1": "Show Smart Links",
                    "0": "Hide Smart Links"
                }, "1")
                .appendTo(this.$el.find('.snapshotSmartLinkSelectWrapper'))
                .on("change", this.changeShowStickies, this);
            this._subViews.push(this.$dlCheck);

            this.$limitSelect = new PureSelect()
                .placeholder("Please Select")
                .addClass("snapshotLimitSelect")
                .updateData({
                    "30": "Show 30 Campaigns",
                    "60": "Show 60 Campaigns",
                    "10000000": "Show All Campaigns",
                }, "30")
                .appendTo(this.$el.find('.snapshotLimitSelectWrapper'))
                .on("change", this.changeLimit, this);
            maxShow = parseInt(self.$limitSelect.val(), 10);
            this._subViews.push(this.$limitSelect);

            this.$searchCheck = this.$el.find('.searchCheck');


            this.$tickerContainer = this.$el.find('.homeHead');

            this.$hotBarContainer = this.$el.find('.homeHotBar');


            this.$smartLinkContainer = this.$el.find('.smartLinkContainer');



            // Add the splitLists


            this.$splitListLeftInner = this.$el.find('.splitListLeftInner');
            this.$leftLegend = this.$el.find('.leftLegend');

            this.$splitListRightInner = this.$el.find('.splitListRightInner');
            this.$rightLegend = this.$el.find('.rightLegend')


            self.hasInitialData = this.getData();
            this.getLeads();
            self.payoutData = {};
            self.splitArr = [];

            self.hasInitialData.done(function () {
                self.initialLoad = true;
                self.render();
            }).always(function () {
                self.startPoll.call(self, true);
            });
        },


        render: function () {
            var self = this,
                maxShow = parseInt(self.$limitSelect.val(), 10),
                toShow = [];



            this.splitArr = [];
            this.$splitListLeftInner.find(".split").remove();
            this.$splitListRightInner.find(".split").remove();
            this.$smartLinkContainer.find(".split").remove();

            $.when(this.hasInitialData).done(function () {
                var sortMethod = self.sortByRevenue,
                    searchRegex = null,
                    stickies = [],
                    searchResults = [];

                 // Search filter in place?
                if (self.$searchCheck.val() !== "" && self.$searchCheck.val() !== self.SEARCH_DEFAULT_TEXT) {
                    searchRegex = new RegExp("^" + self.$searchCheck.val() + "$", "i");
                }


                // Apply Filters
                _.each(self.splitRows, function (row) {

                    if (registry.user.hasVerticalAccess(row.vertical.toLowerCase())) {

                        // Populate Stickies
                        if (row.sticky | 0) {
                            if (row.primary_vertical | 0) {
                                stickies.push(row);
                            }
                            return true;
                        }

                        // If its come up as a search hit then pop it inside the search results array
                        if(searchRegex !== null) {
                            if (searchRegex.test(row.name.substr(0,2)) === false && searchRegex.test(row.vertical) === false) {
                                // return true;
                            } else {
                                searchResults.push(row);
                            }
                        }

                        toShow.push(row);
                    }
                });


                // First pass - left container
                switch(self.$sortingSelect.val()) {
                    case '2':
                        sortMethod = self.sortByRevenue;
                        self.$leftLegend.text("By Revenue");
                        self.$el.find('.leftColTitle').html('Rev.');
                        break;

                    case '3':
                        sortMethod = self.sortByClicks;
                        self.$leftLegend.text("By Clicks");
                        self.$el.find('.leftColTitle').html('Clicks');
                        break;
                }


                // Do the sort
                toShow = toShow.sort(sortMethod);

                var method = 1;
                // Sort each row out
                var leftFragment = $(document.createDocumentFragment());
                _.each(toShow, function (row, index) {
                    if (index < maxShow) {
                        method = self.$sortingSelect.val();
                        leftFragment.append(self.prepareRow(row, method));
                    }
                });
                self.$splitListLeftInner.append(leftFragment);

                // Sort stickies
                var stickyFragment = $(document.createDocumentFragment());
                _.each(stickies, function (row) {
                    method = self.$sortingSelect.val();
                    stickyFragment.append(self.prepareRow(row, method));
                });
                self.$smartLinkContainer.append(stickyFragment);


                // Sort search ting
                var rightFragment = $(document.createDocumentFragment());
                if (_.size(searchResults) > 0) {
                    self.$rightLegend.text("Results");


                    // Formatting for the other views (clicks, revenue)
                    switch (self.$sortingSelect.val()) {

                        case "1":
                            self.$el.find('.rightColTitle').html('EPC');
                            break;

                        case "2":
                            self.$el.find('.rightColTitle').html('Rev.');
                            break;

                        case "3":
                            self.$el.find('.rightColTitle').html('Clicks');
                            break;
                    }



                    searchResults.sort(self.sortByRevenue);
                    _.each(searchResults, function (row, index) {
                        if (index < maxShow) {
                            method = self.$sortingSelect.val();
                            rightFragment.append(self.prepareRow(row, method));
                        }
                    });
                } else {
                    self.$rightLegend.text("By EPC");
                    self.$el.find('.rightColTitle').html('EPC');

                    // Filter out based on minval
                    toShow = _.filter(toShow, function (row) {
                        return row.clicks >= 5000;
                    });

                    toShow = toShow.sort(self.sortByEPC);
                    _.each(toShow, function (row, index) {
                        if (index < maxShow) {
                            method = "1";
                            rightFragment.append(self.prepareRow(row, method));
                        }
                    });
                }

                self.$splitListRightInner.append(rightFragment);

                // Sort again for leads
                toShow.sort(self.sortByLeads);
                toShow = toShow.slice(0, 5);
                self.$hotBarContainer.html('');
                _.each(toShow, function(row) {
                    row.displayType = 'snapshot';
                    row.theGeo = row.name;
                    row.flagPath = require('./../../images/flags/'+row.name+'.png').default;
                    self.$hotBarContainer.append(_.template(buzzLeadTemplate)(row));

                })

            });

            return this.$el;
        },


        prepareRow: function(row, mode) {
            var self = this,
                rEpEl = null,
                rPayoutEl = null,
                rPlannerEl = null,
                epString = "Earnings Per Click: ",
                epType = "EPC: ",
                bgCss = null,
                timeWarning = null,
                epFigure = row.epc !== undefined ? parseFloat(row.epc).toFixed(2) : 0.00;


            // Formatting for the other views (clicks, revenue)
            switch (mode) {

                case "1":
                    epString = "EPC";
                    epType = "EPC: ";
                    epFigure = "$" + parseFloat(row.epc).toFixed(5);
                    rEpEl = $(document.createElement("div"))
                        .text(epType + epFigure)
                            .addClass("epcFigure");
                    break;

                case "2":
                    if (row.revenue >= 0.01) {
                        epString = "Revenue";
                        // epType = epModifierStr + "Revenue: ";
                        epType = "Revenue: ";
                        epFigure = "$" + formatThousands(row.revenue);
                        rEpEl = $(document.createElement("div"))
                            .text(epType + epFigure)
                            .addClass("epcFigure");
                    }
                    break;

                case "3":
                    if (row.clicks >= 1) {
                        epString = "Clicks";
                        // epType = epModifierStr + "Clicks: ";
                        epType = "Clicks: ";
                        epFigure = formatThousands(row.clicks);
                        rEpEl = $(document.createElement("div"))
                            .text(epType + epFigure)
                            .addClass("epcFigure");
                    }
                    break;
            }

            if (row.clicks < 999) {
                rEpEl = $(document.createElement("div"))
                    .text("Low Traffic Test Me!")
                    .addClass("epcFigure")
                    .addClass("lowTraffic");
            } else if (row.clicks < 1999) {
                rEpEl = $(document.createElement("div"))
                    .text("Low Traffic Test Me!")
                    .addClass("epcFigure")
                    .addClass("awaitingTraffic");
            }

            // Prepare the payout EL
            if (row.avg_pay !== undefined && !isNaN(parseFloat(row.avg_pay))) {
                rPayoutEl = $(document.createElement("div"))
                    .text(self.PAYOUT_STR_STEM + parseFloat(row.avg_pay).toFixed(2))
                    .addClass("payoutFigure");
            }

            if (parseInt(self.$timeCheck.val(), 10) !== parseInt(row.time_split, 10) && parseInt(self.$timeCheck.val(), 10) !== 0) {
                timeWarning = $(document.createElement("div"))
                    .addClass("timeWarning")
                    .attr("title", "Caution: this campaign is set up to use " + row.time_split + "hr data to decide which offers are performing best. Launching campaigns based on this data may yield unexpected results.")
                    .text("" + row.time_split + "hr");
            } else {
                timeWarning = null;
            }

            // if (!(parseInt(row.sticky, 10) === 1 && parseInt(row.exclusive, 10) === 1)) {
            //     rPlannerEl = $(document.createElement("div"))
            //         .text('Planner')
            //         .addClass("offerPlanner");
            // } else {
            //     rPayoutEl = null;
            //     rEpEl = null;
            //     timeWarning = null;
            // }


            rPlannerEl = $(document.createElement("div"))
                .text('Planner')
                .addClass("offerPlanner");

            if (parseInt(row.sticky, 10) === 1 && parseInt(row.exclusive, 10) === 1) {
                rPayoutEl = null;
                rEpEl = null;
                timeWarning = null;
            }


            if (row.name.length === 2 || row.name[2] === " ") {
                bgCss = {"background-image": "url(images/flags/" + row.name.substr(0,2) + ".png)"};
            } else {
                bgCss = {};
            }


            return _.template(prepTemplate)({
                name: row.name,
                flagPath: require('./../../images/flags/'+row.name+'.png').default,
                vertical: row.vertical,
                timeWarning: timeWarning,
                sticky: row.sticky,
                exclusive: row.exclusive,
                payout: row.avg_pay,
                clicks: row.clicks,
                revenue: row.revenue,
                id: row.id,
                epString: epString,
                epc: row.epc

            });




        },


        clickMakeLink: function (e) {
            var $splitEl = $(e.currentTarget).closest(".split"),
                vertical = null;

            if ($splitEl.length === 0) {
                $splitEl = $(e.currentTarget).closest(".hotBarItem");
                if ($splitEl.length === 0) {
                    console.error("Cannot find the parent object for this");
                }
            }

            vertical = $splitEl.data("vertical");


            if (vertical !== undefined) {
                Backbone.history.navigate('links/new/'+vertical, {trigger: true});
            } else {
                console.error("Cannot find vertical for this");
            }
        },

        changeSort: function () {
            this.render();
        },

        changeLimit: function () {
            this.render();
        },

        changeTime: function () {
            var dfd = this.getData(),
                self = this;

            dfd.done(function () {
                self.render.call(self);
            });
        },

        // In the event that no row can be found in the hourly table EPC will be undefined
        sortByEPC: function (a, b) {
            var aEpc = 0,
                bEpc = 0;

            if (parseInt(a.sticky, 10) === 1) {
                return -1;
            } else if (parseInt(b.sticky, 10) === 1) {
                return 1;
            } else {
                aEpc = isFinite(parseFloat(a.epc)) ? parseFloat(a.epc) : 0;
                bEpc = isFinite(parseFloat(b.epc)) ? parseFloat(b.epc) : 0;
                return parseFloat(aEpc) < parseFloat(bEpc) ? 1: -1;
            }
        },

        sortByLeads: function(a, b) {
            var aLeads = 0,
                bLeads = 0;

            if (parseInt(a.sticky, 10) === 1) {
                return -1;
            } else if (parseInt(b.sticky, 10) === 1) {
                return 1;
            } else {
                aLeads = isFinite(parseInt(a.leads, 10)) ? parseInt(a.leads, 10) : 0;
                bLeads = isFinite(parseInt(b.leads)) ? parseInt(b.leads, 10) : 0;
                if (parseInt(aLeads) < parseInt(bLeads)) {
                    return 1;
                } else if (parseInt(aLeads) === parseInt(bLeads)) {
                    return 0;
                } else {
                    return -1;
                }
            }
        },


        sortByClicks: function(a, b) {
            var aClicks = 0,
                bClicks = 0;

            if (parseInt(a.sticky, 10) === 1) {
                return -1;
            } else if (parseInt(b.sticky, 10) === 1) {
                return 1;
            } else {
                aClicks = isFinite(parseFloat(a.clicks)) ? parseFloat(a.clicks) : 0;
                bClicks = isFinite(parseFloat(b.clicks)) ? parseFloat(b.clicks) : 0;
                return parseFloat(aClicks) < parseFloat(bClicks) ? 1: -1;
            }
        },

        sortByRevenue: function (a, b) {
            var aRev = 0,
                bRev = 0;

            if (parseInt(a.sticky, 10) === 1) {
                return -1;
            } else if (parseInt(b.sticky, 10) === 1) {
                return 1;
            } else {
                aRev = isFinite(parseFloat(a.revenue)) ? parseFloat(a.revenue) : 0;
                bRev = isFinite(parseFloat(b.revenue)) ? parseFloat(b.revenue) : 0;
                return parseFloat(aRev) < parseFloat(bRev) ? 1: -1;
            }
        },

        startPoll: function () {
            var self = this,
                callback = function () {
                    var dfd = null;

                    if (!self.isAsleep && document.hasFocus()) {
                        dfd = self.getData.call(self);
                        dfd.done(function () {
                            self.render.call(self);
                        });
                    }

                    window.clearTimeout(self.timeoutInstance);
                    self.timeoutInstance = window.setTimeout(callback, self.REFRESH_INTERVAL);
                },
                leadCallback = function () {
                    if (!self.isAsleep && document.hasFocus()) {
                        self.getLeads.call(self);
                    }

                    window.clearTimeout(self.leadTimeoutInstance);
                    self.leadTimeoutInstance = window.setTimeout(leadCallback, self.LEAD_REFRESH_INTERVAL);
                };

            window.clearTimeout(this.timeoutInstance);
            window.clearTimeout(this.leadTimeoutInstance);
            this.timeoutInstance = window.setTimeout(callback, this.REFRESH_INTERVAL);
            this.leadTimeoutInstance = window.setTimeout(leadCallback, this.LEAD_REFRESH_INTERVAL);
        },


        clickStats: function (e) {
            var element = $(e.currentTarget).closest(".split"),
                cid = null,
                name = null,
                vertical = null,
                statsTargetObject = {};

            if (element.length === 0) {
                element = $(e.currentTarget).closest(".hotBarItem");
                if (element.length === 0) {
                    console.error("cannot find parent for this");
                }
            }

            cid = element.data("cid");

            if (cid === undefined) {
                console.error("cannot find cid for this");
                return false;
            }

            name = element.data("name");

            if (name === undefined) {
                console.error("cannot find name for this");
                return false;
            }

            vertical = element.data("vertical");

            if (vertical === undefined) {
                console.error("cannot find vertical for this");
                return false;
            }

            if (element.hasClass("sticky")) {
                statsTargetObject.reportType = "vertical";
            } else {
                statsTargetObject.reportType = "rotator";
            }

            statsTargetObject.rid = cid;
            statsTargetObject.name = name;
            statsTargetObject.vertical = vertical;

            registry.platformStatsView.setTarget(statsTargetObject);
            registry.platformStatsView.show();
        },

        changeShowStickies: function(newVal) {
            if (newVal === "0") {
                this.$smartLinkContainer.hide();
            } else {
                this.$smartLinkContainer.show();
            }
        },


        focusSearch: function(e) {
            var el = $(e.currentTarget);
            if (el.val() === this.SEARCH_DEFAULT_TEXT) {
                el.val("");
            }
        },

        blurSearch: function(e) {
            var el = $(e.currentTarget);
            if (el.val() === "") {
                el.val(this.SEARCH_DEFAULT_TEXT);
            }
        },

        keyupSearch: function(e) {
            if (e.which === 13) {
                this.render();
            }
        },

        showLoading: function() {
            if (this.initialLoad === false) {
                this.$el.find("div.split, div.hotBarItem").remove();

                $(document.createElement("div")).addClass("homeLoading").appendTo([
                    this.$splitListLeftInner,
                    this.$splitListRightInner,
                    this.$smartLinkContainer,
                    this.$hotBarContainer.find(".hotBar")
                ]);
            }
        },

        hideLoading: function () {
            this.$el.find(".homeLoading").remove();
        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function () {
            this.isAsleep = false;
        }



    });

    return new View();
});

