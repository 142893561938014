/*jslint nomen:true, indent:4*/
/*global define, _, alert, console */

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "moment-timezone",
    "views/message",
    "templates/messages.html",
    "templates/messagesRow.html"
], function (Backbone, registry, ApiWorker, moment, messageView, template, itemTemplate) {

    var View = Backbone.View.extend({

        events: {
            "click .messagesHide": "hide",
            "click .notification": "_showMessage",
            "keyup .messagesSearchInput": "searchKeyUpHandler"
        },

        limit: 25,
        page: 0,
        total: 0,
        notificationList: [],
        visible: false,
        searchTerm: '',

        // Methods
        initialize: function () {
            var self = this;

            if(registry.user.allowMessages !== 1) {
                $('#messagesShowLink').hide();
                return false;
            }

            this.setElement(_.template(template)());

            // PROPERTIES
            this.$el = $(this.el);
            this.$listContainer = this.$el.find(".messagesList");
            this.rowTemplate = _.template(itemTemplate);
            this.$emptyMsg = this.$el.find('.messagesEmptyMsg');
            this.pollMessagesTimeout = null;

            $("body").append(this.el);
            this.hide();

            registry.on("updated:messages", function (messages) {
                var unreadRows = _.where(messages,{read_ts:null}),
                    unread  = unreadRows.length;

                if(unread > 10) {
                    $("#messagesShowLink .dot").html('10+');
                } else if(unread > 0) {
                    $("#messagesShowLink .dot").html(unread);
                } else {
                    $("#messagesShowLink .dot").html('');
                }

            });


            $("#messagesShowLink").on("click", function () {
                if (self.$el.is(":visible")) {
                    self.hide();
                } else {
                    self.show();
                }
            });


            this.pollMessages();

        },

        searchKeyUpHandler: function(ev) {
            this.searchTerm = ev.currentTarget.value;
            if (ev.keyCode === 13) {
                this.page = 0;
                this._getRows();
            }
        },


        nNotificationsShown: function () {
            return this.$listContainer.children(".notification").length;
        },


        clickNotification: function (el) {
            var self = this,
                cid = $(el.currentTarget).data("cid");

            api.post("messages", {
                "action": "archive",
                "notification": cid
            }).done(function () {
                $(el.currentTarget).closest(".notification").remove();
                if (self.nNotificationsShown() == 0) {
                    self.$emptyMsg.show();

                }
            });
        },

        show: function () {
            var self = this,
                $appContainer = $("#appContainer");

            this.visible = true;
            this.page = 0;

            if ($appContainer) {
                this.$el.css("left", $appContainer.position().left);
                this.$el.css("top", $appContainer.position().top);
            }

            this.$el.show();
            self.hideOnClickOutside('#messagesContainer');

            this.$listContainer.empty();
            this._getRows();
        },


        hide: function () {
            this.$el.hide();
        },

        pollMessages: function() {
            if (this.pollMessagesTimeout) {
                clearTimeout(this.pollMessagesTimeout);
            }
            var self = this,
                apiRequest = new ApiWorker();

            doPoll = function() {

                if(registry.user.id === null || !document.hasFocus()) {
                    self.pollMessagesTimeout = setTimeout(function () {
                        doPoll();
                    }, 5000);

                } else {
                    apiRequest.request("v3/messages.php")
                        .done(function (data) {
                            try {
                                registry.trigger("updated:messages", data);
                            }
                            catch (e) {
                                console.error("Messages API failed", e);
                            }
                        })
                        .fail(function (err) {
                            if(err.status === 404) {
                                console.error("Messages API missing", e);
                            }
                        })
                        .always(function () {
                            self.pollMessagesTimeout = setTimeout(function () {
                                doPoll();
                            }, 5000);
                        });
                };

                }

            doPoll();
        },

        _getRows: function() {
            var self = this,
                apiRequest = new ApiWorker(),
                apiData = {
                    'limit': this.limit,
                    'page': this.page
                },
                qString = '';

            if(this.searchTerm.length > 0) {
                apiData.search = this.searchTerm;
            }

            this.$emptyMsg.hide();

            qString = new URLSearchParams(apiData);

            apiRequest.request("v3/messages.php?"+qString.toString())
                .then(function(response) {

                    if(self.page === 0) {
                        self.$listContainer.empty();
                    }


                    if(response.length > 0) {
                        self.$el.find(".emptyMessage").hide();

                        self.resultData = response;

                        _.each(response, function(row) {
                            row.mo = moment(row.send_ts);

                            if(row.body.length > 100)  {
                                row.body = row.body.substr(0,97)+'...';
                            }

                            self.$listContainer.append(self.rowTemplate(row));
                        });


                    } else {
                        self.$emptyMsg.show();

                    }

                });

        },
        _showMessage: function(ev) {
            var id = ev.currentTarget.dataset.id,
                message = _.findWhere(this.resultData,{id:id}),
                $msg = $(ev.currentTarget).find('p');

            $msg.removeClass('text-bold');

            new messageView({
                network_id: message.network_id,
            });

        },
        hideOnClickOutside: function (selector) {
            var self = this;
            function outsideClickListener(event) {
                var $target = $(event.target);
                if(!$target.closest(selector).length && !$target.closest('#messagesShowLink').length && $(selector).is(':visible')) {
                    self.hide();
                }
            }
            document.addEventListener('click', outsideClickListener)
        },
    });

    return new View();
});
