/*jslint nomen:true, indent:4*/
/*global define, _, alert, console */

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/filteredLeaguesTemplate.html",
    "templates/filteredLeaguesRowTemplate.html"
], function (Backbone, registry, ApiRequest, filteredLeaguesTemplate, rowTemplate) {

    var View = Backbone.View.extend({

        // Constants

        // Properties
        data: null,
        $overlay: null,
        $table: null,
        $tbody: null,
        $header: null,


        nid: null,
        postBackTimeout: null,


        // Events
        events: {
            "click .closeButton": "hide",
            "click .unfilterLink": "clickUnfilter",
            "focus .postbackInput": "focusInput",
            "keyup .postbackInput": "postBackInputChanged",
            "click .manageMacros": "clickMacro",
        },


        // Methods
        initialize: function () {
            var self = this,
                inner = null,
                closeButton = null;

            this.rowTemplate = _.template(rowTemplate);

            this.setElement($(document.createElement("div")));
            this.$el.append(_.template(filteredLeaguesTemplate)());
            $("#appContainer").append(this.$el);

            inner = this.$el.find('.inner');
            closeButton = this.$el.find('.closeButton');
            this.$overlay = this.$el.find('.overlay');
            this.$tbody = this.$el.find('.filteredLeagueTable');


            $(document).on("keydown", function (e) {
                self.keyPressed.call(self, e);
            });

        },

        focusInput: function(ev) {
            this.$focusInput = $(ev.currentTarget);

        },

        update: function () {
            var self = this,
                rNewRow = null,
                rNidInfo = null,
                leagues = [],
                rFilterText = null,
                filterMap = null;

            this.$tbody.empty();
            registry.user.hasPreferences.always(function () {

                filterMap = registry.user.getLeagueFilterMap();

                if (registry.user.nidAssignmentArr.length > 1) {
                    registry.user.nidAssignmentArr.forEach(function (nid) {
                        rNidInfo = registry.user.getInfoForNid(nid);
                        leagues.push(rNidInfo);
                    });

                    leagues = _.sortBy(leagues, 'name');

                    leagues.forEach(function (league) {
                        if (filterMap[league.nid] !== undefined) {
                            rFilterIcon = "fa-eye";
                            rFilterText = 'Show';
                            rFilterClass = 'btn-success';
                        } else {
                            rFilterIcon = "fa-eye-slash";
                            rFilterText = 'Hide';
                            rFilterClass = 'btn-danger';
                        }

                        rNewRow = $(self.rowTemplate({
                            nid: league.nid,
                            name: league.name,
                            filterText: rFilterText,
                            filterIcon: rFilterIcon,
                            filterClass: rFilterClass,
                            postBack: league.postback_url,
                        }));

                        rNewRow.data("cid", league.nid);
                        self.$tbody.append(rNewRow);

                    });
                } else {
                    self.$tbody.append($(document.createElement("tr"))
                        .html("<td class=\"noData\" colspan=\"100%\" style=\"text-align:center;\" class=\"text-muted\">You have no filters set yet.</td>")
                    );
                }
            });


        },

        // setTarget: function (options) {
        //     this.nid = options.nid;
        //     this.name = options.name;
        // },

        show: function () {
            $("html, body").addClass("overflow-hidden");
            this.$overlay.fadeIn('fast');
            this.update();
        },


        hide: function () {
            var self = this;

            $("html, body").removeClass("overflow-hidden");
            self.$overlay.fadeOut('fast', function() {
                self.$tbody.empty();
            });
        },

        clickUnfilter: function (e) {
            var row = $(e.currentTarget).closest('tr'),
                nid = row.data('cid'),
                filterMap = registry.user.getLeagueFilterMap();

            if (filterMap[nid] !== undefined) {
                registry.user.unfilterLeague(nid);
            } else {
                // There must be atleast one unfiltered league
                if (_.size(filterMap) + 1 === registry.user.nidAssignmentArr.length) {
                    alert("You need to have at least one active league");
                } else {
                    registry.user.filterLeague(nid);
                }
            }

            this.update();
            this.trigger("update");
        },

        clickMacro: function(e) {
            var caretPos = this.$focusInput[0].selectionStart,
                value = this.$focusInput.val(),
                macro = $(e.currentTarget).html();

            this.$focusInput.val(value.substring(0, caretPos) + macro + value.substring(caretPos));

        },

        keyPressed: function(e) {
            if (e.which === 27) {
                this.hide();
            }
        },

        postBackInputChanged: function (e) {
            clearTimeout(this.postBackTimeout);

            this.postBackTimeout = setTimeout(function () {
                var $target = $(e.currentTarget),
                    nid = null,
                    updateReq = new ApiRequest();

                if ($target.closest("tr").length >= 1 && $target.closest("tr").data("nid") !== undefined) {
                    nid = parseInt($target.closest("tr").data("nid"), 10);

                    console.log("submitting", $target.val());
                    updateReq.request("data/partner-league.php", {
                        nid: nid,
                        postback_url: $target.val().trim(),
                    })
                    .done(function () {
                        console.log("Postback Saved");
                        registry.user.getInfoForNid(nid).postback_url = $target.val();
                    })
                    .fail(function (err) {
                        console.error("error sending postback url", err);
                    });

                } else {
                    console.error("missing parent row, or nid", $target.closest("tr"), $target.closest("tr").data());
                }

            }, 1500);
        },


    });

    return new View();
});

