/*jslint nomen:true, indent:4*/
/*global define */

define([
		'Backbone',
		'workers/apirequest',
		'registry',
		'views/pureSelect',
		"libs/uiAlert",
		'templates/newLeagueTemplate.html'
	],
	function (
		Backbone,
		ApiRequest,
		registry,
		PureSelect,
		uiAlert,
		newLeagueTemplate
	)
	{

	var View = Backbone.View.extend({

		// Constants


		// Events
		events: {
			"click .hideButton": "hide",
			"click .createLinkButton": "clickCreate"
		},


		// Methods
		initialize: function () {
			var self = this,
                template = _.template(newLeagueTemplate);


            this.setElement(template());

            $('body').append(this.$el);


			this.$networkSelect = this.$el.find('.networkSelect');
			this.$nameInput = this.$el.find('.nameInput');


			$(document).on("keydown", function (e) {
                self.keyPressed.call(self, e);
            });

		},

		clickCreate: function() {
			var nameParam = "",
				self = this,
				payload = {},
				req = new ApiRequest();

			payload.name = this.$nameInput.val();

			req.request("data/partner-league.php", payload).done(function (data) {
				if(data !== false) {
					registry.user.nidAssignmentArr.push(data.nid);
					registry.user.nidInfoArr.push(data);
					self.trigger("update:leagues", data);
					self.hide();
				}
			}).fail(function(xhr) {
				if (xhr.responseJSON && xhr.responseJSON.message) {
					uiAlert.showError(xhr.responseJSON.message);
				}
			});
		},

		show: function() {
			$("html, body").addClass("noscroll");
			this.$el.fadeIn('fast');
		},

		reposition: function () {
            this.$el.css({
                left: (window.innerWidth / 2) - (this.$el.width() / 2),
                top: (window.innerHeight / 2) - (this.$el.height() / 2)
            });
        },

		hide: function() {
			$("html, body").removeClass("noscroll");
			this.$el.fadeOut('fast');
		},

		keyPressed: function (e) {
            if (e.which === 27) {
                this.$el.fadeOut('fast');
            }
        }


	});


	return new View();

});

