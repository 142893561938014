/*jslint nomen:true, indent:4*/
/*global define, _ */

define(["polyfills/addcomma"], function(addCommas) {
  function Row() {}

  Row.prototype.campaign_id = null;
  Row.prototype.title = null;
  Row.prototype.su = null;
  Row.prototype.epc = null;
  Row.prototype.bid = null;
  Row.prototype.spend = null;
  Row.prototype.paused = null;
  Row.prototype.clicks = null;
  Row.prototype.filtered_clicks = null;
  Row.prototype.leads = null;
  Row.prototype.revenue = null;
  Row.prototype.pay = null;
  Row.prototype.cpa = null;
  Row.prototype.profit = null;
  Row.prototype.cloaked = null;
  Row.prototype.roi = null;
  Row.prototype.naString = "-";

  Row.prototype.forDisplay = function() {
    var clicks = this.clicks,
      filteredClicks = this.filtered_clicks,
      leads = this.leads,
      bid = this.bid,
      revenue = this.revenue,
      spend = this.spend || 0,
      profit = revenue - spend,
      su = (leads / clicks) * 100,
      epc = revenue / clicks,
      cloaked = (filteredClicks / clicks) * 100,
      roi = (profit / spend) * 100,
      cpa = spend / leads,
      pay = revenue / leads,
      na = this.naString;

    return {
      title: this.title,
      bid: _.isFinite(bid) ? bid.toFixed(5) : na,
      su: _.isFinite(su) ? su.toFixed(2) + "%" : na,
      clicks: addCommas(clicks),
      cloaked: _.isFinite(cloaked) ? cloaked.toFixed(2) + "%" : na,
      filtered_clicks: addCommas(filteredClicks),
      leads: addCommas(leads),
      spend: _.isFinite(spend) ? "$" + addCommas(spend.toFixed(2)) : na,
      revenue: "$" + revenue.toFixed(3),
      epc: _.isFinite(epc) ? "$" + epc.toFixed(5) : na,
      pay: _.isFinite(pay) ? "$" + pay.toFixed(2) : na,
      cpa: _.isFinite(cpa) ? "$" + cpa.toFixed(2) : na,
      roi: _.isFinite(roi) ? roi.toFixed(2) + "%" : na,
      profit: _.isFinite(profit) ? "$" + addCommas(profit.toFixed(2)) : na
    };
  };

  return Row;
});
