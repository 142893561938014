define([
    "views/apps/tableView",
    "moment-timezone",
    "views/daterange",
    "workers/apirequest",
    "templates/apps/stats.html",
    "templates/apps/statsRow.html",
    "templates/apps/statsTotalsRow.html",
], function(
    TableView,
    moment,
    DateRange,
    ApiRequest,
    template,
    rowTemplate,
    totalsRowTemplate
) {
    var appsStatsView = TableView.extend({
        id: 'appsStatsContainer',
        collectionName: 'stats',
        template: template,
        rowTemplate: _.template(rowTemplate),
        totalsRowTemplate: _.template(totalsRowTemplate),
        dataEndpointUrl: 'v3/publisherAppsStats.php',

        events: function() {
            return _.extend({}, TableView.prototype.events, {
                'click .refreshBtn': 'loadData'
            });
        },

        initialize: function () {
            TableView.prototype.initialize.apply(this, arguments);
            this.sortBy ='revenue';
        },

        renderSubViews: function () {
            this.dateRange = new DateRange({
                element: this.$el.find('.datetimepicker')
            });
            this.dateRange.on('change', this.loadData, this);
            this._subViews.push(this.dateRange);
            this.dateRange.render();
        },

        getLoadDataParams: function () {
            const range = this.dateRange.get();
            return {
                from: range.start,
                to: range.end,
            };
        },

        castDataRecord: function (record) {
            record.revenueFormatted = (+record.revenue).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
            record.epcFormatted = (+record.epc).toLocaleString('en-US', {
                style: 'currency',
                currency: 'USD'
            });
            record.cvrFormatted = (+record.cvr).toFixed(2) + "%";

            return record;
        },

        onDataStored: function () {
            this.calculateTotals();
        },

        calculateTotals: function () {
            if (this.stats.length > 0) {
                const totals = this.stats.reduce((acc, row) => {
                    acc.clicks += row.clicks;
                    acc.installs += row.installs;
                    acc.leads += row.leads;
                    acc.revenue += row.revenue;

                    return acc;
                }, {clicks: 0, installs: 0, leads: 0, revenue: 0});
                totals.epc = totals.clicks > 0 ? totals.revenue / totals.clicks : 0;
                totals.cvr = totals.clicks > 0 ? 100 * totals.leads / totals.clicks : 0;
                totals.name = '';
                totals.link_id = '';
                this.totals = this.castDataRecord(totals);
            } else {
                this.totals = undefined;
            }
        },

        renderRows: function () {
            TableView.prototype.renderRows.apply(this, arguments);
            const $tableFooter = this.$el.find(".table > tfoot");
            if (this.totals && this.$tableBody.find('tr').length > 0) {
                $tableFooter.html(this.totalsRowTemplate(this.totals));
            } else {
                $tableFooter.empty();
            }
        },
    });

    return new appsStatsView();
});
