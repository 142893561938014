define([
    "Backbone",
    "workers/apirequest",
    "registry",
    "moment-timezone",
    "templates/comments/index.html",
    "templates/comments/addCommentForm.html",
    "templates/comments/comment.html"
], function(
    backbone,
    api,
    registry,
    moment,
    mainTemplate,
    formTemplate,
    commentTemplate
) {
    return Backbone.View.extend({

        // Events
        events:{
            'click .addCommentBtn': '_addComment'

        },

        initialize: function(target, options) {
            var fTemplate =_.template(formTemplate),
                isUser = false;

            this.setElement(target);
            this.$el.html(_.template(mainTemplate));

            this.objectType = options.objectType;
            this.objectId = options.objectId;

            this.$commentsList = this.$el.find('.commentsList');
            this.$noCommentsMsg = this.$el.find('.noCommentsMsg');
            this.$commentsForm = this.$el.find('.commentsForm');

            this._loadComments();
            if (registry.user.id !== null) {
                isUser = true;
            }

            this.$commentsForm.html(fTemplate({isUser:isUser}));
        },

        _loadComments: function() {
            var self = this,
                req = new api(),
                template = _.template(commentTemplate);

            req.request('v3/comments.php?action=comments&object_type='+this.objectType+'&object_id='+this.objectId)
                .then(function(response) {
                    self.$commentsList.empty();
                    if(response.length === 0) {
                        self.$noCommentsMsg.show();
                    } else {
                        self.$noCommentsMsg.hide();
                        var groupedComments = _.groupBy(response, function(row) {
                            if(row.parent_comment_id == null) {
                                return '0';

                            } else {
                                return row.parent_comment_id.toString();

                            }

                        });
                        _.each(groupedComments['0'], function(row) {
                            if(row.user_type == 1) {
                                row.typeLabel = 'Publisher';
                            } else {
                                row.typeLabel = 'Advertiser';
                            }
                            self.$commentsList.append(template(row));
                            if(typeof groupedComments[row.id.toString()] !== 'undefined') {
                                _.each(groupedComments[row.id.toString()], function(subRow) {
                                    if(subRow.user_type == 1) {
                                        subRow.typeLabel = 'Publisher';
                                    } else {
                                        subRow.typeLabel = 'Advertiser';
                                    }
                                    self.$commentsList.append(template(subRow));
                                });
                            }
                        });
                    }
                });

        },

        _addComment: function(ev) {
            var $btn = $(ev.currentTarget),
                self = this,
                req = new api();

            $btn.attr('disabled','disabled');

            if (registry.user.id !== null) {
                req.request('v3/comments.php',{
                    'action': 'add',
                    'object_type': this.objectType,
                    'object_id': this.objectId,
                    'comment': this.$el.find('.commentInput').val()
                })
                    .then(function() {
                        self.$el.find('.commentInput').val();
                        self.$el.find('.commentFormWrapper').hide();
                        self.$el.find('.commentSuccess').show();
                    });


            }
        },

        sleep: function() {},

        wakeUp: function() {}



    });

});
