/*jslint nomen:true, indent:4*/
/*global define, _, alert, console */

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/pidKeywordTool.html",
], function (Backbone, registry, ApiWorker, template) {

    var View = Backbone.View.extend({

        events:  {
            'click .lookupBtn': '_lookup',
        },

        initialize: function () {},

        render: function () {
            this.setElement($(document.createElement("div")));
            this.$el.addClass("mainPage").attr("id", "pidKeywordToolContainer");
            this.$el.append(template);
            $("#appContainer").append(this.$el);

            this.$table = this.$el.find('.resultsTable > tbody');
            this.$pidInput = this.$el.find('.pidInput');

        },

        _lookup: function () {
            var self = this,
                apiRequest = new ApiWorker();

            apiRequest.request("v3/publisherPidSidLookup.php?pid="+this.$pidInput.val().trim())
                .then(function(response) {

                    self.$table.empty();
                    self.$el.find('.resultsPanel').show();

                    _.each(response, function(row) {
                        if(row.kw2 === null) {
                            row.kw2 = '';

                        }

                        self.$table.append('<tr><td>'+row.pid+'</td><td>'+row.sid+'</td><td>'+row.kw2+'</td></tr>');
                    });

                    if(response.length == 0) {
                        self.$table.append('<tr><td colspan="3" class="text-center">No matching PIDs for your search</td></tr>');

                    }
                })
                .fail(function (err) {
                    if (err.responseJSON && err.responseJSON.message) {
                        alert(err.responseJSON.message);
                    } else {
                        console.error(err);
                    }
                });

        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        },

    });

    return new View();
});
