/*jshint nomen:true, indent:4*/
/*global _, define, window, document, $ */

define(["Backbone", "views/league", "views/filteredleagues", "registry", 'templates/leagues.html'], function (Backbone, LeagueView, FilteredLeaguesView, registry, leaguesTemplate) {

    var LeaguesView = Backbone.View.extend({

        // Constants

        // Properties
        _subViews: [],
        showLeagues: null,
        activeLeague: null,
        initComplete: null,
        viewMap: null,
        $leagueSelectContainer: null,

        // Events
        events: {
            "click .nidSelectButton": "nidClicked",
            "change .leagueSelect": "changeLeagueSelect",
            "click .addLeagueButton": "clickAddLeague",
            "click .manageLeaguesButton": "clickManageLeagues",
            "click .exportLeaguesButton": "clickExportLeagues"
        },


        // Methods
        initialize: function () {
            var self = this;
            FilteredLeaguesView.on("update", function () {
                self.updateLeagueSelect();
            });
        },

        render: function () {
            var self = this,
                multiple = false,
                filterMap = null,
                rLeagueView = null,
                // rNidSelectButton = null,
                rNidInfo = null,
                leagues = [],
                mainTemplate = _.template(leaguesTemplate);

            this.initComplete = $.Deferred();
            this.setElement(mainTemplate({
                access: registry.user.getAccessLevel()

            }))
            $('#appContainer').append(this.$el);

            this.$leagueSelect = this.$el.find('.leagueSelect');
            this.updateLeagueSelect();

            registry.user.hasPreferences.always(function () {
                filterMap = registry.user.getLeagueFilterMap();
                self.viewMap = {};
                self.showLeagues = registry.user.nidAssignmentArr;

                // if theres more than one league we need a league select container
                if (self.showLeagues.length > 1) {
                    multiple = true;
                }

                self.showLeagues.forEach(function (nid) {
                    rNidInfo = registry.user.getInfoForNid(nid);
                    leagues.push(rNidInfo);
                });

                leagues = _.sortBy(leagues, 'name');

                leagues.forEach(function (league) {
                    rLeagueView = new LeagueView({
                        nid: league.nid,
                        multiple: multiple,
                        info: league
                    });
                    self._subViews.push(rLeagueView);
                    rLeagueView.on("click:menu", self.show, self);
                    rLeagueView.on("click:leagueIcon", self.leagueSwapSignalReceived, self);
                    rLeagueView.on("click:showAll", self.showAllLeagues, self);
                    self.viewMap[league.nid] = rLeagueView;
                    self.$el.append(rLeagueView.$el);


                    if (self.activeLeague === null && filterMap[league.nid] === undefined) {
                        // console.log("SETTING AS ACTIVE", nid);
                        self.activeLeague = rLeagueView;
                        self.activeLeague.wakeUp();
                    }
                });

                // self.on("post-render", function () {
                //     if (self.showLeagues.length > 1) {
                //         self.show();
                //     }
                // }, self);
                self.initComplete.resolve();
            });


            registry.ready.done(function () {
                registry.createLeagueView.on("update:leagues", self.addNewLeague, self);
            });

            self.initComplete.always(function () {
                self.trigger("post-render");
                self.showLeague(self.activeLeague.nid);
            });

            return this.$el;
        },

        addNewLeague: function (dataRow) {
            var multiple = false,
                rLeagueView = new LeagueView({
                    nid: dataRow.nid,
                    multiple: multiple,
                    info: dataRow
                });
            this._subViews.push(rLeagueView);

            this.showLeagues = registry.user.nidAssignmentArr;
            if (this.showLeagues.length > 1) {
                multiple = true;
            }

            rLeagueView.on("click:menu", this.show, this);
            rLeagueView.on("click:leagueIcon", this.leagueSwapSignalReceived, this);
            this.viewMap[dataRow.nid] = rLeagueView;

            var leaguesSorted = _.sortBy(registry.user.nidInfoArr, 'name');
            var index = _.findIndex(leaguesSorted, function(l) {
                return l.name === dataRow.name;
            });
            if (leaguesSorted.length > 1 && index < leaguesSorted.length - 1) {
                this.$el.find('.league-row').get(index).before(rLeagueView.el);
            } else {
                this.$el.append(rLeagueView.$el);
            }

            this.showLeague(dataRow.nid);

            this.updateLeagueSelect();
            this.$leagueSelect.val(dataRow.nid);
        },

        show: function () {
            var leftOffset = Number(window.innerWidth);
            this.$leagueSelectContainer.show();
            this.$leagueSelectContainer.css({
                left: leftOffset,
                width: window.innerWidth,
                textAlign: "center",
                top: (window.innerHeight / 2) - (this.$leagueSelectContainer.outerHeight(true) / 2),
                opacity: 0
            });
            this.$leagueSelectContainer.show();
            this.activeLeague.$el.find("table").css({opacity: 0.25});
        },

        hide: function () {
            this.activeLeague.$el.find("table").css({opacity: 1});
            this.$leagueSelectContainer.hide();
        },

        showLeague: function (nid) {
            _.each(this.viewMap, function (view) {
                view.$el.hide();
                view.sleep();
            });
            this.activeLeague = this.viewMap[nid];
            this.activeLeague.wakeUp();
            this.activeLeague.$el.show();
        },

        showAllLeagues: function () {
            var filterMap = null,
                primary = true;

            filterMap = registry.user.getLeagueFilterMap();

            _.each(this.viewMap, function (view) {
                if (!filterMap[view.nid]) {
                    view.$el.show();
                    view.wakeUp();

                    if (primary) {
                        view.$miniSelect.val("all");
                        primary = false;
                    }
                }
            });
        },

        leagueSwapSignalReceived: function (nid) {
            if (nid === "all") {
                this.showAllLeagues();
            } else {
                this.showLeague(nid);
            }
        },

        nidClicked: function (ev) {
            var $el = $(ev.currentTarget);
            this.hide();
            this.showLeague($el.data("nid"));
        },

        updateLeagueSelect: function() {
            var self = this,
                filterMap = null,
                leagues = [],
                rNidInfo = null;

            registry.user.hasPreferences.always(function () {

                filterMap = registry.user.getLeagueFilterMap();

                registry.user.nidAssignmentArr.forEach(function (nid) {
                    if (filterMap[nid] === undefined) {
                        rNidInfo = registry.user.getInfoForNid(nid);
                        leagues.push(rNidInfo);
                    }
                });

                leagues = _.sortBy(leagues, 'name');

                self.$leagueSelect.empty();
                leagues.forEach(function (league) {
                    self.$leagueSelect.append($('<option>',{
                        'value': league.nid,
                        'text': league.name
                    }));
                });

                self.$leagueSelect.append($('<option>',{
                    'value': 'all',
                    'text':'Show All'
                }));
            });

        },

        changeLeagueSelect: function() {
            if (this.$leagueSelect.val() === 'all') {
                this.showAllLeagues();

            } else {
                this.leagueSwapSignalReceived(this.$leagueSelect.val());

            }

        },

        clickAddLeague: function() {
            registry.createLeagueView.show();
        },

        clickManageLeagues: function() {
            registry.filteredLeaguesView.show();
        },
        clickExportLeagues: function(ev) {
            var self = this,
                total =  _.size(this.viewMap),
                done = 0,
                $btn = $(ev.currentTarget),
                btnText = $btn.html();

            $btn.text('Exporting...').attr('disabled','disabled');

            // need to loop through te viewMap and trigger an update on all of them...
            _.each(this.viewMap, function(view) {
                if(!view.asleep) {
                    $.when(view.getData(true))
                        .then(function() {
                            done++;
                            if(done === total) {
                                parseLeagues();

                            }
                        });
                } else {
                    done++;
                    if(done === total) {
                        parseLeagues();
                    }
                }

            });

            function parseLeagues() {
                var csvData = [
                    ['league','campaign','clicks','cloaked','leads','epc','cvr','cva','rev']
                ];

                // all data is loaded, lets parse and export
                _.each(self.viewMap, function(view) {
                    if(view.data && view.data.length > 0) {
                        _.each(view.data, function(row) {
                            var epc = row.revenue / row.clicks,
                                cloaked = (row.filtered_clicks / row.clicks) * 100,
                                su = (row.leads / row.clicks) * 100,
                                pay = (row.revenue / row.leads);

                            epc = _.isFinite(epc) ? epc.toFixed(5) : 'NA';
                            cloaked = _.isFinite(cloaked) ? cloaked : 'NA';
                            su = _.isFinite(su) ? su.toFixed(2) : 'NA';
                            pay = _.isFinite(pay) ? pay : 0.00;

                            csvData.push([
                                view.info.name, row.keyword,row.clicks,cloaked,row.leads,epc,su,pay,row.revenue
                            ]);

                        });


                    }
                });

                var csvContent = "data:text/csv;charset=utf-8,";

                csvData.forEach(function(rowArray) {
                    var row = rowArray.join(",");
                    csvContent += row + "\r\n";
                });

                var encodedUri = encodeURI(csvContent);
                var link = document.createElement("a");
                link.setAttribute("href", encodedUri);
                link.setAttribute("download", "monetizer-leagues.csv");
                document.body.appendChild(link); // Required for FF

                link.click();

                $btn.html(btnText).removeAttr('disabled');

            }
            // overriding the viewport checks and listing when they are all done

        },

        sleep: function() {
            _.each(this.viewMap, function (view) {
                view.sleep();
            });
        },

        wakeUp: function() {
        }


        // update: function () {
        //     // update
        // }
    });

    return new LeaguesView();
});
