/*jslint nomen:true, indent:4*/
/*global define, window, console, alert, _, $*/

define([
    "Backbone",
    "registry",
    "moment-timezone",
    "workers/apirequest",
    "polyfills/addcomma",
    "libs/Chart.min",
    "pikaday",
    "templates/earnings.html",
    "templates/earningsRow.html",
    "templates/earningsHistoryRow.html",
    "templates/earningsRefOverallRow.html",
    'views/fundsTransfer',
    'views/referralLinkModal'
], function (Backbone, registry, moment, ApiRequest, addCommas, Chart,Pikaday, earningsTemplate, earningsRowTemplate, historyRowTemplate, refOverallRowTemplate, fundsTransferView , referralLinkModal){

    var AccountsView = Backbone.View.extend({

        data: null,
        hasData: null,
        timeoutInstance: null,
        onMonth: null, // ZERO INDEXED
        onYear: null,

        isAsleep: true,

        REFRESH_INTERVAL: 10000,
        DATE_FORMAT: "ddd, MMMM Do YYYY",

        $tbody: null,
        $header: null,
        $prevButton: null,
        $nextButton: null,
        $unpaidButton: null,
        $totalButton: null,
        paymentThreshold: 150,
        autoPayThreshold: 250,

        events: {
            "click #nextMonthButton": "clickNext",
            "click #prevMonthButton": "clickPrevious",
            "click .routeLink": 'routeLink',
            "click .downloadSlipButton": '_downloadAdviceSlip',
            'click .requestPaymentButton': '_requestPayment',
            'click .transferFundsBtn': '_showTransferFundsModal',
            'click .referralLinkBtn': '_showReferralLinkModal'


        },

        initialize: function () {
        },

        getData: function () {
            var self = this,
                dfd = $.Deferred(),
                paymentReq = new ApiRequest(),
                referralData = new ApiRequest(),
                req = new ApiRequest(),
                startMoment = moment().month(this.onMonth).year(this.onYear).date(1),
                startStr = startMoment.format("YYYY-MM-DD"),
                endMoment = moment().month(this.onMonth).year(this.onYear).endOf("month"),
                endStr = endMoment.format("YYYY-MM-DD"),
                isThisMonth = false;

            if (moment().isSame(startStr, 'month')) {
                isThisMonth = true;
            }


            $.when(
                paymentReq.request("data/partner-payment.php"),
                referralData.request("data/partner-referral-income.php"),
                req.request("data/earnings.php?start_date=" + startStr + "&end_date=" + endStr),
                req.request("data/earnings.php?unpaid=true"),
                req.request('v3/publisherPendingRevenue.php?&start_date='+startStr+'&end_date='+endStr)

            )
                .then(function(paymentReq, referralDataReq, earningsReq, unpaidReq, pendingReq) {
                    var results = paymentReq[0],
                        rows = referralDataReq[0],
                        data = earningsReq[0],
                        unpaid = unpaidReq[0],
                        pending = pendingReq[0];



                    if (results !== false) {
                        self.paymentData = results;
                        self.trigger("update:paymentHistory");
                    } else {
                        console.error("Couldnt get the payments history");
                    }

                    self.referralData = rows;
                    self.trigger("update:referralData");

                    self.unpaid = unpaid;
                    self.pending = pending;

                    if(data !== false)  {
                        self.data = data;
                        self.trigger("update:data");
                        dfd.resolve();
                    } else {
                        console.log("Error", data);
                        dfd.reject();
                    }
                })
                .fail(function () {
                    dfd.reject();
                });

            return dfd.promise();
        },

        render: function() {
            var self = this,
                now = moment(),
                rowTemplate = _.template(earningsRowTemplate);

            this.on("update:data", this.renderData, this);
            this.on("update:paymentHistory", this.renderPaymentHistory, this);
            this.on("update:referralData", this.renderReferralBreakdown, this);
            this.on("post:render", this.startPoll, this);


            this.setElement($(document.createElement("div")));

            this.$el
                .addClass("mainPane")
                .attr("id", "accountsContainer");

            this.$el.append(_.template(earningsTemplate)({
                access: registry.user.getAccessLevel()
            }));

            $('#appContainer').append(this.$el);

            this.onMonth = now.month();
            this.onYear = now.year();
            now = moment().month(this.onMonth).year(this.onYear);

            this.$prevButton = this.$el.find('#prevMonthButton');
            this.$totalButton = this.$el.find('#totalButton');
            this.$monthLabel = this.$el.find('.unpaidMonth');
            this.$monthLabel.text(now.format('MMMM YYYY'));
            this.$unpaidButton = this.$el.find('#unpaidMonthButton');
            this.$nextButton = this.$el.find('#nextMonthButton');
            this.$tbody = this.$el.find('.tableContainer tbody');
            this.$refTable = this.$el.find('.refTable');
            this.$refBreakdownTable = this.$el.find('.refBreakdownTable');
            this.$historyTableBody = this.$el.find(".historyTable tbody");
            this.$revenueMonthButton = this.$el.find('#revenueMonthButton');
            this.$referralMonthEarnings = this.$el.find('#referralMonthEarnings');
            this.$requestPaymentButton = this.$el.find('.requestPaymentButton');
            this.$earningsThreshold = this.$el.find('.earningsThreshold');
            this.$paymentTerms = this.$el.find('.paymentTerms');
            this.$thresholdPaymentWarning = this.$el.find('.thresholdPaymentWarning');
            this.$referralThresholdWarning = this.$el.find('.referralThresholdWarning');
            this.$paymentRequestedWarning = this.$el.find('.paymentRequestedWarning');

            this.$todaysEarnings = this.$el.find('.todaysEarnings');
            this.$yesterdaysEarnings = this.$el.find('.yesterdaysEarnings');
            this.$outstandingBalance = this.$el.find('.outstandingBalance');
            this.$pendingClearing = this.$el.find('.pendingClearing');
            this.$referralBalance = this.$el.find('.referralBalance');
            this.$totalReferrals = this.$el.find('.totalReferrals');

            this.$bankDetailsWarning = this.$el.find('.bankDetailsWarning');
            this.$cryptoWarning = this.$el.find('.cryptoWarning');
            this.$holdPaymentsWarning = this.$el.find('.holdPaymentsWarning');
            this.$referralPaymentNotice = this.$el.find('.referralPaymentNotice');
            this.$revenuePaymentNotice = this.$el.find('.revenuePaymentNotice');

            this.$idVerifiedAlert = this.$el.find('#earnings_idVerifiedAlert');

            this.transferView = new fundsTransferView();
            this.referralLinkModal = new referralLinkModal();
            this._subViews = [];
            this._subViews.push(this.transferView);
            this._subViews.push(this.referralLinkModal);


            this.validBeforeMoment = moment.tz("America/New_York").startOf('day');
            if (registry.user.paymentNet === 30){
                // monthly payment
                this.validBeforeMoment.subtract(1, 'month').startOf('month');
            }
            else if (registry.user.paymentFrequency === 'monthly') {
                this.validBeforeMoment.subtract(parseInt(registry.user.paymentNet), 'day').startOf('month');
            }
            else {
                // weekly payment
                this.validBeforeMoment.subtract(registry.user.paymentNet, 'day');
            }



            var paymentTerm = 'NET '+registry.user.paymentNet;
            if(registry.user.paymentFrequency == 'weekly') {
                paymentTerm += 'w';
            } else {
                paymentTerm += 'm';
            }
            this.$paymentTerms.text(paymentTerm);

            /*
            if(registry.user.idVerified !== 1) {
                this.$idVerifiedAlert.show();
            }
             */


            this.getData();
            this.startPoll();

            return this.$el;

        },

        renderData: function () {
            var self = this,
                rowTemplate = _.template(earningsRowTemplate),
                totalUnpaid = 0.00,
                totalEarnings = 0.00,
                revenueEarnings = 0.00,
                referralEarnings = 0.00,
                rNewMoment,
                rEarnings,
                rPaid,
                rReferralEarnings,
                rReferralPaid,
                revenueData = [],
                referralData = [],
                labelsData = [];



            this.outstandingBalance = 0.00;
            this.outstandingRevenue = 0.00;
            this.outstandingReferral = 0.00;
            this.pendingClearing = 0.00;
            this.lastClearedDateReferral = null;

            this.$tbody.empty();

            if (this.unpaid !== null && this.unpaid.length > 0 ) {
                _.each(this.unpaid, function(row) {
                    var validBeforeMoment = moment.tz("America/New_York").startOf('day');

                    if (parseInt(registry.user.paymentNet) === 30){
                        // monthly payment
                        validBeforeMoment.subtract(1, 'month').startOf('month');
                    }
                    else if (registry.user.paymentFrequency === 'monthly') {
                        validBeforeMoment.subtract(parseInt(registry.user.paymentNet), 'day').startOf('month');
                    }
                    else {
                        // weekly payment
                        validBeforeMoment.subtract(registry.user.paymentNet, 'day');
                    }

                    if (row.type.indexOf('__REFERRAL__') === -1) {
                        // normal payment

                        if(moment.tz(row.date,'YYYY-MM-DD', 'America/New_York').isBefore(validBeforeMoment)) {
                            self.outstandingBalance += (parseFloat(row.amount));
                            self.outstandingRevenue += (parseFloat(row.amount));
                        } else {
                            self.pendingClearing += (parseFloat(row.amount));
                        }
                    }
                    else {
                        // referral payment
                        if(moment.tz(row.date,'YYYY-MM-DD', 'America/New_York').isBefore(validBeforeMoment)) {
                            self.outstandingBalance += (parseFloat(row.amount));
                            self.outstandingReferral += (parseFloat(row.amount));
                            if (self.lastClearedDateReferral === null) {
                                self.lastClearedDateReferral = row.date;
                            }
                        }
                    }



                });
                self.$outstandingBalance.text('$'+addCommas(this.outstandingRevenue.toFixed(2)));
                self.$pendingClearing.text('$'+addCommas(this.pendingClearing.toFixed(2)));
                self.$referralBalance.text('$'+addCommas(this.outstandingReferral.toFixed(2)));
            } else {
                this.$outstandingBalance.text('$0.00');
            }



            this._togglePayButton();



            // table
            if (this.data !== null && this.data.length > 0) {
                this.data.reverse();


                this.data.forEach(function (row) {
                    var payable = false,
                        referralPayable = false,
                        earningsValidBeforeMoment = moment.tz('America/New_York').startOf('day');


                    rNewMoment = moment(row.date);
                    rEarnings = parseFloat(row.amount);
                    rPaid = (parseInt(row.paid, 15) === 1 ? "Yes" : "No");
                    rReferralEarnings = parseFloat(row.referral_amount);
                    rReferralPaid = (parseInt(row.referral_paid, 15) === 1 ? "Yes" : "No");
                    pendingData = _.findWhere(self.pending,{date: row.date});


                    totalUnpaid += (parseInt(row.paid, 15) === 0 ? rEarnings : 0);
                    totalUnpaid += (parseInt(row.referral_paid, 15) === 0 ? rReferralEarnings : 0);
                    totalEarnings += rEarnings;
                    totalEarnings += rReferralEarnings;

                    revenueEarnings += rEarnings;
                    referralEarnings += rReferralEarnings;

                    if (parseInt(registry.user.paymentNet) === 30){
                        // monthly paymene
                        earningsValidBeforeMoment.subtract(1, 'month').startOf('month');
                    }
                    else if (registry.user.paymentFrequency === 'monthly') {
                        earningsValidBeforeMoment.subtract(parseInt(registry.user.paymentNet), 'day').startOf('month');
                    }
                    else {
                        // weekly payment
                        earningsValidBeforeMoment.subtract(registry.user.paymentNet, 'day');
                    }

                    if(moment.tz(row.date,'YYYY-MM-DD', 'America/New_York').isBefore(earningsValidBeforeMoment)) {
                        payable = true;

                    }


                    if(moment.tz(row.date,'YYYY-MM-DD', 'America/New_York').isBefore(earningsValidBeforeMoment)) {
                        referralPayable = true;
                    }

                    if(pendingData !== undefined) {
                        pendingData = '$'+addCommas(parseFloat(pendingData.amount).toFixed(2));
                    } else {
                        pendingData = '';

                    }




                    self.$tbody.append(rowTemplate({
                        'date': rNewMoment.format('DD-MM-YYYY'),
                        'dateFormat': rNewMoment.format(self.DATE_FORMAT),
                        'paid': rPaid,
                        'earnings': addCommas(rEarnings.toFixed(2)),
                        'refEarnings': addCommas(rReferralEarnings.toFixed(2)),
                        'refPaid': rReferralPaid,
                        'access': registry.user.getAccessLevel(),
                        'payable': payable,
                        'referralPayable': referralPayable,
                        'pendingAmount': pendingData
                    }));

                    labelsData.push(rNewMoment.format('MMM Do'));
                    revenueData.push(rEarnings.toFixed(2));
                    referralData.push(rReferralEarnings.toFixed(2));
                });


                if (self.profitChart) {
                    //self.profitChart.destroy();
                } else {
                    self.profitChart = self._plotChart($('#earningsChart'), labelsData, revenueData, referralData);

                }



                // TODO trigger one reflow for this
                self.$unpaidButton.find(".unpaidAmount").text("$" + addCommas(totalUnpaid.toFixed(2)));
                self.$totalButton.find(".unpaidAmount").text("$" + addCommas(totalEarnings.toFixed(2)));
                self.$revenueMonthButton.find(".unpaidAmount").text("$" + addCommas(revenueEarnings.toFixed(2)));
                self.$referralMonthEarnings.find(".unpaidAmount").text("$" + addCommas(referralEarnings.toFixed(2)));
                self.$monthLabel.text(rNewMoment.format("MMMM YYYY"));

                this.trigger("post:render");
            }
            else {
                self.$unpaidButton.find(".unpaidAmount").text("$0.00");
                self.$totalButton.find(".unpaidAmount").text("$0.00");
                self.$revenueMonthButton.find(".unpaidAmount").text("$0.00");
                self.$referralMonthEarnings.find(".unpaidAmount").text("$0.00");
                self.$monthLabel.text(moment().month(self.onMonth).format("MMMM YYYY"));
            }

            self.$todaysEarnings.text(registry.user.earnings.today);
            self.$yesterdaysEarnings.text(registry.user.earnings.yesterday);

        },

        renderPaymentHistory: function() {
            var rNewRow = null,
                self = this,
                rowTemplate = _.template(historyRowTemplate);

            if (_.isObject(this.paymentData)) {
                this.$historyTableBody.empty();

                if (this.paymentData.length > 0) {
                    _.each(this.paymentData, function (row) {
                        var fundsTransfer = false;


                        if(row.details != null && row.details.includes('Funds Transfer')) {
                            fundsTransfer = true;

                        }

                        self.$historyTableBody.append(rowTemplate({
                            'date': moment.unix(row.ts).format("MMMM Do, YYYY"),
                            'amount': "$" + addCommas(row.amount),
                            'id': row.id,
                            'fundsTransfer': fundsTransfer,
                            'details': row.details

                        }));
                    });
                } else {
                    self.$historyTableBody.append('<tr><td colspan="2">No payments sent yet</td></tr>');

                }
            }
        },

        renderReferralBreakdown: function () {
            var self = this;


            function pastWeek() {
                var now = moment.tz('America/New_York'),
                    week = _.range(6, -1, -1);

                return _.map(week, function (day) {
                    return now
                        .clone()
                        .subtract(day, 'days')
                        .format('YYYY-MM-DD');
                });
            }

            function currency(val) {
                return '$' + addCommas(parseFloat(val).toFixed(2));
            }

            function renderCurrent(rawData) {
                var cols = pastWeek(),
                    data = _.chain(rawData)
                        .groupBy('name')
                        .mapObject(function (val) {
                            return _.indexBy(val, 'date');
                        })
                        .value(),
                    totals = {},
                    grandTotal = 0,
                    $thead = $('<thead>'),
                    $tbody = $('<tbody>');

                $thead.append(
                    $('<tr>').append(
                        _.chain([ 'Partner', cols, 'Total' ])
                            .flatten(true)
                            .map(function (col) {
                                if (col === 'Total') {
                                    return $('<th>').text(col);
                                }
                                else if (col === 'Partner') {
                                    return $('<th>').text(col);
                                }
                                else {
                                    return $('<th>').text(moment(col).format('MMM Do'));
                                }
                            })
                            .value()
                    )
                );

                _.each(data, function (row, rowName) {
                    var total = 0;
                    var $tr = $('<tr>').append(
                        $('<td>').text(rowName)
                    ).appendTo($tbody);

                    _.each(cols, function (date, i) {
                        var info = row[date],
                            amount = info ? parseFloat(info.amount) : 0;
                        $('<td>', { class: (i === 6 ? 'today' : '') })
                            .text(currency(amount))
                            .appendTo($tr);

                        total += amount;
                        totals[date] = (totals[date] || 0) + amount;
                        grandTotal += amount;
                    });

                    $('<td>', { class: 'total' })
                        .text(currency(total))
                        .appendTo($tr);
                });

                $('<tr>').append(
                    $('<td>', { class: 'total' }).text('Total'),
                    _.map(cols, function (date, i) {
                        var amount = totals[date] || 0;

                        return $('<td>', { class: 'total' })
                            .addClass(i === 6 ? 'today' : '')
                            .text(currency(amount));
                    }),
                    $('<td>', { class: 'grandTotal' })
                        .text(currency(grandTotal))
                ).appendTo($tbody);

                self.$refBreakdownTable
                    .find('thead').replaceWith($thead).end()
                    .find('tbody').replaceWith($tbody).end();
            }

            function renderOverall(rows) {
                var total = 0,
                    rowTemplate = _.template(refOverallRowTemplate);

                self.$refTable.find('tbody').empty();

                _.each(rows, function(row) {
                    self.$refTable.find('tbody').append(rowTemplate({
                        'label': row.name,
                        'amount': currency(row.amount)
                    }));
                    total += parseFloat(row.amount);

                });

                self.$refTable.find('tbody').append(rowTemplate({
                    'label': 'Total',
                    'amount': currency(total)
                }));

            }


            this.$totalReferrals.html(this.referralData.overall.length);
            renderCurrent(this.referralData.current);
            renderOverall(this.referralData.overall);
        },

        startPoll: function () {
            var self = this,
                callback = function () {
                    if (!self.isAsleep && document.hasFocus()) {
                        self.getData.call(self, true).always(function () {
                            self.timeoutInstance = window.setTimeout(callback, self.REFRESH_INTERVAL);
                        });
                    } else {
                        self.timeoutInstance = window.setTimeout(callback, self.REFRESH_INTERVAL);
                    }
                };

            window.clearTimeout(this.timeoutInstance);
            this.timeoutInstance = window.setTimeout(callback, this.REFRESH_INTERVAL);
        },

        clickNext: function () {
            var futureCheckMoment = false,
                nowMoment = moment(),
                wasMonth = this.onMonth,
                wasYear = this.onYear;

            if (this.onMonth >= 11) {
                this.onMonth = 0;
                this.onYear += 1;
            } else {
                this.onMonth += 1;
            }

            futureCheckMoment = moment().month(this.onMonth).year(this.onYear);


            if (futureCheckMoment.isAfter(nowMoment.endOf('month'))) {
                alert("You cannot travel into the future using Monetizer.");
                this.onMonth = wasMonth;
                this.onYear = wasYear;
            } else {
                if (this.profitChart) {
                    this.profitChart.destroy();
                    this.profitChart = null;
                }
                this.getData();
            }

        },

        clickPrevious: function () {
            if (this.onMonth <= 0) {
                this.onMonth = 11;
                this.onYear -= 1;
            } else {
                this.onMonth -=1;
            }

            if (this.profitChart) {
                this.profitChart.destroy();
                this.profitChart = null;
            }
            this.getData();
        },



        _plotChart: function (element, labelData, profitData, expenseData) {
            var chart = new Chart(element, {
                type: 'line',
                data: {
                    labels: labelData,
                    datasets: [
                        {
                            label: 'Referral',
                            data: expenseData,
                            lineTension: 0,
                            borderWidth: 3,
                            backgroundColor: 'transparent',
                            borderColor: '#bc3c3c',
                            pointRadius: 0,
                            pointHitRadius: 10
                        },
                        {
                            label: 'Affiliate',
                            data: profitData,
                            lineTension: 0,
                            borderWidth: 3,
                            backgroundColor: 'transparent',
                            borderColor: '#34a475',
                            pointRadius: 0,
                            pointHitRadius: 10
                        }
                    ]

                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItems) {
                                return parseFloat(tooltipItems.yLabel).toLocaleString('en-US',{style:'currency',currency:'USD'});
                            }

                        }

                    },
                    scales: {
                        xAxes: [{
                            position: 'bottom'
                        }],
                        yAxes: [{
                            ticks: {
                                callback: function (value) {
                                    return parseFloat(value).toLocaleString('en-US',{style:'currency',currency:'USD'});
                                }
                            }
                        }]

                    }
                }

            });

            return chart;

        },

        _togglePayButton: function () {
            var self = this;


            // has already passed the pay threshold, check the other options

            new ApiRequest().request("data/partner-banking.php")
                .always(function(res, textStatus) {
                    if (textStatus === 'error') {
                        // error, no bank details
                        self.$bankDetailsWarning.show();
                        self.$cryptoWarning.hide();
                        self.$holdPaymentsWarning.hide();
                    } else {

                        // Inform that crypto payments suspended (temporarily?)
                        if (res.method == 3 || res.method == 4) {
                            self.$cryptoWarning.show();
                            self.$bankDetailsWarning.hide();
                            self.$holdPaymentsWarning.hide();
                            return;
                        }

                        var enable = true;

                        self.paymentThreshold = parseFloat(res.min_payout);

                        // has payment details
                        self.$bankDetailsWarning.hide();

                        if (parseInt(res.payment_hold, 10) === 1) {
                            self.$holdPaymentsWarning.show();
                        } else {
                            if (_.isObject(self.paymentData)) {

                                if (self.paymentData.length > 0) {

                                    if (registry.user.payment_request_date !== null || self.paymentData[0].ts < registry.user.payment_request_date) {
                                        enable = false;
                                        self.$requestPaymentButton.text('Payment Requested');
                                        self.$paymentRequestedWarning.show();
                                    }
                                }
                                else if (registry.user.payment_request_date !== null) {
                                    enable = false;
                                    self.$requestPaymentButton.text('Payment Requested');
                                    self.$paymentRequestedWarning.show();

                                }
                            }

                            if (self.outstandingRevenue < self.paymentThreshold) {
                                self.$thresholdPaymentWarning.show();
                            }
                            else if (self.outstandingRevenue >= self.autoPayThreshold) {

                                // normal payment
                                if (parseInt(registry.user.paymentNet) === 30 || registry.user.paymentFrequency === 'monthly') {
                                    // monthly payment
                                    self.validBeforeMoment.subtract(1, 'month').startOf('month');
                                    if (moment.tz('America/New_York').date() > 7) {
                                        self.revenuePayDate = '1st - 7th ' + moment.tz('America/New_York').add(1, 'month').startOf('month').format('MMM');
                                    } else {
                                        self.revenuePayDate = '1st - 7th' + moment.tz('America/New_York').startOf('month').format('MMM');
                                    }
                                } else {
                                    // weekly payment
                                    self.revenuePayDate = moment.tz('America/New_York').startOf('week').add(1, 'day').format('Do') + ' - ' + moment.tz('America/New_York').endOf('week').add(1, 'day').format('Do MMM');

                                }
                                self.$revenuePaymentNotice.find('span').html(self.revenuePayDate);
                                self.$revenuePaymentNotice.show();

                            }


                            if (enable) {
                                self.$holdPaymentsWarning.hide();
                                self.$requestPaymentButton.text('Request Payment');
                                self.$requestPaymentButton.attr('disabled', false).removeClass('btn-default').addClass('btn-success');
                            }

                            // auto payment indicators
                            if (self.outstandingReferral >= self.autoPayThreshold && self.outstandingReferral >= self.paymentThreshold)  {
                                self.referralPayMonth = self.revenuePayDate;
                                // display referral payment message
                                self.$referralPaymentNotice.find('span').html(self.referralPayMonth);
                                self.$referralPaymentNotice.show();

                            } else {
                                self.$referralThresholdWarning.show();

                            }

                        }

                    }

                    self.$earningsThreshold.text(self.paymentThreshold.toFixed(2));


                })

        },


        _downloadAdviceSlip: function (ev) {
            var slipID = $(ev.currentTarget).data('id'),
                req = new XMLHttpRequest();

            // must use tradiitonal ajax as jquery doesn't support stuff like this
            req.open("GET", API_URL+ "data/partner-advice-slip.php?id=" + slipID, true);
            req.setRequestHeader("X_AFFLOW_API_TOKEN", registry.user.token);
            req.setRequestHeader("X_AFFLOW_CLIENT_VERSION", registry.versionStr);
            req.responseType = "blob";

            req.onload = function () {
                var blob = req.response,
                    link = document.createElement('a');

                link.href = window.URL.createObjectURL(blob);
                link.download = 'monetizer-payment-advice-' + slipID + '.pdf';
                link.click();
            };
            req.send();

        },
        _requestPayment: function () {
            var self = this;

            new ApiRequest()
                .request("data/partner-request-payment.php")
                .done(function(res){
                    self.$requestPaymentButton.attr('disabled', true).removeClass('btn-success').addClass('btn-default').text('Payment Requested');

                    alert("Payout request received. If your cleared earnings exceed the minimum threshold, we will process your transfer on the next payment day.");
                    registry.user.payment_request_date = Math.floor(Date.now() / 1000);

                })
                .fail(function(err) {
                    if (err.responseJSON && err.responseJSON.message) {
                        alert('Error: ' + err.responseJSON.message);
                    }
                });
        },

        _showTransferFundsModal: function() {

            this.transferView.show();
        },

        _showReferralLinkModal: function() {

            this.referralLinkModal.show();
        },


        routeLink: function (event) {
            var route = $(event.currentTarget).data("route");
            Backbone.history.navigate(route, {trigger: true});

        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        }



    });

    return new AccountsView();
});
