define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/manualIdVerification.html"
], function (Backbone, registry, ApiRequest, mainTemplate) {

    return Backbone.View.extend({

        events: {
            "click .continueBtn": "_continue",
            "change #uploadFrontIdInput": "_processFrontId",
            "change #uploadBackIdInput": "_processBackId",
            "change #uploadPortraitInput": "_processPortrait",
            "click .toggleLang": "_toggleLang"
        },

        verificationId: null,
        maxUploadSize: 16e6, // 1MB
        fileErrorMsg: "Max file size: 1MB. JPEG and PNG only",

        initialize: function (vars) {

            if(typeof vars.verificationId === 'undefined') {
                console.error('verificationId is required');
                return;
            }

            this.verificationId = parseInt(vars.verificationId);

            this.$el.append(_.template(mainTemplate));
            $('#appContainer').append(this.$el);

            this.$overlay = this.$el.find('#manualIdVerificationModal');
            this.$continueBtn = this.$el.find('.continueBtn');
            this.$uploadFrontIdInput = this.$el.find('#uploadFrontIdInput');
            this.$uploadBackIdInput = this.$el.find('#uploadBackIdInput');
            this.$uploadPortraitInput = this.$el.find('#uploadPortraitInput');
        },

        show: function() {
            this.step = 1;

            this.$overlay.fadeIn('fast');
            $('html, body').addClass('overflow-hidden');
        },

        _continue: function () {

            this.$continueBtn.attr('disabled','disabled');
            switch(this.step) {
                case 1:
                    this.step = 2;
                    this._showStep(2);
                    break;
                case 2:
                    this._processUploadStep();
                    break;
                case 3:
                    this.trigger('complete');
                    break;

            }

        },

        _showStep: function (id) {
            this.$el.find('.stepContent').hide();
            this.$el.find('.stepContent[data-id="'+id+'"]').show();
        },

        _processUploadStep: function () {
            this._showStep(3);
            this.step = 3;
            this.$continueBtn.removeAttr('disabled');
        },

        _processFrontId: function () {
            var self = this,
                $errorMsg = this.$el.find('.frontIdErrorMsg'),
                formData = null,
                options = {
                    'url': API_URL + "data/partner-id-verification.php",
                    'headers': {
                        "X_AFFLOW_API_TOKEN": registry.user.token,
                        "X_AFFLOW_CLIENT_VERSION": registry.versionStr
                    },
                    'crossDomain': true,
                    'type': "POST",
                    'enctype': 'multipart/form-data',
                    'processData': false,
                    'contentType': false,
                };


            $errorMsg.hide();
            self.$el.find('.idFrontIcon').hide();
            self.$el.find('.idFrontIcon[data-id="uploading"]').show();

            formData = new FormData();
            formData.append('action', 'idFront');
            formData.append('verificationId', this.verificationId);

            if (this.$uploadFrontIdInput[0].files[0].size > this.maxUploadSize) {
                $errorMsg.text(this.fileErrorMsg).show();
                return;
            } else {
                formData.append('file', this.$uploadFrontIdInput[0].files[0]);
            }

            options.data = formData;

            $.ajax(options)
                .done(function() {
                    $errorMsg.hide();
                    self.$el.find('.idFrontIcon').hide();
                    self.$el.find('.idFrontIcon[data-id="success"]').show();
                    self.$uploadFrontIdInput.attr('disabled','disabled');
                    self.$uploadBackIdInput.removeAttr('disabled');
                })
                .fail(function(error) {
                    self.$el.find('.idFrontIcon').hide();
                    self.$el.find('.idFrontIcon[data-id="error"]').show();
                    $errorMsg.text(error.responseJSON.error).show();
                });
        },

        _processBackId: function () {
            var self = this,
                $errorMsg = this.$el.find('.backIdErrorMsg'),
                formData = null,
                options = {
                    'url': API_URL + "data/partner-id-verification.php",
                    'headers': {
                        "X_AFFLOW_API_TOKEN": registry.user.token,
                        "X_AFFLOW_CLIENT_VERSION": registry.versionStr
                    },
                    'crossDomain': true,
                    'type': "POST",
                    'enctype': 'multipart/form-data',
                    'processData': false,
                    'contentType': false,
                };


            $errorMsg.hide();
            self.$el.find('.idBackIcon').hide();
            self.$el.find('.idBackIcon[data-id="uploading"]').show();

            formData = new FormData();
            formData.append('action', 'idBack');
            formData.append('verificationId', this.verificationId);

            if (this.$uploadBackIdInput[0].files[0].size > this.maxUploadSize) {
                $errorMsg.text(this.fileErrorMsg).show();
                return;
            } else {
                formData.append('file', this.$uploadBackIdInput[0].files[0]);
            }

            options.data = formData;

            $.ajax(options)
                .done(function() {
                    $errorMsg.hide();
                    self.$el.find('.idBackIcon').hide();
                    self.$el.find('.idBackIcon[data-id="success"]').show();
                    self.$uploadBackIdInput.attr('disabled','disabled');
                    self.$uploadPortraitInput.removeAttr('disabled');
                })
                .fail(function(error) {
                    self.$el.find('.idBackIcon').hide();
                    self.$el.find('.idBackIcon[data-id="error"]').show();
                    $errorMsg.text(error.responseJSON.error).show();
                });

        },

        _processPortrait: function () {
            var self = this,
                $errorMsg = this.$el.find('.portraitErrorMsg'),
                formData = null,
                options = {
                    'url': API_URL + "data/partner-id-verification.php",
                    'headers': {
                        "X_AFFLOW_API_TOKEN": registry.user.token,
                        "X_AFFLOW_CLIENT_VERSION": registry.versionStr
                    },
                    'crossDomain': true,
                    'type': "POST",
                    'enctype': 'multipart/form-data',
                    'processData': false,
                    'contentType': false,
                };


            $errorMsg.hide();
            self.$el.find('.portraitIcon').hide();
            self.$el.find('.portraitIcon[data-id="uploading"]').show();

            formData = new FormData();
            formData.append('action', 'portrait');
            formData.append('verificationId', this.verificationId);

            if (this.$uploadPortraitInput[0].files[0].size > this.maxUploadSize) {
                self.$el.find('.portraitIcon').hide();
                self.$el.find('.portraitIcon[data-id="error"]').show();
                $errorMsg.text(this.fileErrorMsg).show();
                return;
            } else {
                formData.append('file', this.$uploadPortraitInput[0].files[0]);
            }

            options.data = formData;

            $.ajax(options)
                .done(function() {
                    $errorMsg.hide();
                    self.$el.find('.portraitIcon').hide();
                    self.$el.find('.portraitIcon[data-id="success"]').show();
                    self.$uploadPortraitInput.attr('disabled','disabled');
                    self.$continueBtn.text('Processing. Please Wait...');
                    self.$overlay.hide();
                    self.trigger('complete', self.verificationId);

                })
                .fail(function(error) {
                    $errorMsg.text(error.responseJSON.error).show();
                });
        },

        _toggleLang(ev) {
            var lang = ev.currentTarget.dataset.id;

            if(lang === 'en') {
                this.$el.find('.langAr').hide();
                this.$el.find('.langEn').show();
            } else if (lang === 'ar') {
                this.$el.find('.langAr').show();
                this.$el.find('.langEn').hide();
            }

        }
    });
});
