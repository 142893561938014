/*jslint nomen:true, indent:4*/
/*global define, _, console, jstz, alert */

define([
    "Backbone",
    "views/basic-report",
    "jQuery",
    "workers/apirequest",
    "moment-timezone",
    "registry",
    "php-timezone",
    "views/graph",
    "views/pureSelect",
    'jstimezonedetect',
    "templates/platformReportsTemplate.html",
    "templates/platformReportsRow.html"
], function (Backbone, BasicReport, $, ApiRequest, moment, registry, phpList, GraphView, PureSelect, jstz, platformReportsTemplate, platformReportsRowTemplate) {

    var View = BasicReport.extend({

        // Constants

        // Properties
        _subViews: [],
        data: null,
        $overlay: null,
        $table: null,
        $shoppingListView: null,
        $tbody: null,
        $tfootLoading: null,
        $header: null,
        $todayButton: null,
        $dateSelect: null,
        $timezoneInput: null,
        $nameText: null,
        $countryButton: null,
        $cityButton: null,
        $osButton: null,
        $versionButton: null,
        $wifiButton: null,
        $deviceButton: null,
        $deviceTypeButton: null,
        $carrierButton: null,
        $daypartButton: null,
        $headerButtonText: null,
        $timeZoneSelectContainer: null,
        $exportButton: null,
        $fileInput: null,
        $clearButton: null,
        $viewTableButton: null,
        $viewGraphButton: null,
        $tableWrapper: null,
        $graphWrapper: null,
        $graphButtonBar: null,
        $shoppingListWrapper: null,
        $browserButton: null,
        $refererButton: null,
        $resolutionButton: null,
        $languageButton: null,
        $verticalButton: null,

        // Graph Buttons
        $graphClicksButton: null,
        $graphLeadsButton: null,
        $graphCRButton: null,
        $graphRevenueButton: null,
        $graphEPCButton: null,
        $graphEcpmButton: null,
        $graphSortSelect: null,
        $graphLoader: null,

        rid: null,
        name: null,
        currentTimezone: null,
        reportType: "rotator",

        sortBy: "clicks",
        sortByAlias: {
            ecpm: "epc"
        },
        keyword: "geo",
        keywordDesc: "Country",
        direction: false,
        filterUnknown: false,

        shoppingList: null,
        runningRequests: [],
        currentExportUrl: null,

        graphView: null,

        // Events
        events: {
            "click .closeButton": "hide",
            "click th": "clickTh",
            "click #countryButton": "clickGeo",
            "click #cityButton": "clickCity",
            "click #osButton": "clickOS",
            "click #versionButton": "clickVersion",
            "click #refererButton": "clickReferer",
            "click #deviceButton": "clickDevice",
            "click #browserButton": "clickBrowser",
            "click #deviceTypeButton": "clickDeviceType",
            "click #carrierButton": "clickCarrier",
            "click #daypartButton": "clickDaypart",
            "click #refererButton": "clickReferer",
            "click #resolutionButton": "clickResolution",
            "click #languageButton": "clickLanguage",
            "click #verticalButton": "clickVertical",
            "click #wifiButton": "clickWifi",
            "click #offerButton": "clickOffer",
            "click #countryOsButton": "clickCountryOs",
            "click #countryOsDeviceDeviceTypeButton": "clickCountryOsDeviceDeviceType",
            "click #countryDeviceTypeButton": "clickCountryDeviceType",
            "click #countryLanguageButton": "clickCountryLanguage",
            "click #countryOfferButton": "clickCountryOffer",
            "click #carrierOfferButton": "clickCarrierOffer",
            "click .clearButton": "clickClear",
            "click .add_list": "clickRow",
            "click .add_copy": "clickCopy",
            "click .shoppingListItem": "clickListItem",
            "keyup #timezoneInput": "tzInput",
            "focus #timezoneInput": "tzFocus",
            "blur #timezoneInput": "tzBlur",
            "click .timezone": "clickTz",
            "click .graphButton": "clickGraphButton",
            "click .exportButton": "clickExport",
            "click .tableView": "clickViewTable",
            "click .graphView": "clickViewGraph"

        },



        // Methods
        initialize: function () {
            var self = this;

            this.shoppingList = {};

            this.setElement($(document.createElement("div")));
            this.$el.append(_.template(platformReportsTemplate)());
            $("#appContainer").append(this.$el);

            this.$overlay = this.$el.find('.platformReportingContainer');

            this.$viewTableButton = this.$el.find('.tableView');
            this.$viewGraphButton = this.$el.find('.graphView');
            closeButton = this.$el.find('.closeButton');
            this.$nameText = this.$el.find('.panel-header h4 span');
            this.$header = this.$el.find('.panel-header h4');

            buttonBar = this.$el.find('.buttonBar');

            this.$dateSelect = new PureSelect()
                .attr("id", "dateSelectInput")
                .updateData({
                    "today": "Today - All Platform",
                    "yesterday": "Yesterday - All Platform",
                    "three": "Last 3 Days - All Platform",
                    "seven": "Last 7 Days - All Platform",
                    "fourteen": "Last 14 Days -  All Platform",
                    "thirty": "Last 30 Days - All Platform"
                }, "today")
                .appendTo(this.$el.find('.selectDateRange'))
                .on("change", this.getData, this);
            this._subViews.push(this.$dateSelect);

            // Country Dropdown
            this.$countryButton = this.$el.find('#countryButton');
            this.$countryDropdown = this.$countryButton.next('.dropdown');
            this.$countryOsButton = this.$countryDropdown.find('#countryOsButton');
            this.$countryDeviceTypeButton = this.$countryDropdown.find("#countryDeviceTypeButton");
            this.$countryLanguageButton = this.$countryDropdown.find("#countryLanguageButton");
            this.$countryOfferButton = this.$countryDropdown.find("#countryOfferButton");

            this.$cityButton = this.$el.find("#cityButton");

            this.$osButton = this.$el.find("#osButton");
            this.$osDropdown = this.$osButton.next('.dropdown');
            this.$versionButton = this.$osDropdown.find("#versionButton");

            this.$browserButton = this.$el.find("#browserButton");

            this.$deviceButton = this.$el.find("#deviceButton");
            this.$deviceDropdown = this.$deviceButton.next('.dropdown');
            this.$deviceTypeButton = this.$deviceDropdown.find("#deviceTypeButton");

            // Carrier Dropdown
            this.$carrierButton = this.$el.find("#carrierButton");
            this.$carrierDropdown = this.$carrierButton.next('.dropdown');
            this.$carrierOfferButton = this.$carrierDropdown.find("#carrierOfferButton");

            this.$wifiButton = this.$el.find("#wifiButton");

            this.$languageButton = this.$el.find("#languageButton");

            this.$verticalButton = this.$el.find("#verticalButton");

            if (registry.user.private) {
                this.$refererButton = this.$el.find("#refererButton");
            } else {
                this.$el.find("#refererButton").remove();
            }

            this.$resolutionButton = this.$el.find("#resolutionButton");
            this.$daypartButton = this.$el.find("#daypartButton");
            this.$offerButton = this.$el.find("#offerButton");


            this.currentTimezone = jstz.determine().name();
            this.$timezoneInput = this.$el.find('#timezoneInput')
                .val(this.currentTimezone)
            this.$timeZoneSelectContainer = this.$el.find('.timeZoneSelectContainer')

            // Hack for mouseover
            buttonBar.find("li a").mouseover(function () {
                self.buttonHover(this);
            });

            /*
            buttonBar.on('mouseleave', 'li a', function () {
                $('.dropdown').hide();
            });
            */


            // Graph
            this.$graphWrapper = this.$el.find('.graphWrapper');
            this.$graphButtonBar = this.$graphWrapper.find(".graphButtonBar");

            this.graphView = new GraphView();
            this._subViews.push(this.graphView);
            this.graphView.$el.appendTo(this.$graphWrapper);


            // Graph Buttons
            this.$graphSortSelect = new PureSelect()
                .attr("id", "graphSortDirectionSelect")
                .addClass("graphButton")
                .updateData({
                    "1": "Sort Ascending",
                    "0": "Sort Descending"
                }, "1")
                .appendTo(this.$graphButtonBar)
                .on("change", this.changeGraphDirection, this);
            this._subViews.push(this.$graphSortSelect);

            this.$graphLoader = this.$graphWrapper.find('graphLoading');

            this.$tableWrapper = this.$el.find('.table_container');
            this.$tbody = this.$tableWrapper.find('tbody');
            this.$tfootLoading = this.$tableWrapper.find('tfoot');

            this.$shoppingListWrapper = this.$el.find('.shopping_cart');
            this.$shoppingListView = this.$shoppingListWrapper.find('.shoppingListContainer');


            this.rowTemplate = _.template(platformReportsRowTemplate);

            this.on("update:data", this.update, this);
            this.on("update:shoppingList", this.updateShoppingListView, this);

            $(document).on("keydown", function (e) {
                self.keyPressed.call(self, e);
            });
        },

        getData: function () {
            var req = new ApiRequest(),
                self = this,
                args = "",
                typeArg = "",
                dfd = null,
                sortBy = this.sortByAlias[this.sortBy] || this.sortBy;

            this.showLoading();
            this.$tbody.empty();

            _.each(this.runningRequests, function (request) {
                request.abort();
            });

            if (this.reportType === "vertical") {
                typeArg = "vertical=" + this.vertical;
            }
            else if (this.reportType === 'geo') {
                typeArg = "geo=" + this.geo;

            }
            else {
                typeArg = "rid=" + this.rid;
            }

            args = "&order=" + sortBy + "&direction=" + (this.direction ? "asc" : "desc") + "&keyword=" + this.keyword + "&date_range=" + this.$dateSelect.val() + "&tz=" + this.currentTimezone;
            dfd = req.request("data/partner-splitter-report.php?fast=1&" + typeArg + args).done(function (data, afterRequest) {
                if (data !== false) {
                    exportUrl = afterRequest.getResponseHeader("X-Export-URL");
                    if (exportUrl && exportUrl.length > 0) {
                        self.currentExportUrl = exportUrl;
                    }
                    else {
                        self.currentExportUrl = null;
                    }

                    self.data = data;
                    self.trigger("update:data");
                }
            });

            this.runningRequests.push(dfd.promise.request);
        },


        buttonHover: function(button) {
            var offset = $(button).position(),
                reportType = this.reportType,
                numCompliant = 0;

            switch ($(button).attr("id")) {
                case "osButton":
                    numCompliant = this.$osDropdown
                        .find("a")
                        .filter('.' + reportType + 'Report').length;
                    if (numCompliant === 0) {
                        this.$osDropdown
                            .css({
                                display: "none !important",
                            })
                            .hide();
                    } else {
                        this.$osDropdown
                            .css({
                                display: "",
                            });
                    }

                break;

                case "deviceButton":

                    numCompliant = this.$deviceDropdown
                        .find("a")
                        .filter('.' + reportType + 'Report').length;
                    if (numCompliant === 0) {
                        this.$deviceDropdown
                            .css({
                                display: "none !important",
                            })
                            .hide();
                    } else {
                        this.$deviceDropdown
                            .css({
                                display: "",
                            });
                    }

                break;

                case "countryButton":
                    numCompliant = this.$countryDropdown
                        .find("a")
                        .filter('.' + reportType + 'Report').length;
                    if (numCompliant === 0) {
                        this.$countryDropdown
                            .css({
                                display: "none !important",
                            })
                            .hide();
                    } else {
                        this.$countryDropdown
                            .css({
                                display: "",
                            });

                    }

                break;

                case "carrierButton":
                    numCompliant = this.$carrierDropdown
                        .find("a")
                        .filter('.' + reportType + 'Report').length;
                    if (numCompliant === 0) {
                        this.$carrierDropdown
                            .css({
                                display: "none !important",
                            })
                            .hide();
                    } else {
                        this.$carrierDropdown
                            .css({
                                display: "",
                            });
                    }

                break;
            }
        },


        reOrderData: function() {
            var direction = this.direction,
                sortBy = this.sortByAlias[this.sortBy] || this.sortBy,
                first = null,
                second = null,
                firstVal = null,
                secondVal = null;

            this.$tbody.empty();

            this.data = this.data.sort(function (a,b) {
                if (!direction) {
                    first = b;
                    second = a;
                } else {
                    first = a;
                    second = b;
                }

                if (second[sortBy] === undefined || second[sortBy] === undefined) {
                    console.error("Sort slug is not a property of the data row", sortBy);
                    return 0;
                } else {

                    firstVal = isNaN(parseFloat(first[sortBy])) ? first[sortBy] : parseFloat(first[sortBy]);
                    secondVal = isNaN(parseFloat(second[sortBy])) ? second[sortBy] : parseFloat(second[sortBy]);

                    if (firstVal > secondVal) {
                        return 1;
                    } else if (firstVal === secondVal) {
                        return 0;
                    } else {
                        return -1;
                    }
                }
            });
        },


        update: function () {
            var self = this,
                docFragment = $(document.createDocumentFragment()),
                rClicks = null,
                rLeads = null,
                rCr = null,
                rEpc = null,
                rClass = "",
                rOfferEpc = null,
                rEcpm = null,
                nRows = 0,
                rKeyword = null,
                topTenX = [],
                topTenY = [];

            this.hideLoading();
            $("#plannerButtonTypeText").text(this.keywordDesc);
            this.$graphSortSelect.val(this.direction ? "1" : "0");

            if (this.data.length > 0) {
                this.data.every(function (row) {
                    if (self.filterUnknown && row.keyword === "Unknown") {
                        return true;
                    }

                    rClicks = parseInt(row.clicks);
                    rLeads = parseInt(row.leads);

                    if (rLeads > rClicks) {
                        return true;  // skip delayed leads
                    }

                    rCr = isFinite(parseFloat(row.cr)) ? parseFloat(row.cr).toFixed(2) : row.cr;
                    rEpc = isFinite(parseFloat(row.epc)) ? parseFloat(row.epc).toFixed(5) : row.epc;
                    rEcpm = isFinite(parseFloat(row.epc)) ? (parseFloat(row.epc) * 1000).toFixed(2) : row.epc;
                    rOfferEpc = parseFloat(row.offer_epc).toFixed(5);

                    rClass = self.classifyRow(row);

                    try {
                        rKeyword = decodeURIComponent(row.keyword);
                    }
                    catch(URIError) {
                        console.log("ERROR WITH ", row.keyword);
                        rKeyword = row.keyword.replace(/%20/g, " ");
                    }

                    if (nRows < 24) {
                        topTenX.push(rKeyword);

                        switch (self.sortBy) {
                            case "clicks":
                                topTenY.push(row.clicks);
                                break;

                            case "leads":
                                topTenY.push(row.leads);
                                break;

                            case "cr":
                                topTenY.push(rCr);
                                break;

                            case "revenue":
                                topTenY.push(parseFloat(row.revenue).toFixed(2));
                                break;

                            case "epc":
                                topTenY.push(rEpc);
                                break;

                            case "offer_epc":
                                topTenY.push(rOfferEpc);
                                break;

                            case "ecpm":
                                topTenY.push(rEcpm);
                                break;
                        }
                    }

                    docFragment.append(self.rowTemplate({
                        keywordFull: rKeyword,
                        clicks: row.clicks,
                        cr: rCr,
                        date: moment.unix(row.data_start).format("ddd, hA"),
                        leads: row.leads,
                        ecpm: rEcpm,
                        epc: rEpc,
                        offer_epc: rOfferEpc,
                        className: rClass,
                        revenue: "$" + parseFloat(row.revenue).toFixed(2),
                        keyword: rKeyword
                    }));

                    return ++nRows < 200;
                });

                self.$tbody.append(docFragment);
                this.graphView.render(topTenX, topTenY);
            } else {
                self.$tbody.append($(document.createElement("tr"))
                    .html("<td class=\"noData\" colspan=\"100%\" style=\"text-align:center;\">No clicks to show for this date range.</td>")
                );
            }

        },

        updateShoppingListView: function() {
            this.$exportButton = $(document.createElement("div"))
                .text("Export List")
                .addClass("exportButton");

            this.$clearButton = $(document.createElement("div"))
                .text("Start Over")
                .addClass("clearButton");

            this.$shoppingListView.empty()
                .append($(document.createElement("h2"))
                    .text(this.name + " Targetting List"));


            this.addSublist("Geo", "geo");
            this.addSublist("City", "city");
            this.addSublist("OS", "os");
            this.addSublist("OS Version", "os_ver");
            this.addSublist("Device", "device");
            this.addSublist("Carrier", "carrier");
            this.addSublist("Connection", "connection");
            this.addSublist("Hours", "hour");
            this.addSublist("Browsers", "browser");
            this.addSublist("Resolutions", "screen_res");
            this.addSublist("Languages", "language");

            if (this.$shoppingListView.find("li").length === 0) {
                this.$shoppingListView
                .append($(document.createElement("div"))
                    .addClass("emptyNotifier")
                    .html("<h3>Nothing Selected</h3><ul><li class=\"shoppingListItem\">Build a list by clicking items on the left</li></ul>")
                );
            }

            this.$shoppingListView
                .append(this.$exportButton, this.$clearButton)
                .append($(document.createElement("div"))
                    .addClass("timestamp")
                    .text("Created: " + moment.unix(this.shoppingList.createdDate).toString())
                );

        },


        addSublist: function(title, key) {
            var rListEl = null;

            if (this.shoppingList[key] !== undefined && this.shoppingList[key].length > 0) {
                this.$shoppingListView.append($(document.createElement("h3"))
                    .text(title)
                );

                rListEl = $(document.createElement("ul"))
                    .appendTo(this.$shoppingListView);

                _.each(this.shoppingList[key], function(item) {
                    rListEl.append($(document.createElement("li"))
                        .addClass("shoppingListItem")
                        .data("key", key)
                        .text(item)
                    );
                });
            }
        },

        render: function () {
        },

        showLoading: function () {
            this.$graphLoader.show();
            this.$tfootLoading.show();
        },

        hideLoading: function() {
            this.$tfootLoading.hide();
            this.$graphLoader.hide();
        },

        setTarget: function (options) {
            var oldRid = this.rid,
                today = moment().format("YYYY-MM-DD");

            this.reportType = options.reportType;
            this.rid = options.rid;
            this.name = options.name;
            this.vertical = options.vertical;
            this.geo = options.geo;



            var $activeButton = this.$el.find('.buttonBar > li > a.active');
            var $firstValidButton = this.$el
                    .find('.buttonBar>li>a')
                    .first();

            if(options.activeBtn) {
                this.$el.find(options.activeBtn).trigger('click');

            }
            else if ($activeButton.length === 0) {
                if (this.reportType === 'geo') {
                    this.$carrierButton.trigger('click');
                } else {
                    $($firstValidButton, this.$el).trigger('click');

                }
            }

            if (oldRid !== this.rid) {
                // Save current list
                localStorage["shoppingList_" + oldRid] = JSON.stringify(this.shoppingList);

                // Restore
                if (localStorage["shoppingList_" + this.rid] !== undefined) {
                    this.shoppingList = JSON.parse(localStorage["shoppingList_" + this.rid]);
                } else {
                    this.shoppingList = {
                        createdDate: Math.round(Date.now() / 1000)
                    };
                }

                this.exportName = this.name + "_" + today;
                this.trigger("update:shoppingList");
            }

        },

        show: function () {
            var nameText = '"'+this.name+'';

            if (typeof this.vertical !== 'undefined') {
                nameText += ' - '+this.vertical.toUpperCase();

            }

            nameText += '"';

            if (registry.user.getAccessLevel() === 'basic') {
                this.$countryButton.parent().hide();
                this.$carrierOfferButton.hide();
                this.$verticalButton.parent().hide();
                this.$offerButton.parent().hide();

            }

            this.$nameText.text(nameText);
            $("body").addClass("overflow-hidden");
            this.$overlay.fadeIn('fast');
            this.getData();
        },


        hide: function () {
            var self = this;

            // Save current list
            localStorage["shoppingList_" + this.rid] = JSON.stringify(this.shoppingList);

            $("body").removeClass("overflow-hidden");
            self.$overlay.fadeOut('fast');
            self.$tbody.empty();
        },

        clickExport: function () {
            this.exportToCSV();
        },

        exportToCSV: function () {
            var downloadLink = null;

            downloadLink = document.createElement("a");
            downloadLink.setAttribute("href", this.currentExportUrl);
            downloadLink.click();

        },

        /*
        clickExport: function () {
            this.exportList();
        },

        exportList: function () {
            var output = "",
                encodedUri = null,
                downloadLink = null;

            output += "data:text/html;charset=utf-8,";
            output += "<style type=\"text/css\">.clearButton,.exportButton{display:none;}</style>" + this.$shoppingListView.html();

            encodedUri = encodeURI(output);
            downloadLink = document.createElement("a");
            downloadLink.setAttribute("href", encodedUri);
            downloadLink.setAttribute("download", this.exportName + ".html");
            downloadLink.click();

        },

         */

        clickTh: function (e) {
            var slug = $(e.currentTarget).data("slug");
            if (slug !== "none") {
                if (this.sortBy === slug) {
                    this.direction = !this.direction;
                } else {
                    this.direction = false;
                }

                this.sortBy = slug;
                this.sortByChanged();
            }
        },

        sortByChanged: function () {
            var self = this,
                $rEl,
                $graphButtons = this.$el.find("button.graphButton");

            $graphButtons.removeClass("active");
            $graphButtons.each(function (index, el) {
                $rEl = $(el);
                if ($rEl.data("label") === self.sortBy) {
                    $rEl.addClass("active");
                }
            });

            this.reOrderData();
            this.update();
        },

        clickGraphButton: function (e) {
            var $el = $(e.currentTarget);

            if ($el.data("label")) {
                this.sortBy = $el.data("label");
                this.sortByChanged();
            }
        },

        handleDropdownActiveState: function (e) {
            var $target = $(e.target),
                $parent = $target.closest(".dropDownLabel");

            this.$el.find(".navbar-nav a").removeClass("active");
            this.$el.find(".dropDownLabel").removeClass("active");

            if ($parent.length > 0) {
                $parent.addClass("active");
            }
        },

        clickGeo: function (e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "geo";
            this.$countryButton.addClass("active");
            this.keywordDesc = "Country";
            this.getData();
        },

        clickCity: function (e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "city";
            this.$cityButton.addClass("active");
            this.keywordDesc = "City";
            this.getData();
        },

        clickOS: function (e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "os";
            this.$osButton.addClass("active");
            this.keywordDesc = "Operating System";
            this.getData();
        },

        clickVersion: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = false;
            this.keyword = "os_ver";
            this.$versionButton.addClass("active");
            this.keywordDesc = "Operating System Version";
            this.getData();
        },

        clickDevice: function (e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "device";
            this.$deviceButton.addClass("active");
            this.keywordDesc = "Device";
            this.getData();
        },

        clickDeviceType: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = false;
            this.keyword = "device_type";
            this.$deviceTypeButton.addClass("active");
            this.keywordDesc = "Device Type";
            this.getData();
        },

        clickCarrier: function (e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "carrier";
            this.$carrierButton.addClass("active");
            this.keywordDesc = "Carrier";
            this.getData();
        },

        clickWifi: function (e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "connection";
            this.$wifiButton.addClass("active");
            this.keywordDesc = "Connection";
            this.getData();
        },

        clickDaypart: function(e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "hour";
            this.$daypartButton.addClass("active");
            this.keywordDesc = "Day Parting";
            this.getData();
        },

        clickBrowser: function(e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "browser";
            this.$browserButton.addClass("active");
            this.keywordDesc = "Browser";
            this.getData();
        },

        clickReferer: function(e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "referer";
            this.$refererButton.addClass("active");
            this.keywordDesc = "Referer";
            this.getData();
        },

        clickResolution: function(e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "screen_res";
            this.$resolutionButton.addClass("active");
            this.keywordDesc = "Screen Resolution";
            this.getData();
        },

        clickLanguage: function(e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "language";
            this.$languageButton.addClass("active");
            this.keywordDesc = "Language";
            this.getData();
        },

        clickVertical: function(e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = false;
            this.keyword = "actual_vertical";
            this.$verticalButton.addClass("active");
            this.keywordDesc = "Vertical";
            this.getData();
        },

        clickOffer: function (e) {
            this.handleDropdownActiveState(e);
            this.filterUnknown = true;
            this.keyword = "offer";
            this.$offerButton.addClass("active");
            this.keywordDesc = "Offer";
            this.getData();
        },

        clickCountryOs: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = true;
            this.keyword = "geo_os";
            this.$countryOsButton.addClass("active");
            this.keywordDesc = "Country > OS";
            this.getData();
        },

        clickCountryOsDeviceDeviceType: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = true;
            this.keyword = "geo_os_device_device_type";
            this.$countryOsDeviceDeviceTypeButton.addClass("active");
            this.keywordDesc = "Country > OS > Device > Device Type";
            this.getData();
        },

        clickCountryDeviceType: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = true;
            this.keyword = "geo_device_type";
            this.$countryDeviceTypeButton.addClass("active");
            this.keywordDesc = "Country > Device Type";
            this.getData();
        },

        clickCountryLanguage: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = true;
            this.keyword = "geo_language";
            this.$countryLanguageButton.addClass("active");
            this.keywordDesc = "Country > Language";
            this.getData();
        },

        clickCountryOffer: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = true;
            this.keyword = "geo_offer";
            this.$countryOfferButton.addClass("active");
            this.keywordDesc = "Country > Offer";
            this.getData();
        },

        clickCarrierOffer: function (event) {
            this.handleDropdownActiveState(event);
            event.stopPropagation();

            this.filterUnknown = true;
            this.keyword = "carrier_offer";
            this.$carrierOfferButton.addClass("active");
            this.keywordDesc = "Carrier > Offer";
            this.getData();
        },

        clickRow: function(e) {
            var element = $(e.currentTarget).closest("tr"),
                keywordVal = element.find(".reportKeyword").data("actual"),
                rIndex = -1;

            if (this.shoppingList[this.keyword] === undefined) {
                this.shoppingList[this.keyword] = [];
            }

            if (this.shoppingList[this.keyword].indexOf(keywordVal) < 0) {
                this.shoppingList[this.keyword].push(keywordVal);
                element.addClass("added");
            } else {
                rIndex = this.shoppingList[this.keyword].indexOf(keywordVal);
                this.shoppingList[this.keyword].splice(rIndex, 1);
                element.removeClass("added");
            }

            this.trigger("update:shoppingList");
        },

        clickListItem: function(e) {
            var element = $(e.currentTarget),
                keywordVal = element.text(),
                key = element.data("key"),
                rIndex = -1;

            rIndex = this.shoppingList[key].indexOf(keywordVal);
            if (rIndex > -1) {
                this.shoppingList[key].splice(rIndex, 1);
                element.removeClass("added");
                this.trigger("update:shoppingList");
            }
        },

        clickViewTable: function() {
            this.$viewGraphButton.removeClass("active");
            this.$graphWrapper.hide();

            this.$tableWrapper.show();
            this.$viewTableButton.addClass("active");
        },

        clickViewGraph: function() {
            this.$viewTableButton.removeClass("active");
            this.$tableWrapper.hide();
            this.$shoppingListWrapper.hide();

            this.$graphWrapper.show();
            this.$viewGraphButton.addClass("active");
        },

        clickClear: function () {
            this.shoppingList = {
                createdDate: Math.round(Date.now() / 1000)
            };
            this.trigger("update:shoppingList");
        },

        keyPressed: function (e) {
            if (e.which === 27) {
                this.hide();
            }
        },

        tzInput: function () {
            this.searchForTz();
        },

        tzFocus: function (e) {
            $(e.currentTarget).val("");
        },

        tzBlur: function(e) {
            $(e.currentTarget).val(this.currentTimezone);
        },

        searchForTz: function () {
            var self = this,
                regex = new RegExp(this.$timezoneInput.val(), "gi");

            this.$timeZoneSelectContainer.empty();

            _.each(phpList, function (row) {
                if(regex.test(row)) {
                    self.$timeZoneSelectContainer.append($(document.createElement("div"))
                        .text(row)
                        .addClass("timezone")
                    );
                }
            });

            this.$timeZoneSelectContainer.show();

        },

        clickTz: function(e) {
            var el = $(e.currentTarget),
                self = this;

            this.currentTimezone = el.text();
            this.$timezoneInput.val(this.currentTimezone);
            this.$timeZoneSelectContainer.hide();
            this.getData();
        },

        changeGraphDirection: function() {
            this.direction = !this.direction;
            this.reOrderData();
            this.update();
        },


        clickCopy: function(e) {
            var $el = $(e.currentTarget),
                $row = $el.closest("tr"),
                clipboard = registry.gui.Clipboard.get(),
                toCopy = null;

            toCopy = $row.children().eq(0).data("actual");
            clipboard.set(toCopy, "text");
            alert("'" + toCopy + "' copied to clipboard!");
        }

    });

    return new View();
});

