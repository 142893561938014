define([
    "Backbone",
    "registry",
    "moment-timezone",
    "workers/apirequest",
    "views/pureSelect",
    "dict/isocountries.json",
    "dict/verticals.json",
    "dict/language-codes.json",
    "views/directLink",
    "templates/offerMarketplaceDetailsModal.html",
], function(
    Backbone,
    registry,
    moment,
    ApiRequest,
    PureSelect,
    geoDataSrc,
    verticalsSrc,
    languageSrc,
    DirectLinkView,
    mainTemplate
) {
    return Backbone.View.extend({

        WIFI_STRINGS: {
            deny: "Carrier",
            only: "WiFi",
            allow: "All"
        },
        offerData: {},
        offerHashId: null,
        domains: [],
        hasDomains: false,
        smartFallbackDefault: 1,
        verticalDefault: 'vauto',
        events: {
            "click .select-offer": "_selectOffer",
            "click .create-link": "_createHash",
            "change #smartFallback": "_showSmartlinkMsg",
            'click .macroBtn': '_insertMacro',
            'click .clickToOpenPreview': '_openPreviewInNewTab',
            'click .hideButton': '_close'
        },

        initialize: function(params) {
            var self = this;

            if(typeof params !== 'undefined' && typeof params.smartFallback !== 'undefined') {
                this.smartFallbackDefault = parseInt(params.smartFallback);
            }

            if(typeof params !== 'undefined' && typeof params.vertical !== 'undefined') {
                this.verticalDefault = params.vertical;
            }

            this.getDomains()
                .done(function(domains) {
                    var sslDomains = _.where(domains, {ssl_enabled: '1'});

                    if(typeof sslDomains !== 'undefined' && sslDomains.length > 0) {
                        self.hasDomains = true;
                    } else {
                        self.hasDomains = false;
                    }
                    if(params.offerData) {
                        self.offerData = params.offerData;
                        self.offerHashId = self.offerData.offerIdDep;
                        self.render();
                    } else if (params.offerHashId) {
                        self.offerHashId = params.offerHashId.replace('#','');
                        self._getOfferData()
                            .then(function() {
                                self.render();


                            });
                    } else {
                        console.error('offerData or offerId required');
                    }

                });

        },

        render: function() {
            var self = this;

            this.selectedOfferVertical = this.offerData.aff_campaign_vertical;

            console.log(this.offerData);
            if(this.offerData.aff_campaign_geo.includes(',')) {
                this.offerData.flagPath = require('./../../images/flags/00.png').default;
            } else {
                this.offerData.flagPath = require('./../../images/flags/' + this.offerData.aff_campaign_geo + '.png').default;
            }


            this.$el.append(_.template(mainTemplate)(this.offerData));
            $('#appContainer').append(this.$el);

            // Populate dropdown for leagues
            var leagueIds = registry.user.nidAssignmentArr;
            _.forEach(leagueIds, function(leagueId) {
                var league = registry.user.getInfoForNid(leagueId);
                var option = "<option value=" + league.nid + ">" + league.name + "</option>";
                self.$el.find('#leagueSelection').append(option);
            });

            this.$el.find('#smartFallback').val(this.smartFallbackDefault);

            if(this.offerData.aff_campaign_geo && this.offerData.aff_campaign_geo.split(',').length > 1) {
                this.$el.find('.linkNameInput').val("target_multigeo_" + this.offerHashId);
            } else {
                this.$el.find('.linkNameInput').val("target_" + (this.offerData.aff_campaign_geo || '') + "_" + this.offerHashId);

            }
            this._showSmartlinkMsg();
        },

        _getOfferData: function() {
            var self = this,
                req = new ApiRequest(),
                dfd = $.Deferred(),
                docFragment = $(document.createDocumentFragment()),
                now = moment.tz('America/New_York'),
                languageData = languageSrc;


            req.request('v3/offersAggregatedGlobal.php?offer_id='+this.offerHashId)
                .done(function(response) {
                    var offer = response[0];
                    if(typeof offer === 'undefined' || typeof offer.aff_campaign_id === 'undefined') {
                        alert('This offer is currently unavailable.');
                    } else {
                        var createdMoment = moment.tz(offer.created,'America/New_York');

                        offer.aff_campaign_id = parseInt(offer.aff_campaign_id);
                        if(offer.cap !== null) {
                            offer.cap  = parseInt(offer.cap);
                            offer.capLeft = offer.cap - parseInt(offer.today_leads);
                        }
                        offer.clicks = parseInt(offer.clicks);
                        offer.cpa = parseFloat(offer.cpa);
                        offer.ctl = parseFloat(offer.ctl);
                        offer.epc = parseFloat(offer.epc);
                        offer.is_capped = parseInt(offer.is_capped);
                        offer.leads = parseInt(offer.leads);
                        offer.payout = parseFloat(offer.payout);
                        offer.revenue = parseFloat(offer.revenue);
                        offer.vauto_paused = parseInt(offer.vauto_paused);

                        offer.hasDomains = self.hasDomains;
                        offer.daysDiff = now.diff(createdMoment,'day');

                        if(offer.bonus) {
                            offer.bonusAmount = offer.payout * parseFloat(offer.bonus);
                            offer.bonus = parseInt((parseFloat(offer.bonus) - 1) * 100);
                        }


                        if(offer.aff_campaign_language_data !== null) {
                            var languageAr = JSON.parse(offer.aff_campaign_language_data);
                            offer.languages = [];

                            _.each(languageAr, function(lang) {
                                var langRow = _.findWhere(languageData,{alpha2: lang});
                                offer.languages.push(langRow.English);
                            });
                        }

                        offer.roundedEpc = parseFloat(offer.epc).toFixed(3);
                        offer.roundedPayout = parseFloat(offer.payout).toFixed(2);

                        offer.modifiedName = self._modifiedName(
                            offer.conversion_flow,
                            offer.offerIdDep,
                            offer.aff_campaign_geo,
                            offer.aff_campaign_vertical
                        );
                        offer.connection = self.WIFI_STRINGS[offer.wifi] || '';
                        offer.revenueRangeStr = self._revenueRangeStr(offer.revenue);

                        if(!offer.aff_campaign_geo.includes(',')){
                            offer.flagPath = require("../../images/flags/" + offer.aff_campaign_geo + ".png").default;
                        }

                        offer.hourDataFormatted = self._formatHourData(offer.aff_campaign_hour_data);

                        self.offerData = offer;

                    }

                    dfd.resolve();
                })
                .fail(function() {
                    alert('unable to get offer details');
                });

            return dfd.promise();

        },

        _formatHourData: function (data) {
            if (!data || typeof data !== 'string') {
                return data;
            }
            var items = data.split(',');
            var formattedItems = _.map(items, function (item) {
                if (item.includes('-')) {
                    var range = item.split('-');
                    return range[0] + ':00-' + range[1] + ':59';
                } else {
                    return item + ':00-' + item + ':59';
                }
            });
            return formattedItems.join(', ');
        },


        getDomains: function () {
            var dfd = $.Deferred(),
                self = this,
                req = new ApiRequest();

            if (this.domains && this.domains.length > 0) {
                dfd.resolve(this.domains);
            } else {
                req
                    .request("data/domains.php", { action: "list" })
                    .done(function(data) {
                        self.domains = data.domains || [];
                        dfd.resolve(self.domains);
                    })
                    .fail(function(err) {
                        console.error("FAILED TO FETCH PARTNER DOMAINS", err.responseJSON);
                        dfd.resolve([]);
                    });
            }

            return dfd.promise();
        },

        _modifiedName: function(conversionFlow, id, geo, vertical) {
            if(geo && geo.split(',').length > 1) {
                geo = 'multigeo';
            } if (geo) {
                geo = geo;
            } else {
                geo = '';


            }

            if(vertical.includes('in_app')) {
                var modifiedName = "in_app-" + id + "-" + geo;
            } else {
                var modifiedName = conversionFlow + "-" + id + "-" + geo;
            }
            return modifiedName;
        },


        _revenueRangeStr: function(range) {
            if (range > 999) {
                var decimalThousand = range/1000;
                return '$' + decimalThousand.toFixed(1) + 'k+';
            } else if (range >= 1) {
                return '$' + Math.floor(range) + '+';
            } else {
                return '<$1';
            }
        },

        _createHash: function() {
            var self = this,
                linkNameRegex = /^[a-zA-Z0-9_]+$/,
                $leagueNid = this.$el.find('#leagueSelection').val(),
                adultRegEx = new RegExp('adult', 'i'),
                adultOfferFlag = adultRegEx.test(this.selectedOfferVertical) ? 1 : 0,
                hardOfferWhitelist = this.$el.find('#smartFallback').val() === '0' ? 1 : 0,
                $keyword = this._encodeSpaces(this.$el.find("#keyword").val()),
                req = new ApiRequest(),
                campaignName = this.$el.find('.campaignNameInput').val();

            if(campaignName === '') {
                campaignName = this.$el.find('.linkNameInput').val()
            }

            var optionsData = JSON.stringify({
                entry_popup: 1,
                exit_popup: 0,
                vibrate: 1,
                sound: 1,
                redirect_time: 10,
                rcid: campaignName,
                kw1: $keyword,
                kw2: "",
                kw3: "",
                kw4: "",
                kw5: "",
                s4: this.$el.find('.clickIdInput').val(),
            });

            var whitelistOffers = JSON.stringify([this.offerHashId]),
                data = {
                    disable_bbr: 0,
                    disable_popunder: 0,
                    options_data: optionsData,
                    offer_whitelist_data: whitelistOffers,
                    hard_offer_whitelist: hardOfferWhitelist,
                    vertical: this.verticalDefault,
                    adult: adultOfferFlag,
                    allow_aggressive: 1,
                    name: this.$el.find('.linkNameInput').val(),
                    nid: $leagueNid,
                    desktop_redirect: 1
                };

            if (this.$el.find('.linkNameInput').val().search(linkNameRegex) == -1) {
                alert('Link name can only contain letters, numbers and underscores.');
                return;
            }

            req.request("data/partner-link.php", data)
                .done(function(res) {
                    self.$el.find(".urlSelection").remove();

                    var linkData = _.extend(data, res);
                    self.showDirectLink(linkData);

                })
                .fail(function(xhr) {
                    if(xhr.responseJSON && xhr.responseJSON.message) {
                        alert(xhr.responseJSON.message);
                    } else {
                        console.error(xhr.responseJSON);

                    }
                });
        },


        _encodeSpaces: function(text) {
            return text.replace(/ /g, "%20");
        },


        _copyUrl: function(linkStr) {
            return navigator.clipboard.writeText(linkStr);
        },

        _showSmartlinkMsg: function(){
            var state = this.$el.find('#smartFallback').val();

            if(parseInt(state) === 0) {
                this.$el.find('.smartLinkActiveMsg').hide();
                this.$el.find('.smartLinkDisabledMsg').show();
            } else {
                this.$el.find('.smartLinkActiveMsg').show();
                this.$el.find('.smartLinkDisabledMsg').hide();
            }
        },



        _formatHourData: function (data) {
            if (!data || typeof data !== 'string') {
                return data;
            }
            var items = data.split(',');
            var formattedItems = _.map(items, function (item) {
                if (item.includes('-')) {
                    var range = item.split('-');
                    return range[0] + ':00-' + range[1] + ':59';
                } else {
                    return item + ':00-' + item + ':59';
                }
            });
            return formattedItems.join(', ');
        },

        _insertMacro: function (ev) {
            var target = ev.currentTarget.dataset.target,
                macro = $(ev.currentTarget).html();

            $('.'+target).val(macro);

        },


        showDirectLink: function (link) {
            var self = this;
            this.getDomains()
                .then(function (domains) {
                    var sslDomains = _.where(domains, {ssl_enabled: '1'});
                    if (sslDomains && sslDomains.length > 0) {
                        var directLinkView = new DirectLinkView({parent: self, link: link, domains: sslDomains});
                        directLinkView.render();
                        self.listenToOnce(directLinkView, 'close', self._close);
                    } else {
                        alert(
                            "Please add a domain name to your " +
                            "account and wait until SSL cert generated before generating a direct link"
                        );
                        Backbone.history.navigate("domains", { trigger: true });
                    }
                });
        },

        _openPreviewInNewTab: function (ev) {
            var url = $(ev.currentTarget).data('image-tooltip');
            if (url) {
                window.open(url, '_blank');
            }
        },

        _close: function() {
            this.remove();

        }

    });

});
