define([
    "Backbone",
    "registry",
    "moment-timezone",
    "workers/apirequest",
    "views/pureSelect",
    "views/message",
    "libs/image-tooltip",
    "dict/isocountries.json",
    "dict/verticals.json",
    "dict/language-codes.json",
    "views/offerDetailsModal",
    "templates/offerMarketplace.html",
    "templates/offerMarketplaceRow.html",
    "templates/offerMarketplaceEmptyRow.html",
    "templates/offerMarketplaceUnavailableRow.html",
], function (
    Backbone,
    registry,
    moment,
    ApiRequest,
    PureSelect,
    messageView,
    ImageTooltip,
    geoDataSrc,
    verticalsSrc,
    languageSrc,
    offerDetailsModal,
    offerMarketplaceTemplate,
    offerMarketplaceRow,
    offerMarketplaceEmptyRowTemplate,
    offerMarketplaceUnavailableRowTemplate
) {
    var OfferMarketplaceView = Backbone.View.extend({
        rowTemplate: null,
        isAsleep: true,

        _subViews: [],

        WIFI_STRINGS: {
            deny: "Carrier",
            only: "WiFi",
            allow: "All",
        },

        events: {
            "click .apply-filters": "_applyFilter",
            "click .newMessageBtn": "_newMessage",
            "click .plannerBtn": "_showPlanner",
            "click .viewOfferDetailsBtn": "_viewOfferDetails",
        },

        initialize: function () {
            this.rowTemplate = _.template(offerMarketplaceRow);
        },

        render: function () {
            var self = this,
                req = new ApiRequest();

            this.template = _.template(offerMarketplaceTemplate);
            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("offersPane")
                .addClass("mainPane")
                .attr("id", "offersContainer");

            this.$el.append(this.template);

            $("#appContainer").append(this.$el);

            var geoData = geoDataSrc;

            for (var i = 0; i < geoData.length; i++) {
                var optionEle =
                    "<option value=" +
                    geoData[i].alpha_2_code +
                    ">" +
                    geoData[i].country +
                    "</option>";

                this.$el.find("#country").append(optionEle);
            }

            var verticalData = verticalsSrc;
            var appendToVertical = function (value, key) {
                if (key !== "monetizer" && key !== "direct_offers") {
                    self.$el
                        .find("#vertical-filter")
                        .append(
                            "<option value=" + key + ">" + value + "</option>"
                        );
                }
            };

            _.each(verticalData, function (value, key) {
                if (_.isObject(value)) {
                    _.each(value, appendToVertical);
                } else {
                    appendToVertical(value, key);
                }
            });

            this.$el.find("#country").val("topOffers");
            this.domains = [];
            this.getDomains().done(function (domains) {
                var sslDomains = _.where(domains, { ssl_enabled: "1" });

                if (
                    typeof sslDomains !== "undefined" &&
                    sslDomains.length > 0
                ) {
                    self.hasDomains = true;
                } else {
                    self.hasDomains = false;
                    self.$el.find(".noDomainMsg").show();
                }

                self._getData();
            });
        },

        getDomains: function () {
            var dfd = $.Deferred(),
                self = this,
                req = new ApiRequest();

            if (this.domains && this.domains.length > 0) {
                dfd.resolve(this.domains);
            } else {
                req.request("data/domains.php", { action: "list" })
                    .done(function (data) {
                        self.domains = data.domains || [];
                        dfd.resolve(self.domains);
                    })
                    .fail(function (err) {
                        console.error(
                            "FAILED TO FETCH PARTNER DOMAINS",
                            err.responseJSON
                        );
                        dfd.resolve([]);
                    });
            }

            return dfd.promise();
        },

        _applyFilter: function () {
            this.$el.find(".pushPromo").hide();

            this._getData();
        },

        _getData: async function () {
            var self = this,
                countrySelection = this.$el.find("#country").val();

            this.$el.find("#offersData").empty();
            this.$el.find("#top5offersData").empty();
            this.$el.find(".loading--container").show();
            this.$el.find(".wtOfferPanel").hide();

            var queryString = self._buildQueryString();
            var urlResource = "v3/offersAggregatedGlobal.php?";
            if (countrySelection !== "topOffers") {
                urlResource =
                    "v3/offersAggregatedForGeo.php?country_code=" +
                    countrySelection +
                    "&";
            }

            this.offers = [];

            this._populateOfferTable(
                urlResource + queryString,
                "#offersData",
                "#main_loading_container"
            );

            if (countrySelection !== "topOffers") {
                self.$el.find(".top5offersTable--container").hide();
            } else {
                if (
                    this.$el.find(".offerIdInput").val() !== "" ||
                    this.$el.find(".networkIdInput").val() !== ""
                ) {
                    this.$el.find(".top5offersTable--container").hide();
                    this.$el.find(".offerResultsTitle").show();
                    this.$el.find(".topOffersTitle").hide();
                } else {
                    this.$el.find(".offerResultsTitle").hide();
                    this.$el.find(".topOffersTitle").show();
                    this.$el.find(".top5offersTable--container").show();

                    var trendingStartTs =
                        Math.floor(Date.now() / 1000) - 6 * 24000;
                    var trendingQueryString = queryString;
                    trendingQueryString += "&start_ts=" + trendingStartTs;
                    trendingQueryString += "&limit=3";

                    this._populateOfferTable(
                        urlResource + trendingQueryString,
                        "#top5offersData",
                        "#trending_loading_container"
                    );

                    this._walkyTalkyOfferPanel();
                }
            }
        },

        _walkyTalkyOfferPanel: async function () {
            /*
            var urlResource = "v3/offersAggregatedGlobal.php?";

            var wtQueryString = urlResource + this._buildQueryString({
                "offerId": "63681d"
            });
            this._getOffers(wtQueryString);

            wtQueryString = urlResource + this._buildQueryString({
                "offerId": "3830fa"
            });
            await this._getOffers(wtQueryString);

            this.$el.find(".wtOfferPanel").show();
             */
        },

        _getOffers: async function (url) {
            var req = new ApiRequest(),
                now = moment.tz("America/New_York"),
                languageData = languageSrc;

            try {
                const results = await req.request(url);

                var assignmentIds = [];

                if (typeof results.unavailable !== "undefined") {
                    return "unavailable";
                }

                for (var i = 0; i < results.length; i++) {
                    var createdMoment = moment.tz(
                        results[i].created,
                        "America/New_York"
                    );

                    assignmentIds.push(parseInt(results[i].assignment_id));
                    results[i].hourDataFormatted = this._formatHourData(
                        results[i].aff_campaign_hour_data
                    );

                    results[i].aff_campaign_id = parseInt(
                        results[i].aff_campaign_id
                    );
                    if (results[i].cap !== null) {
                        results[i].cap = parseInt(results[i].cap);
                        results[i].capLeft =
                            results[i].cap - parseInt(results[i].today_leads);
                    }
                    results[i].clicks = parseInt(results[i].clicks);
                    results[i].cpa = parseFloat(results[i].cpa);
                    results[i].ctl = parseFloat(results[i].ctl);
                    results[i].epc = parseFloat(results[i].epc);
                    results[i].fb_epc = parseFloat(results[i].fb_epc);
                    results[i].is_capped = parseInt(results[i].is_capped);
                    results[i].leads = parseInt(results[i].leads);
                    results[i].payout = parseFloat(results[i].payout);
                    results[i].revenue = parseFloat(results[i].revenue);
                    results[i].vauto_paused = parseInt(results[i].vauto_paused);

                    results[i].hasDomains = this.hasDomains;
                    results[i].daysDiff = now.diff(createdMoment, "day");

                    if (results[i].bonus) {
                        results[i].bonusAmount =
                            results[i].payout * parseFloat(results[i].bonus);
                        results[i].bonus = parseInt(
                            (parseFloat(results[i].bonus) - 1) * 100
                        );
                    }

                    if (results[i].aff_campaign_language_data !== null) {
                        var languageAr = JSON.parse(
                            results[i].aff_campaign_language_data
                        );
                        results[i].languages = [];

                        _.each(languageAr, function (lang) {
                            var langRow = _.findWhere(languageData, {
                                alpha2: lang,
                            });
                            results[i].languages.push(langRow.English);
                        });
                    }

                    results[i].roundedEpc = parseFloat(results[i].epc).toFixed(
                        3
                    );
                    results[i].roundedFbEpc = results[i].fb_epc
                        ? "$" + results[i].fb_epc.toFixed(3)
                        : "-";
                    results[i].roundedPayout = parseFloat(
                        results[i].payout
                    ).toFixed(2);

                    results[i].modifiedName = this._modifiedName(
                        results[i].conversion_flow,
                        results[i].offerIdDep,
                        results[i].aff_campaign_geo,
                        results[i].aff_campaign_vertical
                    );
                    results[i].connection =
                        this.WIFI_STRINGS[results[i].wifi] || "";
                    results[i].revenueRangeStr = this._revenueRangeStr(
                        results[i].revenue
                    );

                    if (
                        results[i].aff_campaign_geo &&
                        !results[i].aff_campaign_geo.includes(",")
                    ) {
                        results[i].flagPath = require("../../images/flags/" +
                            results[i].aff_campaign_geo +
                            ".png").default;
                    }

                    results[i].allowMessages = registry.user.allowMessages;
                    this.offers[results[i].offerIdDep] = results[i];
                }

                this._getReliabilityScores(assignmentIds);

                return results;
            } catch (e) {
                console.error(e);
            }
        },

        _populateOfferTable: async function (
            dataUrl,
            resultsContainer,
            loadingContainer
        ) {
            const results = await this._getOffers(dataUrl);

            this.$el.find(loadingContainer).hide();

            if (results === "unavailable") {
                this.$el
                    .find(resultsContainer)
                    .append(_.template(offerMarketplaceUnavailableRowTemplate));

                return;
            }

            this.$el.find(resultsContainer).append(this._getRows(results));

            $(".offerImgTooltip").remove();
            this.$el.find(".offerHoverImage").imageTooltip();

            if (results.length <= 0) {
                this.$el
                    .find(resultsContainer)
                    .append(_.template(offerMarketplaceEmptyRowTemplate));
            }
        },

        _getReliabilityScores: function (assignmentIds) {
            var self = this,
                req = new ApiRequest(),
                data = {
                    assignment_id: assignmentIds,
                };

            req.request("v3/offerReliabilityPoints.php?" + $.param(data)).then(
                function (response) {
                    _.each(assignmentIds, function (assignment) {
                        var row = _.findWhere(response, {
                                object_id: assignment.toString(),
                            }),
                            $holder = self.$el.find(
                                '.reliabilityScore[data-id="' +
                                    assignment +
                                    '"]'
                            ),
                            val = 100;

                        if (typeof row === "undefined") {
                            $holder.text(val + "%");
                            $holder.addClass("text-success");
                        } else {
                            val = val - row.score * 5;
                            if (val < 0) {
                                val = 0;
                            }
                            $holder.text(parseFloat(val).toFixed(0) + "%");
                            if (val > 75) {
                                $holder.addClass("text-success");
                            } else if (val > 50) {
                                $holder.addClass("text-warning");
                            } else {
                                $holder.addClass("text-danger");
                            }
                        }
                    });
                }
            );
        },

        _buildQueryString: function (queryValues = {}) {
            var minPayout = this.$el.find("#payout-min").val();
            var maxPayout = this.$el.find("#payout-max").val();
            var minEpc = this.$el.find("#epc-min").val();
            var maxEpc = this.$el.find("#epc-max").val();
            var minRevenue = this.$el.find("#revenue-min").val();
            var maxRevenue = this.$el.find("#revenue-max").val();
            var conversionFlowFilter = this.$el
                .find("#conversion-flow-filter")
                .val();
            var verticalFilter = this.$el.find("#vertical-filter").val();
            var networkId = this.$el.find(".networkIdInput").val();

            if (typeof queryValues.offerId !== "undefined") {
                var offerId = queryValues.offerId;
            } else {
                var offerId = this.$el.find(".offerIdInput").val();
            }

            var string =
                "min_payout=" +
                minPayout +
                "&max_payout=" +
                maxPayout +
                "&min_epc=" +
                minEpc +
                "&max_epc=" +
                maxEpc +
                "&min_revenue=" +
                minRevenue +
                "&max_revenue=" +
                maxRevenue +
                "&conversion_flow=" +
                conversionFlowFilter +
                "&vertical=" +
                verticalFilter;

            if (this.$el.find(".hidePausedInput").is(":checked")) {
                string += "&hide_paused=1";
            }

            if (this.$el.find(".newOffersInput").is(":checked")) {
                string += "&new=1";
            }

            if (networkId !== "") {
                string += "&network_id=" + networkId;
            }

            if (offerId !== "") {
                string += "&offer_id=" + offerId;
            }

            return string;
        },

        _getRows: function (offers) {
            var self = this,
                docFragment = $(document.createDocumentFragment());

            _.each(offers, function (offer) {
                docFragment.append(self.rowTemplate(offer));
            });

            return docFragment;
        },

        _modifiedName: function (conversionFlow, id, geo, vertical) {
            if (geo && geo.split(",").length > 1) {
                geo = "multigeo";
            }
            if (geo) {
                geo = geo;
            } else {
                geo = "";
            }

            if (vertical.includes("in_app")) {
                var modifiedName = "in_app-" + id + "-" + geo;
            } else {
                var modifiedName = conversionFlow + "-" + id + "-" + geo;
            }
            return modifiedName;
        },

        _revenueRangeStr: function (range) {
            if (range > 999) {
                var decimalThousand = range / 1000;
                return "$" + decimalThousand.toFixed(1) + "k+";
            } else if (range >= 1) {
                return "$" + Math.floor(range) + "+";
            } else {
                return "<$1";
            }
        },

        _encodeSpaces: function (text) {
            return text.replace(/ /g, "%20");
        },

        _copyUrl: function (linkStr) {
            return navigator.clipboard.writeText(linkStr);
        },

        sleep: function () {
            this.isAsleep = true;
        },

        wakeUp: function () {
            this.isAsleep = false;
        },

        _newMessage: function (ev) {
            new messageView({
                action: "new",
                network_id: ev.currentTarget.dataset.network_id,
                message:
                    "(Regarding Offer #" +
                    ev.currentTarget.dataset.offer +
                    ") - ",
            });
        },
        _formatHourData: function (data) {
            if (!data || typeof data !== "string") {
                return data;
            }
            var items = data.split(",");
            var formattedItems = _.map(items, function (item) {
                if (item.includes("-")) {
                    var range = item.split("-");
                    return range[0] + ":00-" + range[1] + ":59";
                } else {
                    return item + ":00-" + item + ":59";
                }
            });
            return formattedItems.join(", ");
        },

        _insertMacro: function (ev) {
            var target = ev.currentTarget.dataset.target,
                macro = $(ev.currentTarget).html();

            $("." + target).val(macro);
        },

        _showPlanner: function (ev) {
            var element = $(ev.currentTarget),
                cid = element.data("cid"),
                name = null,
                vertical = null,
                statsTargetObject = {};

            if (cid === undefined) {
                console.error("cannot find cid for this");
                return false;
            }

            name = element.data("name");

            if (name === undefined) {
                console.error("cannot find name for this");
                return false;
            }

            vertical = element.data("vertical");

            if (vertical === undefined) {
                console.error("cannot find vertical for this");
                return false;
            }

            statsTargetObject.reportType = "vertical";

            statsTargetObject.rid = cid;
            statsTargetObject.name = name;
            statsTargetObject.vertical = vertical;
            statsTargetObject.activeBtn = "#offerButton";

            registry.platformStatsView.setTarget(statsTargetObject);
            registry.platformStatsView.show();
        },

        _viewOfferDetails: function (ev) {
            var selectedOffer = this.offers[ev.currentTarget.dataset.id];
            new offerDetailsModal({ offerData: selectedOffer });
        },
    });

    return new OfferMarketplaceView();
});
