define([
    "Backbone",
    "templates/apps/linkDetails.html",
    "views/apps/appDetails"
], function (
    Backbone,
    template,
    AppDetailsView
) {
    return Backbone.View.extend({
        events: {
            'click .urlCopyBtn': 'copyUrl',
            'click .view-close': 'destroy',
        },

        initialize: function(inputs) {
            if (!inputs.parent || !inputs.link) {
                throw new Error('parent and link is required for AppLinkDetailsView');
            }
            const self = this;
            this.parent = inputs.parent;
            this.appDetailsView = null;
            this.link = inputs.link;
            this.template = _.template(template);
            this.parent.getApps().then(function (apps) {
                const app = apps[self.link.package_id] || null;
                self.render(app);
            });
        },

        render: function(app) {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "appLinkDetails");
            this.$el.append(this.template({link: this.link}));

            if (app) {
                this.removeSubviews();
                this.appDetailsView = new AppDetailsView({app});
                this.$el.find('.appLinkPackageInfo').html(this.appDetailsView.render().el);
            }

            $("#appContainer").append(this.$el);
            this.$el.find('.overlay').fadeIn('fast');
        },

        removeSubviews: function() {
            if (this.appDetailsView) {
                this.appDetailsView.remove();
                this.appDetailsView = null;
            }
        },

        copyUrl: function (ev) {
            this.parent.copyUrl(ev);
        },

        destroy: function() {
            this.removeSubviews();
            this.$el.find('.overlay').fadeOut('fast');
            this.remove();
        },
    });
});

