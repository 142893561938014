define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "views/pureSelect",
    "views/message",
    "libs/image-tooltip",
    "dict/isocountries.json",
    "dict/verticals.json",
    "dict/language-codes.json",
    "views/directLink",
    "views/offerDetailsModal",
    "templates/cpaMarketplace.html",
    "templates/cpaMarketplaceRow.html",
], function(
    Backbone,
    registry,
    ApiRequest,
    PureSelect,
    messageView,
    ImageTooltip,
    geoDataSrc,
    verticalsSrc,
    languageSrc,
    DirectLinkView,
    offerDetailsModal,
    offerMarketplaceTemplate,
    offerMarketplaceRow,
) {
    var OfferMarketplaceView = Backbone.View.extend({

        isAsleep: true,
        _subViews: [],
        perPage: 40,
        order: 'payout',
        page: 0,
        offers: [],


        WIFI_STRINGS: {
            deny: "Carrier",
            only: "WiFi",
            allow: "All"
        },

        events: {
            "click .apply-filters": "_updateFilter",
            'click .loadMoreBtn': '_updateFilter',
            'click .newMessageBtn': '_newMessage',
            'click .requestOfferBtn': '_requestOffer',
            'click .rejectOfferBtn': '_showRejectOfferModal',
            'click .confirmRejectOfferBtn': '_rejectOffer',
            'change .jumboCheckbox': 'selectionChanged',
            'click .jumboLinkBtn': 'createJumboLink',
            'click .viewOfferDetailsBtn': '_viewOfferDetails',
        },

        initialize: function () {
            this.rejectReasons = [
                "Select a reason",
                "Payout is too low",
                "Cap is too low",
                "No interest right now",
                "Other: use the messaging system to discuss"
            ];
            this.selectedOfferIds = [];
        },

        render: function() {
            var self = this,
                req = new ApiRequest(),
                geoData = geoDataSrc;

            this.selectedOfferIds = [];
            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("offersPane")
                .addClass("mainPane")
                .attr("id", "cpaMarketplaceContainer");

            this.$el.append(_.template(offerMarketplaceTemplate));

            $("#appContainer").append(this.$el);

            _.each(geoData, function(row) {
                self.$el.find("#country").append($('<option>', {
                    value: row.alpha_2_code,
                    text: row.country
                }));
            });
            this.$el.find("#country").val("");

            this.$loadMoreBtn = this.$el.find('.loadMoreBtn');
            this.$tagInput = this.$el.find('.tagInput');
            this.$noResultsMsg = this.$el.find('.noResultsMsg');
            this.$noOffersMsg = this.$el.find('.noOffersMsg');
            this.$rejectOfferModal = this.$el.find('.rejectOfferModal');
            this.$rejectOfferReasonInput = this.$el.find('.rejectOfferReasonInput');

            _.each(this.rejectReasons, function (reason, index) {
                this.$rejectOfferReasonInput.append($('<option>', {
                    value: index,
                    text: reason
                }));
            }, this);

            registry.user.setLastReadDirectOffers();

            this.domains = [];
            $.when(
                req.request('v3/tags.php?object_type=1'),
                this.getDomains()
            )
                .then(function (tagResponse, domains) {
                    var results = tagResponse[0];

                    _.each(results, function (tag) {
                        self.$tagInput.append($('<option>', {
                            value: tag.id,
                            text: tag.tag
                        }));
                    });

                    var sslDomains = _.where(domains, {ssl_enabled: '1'});

                    if (typeof sslDomains !== 'undefined' && sslDomains.length > 0) {
                        self.hasDomains = true;
                    } else {
                        self.hasDomains = false;
                        self.$el.find('.noDomainMsg').show();
                    }

                    self._getData();

                });
        },

        getDomains: function () {
            var dfd = $.Deferred(),
                self = this,
                req = new ApiRequest();

            if (this.domains && this.domains.length > 0) {
                dfd.resolve(this.domains);
            } else {
                req
                    .request("data/domains.php", { action: "list" })
                    .done(function(data) {
                        self.domains = data.domains || [];
                        dfd.resolve(self.domains);
                    })
                    .fail(function(err) {
                        console.error("FAILED TO FETCH PARTNER DOMAINS", err.responseJSON);
                        dfd.resolve([]);
                    });
            }

            return dfd.promise();
        },

        _getData: function() {
            var self = this,
                req = new ApiRequest(),
                queryString = self._buildQueryString(),
                urlResource = "v3/directOffersAggregatedGlobal.php?";


            self.$noOffersMsg.hide();
            self.$noResultsMsg.hide();

            req.request(urlResource + queryString)
                .then(function (results) {
                    self.$el.find("#main_loading_container").hide();
                    self._getRows('#offersData', results);

                    if(results.length < self.perPage) {
                        self.$loadMoreBtn.hide();
                    } else {
                        self.$loadMoreBtn.show();
                    }

                    if(results.length === 0) {
                        self._showNoResults();
                    }

                    _.each(results, function(row) {
                        row.hourDataFormatted = self._formatHourData(row.aff_campaign_hour_data);
                        self.offers[row.offerIdDep] = row;
                    });
                    $('.offerImgTooltip').remove();
                    self.$el.find('.offerHoverImage').imageTooltip();
                });



        },

        _formatHourData: function (data) {
            if (!data || typeof data !== 'string') {
                return data;
            }
            var items = data.split(',');
            var formattedItems = _.map(items, function (item) {
                if (item.includes('-')) {
                    var range = item.split('-');
                    return range[0] + ':00-' + range[1] + ':59';
                } else {
                    return item + ':00-' + item + ':59';
                }
            });
            return formattedItems.join(', ');
        },

        _buildQueryString: function() {
            var networkId = this.$el.find(".networkIdInput").val(),
                offerId = this.$el.find(".offerIdInput").val(),
                order = this.$el.find('.orderByInput').val(),
                string = "&page=" + this.page
                    + "&limit=" + this.perPage
                    + "&order=" + order;

            if(this.$el.find('.hidePausedInput').is(':checked')) {
                string += '&hide_paused=1';
            }

            if(networkId !== '') {
                string += '&network_id='+networkId;
            }

            if(offerId !== '') {
                string += '&offer_id='+offerId;
            }

            return string;
        },



        _getRows: function(parent, offers) {
            var self = this,
                rowTemplate = _.template(offerMarketplaceRow),
                languageData = languageSrc;

            _.each(offers, function (offer) {
                offer.aff_campaign_id = parseInt(offer.aff_campaign_id);
                if(offer.cap !== null) {
                    offer.cap  = parseInt(offer.cap);
                    offer.capLeft = offer.cap - parseInt(offer.today_leads);
                }
                offer.clicks = parseInt(offer.clicks);
                offer.hasDomains = self.hasDomains;
                offer.cpa = parseFloat(offer.cpa);
                offer.is_capped = parseInt(offer.is_capped);
                offer.payout = parseFloat(offer.payout);
                offer.paused = parseInt(offer.vauto_paused);
                offer.lander_required = parseInt(offer.lander_required);
                offer.flagPath = require('./../../images/flags/'+offer.aff_campaign_geo+'.png').default;
                if(offer.paused === 0) {
                    offer.paused = false;
                    offer.vauto_paused = false;

                } else {
                    offer.paused = true;
                    offer.vauto_paused = true;
                }

                if(offer.payout === parseInt(offer.payout)) {
                    offer.payout = parseInt(offer.payout);
                } else {
                    offer.payout = offer.payout.toFixed(2);
                }

                offer.roundedEpc = parseFloat(offer.epc).toFixed(3);
                offer.roundedPayout = parseFloat(offer.payout).toFixed(2);

                offer.modifiedName = self._modifiedName(
                    'private_offer',
                    offer.offerIdDep,
                    offer.aff_campaign_geo
                );

                if(offer.aff_campaign_language_data !== null) {
                    var languageAr = JSON.parse(offer.aff_campaign_language_data);
                    offer.languages = [];

                    _.each(languageAr, function(lang) {
                        var langRow = _.findWhere(languageData,{alpha2: lang});
                        offer.languages.push(langRow.English);
                    });
                }


                if(_.findWhere(offer.tags,{tag:'Adult'})) {
                    offer.adult = true;
                } else {
                    offer.adult = false;
                }

                offer.connection = self.WIFI_STRINGS[offer.wifi] || '';
                self.$el.find(parent).append(rowTemplate(offer));

            });
        },


        _modifiedName: function(conversionFlow, id, geo) {
            return conversionFlow + "-" + id + "-" + geo;
        },


        _revenueRangeStr: function(range) {
            var decimalThousand = range/1000;

            if (range > 999) {
                return '$' + decimalThousand.toFixed(1) + 'k+';
            } else if (range >= 1) {
                return '$' + Math.floor(range) + '+';
            } else {
                return '<$1';
            }
        },






        _updateFilter: function(ev) {
            var $input = $(ev.currentTarget);

            if($input.hasClass('loadMoreBtn')) {
                this.page++;
                this.$el.find(".loading--container").show();
            } else {
                this.page = 0;
                this.offers = [];
                this.$el.find("#offersData").empty();
            }

            this.$noResultsMsg.hide();
            this.$loadMoreBtn.hide();
            this.$el.find("#main_loading_container").show();

            this._getData();
        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        },

        _newMessage: function (ev) {
            new messageView({
                'action': 'new',
                'network_id': ev.currentTarget.dataset.network_id,
                'message': '(Regarding Offer #'+ev.currentTarget.dataset.offer+') - '
            });
        },

        _requestOffer: function (ev) {
            new messageView({
                'action': 'new',
                'message': 'Private Offer Request: '
            });
        },

        _showRejectOfferModal: function (ev) {
            this.rejectedOfferId = $(ev.currentTarget).data('id');
            this.$rejectOfferReasonInput.val(0);
            this.$rejectOfferModal.show();
        },

        _rejectOffer: function () {
            if (!this.rejectedOfferId) {
                this.$rejectOfferModal.hide();
                return;
            }

            var reason = +this.$rejectOfferReasonInput.val();
            if (!reason) {
                alert('Please provide a reason for reject.');
                return;
            }

            var apiRequest = new ApiRequest();
            var self = this;
            var $btn = $('.confirmRejectOfferBtn');
            $btn.prop('disabled', true);
            apiRequest.request('v3/reject-private-offer.php', {
                offerId: this.rejectedOfferId,
                reason: this.rejectReasons[reason]
            })
                .then(function () {
                    $('#offer-' + self.rejectedOfferId).remove();
                    var $offers = $('#offersData').find('.offerPanel');
                    if ($offers.length === 0) {
                        self._showNoResults();
                    }

                    self.$rejectOfferModal.hide();
                    self.rejectedOfferId = undefined;
                })
                .fail(function (error) {
                    if (error.responseJSON && error.responseJSON.message) {
                        alert(error.responseJSON.message);
                    }
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        _showNoResults: function () {
            if(this.$el.find('.networkIdInput').val() === '' && this.$el.find('.offerIdInput').val() === '') {
                this.$noOffersMsg.show();
            } else {
                this.$noResultsMsg.show();
            }
        },

        selectionChanged: function(ev) {
            var $checkbox = $(ev.currentTarget);
            var id = $checkbox.data('id');
            if ($checkbox.prop('checked')) {
                this.selectedOfferIds.push(id);
            } else {
                this.selectedOfferIds = _.filter(this.selectedOfferIds, function (offerId) {
                   return offerId !== id ;
                }, this);
            }
            this.updateJumboLinkBtn()
        },

        updateJumboLinkBtn: function() {
            var $btn = this.$el.find('.jumboLinkBtn');
            if (this.selectedOfferIds.length > 1) {
                $btn.show();
            } else {
                $btn.hide();
            }
        },

        createJumboLink: function() {
            if (this.selectedOfferIds.length < 2) {
                return;
            }
            var offers = _.map(this.selectedOfferIds, function(offerId) {
                return _.clone(this.offers[offerId] || null);
            }, this);
            offers = _.filter(offers, function(o) { return o !== null;});
            registry.jumboLinkOffers = offers;
            Backbone.history.navigate('links/new/direct_offers', {trigger: true});
        },

        _viewOfferDetails: function(ev) {
            var selectedOffer = this.offers[ev.currentTarget.dataset.id];

            new offerDetailsModal({
                offerData: selectedOffer,
                vertical: 'direct_offers',
                smartFallback: 0
            });
        },
    });

    return new OfferMarketplaceView();
});
