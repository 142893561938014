define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "libs/uiAlert",
    "libs/copyToClipboard",
    "views/selectLink",
    "templates/domainParking.html",
    "templates/domainParkingRow.html"
], function (
    Backbone,
    registry,
    ApiRequest,
    uiAlert,
    copyToClipboard,
    SelectLinkView,
    template,
    rowTemplate
) {

    var DomainParkingView = Backbone.View.extend({

        events: {
            'click .addDomainsModalBtn': 'showAddDomainsModal',
            'click #dp_linkInput': 'selectLink',
            'click .addDomainsBtn': 'addDomains',
            'click .copyBtn': 'copyInputVal',
            'click .pageBtn': 'changePage',
            'click .domainDeleteBtn': 'deleteDomain',
            'click .multiSelectDeleteBtn': 'deleteDomains',
            'click #selectAllDomainsCheckbox': 'selectAllDomains',
            'click .multiSelectCheckbox': 'toggleDomainSelection',
            'click .changeLinkBtn': 'changeLink',
            'click .showNSBtn': 'showNameservers',
            'click .multiSelectChangeLinkBtn': 'changeLinkInDomains',
            'click .filterBtn': 'filterDomains',
            'click .resetFiltersBtn': 'resetFilters',
            'click #dp_helpBtn': 'showHelp',
        },

        initialize: function () {
            this.rowTemplate = _.template(rowTemplate);
        },

        render: function () {
            this.loadedDfd = $.Deferred();
            this.page = 1;
            this.perPage = 25;
            this.parkedDomains = [];
            this.nameservers = [];
            this.links = undefined;
            this.setElement($(document.createElement('div')));
            this.$el.addClass("mainPane").attr('id', 'domainParkingContainer');
            this.$el.append(_.template(template)());
            $('#appContainer').append(this.$el);

            this.$addDomainsModal = this.$el.find('#dp_addDomainsModal');
            this.$domainsInput = this.$el.find('#dp_domainsInput')
            this.$linkInput = this.$el.find("#dp_linkInput");
            this.$domainsTableBody = this.$el.find('.parkedDomainsTable > tbody');
            this.$prevPageBtn = this.$el.find('.pageBtn[data-id="' + 'prev' + '"]');
            this.$nextPageBtn = this.$el.find('.pageBtn[data-id="' + 'next' + '"]');
            this.$multiselectBtns = this.$el.find('.multiSelectBtnContainer');
            this.$selectAllCheckbox = this.$el.find('#selectAllDomainsCheckbox');
            this.$statusFilterInput = this.$el.find('#dp_StatusFilterInput');
            this.$domainFilterInput = this.$el.find('#dp_domainFilterInput');
            this.$linkHashFilterInput = this.$el.find('#dp_linkHashFilterInput');

            if (registry.user && registry.user.allowDomains === 0) {
                this.$el.find('.allowDomains').hide();
                this.$el.find('.domainsManagingDisabledWarning').show();
            } else {
                this.$el.find('.allowDomains').show();
                this.$el.find('.domainsManagingDisabledWarning').hide();
            }

            this.getLinks();
            this.fetchDomains();
        },

        getLinks: function () {
            var self = this,
                dfd = $.Deferred(),
                req = new ApiRequest();

            if (typeof this.links !== 'undefined') {
               dfd.resolve(this.links);
            } else {
                req
                    .request(
                        "data/partner-link.php?all=1"
                    )
                    .done(function(response) {
                        self.links = response;
                        dfd.resolve(self.links);
                    })
                    .fail(function(xhr) {
                        var msg = "There was an error fetching your links, please restart Monetizer and if the problem persists contact Support.";
                        uiAlert.showError(msg)
                        console.error("Error fetching links", xhr.responseJSON);
                    });
            }

            return dfd.promise();
        },

        fetchDomains: function () {
            var self = this;
            var req = new ApiRequest();
            var params = {
                page: this.page,
                perPage: this.perPage,
            };

            if (this.$statusFilterInput.val() !== '') {
                params.status = this.$statusFilterInput.val();
            }

            if (this.$domainFilterInput.val() !== '') {
                params.domain = this.$domainFilterInput.val().trim();
            }

            if (this.$linkHashFilterInput.val() !== '') {
                params.linkHash = this.$linkHashFilterInput.val().trim();
            }

            req.request('v3/publisherParkedDomains.php?' + $.param(params))
                .then(function (response) {
                    self.parkedDomains = _.map(response.domains, self.castDomainRecord.bind(self));
                    self.nameservers = response.nameservers;
                    self.loadedDfd.resolve();
                    self.updatePaginationBtns();
                    self.showDomains();
                    self.updateMultiselectBtns();
                    self.updateSelectAllCheckbox();
                })
                .fail(function (error) {
                    if (error && error.responseJSON && error.responseJSON.message) {
                        uiAlert.showError(error.responseJSON.message);
                    } else {
                        uiAlert.showError('Error occured.');
                    }
                });
        },

        castDomainRecord: function (domain) {
            domain.name_servers = domain.name_servers ? JSON.parse(domain.name_servers) : [];

            return domain;
        },

        showDomains: function () {
            this.$domainsTableBody.empty();
            if (this.parkedDomains && this.parkedDomains.length > 0) {
                _.each(this.parkedDomains, function (domain) {
                    this.$domainsTableBody.append(this.rowTemplate(domain));
                }, this);
            } else {
                this.$domainsTableBody.append('<tr><td colspan="6" class="text-center">No domains</td></tr>');
            }
        },

        showAddDomainsModal: function (ev) {
            this.$domainsInput.val('');
            this.$linkInput.text('');
            this.$addDomainsModal.show();
        },

        showLinkSelect: function (callback) {
            var self = this;
            var selectLinkView;
            this.getLinks().then(function (links) {
                selectLinkView = new SelectLinkView({links: links});
                self.listenToOnce(selectLinkView, 'select', callback);
            });
        },

        selectLink: function (ev) {
            this.showLinkSelect(function (link) {
                if (link && link.hash) {
                    this.$linkInput.text(link.hash);
                }
            });
        },

        changeLink: function (ev) {
            var $btn = $(ev.currentTarget);
            var linkHash = $btn.data('hash');
            var domain = $btn.data('cid');
            this.showLinkSelect(function (link) {
                this.updateLink([domain], link);
            });
        },

        changeLinkInDomains: function (ev) {
            this.showLinkSelect(function (link) {
                var selectedDomains = this.getSelectedDomains();
                if (selectedDomains.length > 0 &&
                    confirm("Are you sure you want to set link '" + link.name + "' for all selected domains?")) {
                    this.updateLink(selectedDomains, link);
                }
            });
        },

        updateLink: function (domains, link) {
            var self = this;
            var req = new ApiRequest();
            req.post('v3/publisherParkedDomains.php', {
                action: 'changeLink',
                domains: JSON.stringify(domains),
                link: link.hash
            })
                .then(function (response) {
                    uiAlert.showSuccess('Link has been changed.');
                    self.fetchDomains();
                })
                .fail(function (error) {
                    if (error && error.responseJSON && error.responseJSON.message) {
                        uiAlert.showError(error.responseJSON.message);
                    } else {
                        uiAlert.showError('Server error occured.');
                    }
                });
        },

        addDomains: function (ev) {
            var self = this;
            var $btn = $(ev.currentTarget);
            var originalBtnText = $btn.text();
            var domains = this.getDomains();
            var linkHash = this.$linkInput.text();
            var req = new ApiRequest();

            $btn.prop('disabled', true);
            $btn.text('Saving...');
            req.post('v3/publisherParkedDomains.php', {
                action: 'addDomain',
                domain: domains[0],
                link: linkHash
            })
                .then(function (response) {
                    uiAlert.showSuccess("Domain has been added");
                    self.$addDomainsModal.hide();
                    self.trigger('domains:added', response);
                })
                .fail(function (error) {
                    if (error && error.responseJSON && error.responseJSON.message) {
                        uiAlert.showError(error.responseJSON.message);
                    } else {
                        uiAlert.showError('Server error occured.');
                    }
                })
                .always(function () {
                    $btn.prop('disabled', false);
                    $btn.text(originalBtnText);
                });
        },

        getDomains: function () {
            var domains = this.$domainsInput.val().split(/(?:\r?\n)|,/);
            return _.filter(
                _.map(domains, function (domain) {
                    return domain.trim();
                }),
                function (domain) {
                    return domain !== ''
                });
        },

        onDomainsAdded: function (ev) {
            this.showInstructionsModal(ev.nameservers);
            this.page = 1;
            this.fetchDomains();
        },

        showInstructionsModal: function (nameservers) {
            this.$el.find('#dp_ns1Input').val(nameservers[0]);
            this.$el.find('#dp_ns2Input').val(nameservers[1]);
            this.$el.find('#dp_instructionsModal').show();
        },

        showHelp: function () {
            var self = this;
            var $helpModal = this.$el.find('#dp_helpModal');
            // var $nameServersList = this.$el.find('#dp_nameServersList');
            // this.loadedDfd.promise().then(function () {
            //     $nameServersList.text(self.nameservers.join(', '));
            // });
            $helpModal.show();
        },

        showNameservers: function (ev) {
            var domainName = $(ev.currentTarget).data('cid');
            var domain = _.findWhere(this.parkedDomains, {domain: domainName});
            var $modal = this.$el.find('#dp_NSModal');
            $modal.find('.domainName').text(domain.domain);
            if (domain.status === 'pending') {
                $modal.find('.deleteWarning').show();
            } else {
                $modal.find('.deleteWarning').hide();
            }
            if (domain.name_servers.length > 0) {
                $modal.find('#dp_NSModal_ns1Input').val(domain.name_servers[0]);
                $modal.find('#dp_NSModal_ns2Input').val(domain.name_servers[1]);
                $modal.find('.nameservers').show();
                $modal.find('.noNameservers').hide();
            } else {
                $modal.find('.nameservers').hide();
                $modal.find('.noNameservers').show();
            }
            $modal.show();
        },

        copyInputVal: function (ev) {
           var id = $(ev.currentTarget).data('id');
           var $input = this.$el.find('#' + id);
           copyToClipboard($input.val());
        },

        changePage: function (ev) {
            var $btn = $(ev.currentTarget);
            var id = $btn.data('id');
            if (id === 'prev') {
                this.page = Math.max(this.page - 1, 1);
            } else if (id === 'next') {
                this.page += 1;
            } else {
                return;
            }
            this.fetchDomains();
        },

        updatePaginationBtns: function () {
            if (this.page > 1) {
                this.$prevPageBtn.prop('disabled', false);
            } else {
                this.$prevPageBtn.prop('disabled', true);
            }
            if (this.parkedDomains && this.parkedDomains.length === this.perPage) {
                this.$nextPageBtn.prop('disabled', false);
            } else {
                this.$nextPageBtn.prop('disabled', true);
            }
        },

        deleteDomain: function (ev) {
            var self = this;
            var domain = $(ev.currentTarget).data('cid');
            var req = new ApiRequest();
            if (confirm("Are you sure you want to delete domain: '" + domain +"'?")) {
                req.request('v3/publisherParkedDomains.php', {
                    action: 'delete',
                    domains: JSON.stringify([domain]),
                })
                    .done(function (response) {
                        self.fetchDomains();
                    })
                    .fail(function (error) {
                        if (error && error.responseJSON && error.responseJSON.message) {
                            uiAlert.showError(error.responseJSON.message);
                        } else {
                            uiAlert.showError('Server error occured.');
                        }
                    });
            }
        },

        deleteDomains: function (ev) {
            var self = this;
            var $btn = $(ev.currentTarget);
            var req = new ApiRequest();
            var selectedDomains = this.getSelectedDomains();
            if (selectedDomains.length > 0 &&
                confirm("Are you sure you want to delete selected domains?")) {
                $btn.prop('disabled', true);
                req.request('v3/publisherParkedDomains.php', {
                    action: 'delete',
                    domains: JSON.stringify(selectedDomains),
                })
                    .done(function (response) {
                        if (response.success) {
                           uiAlert.showSuccess(response.deleted + ' domain(s) deleted');
                        } else {
                           uiAlert.showError('Error: ' + response.deleted + ' domain(s) deleted');
                        }
                        self.fetchDomains();
                    })
                    .fail(function (error) {
                        if (error && error.responseJSON && error.responseJSON.message) {
                            uiAlert.showError(error.responseJSON.message);
                        } else {
                            uiAlert.showError('Server error occured.');
                        }
                    })
                    .always(function () {
                        $btn.prop('disabled', false);
                    });
            }
        },

        selectAllDomains: function (ev) {
            var checked = ev.currentTarget.checked;

            this.$el.find('.multiSelectCheckbox').prop('checked', checked);
            this.updateMultiselectBtns();
        },

        toggleDomainSelection: function (ev) {
            this.updateSelectAllCheckbox();
            this.updateMultiselectBtns();
        },

        updateSelectAllCheckbox: function () {
            var allChecked = (this.parkedDomains.length > 0 &&
                this.getSelectedDomains().length === this.parkedDomains.length);
            this.$selectAllCheckbox.prop('checked', allChecked);
        },

        updateMultiselectBtns: function () {
            if (this.getSelectedDomains().length > 0) {
                this.$multiselectBtns.show();
            } else {
                this.$multiselectBtns.hide();
            }
        },

        filterDomains: function (ev) {
            this.page = 1;
            this.fetchDomains();
        },

        resetFilters: function (ev) {
            this.$statusFilterInput.val('');
            this.$domainFilterInput.val('');
            this.$linkHashFilterInput.val('');
            this.filterDomains();
        },

        getSelectedDomains: function () {
            var domains = [];
            this.$el.find('.multiSelectCheckbox:checked').each(function () {
                domains.push($(this).data('domain'));
            });
            return domains;
        },

        sleep: function () {
        },

        wakeUp: function () {
            this.listenTo(this, 'domains:added', this.onDomainsAdded);
        }
    });

    return new DomainParkingView();
});
