define([
    'Backbone',
    'dict/isocountries.json',
    'dict/forbidden-countries.json',
    'templates/register/monetizer.html',
    'templates/register/countryInputRow.html',
], function (
    Backbone,
    countryData,
    forbiddenCountries,
    template,
    countryInputRowTemplate,
) {

    return Backbone.View.extend({

        events: {
            'click .view-close': 'destroy',
            'focusin .input-wrapper input': 'onInputFocusIn',
            'focusout .input-wrapper input': 'onInputFocusOut',
            'click .signup-select': 'showHideSelect',
            'click .registerModal .panel': 'hideSelect',
            'change .country-select input': 'onCountryChanged',
            'click .registerBtn': 'register',
        },

        initialize: function (options) {
            options || (options = {});
            this.helpText = options.helpText || 'Complete the form below to sign up.';
            this.successHelpContent = options.successHelpContent || '';
            this.template = _.template(template);
            this.apiUrl = API_URL + 'data/monetizersite-register.php';
            this.countryInputRowTemplate = _.template(countryInputRowTemplate);
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.append(this.template({
                helpText: this.helpText,
                successHelpContent: this.successHelpContent
            }));
            var $container = this.$el.find('.countryInputOptions');
            _.each(countryData, function (country) {
                if (!forbiddenCountries.includes(country.alpha_2_code)) {
                    $container.append(this.countryInputRowTemplate({
                        value: country.alpha_2_code,
                        text: country.country
                    }));
                }
            }, this);
            $("#appContainer").append(this.$el);
            this.$el.find('.overlay').fadeIn('fast');
        },

        destroy: function() {
            this.$el.find('.overlay').fadeOut('fast');
            this.remove();
        },

        onInputFocusIn: function(ev) {
            $(ev.currentTarget).closest('.input-wrapper').addClass('focus');
        },

        onInputFocusOut: function(ev) {
            $(ev.currentTarget).closest('.input-wrapper').removeClass('focus');
        },

        onCountryChanged: function(ev) {
            var $target = $(ev.target);
            var $select = $target.closest('.country-select');
            var country = $target.data('country');
            $select.removeClass('show');
            if ($target.val()) {
                $select.find('.selectedValue').text(country);
            }
        },

        hideSelect: function(ev) {
            this.$el.find('.signup-select').removeClass('show');
        },

        showHideSelect: function(ev) {
            $(ev.currentTarget).toggleClass('show');
            ev.stopPropagation();
        },

        getFormData: function() {
           return {
                action: 'register',
                name: this.$el.find('.signup-input.name').val(),
                email: this.$el.find('.signup-input.email').val(),
                comment: this.$el.find('.more_info').val(),
                linkedin: this.$el.find('.signup-input.linkedin').val(),
                skype: this.$el.find('.signup-input.skype').val(),
                twitter: this.$el.find('.signup-input.twitter').val(),
                country_code: this.$el.find('.countryInput:checked').val(),
                type: 1,
                sid: 0,
                facebook: '',
                pid: 0
            };
        },

        displayErrors: function(errors) {
            var self = this;
            $.each(errors, function(field, value) {
                switch(field) {
                    case 'name':
                        self.$el.find('.signup-input.name').addClass('input-danger');
                        break;
                    case 'email':
                        self.$el.find('.signup-input.email').addClass('input-danger');
                        break;
                    case 'comment':
                        self.$el.find('.more_info').addClass('input-danger');
                        break;
                    case 'country_code':
                        self.$el.find('.country-select').addClass('select-danger');
                        break;
                    case 'linkedin':
                        self.$el.find('.signup-input.linkedin').addClass('input-danger');
                        break;
                    case 'twitter':
                        self.$el.find('.signup-input.twitter').addClass('input-danger');
                        break;
                    case 'message':
                        alert(value);
                        break;
                }
            });
        },

        register: function(ev) {
            var self = this;
            var $btn = $(ev.currentTarget);
            this.$el.find('.signup-input').removeClass('input-danger');
            this.$el.find('.country-select').removeClass('select-danger');
            this.$el.find('.signup-input.twitter').removeClass('input-danger');
            this.$el.find('.signup-input.linkedin').removeClass('input-danger');

            $btn.prop('disabled', true);
            $.ajax({
                url: this.apiUrl,
                method: 'POST',
                dataType: 'json',
                data: this.getFormData()
            })
                .done(function(response) {
                    $btn.prop('disabled', false);
                    if (response.status === 'error') {
                        self.displayErrors(response.errors);
                    } else if (response.status === 'success') {
                        self.$el.find('.signup-form').fadeOut('fast');
                        self.$el.find('.successMsg').fadeIn('fast');

                        //ga('send', 'event', 'Users', 'register');
                    }
                });
        },

    });
});
