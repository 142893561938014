define([
    'registry',
    'views/message',
    'views/offerMarket/requestDetails',
    'templates/offerMarket/advertiserRequestDetails.html',
], function (
    registry,
    messageView,
    RequestDetailsView,
    template
) {
    var AdvertiserRequestDetailsView = RequestDetailsView.extend({
        template: _.template(template),
        collection: registry.advertisersRequests,
        requestType: 'advertiserRequest',

        events: function() {
            return _.extend({}, RequestDetailsView.prototype.events, {
                'click .messageBtn': 'sendMessage'
            });
        },

        sendMessage: function(ev) {
            if (this.type !== 'public' && registry.user && registry.user.id) {
                var advertiserId = this.model.get('network_id');
                var message = '(Regarding your traffic request "' + this.model.get('name') + '" [#' + this.model.get('id') + ']) - ';

                if (advertiserId) {
                    new messageView({
                        network_id: advertiserId,
                        message: message,
                        action: 'new'
                    });
                }
            }
        },
    });

    return new AdvertiserRequestDetailsView();
});
