define([
    'dict/isocountries.json'
], function (
    countryData
) {
    var countryByCode = {};
    _.each(countryData, function(countryRecord) {
        countryByCode[countryRecord.alpha_2_code] = countryRecord.country;
    });

    var flowTitlesMap = {
        'carrier billing': "Carrier Billing",
        leadgen: "Leadgen",
        revshare: "Revshare",
        sweepstakes: "Sweepstakes",
        cpi: "CPI (Apps)",
        dating: "Dating",
        'cash on delivery': "Cash on Delivery",
        ecommerce: "ECommerce",
        'apk direct download': "APK/Direct Download",
        'finance insurance': "Finance/Insurance",
        'cc submit': "CC Submit",
        nutra: "Nutra",
        other: "Other",
    };

    var trafficTypeTitlesMap = {
        'search engine': "Search Engine",
        ppv: "PPV",
        social: "Social",
        push: "Push Notifications",
        native: "Native",
        video: "Video",
        pop: "Pop",
        seo: "SEO",
        ecommerce: "Ecommerce",
        incentive: "Incentive",
        adult: "Adult",
        other: "Other ",
    };

    function processGeo(record) {
        if (record.geo) {
            record.geo = record.geo.split(',');
            record.countryCodes = record.geo.join(', ');
            if (countryByCode[record.geo[0]]) {
                record.flagPath = require("../../images/flags/" + record.geo[0] + ".png").default;
            } else {
                record.flagPath = '';
            }
            var fullCountryNames = [];
            _.each(record.geo, function(geo) {
                var fullName = countryByCode[geo];
                if (fullName) {
                    fullCountryNames.push(fullName + ' (' + geo + ')');
                }
            });
            record.fullCountryNames = fullCountryNames.join(', ');
        } else {
            record.geo = [];
            record.countryCodes = "All";
            record.flagPath = '';
            record.fullCountryNames = "All";
        }

        return record;
    }

    function generateName(request, type = 'publisher') {
        request || (request = {});
        var conversionFlow = flowTitlesMap[request.conversion_flow] || '{Flow/Vertical}';
        var countries = request.geo;
        if (!_.isArray(countries)) {
            countries = countries.split(',');
        }
        var country = '{Country}';
        if (countries.length > 1) {
            country = countryByCode[countries[0]] + '...';
        } else if (countries.length === 1) {
            country = countryByCode[countries[0]];
        }

        if (type === 'publisher') {
            return "Offer wanted: " + conversionFlow + ' - ' + country;
        } else {
            return "Traffic wanted: " + conversionFlow + ' - ' + country;
        }
    }

    return {
        flowTitlesMap: flowTitlesMap,
        trafficTypeTitlesMap: trafficTypeTitlesMap,
        countryByCode: countryByCode,
        processGeo: processGeo,
        generateName: generateName,
    };
});

