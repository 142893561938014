define([
    "Backbone",
    "dict/isocountries.json",
    "templates/apps/appDetails.html",
    "templates/apps/appGeoRates.html"
], function (
    Backbone,
    countryData,
    template,
    ratesTemplate
) {
    return Backbone.View.extend({
        events: {
            'click .btn-flag': 'geoSelected',
        },

        initialize: function(inputs) {
            this.countryByCode = _.reduce(countryData, function(acc, record) {
                acc[record.alpha_2_code] = record.country;
                return acc;
            }, {});
            this.app = inputs.app || null;
            this.offersByGeo =_.groupBy(this.app.offers, 'geo');
            this.geos = Object.keys(this.offersByGeo).sort();
            this.setFlags();
            this.template = _.template(template);
            this.ratesTemplate = _.template(ratesTemplate);
        },

        setFlags: function () {
            this.flags = {};
            for (let geo of Object.keys(this.offersByGeo)) {
                if (!this.flags[geo]) {
                    this.flags[geo] = require('../../../images/flags/' + geo + '.png').default;
                }
            }
        },

        render: function() {
            if (this.app) {
                this.$el.html(this.template({app: this.app, geos: this.geos, flags: this.flags}));
                this.$el.find(`.btn-flag[data-geo="${this.geos[0]}"]`).click();
            }
            return this;
        },

        geoSelected: function (ev) {
            this.$el.find('.btn-flag').removeClass('active');
            const $btn = $(ev.currentTarget);
            $btn.addClass('active');
            const geo = $btn.data('geo');
            this.renderRates(geo);
        },

        renderRates(geo) {
            const offers = _.sortBy(this.offersByGeo[geo] || [], function(offer) { return -1 * parseFloat(offer.rate); });
            const $el = this.$el.find('.geoRates');
            $el.html(this.ratesTemplate({country: this.countryByCode[geo], offers}));
        }
    });
});

