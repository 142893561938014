define([
], function (
) {
    return {
        showSuccess: function (msg) {
            var $panel = $('#globalSuccessMsg');

            $panel.html(msg);

            $panel.fadeIn('fast', function() {
                setTimeout(function() {
                    $panel.fadeOut('fast');
                }, 5000)
            });
        },

        showError: function (msg) {
            var $panel = $('#globalErrorMsg');

            $panel.html(msg);

            $panel.fadeIn('fast', function() {
                setTimeout(function() {
                    $panel.fadeOut('fast');
                }, 5000)
            });
        },
    };
});

