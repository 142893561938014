define([
    "Backbone",
    "workers/apirequest",
    "registry",
    "moment-timezone",
    "views/academySidebar",
    "templates/academy/academyTemplate.html",
    "templates/academy/academyPostPanel.html",
], function(
    backbone,
    api,
    app,
    moment,
    sidebarView,
    academyTemplate,
    academyPostPanel

) {
    var academyView = Backbone.View.extend({

        // Events
        events: {
            'click .pageBtn': '_paginate'
        },

        initialize: function() {

        },

        render: function (subpage, filter) {
            var self = this,
                typeMap = {
                    'news': 29,
                    'guides': 30,
                    'faq': 28
                };

            this.setElement($(document.createElement("div")));
            this.$el.addClass("mainPane").attr("id", "academyContainer");

            self.$el.append(academyTemplate);
            $("#appContainer").append(self.$el);

            this.page = 0;
            this.limit = 24;
            this.type = '';
            this.tag = '';
            this.searchTerm = '';

            this.$el.find('.typeBtn').removeClass('active');
            if(subpage === 'type') {
                this.type = typeMap[filter];
                this.$el.find('.typeBtn[data-id="' + filter + '"]').addClass('active');
            } else if(subpage === 'tag') {
                var filter = filter.split('-'),
                    tagId = filter[filter.length-1];
                this.type = '';
                this.tag = tagId;
                this.$el.find('.typeBtn[data-id=""]').addClass('active');
            } else if(subpage === 'search') {
                this.type = '';
                this.tag = '';
                this.searchTerm = filter;
                this.$el.find('.typeBtn[data-id=""]').addClass('active');
            } else {
                this.type = '';
                this.$el.find('.typeBtn[data-id=""]').addClass('active');
            }

            this.$newPostsContainer = this.$el.find('.newPostsContainer');
            this.sideBar = new sidebarView(this.$el.find('.sidebarContainer'));


            this._displayNewPosts();

        },

        _getResults: function(apiData) {
            var req = new api(),
                params = '';

            _.each(apiData, function(val, key) {
                params += '&'+key+'='+encodeURIComponent(val);
            });



            return req.request('v3/posts.php?action=results&website=monetizer'+params);
        },


        _displayNewPosts: function() {
            var self = this,
                rowTemplate = _.template(academyPostPanel);

            $.when(this._getResults({
                'page': this.page,
                'limit': this.limit,
                'type': this.type,
                'tag': this.tag,
                'search': this.searchTerm
            }))
                .then(function(response) {
                    self.$newPostsContainer.empty();


                    if(response.length >= self.limit) {
                        self.$el.find('.pageBtn[data-direction="next"]').removeAttr('disabled');
                    } else {
                        self.$el.find('.pageBtn[data-direction="next"]').attr('disabled','disabled');
                    }

                    if(self.page === 0) {
                        self.$el.find('.pageBtn[data-direction="prev"]').attr('disabled','disabled');
                    } else {
                        self.$el.find('.pageBtn[data-direction="prev"]').removeAttr('disabled');
                    }

                    _.each(response, function(row) {
                        if(row.meta_title == null) {
                            row.meta_title = row.title;
                        }
                        self.$newPostsContainer.append(rowTemplate(row));

                    })


                });

        },

        _paginate: function(ev) {
            var $btn = $(ev.currentTarget),
                direction = $btn.data('direction');

            if(direction === 'next') {
                this.page++;

            } else if (direction === 'prev') {
                if(this.page > 0) {
                    this.page--;
                }
            }

            this._displayNewPosts();
        },

        sleep: function() {},

        wakeUp: function() {}



    });

    return new academyView();
});
