define([
    "moment-timezone",
    'libs/offerMarketUtils',
    'collections/offerMarket/baseRequests'
], function (moment, Utils, BaseRequestsCollection) {
    return BaseRequestsCollection.extend({
        name: 'publishersRequests',
        getAllAction: 'publishersRequests',

        castDataRecord: function(record) {
            if (!record) {
                return record;
            }

            var now = moment.tz('America/New_York');
            var updatedAt = moment.tz(record.updated_at, 'America/New_York');

            record.id = +record.id;
            record.trafficType = Utils.trafficTypeTitlesMap[record.traffic_type] || 'N/A';
            record.flowVertical = Utils.flowTitlesMap[record.conversion_flow] || 'N/A';
            record.minPayoutFormatted = record.min_payout ? '$' + parseFloat(record.min_payout).toPrecision() : 'NSF';
            record.minCapFormatted = record.min_cap ? '$' + parseFloat(record.min_cap).toFixed(2) : 'NSF';
            Utils.processGeo(record);
            record.createdAtFormatted = moment.tz(record.created_at, 'America/New_York').format('MMM Do YYYY');
            record.updatedAtFormatted = updatedAt.format('MMM Do YYYY');
            record.isOld = now.diff(updatedAt, 'months') > 12;
            record.os_data = JSON.parse(record.os_data);
            record.carrier_data = JSON.parse(record.carrier_data);
            record.lp_wanted = !!+record.lp_wanted;
            record.partner_id = +record.partner_id;
            record.isMyRequest = false;

            return record;
        },
    })
});
