define([
    "backbone",
    "templates/baseModal.html",
], function(
    Backbone,
    template
) {
    return Backbone.View.extend({
        events: {
            'click .modal-close': 'close',
            'click .overlay': 'close',
            'click .modal': 'stopPropagation',
            'click .navigate-to': 'navigateTo',
        },

        /**
         * @param options
         * options.modalType (optional) - class that specify modal type like: modal-lg, modal-fluid
         * options.header (optional) - text of the modal's header
         * options.content (optional) - text of the modal's content
         * options.footerBtn (optional) - label of the close button in the modal's footer
         * options.headerHtml (optional) - can be used instead of 'header' option to specify html of the modal's header
         * options.contentHtml (optional) - can be used instead of 'content' option to specify html of the modal's content
         * options.footerHtml (optional) - can be used instead of 'footer' option to specify html of the modal's footer
         */
        initialize: function (options) {
            this.options = options || {};
            this.template = _.template(this.options.template || template);
            this.render();
            $("#appContainer").append(this.$el);
        },

        render: function () {
            this.$el.html(this.template({data: this.options}))
            if (this.options.modalHtml) {
                this.$el.find('.modal').html(this.options.modalHtml);
            } else {
                if (this.options.headerHtml) {
                    this.$el.find('.panel-header').html(this.options.headerHtml);
                }
                if (this.options.contentHtml) {
                    this.$el.find('.panel-content').html(this.options.contentHtml);
                }
                if (this.options.footerHtml) {
                    this.$el.find('.panel-footer').html(this.options.footerHtml);
                }
            }
        },

        close: function () {
            this.undelegateEvents();
            this.$el.removeData().unbind();
            this.remove();
        },

        stopPropagation: function (ev) {
            ev.stopImmediatePropagation();
        },

        navigateTo: function(ev) {
            var route = $(ev.currentTarget).data('route') || '';
            this.close();
            Backbone.history.navigate(route, {trigger: true});
        }
    });
});
