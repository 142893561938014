/*jslint nomen:true, indent:4*/
/*jshint -W079 */
/*global define, _, console, $ */

define([
	"Backbone",
	"templates/pureSelectTemplate.html",
	"templates/pureSelectOptionTemplate.html",
	"templates/pureSelectGroupTemplate.html",
	"templates/pureSelectScrollButtonTemplate.html"
],
function (Backbone, pureSelectTemplate, optionTemplate, groupLabelTemplate, scrollTemplate) {

	var ViewPureSelect = Backbone.View.extend({

		// Constants

		// Events
		events: {
			"click .pureSelectValue": "clickSelect",
			"click .pureSelectOption": "clickItem",
			"mouseenter .pureSelectScrollButton": "enterScroll",
			"mouseenter .pureSelectOption": "leaveScroll",
			"mouseleave .pureSelectScrollButton": "leaveScroll",
			"touchstart .pureSelectScrollButton": "touchScroll",
		},


		// Methods
		initialize: function () {
			var self = this;

			this._data = null; // RAW DATA
			this._processed = []; // IN A LIST FORMAT
			this._currentSelection = null;
			this._placeholder = null;
			this._enabled = true;

			this._scrollPos = 0;
			this._scrollTimeout = null;
			this._scrolling = false;
			this._scrollMax = 0;

			// For skipping through portions of the scroll
			//  by repeatedly tapping the same character
			this._lastScrollChar = null;
			this._scrollRepeat = 0;

			this._optionTemplate = _.template(optionTemplate);
			this._groupLabelTemplate = _.template(groupLabelTemplate);
			this._scrollButtonTemplate = _.template(scrollTemplate);
			this._optionHeight = 33;

			this.setElement(pureSelectTemplate);

			this.$shownText = this.$el.find(".pureSelectActualVal");
			this.$optionsContainer = this.$el.find(".pureSelectOptionsContainer");

			this.$optionsContainer.hide();

			this.showPlaceholder();

			// Set the close handler globally
			if (window.pureSelectGlobal === undefined) {
				$(document).on("click", '*', function (e) {
					if ($(e.target).closest(".pureSelectContainer").length === 0) {
						$(".pureSelectOptionsContainer").hide();
					}
				});

				window.pureSelectGlobal = {
					"init": true
				};
			}


			$(document).on("keypress", function (e) {
				if (self.$optionsContainer.is(":visible")) {
					self._scrollToChar.call(self, String.fromCharCode(e.charCode));
				}
			});
		},



		render: function () {
			return this.$el;
		},



		updateData: function(data, defaultVal) {
			var self = this;

			if (!_.isObject(data)) {
				console.error("Cannot add data passed to the PureSelect");
				return this;
			}
			else {

				if (_.size(data) === 0) {
					this.disable();
				}
				else {
					this.enable();
				}

				this._data = data;
				this._processed = [];

				if (this._isGrouped()) {
					_.each(this._data, function (val, key) {
						self._processGroup(val, key);
					});
				}
				else {
					this._processGroup(this._data);
				}

				// Be careful, the data isn't always indexed in the same order.
				if (defaultVal !== undefined) {
					this.val(defaultVal);
					// Fall back to first val on inappropriate defaultVal
					if (this.val() !== defaultVal) {
						this.reset();
					}
				}
				else {
					this.reset();
				}

				this.trigger("updated:data");
				return this;
			}

		},



		_processGroup: function (groupData, groupText) {
			var self = this;

			if (groupText !== undefined) {
				this._processed.push({
					type: "group",
					text: groupText
				});
			}

			_.each(groupData, function (childText, childValue) {
				var newListItem = {};

				if (_.isObject(childText)) {
					console.error("Only one level of depth allowed in PureSelect");
					return true;
				}
				else {
					childValue = childValue.toString();

					newListItem.type = "option";
					newListItem.text = childText;
					newListItem.val = childValue;

					self._processed.push(newListItem);
				}

			});
		},



		_setOptionHeight: function () {
			var testOption;

			if (this._optionHeight === null) {
                /*
				testOption = $(this._optionTemplate({
					text: "Test",
					value: "Test"
				}));

				this.$optionsContainer.append(testOption);
				this._optionHeight = testOption.height();
				this.$optionsContainer.empty();

				if (this._optionHeight === 0) {
					this._optionHeight = null;
					return false;
				}
				else {
                */
                return true;
				//}
			}
			else {
				return true;
			}
		},



		update: function () {
			var self = this,
				docFragment = $(document.createDocumentFragment()),
				fullHeight = 0;

			var scrolling = false;
			var optionsPerScreen = _.size(this._processed),
				rOption = null,
				$rAppendee = null;

			if (this._data === null) {
				return false;
			}

			// Add Options
			this.$optionsContainer.empty();

			// We need to get a feel for how many options we can display
			// Ok if the options fill less than 60% of the screen then no scrolling is required
			// Then if the box is due to run off the page we need to move it up
			// We also need to disable page scrolling whilst the boxes are open
			if (this._setOptionHeight()) {
				fullHeight = this._optionHeight * _.size(this._processed);

				if (fullHeight > (window.innerHeight * 0.65)) {
					scrolling = true;
					optionsPerScreen = Math.floor((window.innerHeight * 0.65) / this._optionHeight);
					this._scrollMax = _.size(this._processed) - optionsPerScreen;
				}
			}
			else {
				console.error("NO OPTION HEIGHT FOR THIS!!");
			}


			// Top Scroll Button
			if (scrolling) {


				$rAppendee = $(self._scrollButtonTemplate({
					direction: 0
				}));
				docFragment.append($rAppendee);

				if (this._scrollPos === 0) {
					$rAppendee.addClass("disabled");
				}

			}


			// Render Loop
			// TODO is there a bug here? this probably allows for an overrun no?
			for (var i = this._scrollPos; i < this._scrollPos + optionsPerScreen; i++) {
				rOption = this._processed[i];

				if (rOption.type === "group") {

					// Option Group
					$rAppendee = $(self._groupLabelTemplate({
						text: rOption.text
					}));
					docFragment.append($rAppendee);

				} else {

					$rAppendee = $(self._optionTemplate({
						text: rOption.text,
						value: rOption.val
					}));

					if (self._currentSelection !== null &&
						rOption.val === self._currentSelection.val)
					{
						$rAppendee.addClass("selected");
					}

					docFragment.append($rAppendee);
				}
			}

			// Button Scroll Button
			if (scrolling) {

				$rAppendee = $(self._scrollButtonTemplate({
					direction: 1
				}));
				docFragment.append($rAppendee);

				if (this._scrollPos === this._scrollMax) {
					$rAppendee.addClass("disabled");
				}

			}

			this.$optionsContainer.html(docFragment);
		},

		touchScroll: function(e) {
			e.preventDefault();
			e.stopImmediatePropagation();
			this.enterScroll(e);
			var self = this;
			var target = e.currentTarget;
			var direction = parseInt(target.dataset.direction);
			var callback = function () {
				target.removeEventListener('touchend', callback);
				self.leaveScroll();
				if (direction === 1) {
					self.$el.find('.pureSelectOptionsContainer').scrollTop(270);
				} else {
					self.$el.find('.pureSelectOptionsContainer').scrollTop(0);
				}
			};
			target.addEventListener('touchend', callback);
		},

		enterScroll: function(e) {
			var $target = $(e.currentTarget),
				direction = parseInt($target.data("direction"));

			if (this._scrollTimeout === null) {
				this._scrolling = true;
				this.doScroll(direction);
			}
		},


		doScroll: function (direction) {
			var self = this;

			if (this._scrolling === true) {

				if (direction === 1 && this._scrollPos < this._scrollMax) {
					this._scrollPos++;
				}
				else if (direction === 0 && this._scrollPos > 0) {
					this._scrollPos--;
				}
				else {
					clearTimeout(this._scrollTimeout);
					this._scrollTimeout = null;
					return false;
				}

				this.update();

				self._scrollTimeout = setTimeout(function () {
					self.doScroll.call(self, direction);
				}, 50);
			}
			else {
				clearTimeout(this._scrollTimeout);
				this._scrollTimeout = null;
			}
		},


		_scrollToChar: function (char) {
			var rObj = null,
				pos = 0,
				skipCount = 0,
				rCheckChar = null;

			char = char.toLowerCase();

			// Allow for scrolling through options with the same letter
			if (char === this._lastScrollChar) {
				this._scrollRepeat++;
			}
			else {
				this._scrollRepeat = 0;
				this._lastScrollChar = char;
			}

			// Main loop
			for (var index in this._processed) {
				rObj = this._processed[index];

				if (rObj.type === "option") {
					rCheckChar = rObj.text[0].toLowerCase();
					if (rCheckChar === char) {
						if (skipCount === this._scrollRepeat) {
							break;
						}
						else {
							skipCount++;
						}
					}
				}

				rObj = null;
				pos++;
			}


			if (rObj !== null) {
                /*
				if (pos > this._scrollMax) {
					this._scrollPos = this._scrollMax;
				}
				else {
                */
					this._scrollPos = pos;
                /*
				}
                */


				this.update();
			}
			else {
				// Result not found so reset
				this._scrollRepeat = 0;
				this._lastScrollChar = null;
			}
		},


		leaveScroll: function () {
			this._scrolling = false;
			clearTimeout(this._scrollTimeout);
			this._scrollTimeout = null;
		},



		clickSelect: function() {
			if (this._enabled) {

				// Reset Scroll
				this._scrollPos = 0;
				clearTimeout(this._scrollTimeout);
				this._scrollTimeout = null;

				this.update();

				this.$optionsContainer
					.css({
                        position: "absolute",
                        width: this.$el.width() + 'px'
                    })
					.show();

				this._position();

			}
		},


		currentLabel: function () {
            return this._currentSelection ? this._currentSelection.text :  null;
        },


		clickItem: function(e) {
			var $target = $(e.currentTarget),
				newVal = $target.data("val").toString();


			if (this._currentSelection === null ||
				this._currentSelection.val !== newVal) {
				this.val(newVal);
				this.trigger("change", newVal);
			}
		},


		// This is responsible for setting the current selection,
		//  and setting the shown text - it should be called after
		// 	data is updated
		val: function(passedVal) {
			var self = this,
				foundIndex = -1,
				found = false;

			if (passedVal === undefined) {
				if (this._currentSelection !== null) {
					return this._currentSelection.val;
				}
				else {
					return null;
				}
			}
			else {

				passedVal = '' + passedVal;

				foundIndex = _.findIndex(this._processed, function (obj) {
					if (obj.type === "option") {
						return obj.val === passedVal;
					}
					else {
						return false;
					}
				});

				if (foundIndex !== -1) {
					found = this._processed[foundIndex];
					self._currentSelection = found;
					self.$shownText.text(found.text);
					this.$optionsContainer.hide();
				}

				return this;
			}

		},


		// This should be decrecated now in favor of using (this._processed)
		_dataFlattened: function () {
			var flattened = {};

			if (this._data === null) {
				return this._data;
			}

			if (this._isGrouped()) {
				_.each(this._data, function (group) {
					_.extend(flattened, group);
				});
				return flattened;
			}
			else {
				return this._data;
			}
		},



		_getFirstAvailable: function() {
			var firstIndex = null;

			if (this._processed.length < 1) {
				return false;
			} else {

				firstIndex = _.findIndex(this._processed, function (obj) {
					return obj.type === "option";
				});

				if (firstIndex === -1) {
					return false;
				} else {
					return this._processed[firstIndex];
				}
			}
		},


		reset: function () {
			var first = this._getFirstAvailable();
			if (this._getFirstAvailable !== false) {
				this.val(first.val);
			}
		},



		_isGrouped: function () {
			return _.isObject(_.values(this._data)[0]);
		},


		_position: function () {

            /*
			var offsetTop = this.$el.offset().top;
			var topPos = offsetTop - window.scrollY;

			var viewPortMax = window.scrollY + window.innerHeight;

			var optionsHeight = this.$optionsContainer.height();

			var padding = Math.floor(window.innerHeight * 0.05);
			var overhang = (topPos + optionsHeight) - window.innerHeight;


			if (offsetTop + optionsHeight > viewPortMax) {
				this.$optionsContainer.css("top", 0 - (overhang + padding));
			}
            */
		},


		appendTo: function(el) {
			this.$el.appendTo(el);
			return this;
		},



		addClass: function(className) {
			this.$el.addClass(className);
			return this;
		},



		removeClass: function(className) {
			this.$el.removeClass(className);
			return this;
		},



		attr: function(name, value) {
			this.$el.attr(name, value);
			return this;
		},



		placeholder: function (val) {
			this._placeholder = val;
			return this;
		},



		enable: function() {
			this._enabled = true;
			this.$shownText.removeClass("disabled");
		},



		disable: function() {
			this._enabled = false;
			this.$shownText.addClass("disabled");
		},



		showPlaceholder: function() {
			if (this._placeholder === undefined) {
				return;
			}
			else {
				this.$shownText.text(this._placeholder);
			}
		}

	});

	return ViewPureSelect;
});

