define([
    "Backbone",
    "registry",
    "moment-timezone",
    "workers/apirequest",
    "pikaday",
    "templates/fundsTransfer.html"
], function (Backbone, registry, moment, ApiRequest, Pikaday, mainTemplate) {

    return Backbone.View.extend({


        events: {
            'click .transferBtn': '_clickTransferBtn',
            'change #transferToDateInput': '_changeTransferToDate',
            'click .transferCloseBtn': '_closeTransferModal'
        },

        initialize: function () {
            var validBeforeMoment = moment.tz("America/New_York").startOf('day');

            this.UKDay= moment.tz("Europe/London").day();

            this.$el.append(_.template(mainTemplate));
            $('#appContainer').append(this.$el);

            this.$overlay = this.$el.find('#transferModal');
            this.$transferStep1 = this.$el.find('.transferStep1');
            this.$transferStep2 = this.$el.find('.transferStep2');
            this.$transferStep3 = this.$el.find('.transferStep3');
            this.$transferStep4 = this.$el.find('.transferStep4');
            this.$transferUsernameInput = this.$el.find('.transferUsernameInput');
            this.$transferAmountInput = this.$el.find('.transferAmountInput');
            this.$transferCodeInput = this.$el.find('.transferCodeInput');
            this.$transferAmount = this.$el.find('.transferAmount');
            this.$transferRecipient = this.$el.find('.transferRecipient');
            this.$transferFundsBtn = this.$el.find('.transferFundsBtn');
            this.$transfersDisabled = this.$el.find('.transfersDisabled');


            if(this.UKDay === 1
                || this.UKDay === 5
                || (parseInt(registry.user.idVerified) === 0 && parseInt(registry.user.bypassVerification) === 0)
            ) {
                this.$transferStep1.hide();
                this.$transfersDisabled.show();
                this.$el.find('.transferBtn').remove();
            }


            if (parseInt(registry.user.paymentNet) === 30){
                // monthly payment
                validBeforeMoment.subtract(1, 'month').startOf('month');
            }
            else if (registry.user.paymentFrequency === 'monthly') {
                validBeforeMoment.subtract(parseInt(registry.user.paymentNet), 'day').startOf('month');
            }
            else {
                // weekly payment
                validBeforeMoment.subtract(registry.user.paymentNet, 'day');
            }
            validBeforeMoment.subtract(1,'day');

            this.$toDatePicker = new Pikaday({
                field: document.getElementById('transferToDateInput'),
                format: 'D MMM YYYY',
                defaultDate: validBeforeMoment.toDate(),
                //minDate: minDate.toDate(),
                maxDate: validBeforeMoment.toDate(),
                setDefaultDate: true
            });

            this._changeTransferToDate();

        },

        show: function() {
            this.$transferCodeInput.val('');
            this.$transferAmount.text('');
            this.$transferUsernameInput.val('');
            this.$transferRecipient.text('');
            this.$transferAmountInput.text('');
            this.$transferStep1.show();
            this.$transferStep2.hide();
            this.$transferStep3.hide();
            this.$transferStep4.hide();
            this.$el.find('.transferBtn').hide();
            this.$el.find('.transferBtn[data-step="1"]').show();
            this.$el.find('#transferToDateInput').change();

            if(this.UKDay === 1
                || this.UKDay === 5
                || (parseInt(registry.user.idVerified) === 0 && parseInt(registry.user.bypassVerification) === 0)
            ) {
                this.$transferStep1.hide();
                this.$transfersDisabled.show();
                this.$el.find('.transferBtn').remove();

            }
            this.$overlay.fadeIn('fast');
            $('html, body').addClass('overflow-hidden');
        },

        _clickTransferBtn: function(ev) {
            var self = this,
                $req = new ApiRequest(),
                $btn = $(ev.currentTarget),
                step = $btn.data('step');

            switch(step) {
                case 1:
                    this.$transferStep1.hide();
                    this.$transferStep2.show();
                    this.$el.find('.transferBtn').hide();
                    this.$el.find('.transferBtn[data-step="2"]').show();

                    break;

                case 2:

                    if(self.amount < 100) {
                        alert('You must transfer a minimum of $100');
                    } else {

                        $req.request("v3/publisherFundsTransfer.php", {
                            'action': 'create',
                            'username': this.$transferUsernameInput.val(),
                            'toDate': this.transferToDate,
                            'amount': self.amount
                        })
                            .done(function(response) {
                                if(response.status === 'success') {
                                    self.transferId = response.id;
                                    self.$transferStep2.hide();
                                    self.$transferStep3.show();
                                    self.$el.find('.transferBtn').hide();
                                    self.$el.find('.transferBtn[data-step="3"]').show();
                                    self.$transferAmount.text(self.$transferAmountInput.html());
                                    self.$transferRecipient.text(self.$transferUsernameInput.val());
                                } else {
                                    alert(response.error);

                                }
                            });
                    }

                    break;

                case 3:
                    $btn.prop('disabled',true);

                    $req.request("v3/publisherFundsTransfer.php", {
                        'action': 'auth',
                        'id': this.transferId,
                        'secret': this.$transferCodeInput.val()
                    })
                        .done(function(response) {
                            if(response.status === 'success') {
                                self.$transferStep3.hide();
                                self.$transferStep4.show();
                                self.$el.find('.transferBtn').hide();
                            } else {
                                alert(response.error);
                                $btn.prop('disabled',false);

                            }
                        });




                    break;

            }

        },

        _closeTransferModal: function () {


        },

        _changeTransferToDate: function() {
            var self = this,
                $req = new ApiRequest(),
                val = this.$el.find('#transferToDateInput').val(),
                formattedDate = moment(val, 'D MMM YYYY').format('YYYY-MM-DD');


            this.transferToDate = formattedDate;


            $req.request("v3/publisherFundsTransfer.php", {
                'action': 'getAmount',
                'toDate': formattedDate
            })
                .done(function(response) {
                    if(response.status === 'success') {
                        self.$transferAmountInput.text(response.amount);
                        self.amount = parseFloat(response.amount);
                    } else {
                        alert(response.error);

                    }
                });

        },


    });

});
