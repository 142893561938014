/*jslint nomen:true, indent:4*/
/*global define*/

define(
    ["jQuery"],
    function ($) {
        function formatCurrency(num) {
            return '$' + addComma(parseFloat(num).toFixed(2));
        }

        function wait() {
            $('html').addClass('wait');

            return function () {
                $('html').removeClass('wait');
            };
        }

        function addComma(x) {
            return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }

        return {
            formatCurrency: formatCurrency,
            addComma: addComma,
            wait: wait
        };
    }
);
