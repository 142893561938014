/*jslint nomen:true, indent:4*/
/*global define, _, $ */

define([
    "Backbone",
    "util",
    "registry",
    "workers/apirequest",
    "templates/verticalOsCarrier.html",
    "views/bigselect",
    "views/daterange" 
], function (Backbone, util, registry, ApiRequest, template, BigSelectView,
              DateRange) {
        var VerticalOsCarrierView = Backbone.View.extend({
            ROW_LIMIT: 100,

            report: [],
            _subViews: [],
            order: 'clicks',

            events: {
                'click .reportButton': '_clickGenerateReport',
                'click .report th': '_clickTableHeader'
            },

            initialize: function () {
            },

            render: function () {
                if (registry.user.getAccessLevel() === 'basic') {
                    return;
                }

                this.setElement($(document.createElement('div')));
                this.$el
                    .addClass("mainPane")
                    .attr('id', 'verticalOsCarrierContainer');
                this.$el.append(_.template(template)());

                $('#appContainer').append(this.$el);

                this.dateRange = new DateRange();
                this._subViews.push(this.dateRange);

                this.$el
                    .find('.dateRangeContainer')
                    .append(this.dateRange.render());

                this.verticalBigSelect = new BigSelectView({
                    searchLabel: "Filter by Vertical:",
                    allText: "No Verticals"
                });
                this._subViews.push(this.verticalBigSelect);

                this.$el
                    .find('.verticalSelectContainer')
                    .append(this.verticalBigSelect.render());

                this.osBigSelect = new BigSelectView({
                    searchLabel: "Filter by OS:",
                    allText: "No OS"
                });
                this._subViews.push(this.osBigSelect);

                this.$el
                    .find('.osSelectContainer')
                    .append(this.osBigSelect.render());

                this._populateVerticalSelect();
                this._populateOsSelect();

                this.on('update:report', this._updateReport, this);
                return this.$el;
            },

            sleep: function() {},

            wakeUp: function() {},

            _clickGenerateReport: function () {
                var self = this,
                    req = new ApiRequest(),
                    range = this.dateRange.get(),
                    verticals = this.verticalBigSelect.val(),
                    oses = this.osBigSelect.val(),
                    params;

                if (!verticals.length) {
                    return alert('Please select at least one vertical');
                }
                if (!oses.length) {
                    return alert('Please select at least one OS');
                }

                params = {
                    vertical: verticals,
                    os: oses,
                    start: range.start,
                    end: range.end
                };


                req.request('data/partner-vertical-os-carrier.php', params)
                    .then(
                        function (report) {
                            self.report = report;
                            self.trigger('update:report');
                        },
                        this._errorCb('Cannot generate report')
                    );
            },

            _clickTableHeader: function(event) {
                var $th = $(event.target);

                this.$el.find('th').removeClass('selected');
                $th.addClass('selected');

                this.order = $th.data('order');
                this.trigger('update:report');
            },

            _updateReport: function () {
                var $tbody = $('<tbody>');

                function generateRow(row) {
                    function _numCell(val) {
                        return $('<td>', { class: 'num text-right' }).text(val);
                    }

                    function intCell(val) {
                        return _numCell(val.toLocaleString('en-US'));
                    }

                    function moneyCell(val) {
                        return _numCell(val.toLocaleString('en-US',{style:'currency','currency':'USD'}));
                    }

                    function textCell(val) {
                        return $('<td>').text(val);
                    }

                    $('<tr>').append(
                        textCell(row.carrier),
                        intCell(row.clicks),
                        intCell(row.leads),
                        moneyCell(row.revenue),
                        moneyCell(row.ecpm)
                    ).appendTo($tbody);
                }

                _.chain(this.report)
                    .sortBy(this.order)
                    .reverse()
                    .first(this.ROW_LIMIT)
                    .each(generateRow);

                this.$el.find('.report tbody').replaceWith($tbody);
            },

            _populateVerticalSelect: function () {
                var self = this,
                    req = new ApiRequest();

                req.request('data/partner-ls-vertical.php')
                    .then(
                        function (data) {
                            var verticals = [];


                            _.each(data, function(row) {
                                if (registry.user.hasVerticalAccess(row.str.toLowerCase())) {
                                    verticals.push({
                                        text: row.str,
                                        value: row.str
                                    });
                                }
                            });

                            self.verticalBigSelect.updateData(verticals);
                        },
                        this._errorCb('Cannot list verticals')
                    );
            },

            _populateOsSelect: function () {
                var self = this,
                    req = new ApiRequest();

                req.request('data/partner-ls-os.php')
                    .then(
                        function (data) {
                            var oses = _.map(data, function (row) {
                                return {
                                    text: row.os_name,
                                    value: row.os_name
                                };
                            });

                            self.osBigSelect.updateData(oses);
                        },
                        this._errorCb('Cannot list OSes')
                    );
            },

            _errorCb: function (message) {
                return function () {
                    alert(message);
                };
            }
        });

        return new VerticalOsCarrierView();
    }
);

