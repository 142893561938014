define([
    "backbone",
    "workers/apirequest",
    "templates/unsubscribe.html"
], function(
    backbone,
    api,
    mainTemplate
) {
    var academyView = Backbone.View.extend({


        initialize: function() {

        },

        render: function (list, id, hash) {
            var self = this,
                req = new api();

            this.setElement($(document.createElement("div")));
            this.$el.addClass("mainPane").attr("id", "unsubscribeContainer");

            self.$el.append(mainTemplate);
            $("#appContainer").append(self.$el);

            this.$spinner = this.$el.find('.loading-spinner');
            this.$responseText = this.$el.find('.responseText');


            req.request('v3/unsubscribe.php',{
                'user': 'publisher',
                'list': list,
                'id': id,
                'hash': hash

            })
                .then(function(response) {
                    self.$spinner.hide();
                    self.$responseText.text(response.message);

                })
                .fail(function(response) {
                    self.$spinner.hide();
                    self.$responseText.text(response.responseJSON.message);

                });


        },

    });

    return new academyView();
});
