define([
    'registry',
    //"libs/image-tooltip", // already loaded and applied in cpaMarketplace.js
    'views/message',
    'collections/offerMarket/advertisersRequests',
    'views/offerMarket/baseRequests.js',
    'templates/offerMarket/advertisersRequests.html',
    'templates/offerMarket/advertiserRequestRow.html',
], function (
    registry,
    //ImageTooltip,
    messageView,
    AdvertisersRequestsCollection,
    BaseRequests,
    template,
    rowTemplate,
) {
    return BaseRequests.extend({
        template: template,
        rowTemplate: _.template(rowTemplate),
        Collection: AdvertisersRequestsCollection,
        routeFragment: 'advertiserRequest',

        events: function() {
            return _.extend({}, BaseRequests.prototype.events, {
                'click .messageBtn': 'sendMessage'
            });
        },

        onShow: function() {
            $('.offerImgTooltip').remove();
            this.$el.find('.hoverImage').imageTooltip();
            registry.user.updateUnreadOfferMarketTs();
        },

        sendMessage: function(ev) {
            if (this.type !== 'public' && registry.user && registry.user.id) {
                var id = $(ev.currentTarget).data('id');
                var model = this.data.get(id);
                var advertiserId = model.get('network_id');
                var message = '(Regarding your traffic request "' + model.get('name') + '" [#' + model.get('id') + ']) - ';

                if (advertiserId) {
                    new messageView({
                        network_id: advertiserId,
                        message: message,
                        action: 'new'
                    });
                }
            }
        }
    });
});
