define([
    "Backbone",
    "moment",
    "dict/isocountries.json",
    "registry",
    "workers/apirequest",
    "templates/subsourceAnalysis.html",
    "templates/subsourceAnalysisRow.html"
], function (
    Backbone,
    moment,
    countryData,
    registry,
    ApiWorker,
    template,
    rowTemplate
) {

    var View = Backbone.View.extend({

        events:  {
            'click #sa_filterBtn': 'filterChanged',
            'click #sa_resetFiltersBtn': 'resetFilters',
            'click .pageBtn': 'changePage',
            'click .perPageDropdown .dropdown-item': 'changePerPage',
        },

        initialize: function () {},

        render: function () {
            this.setElement($(document.createElement("div")));
            this.$el.addClass("mainPage").attr("id", "subsourceAnalysisContainer");
            this.$el.append(template);
            $("#appContainer").append(this.$el);

            this.$prevBtns = this.$el.find('.pageBtn[data-id="prev"]');
            this.$nextBtns = this.$el.find('.pageBtn[data-id="next"]');
            this.$pageNumbers = this.$el.find('.paginationPageNumber');
            this.$dataTableBody = this.$el.find('.dataTable tbody');
            this.$noDataMessage = this.$el.find('.noData');
            this.$loadingSpinner = this.$el.find('.loading');
            this.rowTemplate = _.template(rowTemplate);

            this.initFilters();
            this.refreshData();
        },

        initFilters: function () {
            _.each (['pid', 'networkId', 'geo'], function (key) {
                this.filterInputs[key] = this.$el.find('#sa_' + key + 'FilterInput');
            }, this);

            _.each(countryData, function (country) {
                this.filterInputs.geo.append(
                    $("<option>", {
                        value: country.alpha_2_code,
                        text: country.alpha_2_code + " - " + country.country
                    })
                );
            }, this);

            this.setFilterInputValues();
        },

        setFilterInputValues: function () {
            _.each(this.filterInputs, function($input, key) {
                if (this.filters[key] !== undefined) {
                    $input.val(this.filters[key]);
                } else {
                    $input.val('');
                }
            }, this);
        },

        filterChanged: function(ev) {
            this.page = 1;
            this.setFilters();
            this.refreshData();
        },

        setFilters: function() {
            this.filters =  _.reduce(this.filterInputs, function (result, $input, key) {
                if ($input.val() !== '') {
                    result[key] = $input.val().trim();
                }
                return result;
            }, {}, this);
        },

        resetFilters: function(ev) {
            this.filters = {};
            this.page = 1;
            this.setFilterInputValues();
            this.refreshData();
        },

        refreshData: function () {
            if (this.isDataExpired()) {
                this.getPidBlacklist();
            } else {
                this.displayData();
            }
        },

        isDataExpired: function () {
            var currentTimestamp = Math.round((new Date()).getTime() / 1000);
            return currentTimestamp > this.expiredTs;
        },

        getPidBlacklist: function () {
            var self = this,
                apiRequest = new ApiWorker();

            this.$dataTableBody.empty();
            this.$noDataMessage.hide();
            this.$loadingSpinner.show();
            apiRequest.request("v3/publisherPidBlacklist.php")
                .then(function (response) {
                    self.setData(response);
                    if (response.length > 0) {
                        // data on server updated once an hour
                        self.expiredTs = moment.tz(response[0].ts, 'America/New_York').unix() + 3600;
                    } else {
                        self.expiredTs = Math.round((new Date()).getTime() / 1000) + 900;
                    }
                    self.displayData();
                    self.displayStatsData();
                })
                .fail(function (error) {
                    if (error && error.responseJSON && error.responseJSON.message) {
                        alert(error.responseJSON.message);
                    } else {
                        alert('Server error occured.');
                    }
                })
                .always(function () {
                    self.$loadingSpinner.hide();
                });
        },

        castDataRecord: function (record) {
            if (!record) {
                return record;
            }

            record.networkId = record.network_id;

            return record;
        },

        setData: function (data) {
            var self = this;
            var field;

            this.resetStats();

            if (!_.isEmpty(data)) {
                _.each(data, function (record) {
                    record = self.castDataRecord(record);

                    _.each(['geo', 'network_id'], function (key) {
                        if (!self.stats[key][record[key]]) {
                            self.stats[key][record[key]] = +record.count;
                        } else {
                            self.stats[key][record[key]] += record.count
                        }
                    });

                    if (record.pid && record.pid.includes('-')) {
                        // includes only subsources not publisher id.
                        if (!self.stats['pid'][record['pid']]) {
                            self.stats['pid'][record['pid']] = +record.count;
                        } else {
                            self.stats['pid'][record['pid']] += record.count
                        }

                        field = record.pid + ' + ' + record.geo;
                        if (!self.stats.pidGeo[field]) {
                            self.stats.pidGeo[field] = +record.count;
                        } else {
                            self.stats.pidGeo[field] += record.count
                        }
                    }

                });
            }

            this.data = data;
        },

        displayData: function () {
            var filtered = this.getFilteredData(this.data, this.filters);
            this.updatePagination(filtered);
            var paginated = this.getPageData(filtered);
            this.renderData(paginated);
        },

        renderData: function (data) {
            var self = this;
            this.$dataTableBody.empty();
            this.$noDataMessage.hide();
            if (_.isEmpty(data)) {
                this.$noDataMessage.show();
            } else {
                _.each(data, function (record) {
                    self.$dataTableBody.append(self.rowTemplate(record));
                });
            }
        },

        getFilteredData: function (data, filters) {
            return _.filter(data, function (record) {
               return _.reduce(filters, function (includeInResult, value, key) {
                   return includeInResult && record[key] == value;
               }, true);
            }, this);
        },

        getPageData: function (data) {
            if (_.isEmpty(data)) {
                return data;
            }

            var start = (this.page - 1) * this.perPage;
            var end = start + this.perPage;

            return data.slice(start, end);
        },

        displayStatsData: function () {
            var self = this;
            var $tableBody, statsArray;
            var template = _.template("<tr><td><%- key %></td><td><%- count %></td></tr>");
            var noDataTemplate = _.template('<tr><td colspan="2" class="text-center">No data</td></tr>');
            _.each(self.stats, function (data, key) {
                $tableBody = self.$el.find('.' + key + 'StatsTable tbody');
                $tableBody.empty();
                if (!_.isEmpty(data)) {
                    statsArray = self.convertStatsMapToArray(data);
                    statsArray = _.sortBy(statsArray, 'count').reverse();
                    statsArray = statsArray.slice(0, 10);
                    _.each(statsArray, function (record) {
                        $tableBody.append(template({key: record.key, count: record.count}));
                    });
                } else {
                    $tableBody.append(noDataTemplate());
                }
            });
        },

        convertStatsMapToArray: function (statsObject) {
            var result = [];
            _.each(statsObject, function (count, key) {
                result.push({key: key, count: count});
            });
            return result;
        },

        changePage: function(ev) {
            var $btn = $(ev.currentTarget);
            var id = $btn.data('id');
            if (id === 'next') {
                this.page += 1;
            } else if (id === 'prev' && this.page > 1) {
                this.page -= 1;
            }
            this.scrollToTop();
            this.displayData();
        },

        scrollToTop: function() {
            $(window).scrollTop(0);
        },

        updatePagination: function(data) {
            var pages = Math.max(1, Math.ceil(data.length/this.perPage));
            if (this.page <= 1) {
                this.$prevBtns.prop('disabled', true);
            } else {
                this.$prevBtns.prop('disabled', false);
            }
            if (this.page >= pages) {
                this.$nextBtns.prop('disabled', true);
            } else {
                this.$nextBtns.prop('disabled', false);
            }
            this.$pageNumbers.text('Page ' + this.page);
            this.$el.find('.perPageDropdown .dropdown-value').text(this.perPage);
        },

        changePerPage: function(ev) {
            var $target = $(ev.currentTarget);
            $target.parents('.dropdown').removeClass('over');
            var value = +$target.data('value');
            if (value === this.perPage) {
                return;
            }
            this.page = 1;
            this.perPage = value;
            this.scrollToTop();
            this.displayData();
        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.resetState();
            this.render();
            this.isAsleep = false;
        },

        resetState: function () {
            this.filters = {};
            this.filterInputs = {};
            this.data = [];
            this.resetStats();
            // scores of how many times subsource in specific dimension was blacklisted.
            this.expiredTs = 0;
            this.page = 1;
            this.perPage = 10;
        },

        resetStats: function () {
            this.stats = {
                pid: {},
                geo: {},
                network_id: {},
                pidGeo: {}
            };
        },
    });

    return new View();
});
