define([
    "Backbone",
    "workers/apirequest",
    "registry",
    "templates/academy/sidebarTemplate.html",
    "templates/academy/mediaItem.html",
    "templates/academy/sidebarTag.html",
], function(
    backbone,
    api,
    app,
    sidebarTemplate,
    mediaItemTemplate,
    tagTemplate
) {
    return Backbone.View.extend({

        // Events
        events: {
            'click .academySearchBtn': '_triggerSearch',
            'keyup .academySearchInput': '_triggerSearch'
        },

        initialize: function(target) {



            this.setElement(target);
            this.$el.html(_.template(sidebarTemplate));

            this.searchTerm = '';

            this.$topTagsContainer = this.$el.find('.topTagsContainer');
            this.$latestFaqsContainer = this.$el.find('.latestFaqsContainer');
            this.$latestGuidesContainer = this.$el.find('.latestGuidesContainer');
            this.$latestNewsContainer = this.$el.find('.latestNewsContainer');
            this.$searchInput = this.$el.find('.academySearchInput');

            this._displayLatestFAQs();
            this._displayLatestGuides();
            this._displayLatestNews();
            this._displayTopTags();

        },


        _getResults: function(apiData) {
            var req = new api(),
                params = '';

            _.each(apiData, function(val, key) {
                params += '&'+key+'='+encodeURIComponent(val);
            });

            return req.request('v3/posts.php?action=results&website=monetizer'+params);
        },

        _getTags: function() {
            var req = new api();

            return req.request('v3/posts.php?action=tags&website=monetizer');
        },

        _displayLatestFAQs: function() {
            var self = this,
                rowTemplate = _.template(mediaItemTemplate);

            $.when(this._getResults({
                'page': 0,
                'limit': 5,
                'type': 28,
            }))
                .then(function(response) {
                    self.$latestFaqsContainer.empty();
                    _.each(response, function(row) {
                        row.small = true;
                        if(row.meta_title == null) {
                            row.meta_title = row.title;
                        }
                        self.$latestFaqsContainer.append(rowTemplate(row));

                    })


                });

        },
        _displayLatestGuides: function() {
            var self = this,
                rowTemplate = _.template(mediaItemTemplate);

            $.when(this._getResults({
                'page': 0,
                'limit': 5,
                'type': 30,
            }))
                .then(function(response) {
                    self.$latestGuidesContainer.empty();
                    _.each(response, function(row) {
                        row.small = true;
                        if(row.meta_title == null) {
                            row.meta_title = row.title;
                        }
                        self.$latestGuidesContainer.append(rowTemplate(row));

                    })


                });

        },
        _displayLatestNews: function() {
            var self = this,
                rowTemplate = _.template(mediaItemTemplate);

            $.when(this._getResults({
                'page': 0,
                'limit': 5,
                'type': 29,
            }))
                .then(function(response) {
                    self.$latestNewsContainer.empty();
                    _.each(response, function(row) {
                        row.small = true;
                        if(row.meta_title == null) {
                            row.meta_title = row.title;
                        }
                        self.$latestNewsContainer.append(rowTemplate(row));

                    })


                });

        },
        _displayTopTags: function () {
            var self = this,
                template = _.template(tagTemplate);

            $.when(this._getTags())
                .then(function(response) {
                    self.$topTagsContainer.empty();

                    _.each(response, function(tag) {
                        self.$topTagsContainer.append(template(tag));

                    });

                });

        },
        _triggerSearch: function(ev) {
            var $el = $(ev.currentTarget);

            if(($el.hasClass('academySearchInput') && ev.keyCode === 13) || $el.hasClass('academySearchBtn')) {
                this.searchTerm = this.$searchInput.val();
                Backbone.history.navigate('/academy/search/'+this.searchTerm,true);
            }
        }



    });

});
