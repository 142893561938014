define([
    "Backbone",
    "registry",
    "views/pureSelect",
    "dict/verticals.json",
    "workers/apirequest",
    "moment-timezone",
    "templates/pushLinkDetailsModal.html"
], function(
    Backbone,
    registry,
    PureSelect,
    verticalDataSrc,
    ApiRequest,
    moment,
    mainTemplate
) {
    return Backbone.View.extend({
        events: {
            'click .createBtn': '_createLink'
        },

        initialize: function() {
            var self = this;

            this.$el.html(_.template(mainTemplate));
            $("#appContainer").append(this.$el);

            verticalData = verticalDataSrc;

            this.$overlay = this.$el.find('.mainModal');
            this.$createBtn = this.$el.find('.createBtn');
            this.$linkNameInput = this.$el.find('.linkNameInput');
            this.$campaignNameInput = this.$el.find('.campaignNameInput');
            this.$keywordInput = this.$el.find('.keywordInput');
            this.$leagueInput = this.$el.find('.leagueInput');
            this.$verticalInput = this.$el.find('.verticalInput');

            _.forEach(registry.user.nidAssignmentArr, function(leagueId) {
                var league = registry.user.getInfoForNid(leagueId);
                self.$leagueInput.append($('<option>', {
                    value: league.nid,
                    text: league.name
                }));
            });

            this.$verticalInput.append($('<option>',{
                text:'vAuto (all)',
                value:'vauto'
            }));

            _.each(verticalData, function(category) {
                _.each(category, function(vertical, key) {
                    if (registry.user.hasVerticalAccess(key) && key !== 'monetizer') {
                        self.$verticalInput.append($('<option>',{
                            text:vertical,
                            value:key
                        }));
                    }
                });
            });

        },

        show: function() {
            this.$overlay.fadeIn('fast');
            $('html, body').addClass('overflow-hidden');

            this.$keywordInput.val('');
            this.$leagueInput.prop('selectedIndex',0);
            this.$verticalInput.prop('selectedIndex',0);
            this.$createBtn.text('Create Link').removeAttr('disabled');
            this.$linkNameInput.val('push_subs_'+moment().format('DD_MM_YYYY_HHmm'));
            this.$campaignNameInput.val('');
        },

        _createLink: function() {
            var self = this,
                linkNameRegex = /^[a-zA-Z0-9_]+$/,
                campaignNameRegex = /^[a-zA-Z0-9_-]+$/,
                req = new ApiRequest(),
                keyword = this.$keywordInput.val().replace(/ /g, "%20"),
                linkName = this.$linkNameInput.val(),
                campaignName = this.$campaignNameInput.val();

            if (linkName.search(linkNameRegex) === -1) {
                alert('Link name can only contain letters, numbers and underscores.');
                return;
            }

            if(campaignName === '') {
                campaignName = linkName;
            } else {
                if (campaignName.search(campaignNameRegex) === -1) {
                    alert('Campaign name can only contain letters, numbers, underscores and hyphens.');
                    return;
                }
            }

            var optionsData = JSON.stringify({
                entry_popup: 1,
                exit_popup: 0,
                vibrate: 1,
                sound: 1,
                redirect_time: 10,
                rcid: campaignName,
                kw1: keyword,
                kw2: "",
                kw3: "",
                kw4: "",
                kw5: "",
                s4: this.$el.find('.clickIdInput').val()
            });

            var data = {
                disable_bbr: 0,
                disable_popunder: 0,
                options_data: optionsData,
                offer_whitelist_data: JSON.stringify([]),
                hard_offer_whitelist: 0,
                vertical: this.$verticalInput.val(),
                adult: 0,
                allow_aggressive: 1,
                name: linkName,
                nid: this.$leagueInput.val(),
                desktop_redirect: 1,
                tag: 'push',

            };

            this.$createBtn.text('Creating...').prop('disabled', true);

            req.request("data/partner-link.php", data)
                .done(function(res) {
                    var linkData = _.extend(data, res);
                    self.trigger('created', linkData);
                    self.$overlay.hide()
                })
                .fail(function(xhr) {
                    if (xhr.responseJSON && xhr.responseJSON.message) {
                        alert('ERROR: ' + xhr.responseJSON.message);
                    }
                    console.error(xhr.responseJSON);
                })
                .always(function () {
                    self.$createBtn.text('Create Link').prop('disabled', false);
                });

        },
    });

});
