/*jslint nomen:true, indent:4*/
/*jshint -W079 */
/*global define, _, console, $ */

define([
	'Backbone',
	'templates/bigSelectTemplate.html',
	'templates/bigSelectOptionTemplate.html',
	'templates/bigSelectSelectedBox.html'
],
function (Backbone, bigSelectTemplate, optionTemplate, selectedBoxTemplate) {

	var ViewBigSelect = Backbone.View.extend({

		// Constants
		SCROLLER_MIN_HEIGHT: 10,
		ROW_HEIGHT: 33,
		OVERHANG: 1.5,

		// Events
		events: {
			"mousedown .bigSelectOption": "clickItem",
			"keyup .big-select-input": "searchEntry",
			"click .big-select-item": "clickRemoveSelection",
            'focusin .big-select-input': 'focusIn',
            'blur .big-select-input': 'focusOut'
		},


		// Methods
		initialize: function (options) {

			this._data = null;
			this._lastSearch = null;
			this._searchTerm = null;
			this._filteredData = null;
			this._dragStartY = 0;
			this._dragEndY = 0;
			this._currentSelection = [];
			this._allText = null;
			this._placeholder = options.placeholder;

			this._bigSelectTemplate = _.template(bigSelectTemplate);
			this._optionTemplate = _.template(optionTemplate);
			this._selectedBoxTemplate = _.template(selectedBoxTemplate);

			// Template options
			if (options.searchLabel === undefined) {
				options.searchLabel = "Search";
			}

			this._allText = (options.allText === undefined ? "All" : options.allText);

			this.setElement(this._bigSelectTemplate(options));

			this.$searchInput = this.$el.find(".big-select-wrap input");
			this.$selectedBar = this.$el.find(".big-select-wrap ul");
			this.$optionsContainer = this.$el.find(".big-select-options");

			this.showPlaceholder();

			this.on("updated:data", this.update, this);
		},

		render: function () {
			return this.$el;
		},

        focusIn: function() {
            this.$optionsContainer.show();

        },

        focusOut: function(e) {
            var self = this,
                target = $(e.currentTarget);


            setTimeout(function(){
                self.$optionsContainer.hide();
                $(e.currentTarget).val('').attr('size', '15');
                self._searchTerm = null;
                self.update();
            }, 100);






        },

		updateData: function(data) {
            var self = this;

			if (!_.isArray(data)) {
				console.error("Cannot add data passed to the bigSelect");
				return false;
			}


			this._data = data;

            /*
			_.each(this._data, function (row, index, list) {
                self.$optionsContainer.find('ul').append($(self._optionTemplate({
					text: row.text,
					value: row.value
				})));
			});
            */

			this.updateInfo();
			this.trigger("updated:data");
		},

		update: function () {
			var self = this,
				visibleArea = 0,
				startAt = 0,
				topHang = 0,
				scrollPos = 0,
				bottomHang = 0,
				dataLength = null,
				dataFiltered = null,
				docFragment = $(document.createDocumentFragment()),
				optionsOnScreen = null,
				searchEx = new RegExp(this._searchTerm, "i");

			if (this._data === null) {
				// console.error("TRYING TO UPDATE BIG SELECT WITH NO DATA");
				return false;
			}


			// Apply Search
			if (this._searchTerm === null) {
                this._filteredData = _.filter(this._data, function (row) {
                    if (self.alreadySelected(row.value)) {
                        return false;
                    }
                    return true;


                });
				dataFiltered = this._filteredData;
			}
			else {

				if (this._searchTerm !== this._lastSearch) {
					this._filteredData = _.filter(this._data, function (row) {
                        if (self.alreadySelected(row.value)) {
                            return false;
                        }
						return searchEx.test(row.text);
					});

					this._lastSearch = this._searchTerm;
				}

				dataFiltered = this._filteredData;
			}

            // remove the placeholder, if it's there.
            this.$optionsContainer.find('.bigSelectPlaceholder').remove();

            visibleArea = parseInt(this.$optionsContainer.find('ul').height());



			// Variable Init
			dataLength = _.size(dataFiltered);
			scrollPos = this.$optionsContainer.scrollTop();
			startAt = Math.floor(scrollPos / this.ROW_HEIGHT);
			optionsOnScreen = Math.floor((visibleArea / this.ROW_HEIGHT) * this.OVERHANG);
            if (optionsOnScreen <= 1) {
                optionsOnScreen = dataLength;
            }

			topHang = startAt * this.ROW_HEIGHT;
			bottomHang = (dataLength - (startAt + optionsOnScreen)) * this.ROW_HEIGHT;
            this.$optionsContainer.find('ul').empty();


			// Add Options

            /*
            _.each(self.$optionsContainer.find('ul li'), function(row, index, list) {
                var cid = $(row).data('cid');
                if(typeof _.findWhere(dataFiltered, {value: cid}) === 'undefined' || self.alreadySelected(row.value)) {
                    $(row).hide();
                } else {
                    $(row).show();
                }

            });
            */



			_.each(dataFiltered.slice(startAt, startAt + optionsOnScreen), function (row, index, list) {
				var $rAppendee = $(self._optionTemplate({
					height: self.ROW_HEIGHT + "px",
					text: row.text,
					value: row.value
				}));



				if (index === 0) {
					// Set the top margin here
					$rAppendee
						.addClass("top");
				}
				else if (index + 1 === _.size(list)) {
					// Set the bottom margin
					$rAppendee
						.addClass("bottom");
				}

				docFragment.append($rAppendee);

			});


			this.$optionsContainer.find('ul').html(docFragment);

			if (dataLength === 0) {
				this.showPlaceholder();
			}

			var scrollCallback = _.throttle(self.scrollingOptions, 33);
			self.$optionsContainer
				.unbind("scroll")
				.scrollTop(scrollPos)
				.scroll(function () {
					scrollCallback.call(self);
				});

		},

		scrollingOptions: function () {
			this.update();
		},

		alreadySelected: function (value) {
			var result = null;
			result = _.find(this._currentSelection, function (row) {
				return row.value === value;
			});

			return result !== undefined;
		},

		clickItem: function(e) {
			var $target = $(e.currentTarget);

            this.$optionsContainer.hide();

			if (!this.alreadySelected($target.attr("data-cid"))) {
                $target.hide();

				this._currentSelection.push({
					text: $target.text().trim(),
					value: $target.attr("data-cid")
				});

				this.updateInfo();
				this.trigger("change");
			}
		},


		updateInfo: function () {
			var self = this;

			this.$selectedBar.find('.big-select-item').remove();
			if (_.size(this._currentSelection) > 0) {
				_.each(this._currentSelection, function (row) {
                    self.$el.find('.big-select-wrap ul li:last').before(self._selectedBoxTemplate({
						value: row.value,
						text: row.text
					}));
				});
			}
			else {
                self.$el.find('.big-select-wrap ul li:last').before(self._selectedBoxTemplate({
                    value: 'ALL',
                    text: this._allText
                }));
			}
		},


		searchEntry: function(e) {
            $(e.currentTarget).attr('size', $(e.currentTarget).val().length + 1);
            if ($(e.currentTarget).val() !== "") {
                this._searchTerm = $(e.currentTarget).val();
            }
            else {
                $(e.currentTarget).attr('size', 15);
                this._searchTerm = null;
            }

            this.update();
		},

		clickRemoveSelection: function(e) {
			var $target = $(e.currentTarget);

			if ($target.attr("data-cid") !== "ALL") {
				this._currentSelection = _.reject(this._currentSelection, function (row) {
					return row.value === $target.attr("data-cid");
				});

				this.$optionsContainer.find("[data-cid='" + $target.attr("data-cid") + "']").show();
				$target.remove();
				this.updateInfo();
                this.update();
				this.trigger("change");
			}
		},

		val: function(options) {
			var self = this,
				translated = [];

			if (options === undefined) {
				return _.pluck(this._currentSelection, "value");
			}
			else {

				_.each(options, function (option) {
					var matchingDataRow = _.find(self._data, function (chunk) {
						return chunk.value === option;
					});

					if (matchingDataRow !== undefined) {
						translated.push({
							value: option,
							text: matchingDataRow.text
						});
					}
				});

				this._currentSelection = translated;

				this.update();
				this.updateInfo();
			}
		},

		showPlaceholder: function() {
			if (this._placeholder === undefined) {
				return;
			}

			$('<li>', { class: 'bigSelectPlaceholder'})
				.text(this._placeholder)
				.appendTo(this.$optionsContainer.find('ul'));
		}

	});

	return ViewBigSelect;
});

