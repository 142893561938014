define([
    'dict/isocountries.json',
    'Backbone',
    'registry',
    'libs/offerMarketUtils',
    'templates/offerMarket/offerMarket.html',
    'views/offerMarket/advertisersRequests',
    'views/offerMarket/publishersRequests',
    'views/register/monetizer',
    'views/register/advertizer',
    "views/requests/requestForm",
], function (
    countryData,
    Backbone,
    registry,
    Utils,
    template,
    AdvertisersRequestsView,
    PublishersRequestsView,
    MonetizerRegisterView,
    AdvertizerRegisterView,
    RequestFormView,
) {
    var offerMarketView = Backbone.View.extend({

        events: {
            'click .tab': 'switchTab',
            'click #om_filtersSwitch': 'switchFilters',
            'click #om_filterBtn': 'filterData',
            'click #om_searchBtn': 'filterData',
            'keyup #om_searchInput': 'searchKeyUpHandler',
            'click #om_resetFiltersBtn': 'resetFilters',
            'click .registerBtn': 'register',
            'click .addOfferRequestButton': 'createRequest',
        },

        initialize: function(type) {
            this.type = type || 'internal';
            this.template = _.template(template);
            this.advertisersRequestsView = new AdvertisersRequestsView({parent: this});
            this.publishersRequestsView = new PublishersRequestsView({parent: this});
            this.reset(false);
        },

        reset: function(resetSubviews = true) {
            this.filters = {};
            this.filterInputs = {};
            this.showFilters = false;
            this.activeTab = 'advertiser';
            if (resetSubviews) {
                this.advertisersRequestsView.reset();
                this.publishersRequestsView.reset();
            }
        },

        setupSubviews: function() {
            this._subViews = [];
            this._subViews.push(this.advertisersRequestsView);
            this._subViews.push(this.publishersRequestsView);
        },

        wakeUp: function() {
            if (this.type === 'internal') {
                this.stopListening(this, 'myRequests:new');
                this.listenTo(this, 'myRequests:new', this.onRequestCreated.bind(this));
            }
            this.advertisersRequestsView.wakeUp();
            this.publishersRequestsView.wakeUp();
            this.setupSubviews();
            this.render();
        },

        sleep: function() {
            this.trigger('sleep');
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "offerMarketContainer");
            this.$el.append(this.template({type: this.type}));

            this.initFilters();

            this.$el.find('#om_AdvertisersRequests').append(this.advertisersRequestsView.el);
            this.$el.find('#om_PublishersRequests').append(this.publishersRequestsView.el);

            this.trigger('render');

            this.showActiveTab();

            $("#appContainer").append(this.$el);
        },

        getState: function() {
            return {filters: this.getFilters()};
        },

        stateChanged: function() {
            this.trigger('state:changed', this.getState());
        },

        initFilters: function() {
            this.$filtersSwitch = this.$el.find('#om_filtersSwitch');
            this.$filtersContainer = this.$el.find('#om_filtersContainer');
            this.filterInputs.search = this.$el.find('#om_searchInput');
            this.filterInputs.conversion_flow = this.$el.find('#om_conversionFlowFilterInput');
            _.each(Utils.flowTitlesMap, function(title, value) {
                this.filterInputs.conversion_flow.append(
                    $("<option>", {value: value, text: title})
                );
            }, this);
            this.filterInputs.traffic_type = this.$el.find('#om_trafficTypeFilterInput');
            _.each(Utils.trafficTypeTitlesMap, function(title, value) {
                this.filterInputs.traffic_type.append(
                    $("<option>", {value: value, text: title})
                );
            }, this);
            this.filterInputs.payout = this.$el.find('#om_payoutFilterInput');
            this.filterInputs.cap = this.$el.find('#om_capFilterInput');
            this.filterInputs.geo = this.$el.find('#om_geoFilterInput');
            _.each(countryData, function (country) {
                this.filterInputs.geo.append(
                    $("<option>", {
                        value: country.alpha_2_code,
                        text: country.alpha_2_code + " - " + country.country
                    })
                );
            }, this);
            this.filterInputs.device = this.$el.find('#om_deviceFilterInput');
            this.filterInputs.connection_type = this.$el.find('#om_connectionTypeFilterInput');

            this.setFilterInputValues();
            this.applyShowFiltersState();
        },

        getFilters: function() {
            return this.filters;
        },

        setFilters: function() {
            this.filters =  _.reduce(this.filterInputs, function (result, $input, key) {
                if ($input.val() !== '') {
                    result[key] = $input.val();
                }
                return result;
            }, {}, this);
        },

        filterData: function(ev) {
            this.setFilters();
            this.stateChanged();
        },

        resetFilters: function(ev) {
            this.filters = {};
            this.setFilterInputValues();

            this.stateChanged();
        },

        setFilterInputValues: function() {
            _.each(this.filterInputs, function($input, key) {
                if (this.filters[key] !== undefined) {
                    $input.val(this.filters[key]);
                } else {
                    $input.val('');
                }
            }, this);
        },

        switchFilters: function(ev) {
            this.showFilters = !this.showFilters;
            this.applyShowFiltersState();
        },

        applyShowFiltersState: function() {
            if (this.showFilters) {
                this.$filtersSwitch.find('i')
                    .removeClass('fa-chevron-right')
                    .addClass('fa-chevron-down');
                this.$filtersContainer.show();
            } else {
                this.$filtersSwitch.find('i')
                    .removeClass('fa-chevron-down')
                    .addClass('fa-chevron-right');
                this.$filtersContainer.hide();
            }
        },

        switchTab: function(ev) {
            this.activeTab = $(ev.currentTarget).data('id');
            this.showActiveTab();
        },

        showActiveTab: function() {
            var title = this.activeTab === 'publisher' ? 'Offer Requests' : 'Traffic Requests';
            $(".page-title").html("Offer Market - " + title);
            document.title = "Monetizer - " + title;
            this.$el.find('.tab').removeClass('active');
            this.$el.find('.tab[data-id="' + this.activeTab + '"]').addClass('active');
            this.$el.find('.tab-content').hide();
            this.$el.find('.tab-content[data-target="' + this.activeTab + '"]').show();
        },

        searchKeyUpHandler: function(ev) {
            if (ev.keyCode === 13) {
                this.filterData();
            }
        },

        register: function(ev) {
            var site = $(ev.currentTarget).data('site');
            if (site === 'monetizer') {
                new MonetizerRegisterView({
                    helpText: 'In order to post an offer request you must have an account on <a href="https://www.monetizer.com/">monetizer.com</a>. Complete the form below to sign up.',
                    successHelpContent: '<p>After your application has been approved, you will be able to post offer requests on <a href="https://app.monetizer.com/offerRequests/myRequests">this</a> page.</p>',
                });
            } else {
                new AdvertizerRegisterView({
                    helpText: 'In order to post a traffic request you must have an account on <a href="https://www.advertizer.com">advertizer.com</a>. Complete the form below to sign up.',
                    successHelpContent: '<p>After your application has been approved, you will be able to post traffic requests on <a href="https://app.advertizer.com/offerRequests/myRequests">this</a> page.</p>',
                });
            }
        },

        createRequest: function() {
            new RequestFormView({parent: this});
        },

        onRequestCreated: function(request) {
            if (!request) {
                return;
            }
            Backbone.history.navigate('offerRequests/myRequests', {trigger: true});
        },
    });

    return {
        internal: new offerMarketView('internal'),
        public: new offerMarketView('public'),
    };
});
