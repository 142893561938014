define([
    "libs/uiAlert",
], function (
    uiAlert
) {
    return function(content) {
        const el = document.createElement('textarea');
        el.value = content;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
        uiAlert.showSuccess('Copied to clipboard');
    };
});

