define([
    'backbone',
    "workers/apirequest",
    'views/register/monetizer',
    'views/register/advertizer',
], function (
    Backbone,
    ApiRequest,
    MonetizerRegisterView,
    AdvertizerRegisterView,
) {
    return Backbone.View.extend({
        msgTemplate: _.template('<h4 class="text-center"><%= message %></h4>'),

        events: {
            'click .messageRegisterBtn': 'register',
            'click .page-close': 'closePage',
        },

        initialize: function() {
            this.type = 'internal';
            this.model = null;
        },

        sleep: function() {
            // do nothing
        },

        wakeUp: function() {
            this.initialize();
        },

        showRequest: function(id, type) {
            this.type = type || 'internal';
            id = +id;
            if (!id) {
                this.closePage();
            }
            var self = this;
            var model = this.collection.get(id);
            if (model) {
                this.model = model;
                this.render();
            } else {
                var api = new ApiRequest();
                api.request('v3/offerMarket.php?' + $.param({action: this.requestType, id: id}))
                    .then(function (response) {
                        if (response && response.length > 0) {
                            self.model = new Backbone.Model(self.collection.castDataRecord(response[0]))
                        } else {
                            self.uiMessage = 'Not Found';
                        }
                        self.render();
                    })
                    .fail(function () {
                        self.uiMessage = 'Failed to load data.';
                        self.render();
                    });
            }
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "om_" + this.requestType + "Details");
            if (this.model && this.model.id) {
                this.$el.append(this.template(_.extend({type: this.type} ,this.model.toJSON())));
            } else {
                this.$el.append(this.msgTemplate({message: this.uiMessage}));
            }

            $("#appContainer").append(this.$el);
        },

        register: function(ev) {
            var $currentTarget = $(ev.currentTarget);
            var site = $currentTarget.data('site');
            var id = $currentTarget.data('id');
            if (site === 'monetizer') {
                new MonetizerRegisterView({
                    helpText: 'In order to message an advertizer you must have an account on <a href="https://www.monetizer.com/">monetizer.com</a>. Complete the form below to sign up.',
                    successHelpContent: '<p>After your application has been approved, you will be able to message selected advertiser on <a href="https://app.monetizer.com/offerRequests/advertiserRequest/'+ id +'">this</a> page.</p>',
                });
            } else {
                new AdvertizerRegisterView({
                    helpText: 'In order to message a publisher you must have an account on the <a href="https://www.advertizer.com">advertizer.com</a>. Complete the form below to sign up.',
                    successHelpContent: '<p>After your application has been approved, you will be able to message selected publisher on <a href="https://app.advertizer.com/offerRequests/publisherRequest/'+ id +'">this</a> page.</p>',
                });
            }
        },

        closePage: function() {
            var route = this.type === 'public' ? 'offerRequests/main' : 'offerRequests';
            Backbone.history.navigate(route, {trigger: true});
        },
    });
});
