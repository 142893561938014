/*jslint nomen:true, indent:4*/
/*jshint -W079 */
/*global define, _, sprintf */

define(["Backbone",
    "registry",
    "templates/trends.html",
    "templates/trendsMoverRow.html",
    "templates/trendsCarrierRow.html",
    "templates/trendsOsRow.html",
    "templates/trendsOfferRow.html",
    "workers/apirequest",
    "views/pureSelect",
    "views/platformreports",
    "moment-timezone",
    "sprintf"],
    function (Backbone, registry, template, moverTemplate, carrierTemplate,
        osTemplate, offerTemplate, ApiRequest, PureSelect, platformReports, moment, sprintf) {

        var RangeSelect = Backbone.View.extend({
            initialize: function () {
                var timespans = {
                    today: "Today",
                    yesterday: "Yesterday",
                    three: "3 Day Average",
                    seven: "7 Day Average",
                    twentyone: "21 Day Average"
                };

                this.$el.addClass("rangeSelect");

                this.$aSelect = new PureSelect()
                    .addClass("white")
                    .updateData(timespans, "today")
                    .on("change", this._triggerChange, this)
                    .appendTo(this.$el);

                this.$vsText = $(document.createElement("span"))
                .html("&nbsp; vs &nbsp;")
                .addClass("trendsVS")
                .appendTo(this.$el);

                this.$bSelect = new PureSelect()
                    .addClass("white")
                    .updateData(timespans, "yesterday")
                    .on("change", this._triggerChange, this)
                    .appendTo(this.$el);
            },

            getTimescaleParams: function() {
                var aRange = this._getRange(this.$aSelect.val()),
                    bRange = this._getRange(this.$bSelect.val());

                return {
                    a_start: aRange.start,
                    a_end: aRange.end,
                    b_start: bRange.start,
                    b_end: bRange.end
                };
            },

            _getRange: function (val) {
                var maxTs = moment().startOf('hour').unix() - 3600,
                    start,
                    end = maxTs - 1;

                switch  (val) {
                case "yesterday":
                    start = maxTs - 86400 * 2;
                    end = maxTs - 86400 - 1;
                    break;
                case "three":
                    start = maxTs - 86400 * 3;
                    break;
                case "seven":
                    start = maxTs - 86400 * 7;
                    break;
                case "twentyone":
                    start = maxTs - 86400 * 21;
                    break;
                default: // today
                    start = maxTs - 86400;
                    break;
                }

                return { start: start, end: end };
            },

            _triggerChange: function () {
                this.trigger('change');
            }
        });


        var TrendsView = Backbone.View.extend({

            _subViews: [],


            events: {
                "click tr": "clickRow"
            },


            initialize: function () {

            },


            calculateMoves: function (rowToMutate) {
                rowToMutate.clickMove = rowToMutate.clicks_delta / (rowToMutate.clicks - rowToMutate.clicks_delta);
				rowToMutate.clickMove = isFinite(rowToMutate.clickMove) ? Math.floor(rowToMutate.clickMove * 100) : "100";
				if (rowToMutate.clicks_delta === 0) {
					rowToMutate.clickDirection = "none";
				}
				else if (rowToMutate.clicks_delta > 0) {
					rowToMutate.clickDirection = "up";
				}
				else {
					rowToMutate.clickDirection = "down";
				}


				rowToMutate.leadMove = rowToMutate.leads_delta / (rowToMutate.leads - rowToMutate.leads_delta);
				rowToMutate.leadMove = isFinite(rowToMutate.leadMove) ? Math.floor(rowToMutate.leadMove * 100) : "100";
				if (rowToMutate.leads_delta === 0) {
					rowToMutate.leadDirection = "none";
				}
				else if (rowToMutate.leads_delta > 0) {
					rowToMutate.leadDirection = "up";
				}
				else {
					rowToMutate.leadDirection = "down";
				}

				rowToMutate.revMove = rowToMutate.revenue_delta / (rowToMutate.revenue - rowToMutate.revenue_delta);
				rowToMutate.revMove = isFinite(rowToMutate.revMove) ? Math.floor(rowToMutate.revMove * 100) : "100";
				if (rowToMutate.revenue_delta === 0) {
					rowToMutate.revenueDirection = "none";
				}
				else if (rowToMutate.revenue_delta > 0) {
					rowToMutate.revenueDirection = "up";
				}
				else {
					rowToMutate.revenueDirection = "down";
				}

				rowToMutate.epcMove = rowToMutate.epc_delta / (rowToMutate.epc - rowToMutate.epc_delta);
				rowToMutate.epcMove = isFinite(rowToMutate.epcMove) ? Math.floor(rowToMutate.epcMove * 100) : "100";
				if (rowToMutate.epc_delta === 0) {
					rowToMutate.epcDirection = "none";
				}
				else if (rowToMutate.epc_delta > 0) {
					rowToMutate.epcDirection = "up";
				}
				else {
					rowToMutate.epcDirection = "down";
				}
            },


            generateMovers: function (direction) {
                var self = this,
                    times = null,
                    req = null,
                    $rowContainer = null,
                    $loadingContainer = null,
                    timeSelect = null;

                if (direction === "asc") {
                    $rowContainer = this.$moversDownContainer;
                    $loadingContainer = this.$moversDownLoading;
                    timeSelect = this.geoVerticalDownSelect;
                }
                else {
                    direction = "desc";
                    $rowContainer = this.$moversContainer;
                    $loadingContainer = this.$moversLoading;
                    timeSelect = this.geoVerticalSelect;
                }

                $rowContainer.empty();
                $loadingContainer.show();

                times = timeSelect.getTimescaleParams();
                req = new ApiRequest().request(this.formatReportUrl("geo_vertical",
                    times.a_start,
                    times.a_end,
                    times.b_start,
                    times.b_end,
                    direction
                ));

                req.done(function (res) {
                    var df = $(document.createDocumentFragment());

                    _.each(res, function (row) {

                        self.calculateMoves(row);
                        if (registry.user.hasVerticalAccess(row.vertical.toLowerCase())) {

                            if(row.country_code !== '') {
                                df.append(self.moverRowTemplate({
                                    geo: row.country_code,
                                    flagPath: require('./../../images/flags/' + row.country_code + '.png').default,
                                    vertical: row.vertical,
                                    click_delta_direction: row.clickDirection,
                                    click_delta: row.clicks_delta,
                                    click_move: Math.abs(row.clickMove),
                                    lead_delta_direction: row.leadDirection,
                                    lead_delta: row.leads_delta,
                                    lead_move: Math.abs(row.leadMove),
                                    rev_delta_direction: row.revenueDirection,
                                    rev_delta: row.revenue_delta,
                                    rev_move: Math.abs(row.revMove),
                                    epc_delta_direction: row.epcDirection,
                                    epc_delta: row.epc_delta,
                                    epc_move: Math.abs(row.epcMove)
                                }));
                            }
                        }
                    });

                    $loadingContainer.hide();
                    $rowContainer.append(df);
                });

            },


            generateShakers: function() {
                // This is just a joke
            },

            generateCarriers: function (direction) {
                var self = this,
                    times = null,
                    req = null,
                    $rowContainer = null,
                    $loadingContainer = null,
                    timeSelect = null;

                 if (direction === "asc") {
                    $rowContainer = this.$carriersDownContainer;
                    $loadingContainer = this.$carriersDownLoading;
                    timeSelect = this.geoVerticalCarrierDownSelect;
                }
                else {
                    direction = "desc";
                    $rowContainer = this.$carriersContainer;
                    $loadingContainer = this.$carriersLoading;
                    timeSelect = this.geoVerticalCarrierSelect;
                }


                $rowContainer.empty();
                $loadingContainer.show();

                times = timeSelect.getTimescaleParams();
                req = new ApiRequest().request(this.formatReportUrl("geo_vertical_carrier",
                    times.a_start,
                    times.a_end,
                    times.b_start,
                    times.b_end,
                    direction
                ));

                req.done(function (res) {
                    var df = $(document.createDocumentFragment());

                    _.each(res, function (row) {

                        self.calculateMoves(row);
                        if (registry.user.hasVerticalAccess(row.vertical.toLowerCase())) {

                            if(row.country_code !== '') {
                                df.append(self.carrierTemplate({
                                    geo: row.country_code,
                                    flagPath: require('./../../images/flags/' + row.country_code + '.png').default,
                                    vertical: row.vertical,
                                    carrier: row.carrier,
                                    click_delta_direction: row.clickDirection,
                                    click_delta: row.clicks_delta,
                                    click_move: Math.abs(row.clickMove),
                                    lead_delta_direction: row.leadDirection,
                                    lead_delta: row.leads_delta,
                                    lead_move: Math.abs(row.leadMove),
                                    rev_delta_direction: row.revenueDirection,
                                    rev_delta: row.revenue_delta,
                                    rev_move: Math.abs(row.revMove),
                                    epc_delta_direction: row.epcDirection,
                                    epc_delta: row.epc_delta,
                                    epc_move: Math.abs(row.epcMove)
                                }));
                            }
                        }
                    });

                    $loadingContainer.hide();
                    $rowContainer.append(df);
                });
            },

            generateOsTable: function (direction) {
                var self = this,
                    times = null,
                    req = null,
                    $rowContainer = null,
                    $loadingContainer = null,
                    timeSelect = null;

                 if (direction === "asc") {
                    $rowContainer = this.$osDownContainer;
                    $loadingContainer = this.$osDownLoading;
                    timeSelect = this.geoVerticalOsDownSelect;
                }
                else {
                    direction = "desc";
                    $rowContainer = this.$osContainer;
                    $loadingContainer = this.$osLoading;
                    timeSelect = this.geoVerticalOsSelect;
                }

                $rowContainer.empty();
                $loadingContainer.show();

                times = timeSelect.getTimescaleParams();
                req = new ApiRequest().request(this.formatReportUrl( "geo_vertical_os",
                    times.a_start,
                    times.a_end,
                    times.b_start,
                    times.b_end,
                    direction
                ));

                req.done(function (res) {
                    var df = $(document.createDocumentFragment());

                    _.each(res, function (row) {
                        self.calculateMoves(row);
                        if (registry.user.hasVerticalAccess(row.vertical.toLowerCase())) {

                            if(row.country_code !== '') {
                                df.append(self.osTemplate({
                                    geo: row.country_code,
                                    flagPath: require('./../../images/flags/'+row.country_code+'.png').default,
                                    vertical: row.vertical,
                                    os: row.os,
                                    click_delta_direction: row.clickDirection,
                                    click_delta: row.clicks_delta,
                                    click_move: Math.abs(row.clickMove),
                                    lead_delta_direction: row.leadDirection,
                                    lead_delta: row.leads_delta,
                                    lead_move: Math.abs(row.leadMove),
                                    rev_delta_direction: row.revenueDirection,
                                    rev_delta: row.revenue_delta,
                                    rev_move: Math.abs(row.revMove),
                                    epc_delta_direction: row.epcDirection,
                                    epc_delta: row.epc_delta,
                                    epc_move: Math.abs(row.epcMove)
                                }));

                            }
                        }
                    });

                    $loadingContainer.hide();
                    $rowContainer.append(df);
                });
            },

            generateOfferTable: function (direction) {
                var self = this,
                    times = null,
                    req = null,
                    $rowContainer = null,
                    $loadingContainer = null,
                    timeSelect = null;

                 if (direction === "asc") {
                    $rowContainer = this.$offersDownContainer;
                    $loadingContainer = this.$offersDownLoading;
                    timeSelect = this.geoVerticalOfferDownSelect;
                }
                else {
                    direction = "desc";
                    $rowContainer = this.$offersContainer;
                    $loadingContainer = this.$offersLoading;
                    timeSelect = this.geoVerticalOfferSelect;
                }

                $rowContainer.empty();
                $loadingContainer.show();

                times = timeSelect.getTimescaleParams();
                req = new ApiRequest().request(this.formatReportUrl( "geo_vertical_offer",
                    times.a_start,
                    times.a_end,
                    times.b_start,
                    times.b_end,
                    direction
                ));

                req.done(function (res) {
                    var df = $(document.createDocumentFragment());

                    _.each(res, function (row) {
                        if (registry.user.hasVerticalAccess(row.vertical.toLowerCase())) {

                            self.calculateMoves(row);


                            if(row.country_code !== '') {
                                df.append(self.offerTemplate({
                                    geo: row.country_code,
                                    flagPath: require('./../../images/flags/' + row.country_code + '.png').default,
                                    vertical: row.vertical,
                                    offer: row.offer,
                                    click_delta_direction: row.clickDirection,
                                    click_delta: row.clicks_delta,
                                    click_move: Math.abs(row.clickMove),
                                    lead_delta_direction: row.leadDirection,
                                    lead_delta: row.leads_delta,
                                    lead_move: Math.abs(row.leadMove),
                                    rev_delta_direction: row.revenueDirection,
                                    rev_delta: row.revenue_delta,
                                    rev_move: Math.abs(row.revMove),
                                    epc_delta_direction: row.epcDirection,
                                    epc_delta: row.epc_delta,
                                    epc_move: Math.abs(row.epcMove)
                                }));
                            }
                        }
                    });

                    $loadingContainer.hide();
                    $rowContainer.append(df);
                });
            },


            getData: function() {
                this.generateMovers();
                this.generateMovers("asc");

                this.generateCarriers();
                this.generateCarriers("asc");

                this.generateOsTable();
                this.generateOsTable("asc");

                this.generateOfferTable();
                this.generateOfferTable("asc");
            },


            clickRow: function (e) {
                var statsTargetParams = {},
                    rowType= $(e.currentTarget).data("keyword"),
                    rowName = $(e.currentTarget).data("value"),
                    rowGeo = $(e.currentTarget).data("geo"),
                    rowVertical = $(e.currentTarget).data("vertical"),
                    req = new ApiRequest(),
                    lookupUrl = "data/guess-rotator.php";

                if (rowName && rowVertical) {
                    req.request(lookupUrl + "?vertical=" + rowVertical + "&geo=" + rowGeo).done(function (res) {
                        if (res.rid !== undefined) {

                            statsTargetParams.reportType = "rotator";
                            statsTargetParams.name = rowGeo;
                            statsTargetParams.vertical = rowVertical;
                            statsTargetParams.rid = res.rid;
                            platformReports.setTarget(statsTargetParams);
                            platformReports.show();

                        }
                    });

                } else {
                    console.error("Problem retrieving row data");
                }

            },



            render: function () {

                if (registry.user.getAccessLevel() === 'basic') {
                    return;
                }

                this.moverRowTemplate = _.template(moverTemplate);
                this.carrierTemplate = _.template(carrierTemplate);
                this.osTemplate = _.template(osTemplate);
                this.offerTemplate = _.template(offerTemplate);


                this.setElement($(document.createElement('div')));
                this.$el.addClass("settingsPane")
                    .addClass("mainPane")
                    .attr('id', 'trendingContainer');
                this.$el.append(template);

                $('#appContainer').append(this.$el);


                // Movers UP
                this.$trendsGeoVerticalTimeSelectContainer =
                    this.$el.find("#trendsGeoVerticalTimeSelect");

                this.geoVerticalSelect = new RangeSelect();
                this.geoVerticalSelect
                    .on("change", this.generateMovers, this)
                    .$el
                    .appendTo(this.$trendsGeoVerticalTimeSelectContainer);
                this._subViews.push(this.geoVerticalSelect);


                // Movers Down
                this.$trendsGeoVerticalDownTimeSelectContainer =
                    this.$el.find("#trendsGeoVerticalDownTimeSelect");

                this.geoVerticalDownSelect = new RangeSelect();
                this.geoVerticalDownSelect.on("change", function () {
                    this.generateMovers("asc");
                }, this)
                .$el.appendTo(this.$trendsGeoVerticalDownTimeSelectContainer);
                this._subViews.push(this.geoVerticalDownSelect);


                // Carrier
                this.$trendsGeoVerticalCarrierTimeSelectContainer =
                    this.$el.find("#trendsGeoVerticalCarrierTimeSelect");

                this.geoVerticalCarrierSelect = new RangeSelect();
                this.geoVerticalCarrierSelect
                    .on("change", this.generateCarriers, this)
                    .$el
                    .appendTo(this.$trendsGeoVerticalCarrierTimeSelectContainer);
                this._subViews.push(this.geoVerticalCarrierSelect);

                // Carrier Down
                this.$trendsGeoVerticalCarrierDownTimeSelectContainer =
                    this.$el.find("#trendsGeoVerticalCarrierDownTimeSelect");

                this.geoVerticalCarrierDownSelect = new RangeSelect();
                this.geoVerticalCarrierDownSelect
                    .on("change", function () {
                        this.generateCarriers("asc");
                    }, this)
                    .$el
                    .appendTo(this.$trendsGeoVerticalCarrierDownTimeSelectContainer);
                this._subViews.push(this.geoVerticalCarrierDownSelect);


                // OS Uppers
                this.$trendsGeoVerticalOsTimeSelectConatainer =
                    this.$el.find("#trendsGeoVerticalOsTimeSelect");

                this.geoVerticalOsSelect = new RangeSelect();
                this.geoVerticalOsSelect
                    .on("change", this.generateOsTable, this)
                    .$el
                    .appendTo(this.$trendsGeoVerticalOsTimeSelectConatainer);
                this._subViews.push(this.geoVerticalOsSelect);

                // OS Downers
                this.$trendsGeoVerticalOsDownTimeSelectConatainer =
                    this.$el.find("#trendsGeoVerticalOsDownTimeSelect");

                this.geoVerticalOsDownSelect = new RangeSelect();
                this.geoVerticalOsDownSelect
                    .on("change", function () {
                        this.generateOsTable("asc");
                    }, this)
                    .$el
                    .appendTo(this.$trendsGeoVerticalOsDownTimeSelectConatainer);
                this._subViews.push(this.geoVerticalOsDownSelect);

                // Offer Uppers
                this.$trendsGeoVerticalOfferTimeSelectContainer =
                    this.$el.find("#trendsGeoVerticalOfferTimeSelect");

                this.geoVerticalOfferSelect = new RangeSelect();
                this.geoVerticalOfferSelect
                    .on("change", this.generateOfferTable, this)
                    .$el
                    .appendTo(this.$trendsGeoVerticalOfferTimeSelectContainer);
                this._subViews.push(this.geoVerticalOfferSelect);

                // Offer Downers
                this.$trendsGeoVerticalOfferDownTimeSelectContainer =
                    this.$el.find("#trendsGeoVerticalOfferDownTimeSelect");

                this.geoVerticalOfferDownSelect = new RangeSelect();
                this.geoVerticalOfferDownSelect
                    .on("change", function () {
                        this.generateOfferTable("asc");
                    }, this)
                    .$el
                    .appendTo(this.$trendsGeoVerticalOfferDownTimeSelectContainer);
                this._subViews.push(this.geoVerticalOfferDownSelect);


                this.$moversContainer = this.$el.find("#trendsMoversContainer");
                this.$moversDownContainer = this.$el.find("#trendsMoversDownContainer");
                this.$moversLoading = this.$el.find("#trendsMoversLoading");
                this.$moversDownLoading = this.$el.find("#trendsMoversDownLoading");

                this.$carriersContainer = this.$el.find("#trendsCarriersContainer");
                this.$carriersDownContainer = this.$el.find("#trendsCarriersDownContainer");
                this.$carriersLoading = this.$el.find("#trendsCarriersLoading");
                this.$carriersDownLoading = this.$el.find("#trendsCarriersDownLoading");

                this.$osContainer = this.$el.find("#trendsOsContainer");
                this.$osLoading = this.$el.find("#trendsOsLoading");
                this.$osDownContainer = this.$el.find("#trendsOsDownContainer");
                this.$osDownLoading = this.$el.find("#trendsOsDownLoading");

                this.$offersContainer = this.$el.find("#trendsOffersContainer");
                this.$offersLoading = this.$el.find("#trendsOffersLoading");
                this.$offersDownContainer = this.$el.find("#trendsOffersDownContainer");
                this.$offersDownLoading = this.$el.find("#trendsOffersDownLoading");

                return this.$el;
            },

            formatReportUrl: function(report, aStartTs, aEndTs, bStartTs, bEndTs, dir) {
                return "data/trends.php?report="+report+"&a_start_ts="+aStartTs+"&a_end_ts="+aEndTs+"&b_start_ts="+bStartTs+"&b_end_ts="+bEndTs+"&dir="+dir;
            },


            sleep: function() {},

            wakeUp: function() {
                if (registry.user.getAccessLevel() === 'basic') {
                    return;
                }
                this.getData();
            },

        });


        return new TrendsView();
});

