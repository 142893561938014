define([
    "Backbone",
    "registry",
    "moment-timezone",
    "workers/apirequest",
    "libs/uiAlert",
    "templates/message.html",
    "templates/messageRow.html",
    "templates/newMessage.html"
], function (Backbone, registry, moment, ApiWorker, uiAlert, mainTemplate, rowTemplate, newTemplate) {

    return Backbone.View.extend({

        events: {
            'click .messageSendBtn': '_sendMessage',
            'click .sendNewMessageBtn': '_showConfirmMessageModal',
            'click .confirmSendNewMessageBtn': '_sendNewMessage',
            'click .blockSenderBtn': 'showBlockSenderModal',
            'click .confirmBlockSenderBtn': 'blockSender',
        },

        network_id: null,
        message: null,
        action: null,
        sending: false,

        initialize: function (options) {


            if(typeof options !== 'undefined') {
                if (typeof options.parent !== 'undefined') {
                    this.parent = options.parent;
                }

                if(typeof options.network_id !== 'undefined') {
                    this.network_id = options.network_id;
                }

                if(typeof options.message !== 'undefined') {
                    this.message = options.message;
                }

                if(typeof options.action !== 'undefined') {
                    this.action = options.action;

                }

            }

            if(this.network_id && this.action !== 'new') {
                this.render();
            } else {
                this._newMessage();
            }

        },

        render: function() {
            var self = this,
                messageTemplate = _.template(rowTemplate),
                apiRequest = new ApiWorker();

            if(registry.user.allowMessages !== 1) {
                return;
            }


            this.setElement($(document.createElement("div")));
            this.$el.append(mainTemplate);
            $("#appContainer").append(this.$el);

            this.$el.find('.advertiserIdLabel').text(this.network_id);
            this.$messageLog = this.$el.find('.messageLog');
            this.$messageInput = this.$el.find('.messageInput');
            this.$el.find('.messageModal').show();

            apiRequest.request('v3/messages.php?action=log&network_id='+this.network_id)
                .then(function(response) {

                    if (response && response[0] && +response[0].blocked === 1) {
                        self.$el.find('.blockSenderBtn').hide();
                        self.$el.find('.senderIsBlockedBadge').show();
                    } else {
                        self.$el.find('.blockSenderBtn').show();
                        self.$el.find('.senderIsBlockedBadge').hide();
                    }

                    _.each(response, function(row) {
                        self.$messageLog.append(messageTemplate(self._castDataRecord(row)));
                    });
                    self.$el.find('.loading-spinner').hide();
                    self.$messageLog.show();
                    self.$el.find('.panel-content').scrollTop(self.$el.find('.panel-content').prop("scrollHeight"));

                    apiRequest.request('v3/messages.php', {
                        'action': 'read',
                        'network_id': self.network_id
                    });
                });


        },

        _castDataRecord: function(record) {
            record.cssClasses = this._getCssClasses(record);
            record.formattedDate = moment
                .tz(record.send_ts,'America/New_York')
                .format('ddd, MMM Do YYYY HH:mm');
            return record;
        },

        _getCssClasses: function (message) {
            var cssClasses = ['msg' + message.id];

            switch(+message.sender_type) {
                case 1:
                    cssClasses.push('left');
                    break;
                case 2:
                    cssClasses.push('right');
                    break;
                case 3:
                    cssClasses.push('center');
                    break;

            }

            if (+message.is_approved === 2) {
                cssClasses.push('messagePending');
            } else if (+message.is_approved === 0) {
                cssClasses.push('messageRejected');
            } else {
                if (+message.sender_type === 2) {
                    cssClasses.push(message.network_read_ts ? 'messageSeen' : 'messageNotSeen');
                }
            }

            return cssClasses.join(' ');
        },

        _sendMessage: function (ev) {
            var self = this,
                $element = $(ev.currentTarget),
                errorStr = '',
                error = false,
                message = this.$messageInput.val(),
                messageTemplate = _.template(rowTemplate),
                apiRequest = new ApiWorker();

            if(!this.sending) {

                if(message === '') {
                    error = true;
                    errorStr += "Please enter a message.\n";
                }

                if(error) {
                    alert(errorStr);
                } else {
                    this.sending = true;

                    apiRequest.request('v3/messages.php',{
                        'action': 'send',
                        'network_id': this.network_id,
                        'message': message
                    })
                        .then(function(response) {
                            if(response.status === 'error') {
                                _.each(response.errors, function(err) {
                                    errorStr += err+"\n";
                                });
                                alert(errorStr);
                            } else {
                                if (response && response.record) {
                                    var msg = _.clone(response.record);
                                    self.$messageLog.append(messageTemplate(self._castDataRecord(msg)));
                                    self.$messageInput.val('');
                                    self.$el.find('.panel-content').scrollTop(self.$el.find('.panel-content').prop("scrollHeight"));

                                    self.notifyParent('newMessage', response.record);
                                }
                            }
                        })
                        .always(function () {
                            self.sending = false;
                        });

                }

            }

        },

        _newMessage: function () {
            this.setElement($(document.createElement("div")));
            this.$el.append(newTemplate);
            $("#appContainer").append(this.$el);

            this.$advertiserIdInput = this.$el.find('.advertiserIdInput');
            this.$messageInput = this.$el.find('.messageInput');
            this.$confirmSendModal = this.$el.find('.newMessageTermsModal');

            if(this.network_id) {
                this.$advertiserIdInput.val(this.network_id);

            }

            if(this.message) {
                this.$messageInput.val(this.message);
            }


            this.$el.find('.messageModal').fadeIn();
        },
        _showConfirmMessageModal: function () {
            this.$confirmSendModal.show();
        },

        _sendNewMessage: function (ev) {
            var self = this,
                $btn = $(ev.currentTarget),
                btnHtml = $btn.html(),
                advertiserId = parseInt(this.$advertiserIdInput.val()),
                message = this.$messageInput.val(),
                error = false,
                errorStr = '',
                apiRequest = new ApiWorker();

            this.$confirmSendModal.hide();


            if(isNaN(advertiserId) || advertiserId <= 0){
                error = true;
                errorStr += "Please enter a valid advertiser id.\n";
            }


            if(message == '' || message == this.message) {
                error = true;
                errorStr += "Please enter a message.\n";
            }

            if(error) {
                alert(errorStr);
            } else {
                $btn.html('Sending...').attr('disabled','disabled');
                apiRequest.request('v3/messages.php',{
                    'action': 'send',
                    'network_id': advertiserId,
                    'message': message
                })
                    .then(function(response) {
                        if(response.status === 'error') {
                            _.each(response.errors, function(err) {
                                errorStr += err+"\n";
                            });
                            alert(errorStr);
                        } else {
                            uiAlert.showSuccess('Message sent and pending approval.');
                            if (response && response.record) {
                                self.notifyParent('newMessage', response.record);
                            }
                            self.sleep();

                        }
                    })
                    .always(function () {
                        $btn.html(btnHtml).removeAttr('disabled');
                    });

            }

        },

        sleep: function () {
            var dfd = $.Deferred();

            $.when(
                this.remove(),
                this.unbind(),
                this.undelegateEvents(),
                this.stopListening(),
                this.$el.empty()
            ).then(function() {
                dfd.resolve();
            });

            this.trigger("sleep");
            return dfd.promise();

        },

        showBlockSenderModal: function(ev) {
            this.$el.find('.confirmBlockSenderModal').show();
        },

        hideBlockSenderModal: function() {
            this.$el.find('.confirmBlockSenderModal').hide();
        },

        blockSender: function(ev) {
            var self = this;
            var networkId = this.network_id;
            var api = new ApiWorker();

            this.hideBlockSenderModal();

            api.request('v3/messages.php',{
                'action': 'block',
                'network_id': networkId,
            })
                .then(function(response) {
                    uiAlert.showSuccess('Advertiser ' + networkId + ' have been blocked.')
                })
                .fail(function (error) {
                    if (error && error.responseJSON && error.responseJSON.message) {
                        uiAlert.showError(error.responseJSON.message);
                    } else {
                        uiAlert.showError('Server error occured.');
                    }
                })
                .always(function () {
                    self.sleep();
                });
        },

        notifyParent: function(eventName, payload) {
            if (this.parent && _.isFunction(this.parent.trigger)) {
                this.parent.trigger(eventName, payload) ;
            }
        },

    });
});
