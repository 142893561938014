/*jslint nomen:true, indent:4*/
/*global define, _ */

define([
    "Backbone",
    "registry",
    "templates/filteredTemplate.html",
    "templates/filteredRowTemplate.html"
], function (Backbone, registry, filteredTemplate, rowTemplate) {

    var View = Backbone.View.extend({
    
        // Constants
    
        // Properties
        data: null,
        $overlay: null,
        $table: null,
        $tbody: null,
        $header: null,
        $nameText: null,


        nid: null,

        
        // Events
        events: {
            "click .closeButton": "hide",
            "click .unfilterLink": "clickUnfilter"
        },
    
        
        // Methods
        initialize: function () {
            var self = this,
                inner = null,
                closeButton = null;

            this.setElement($(document.createElement("div")));
            this.$el.append(_.template(filteredTemplate)());
            $("#appContainer").append(this.$el);

            this.$overlay = this.$el.find('.overlay');
            inner = this.$el.find('.inner');
            
            this.$nameText = this.$el.find('.panel-header h4 span');

            this.$table = this.$el.find('table');
            this.$tbody = this.$table.find('tbody');

            this.rowTemplate = _.template(rowTemplate);

            $(document).on("keydown", function (e) {
                self.keyPressed.call(self, e);
            });

        },

        update: function () {
            var self = this,
                rNewRow = null,
                filterMap = registry.user.getFilterMapForNid(this.nid);

            this.$tbody.empty();

            if (_.keys(filterMap).length > 0) {
                _.each(filterMap, function (row, index) {
                    rNewRow = $(self.rowTemplate({
                        rcid: index,
                        name: row.name
                    }));
                    rNewRow.data("cid", index);
                    self.$tbody.append(rNewRow);
                });
            } else {
                self.$tbody.append($(document.createElement("tr"))
                    .html("<td class=\"noData text-muted\" colspan=\"100%\" style=\"text-align:center;\">You have no filters set yet.</td>")
                );
            }
        },
    

        setTarget: function (options) {
            this.nid = options.nid;
            this.name = options.name;
        },

        show: function () {
            this.$nameText.text("\"" + this.name + "\"");
            $("html, body").addClass("overflow-hidden");
            this.$overlay.fadeIn('fast');
            this.update();
        },


        hide: function () {
            var self = this;
            self.$overlay.fadeOut('fast', function() {
                $("html, body").removeClass("overflow-hidden");
                self.$tbody.empty();

            });
        },

        clickUnfilter: function (e) {
            var row = $(e.currentTarget).closest('tr'),
                rcid = row.data('cid');

            registry.user.unfilterCampaign(this.nid, rcid);
            this.update();
            this.trigger("update");
        },

        keyPressed: function(e) {
            if (e.which === 27) {
                this.hide();
            }
        }
        
    });

    return new View();
});

