/*jslint nomen:true, indent:4*/
/*global define, $*/

define([
    "Backbone",
    "workers/apirequest",
    "templates/menu.html",
    "registry",
    "views/referralLinkModal",
    "moment-timezone"
], function(Backbone, ApiRequest, menuTemplate, registry, referralLinkModal, moment) {
    var MenuView = Backbone.View.extend({
        $homeButton: null,
        $revTotal: null,
        $leaguesButton: null,
        $earningsButton: null,
        $infoButton: null,
        $settingsButton: null,
        $bankingButton: null,
        $domainsButton: null,
        $resourcesButton: null,
        $logoutButton: null,
        $supportButton: null,

        events: {
            "click #menu_logoutButton": "clickLogout",
            "click #menu_referralButton": "_showReferralLinkModal",
        },

        render: function() {
            var self = this,
                menuItems = {};

            this.menuTemplate = _.template(menuTemplate);

            this.setElement($(".userMenu"));
            this.$el.attr("id", "menuView");


            this.referralLinkModal = new referralLinkModal();


            this.$revTotal = $(document.createElement("div"))
                .text("Total Monetizer Revenue:")
                .addClass("revTotal")
                .attr("id", "menu_revTotal");

            menuItems.marketplace = {
                text: "Marketplace",
                icon: "tag",
                children: []
            };

            menuItems.marketplace.children.push({
                text: '<i class="fa fa-random" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Smartlink Offers',
                id: "menu_offers",
                icon: "bullseye",
                url: "marketplace"
            });

            menuItems.marketplace.children.push({
                text: '<i class="fa fa-lock" style="padding: 0 13px 0 2px;font-size: 14px;color: #999;"></i>Private Offers <span class="badge badge-success menuDirectOffers" style="display:none;background:#485063;color:#e1e1e1;border-radius:1px;padding:3px 6px;"></span>',
                id: "menu_offers",
                icon: "bullseye",
                url: "cpamarketplace"
            });

            menuItems.offerMarket = {
                text: "Traffic & Offer Requests",
                icon: 'handshake-o',
                children: [
                    {
                        text: '<i class="fa fa-cart-plus" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Offer Market <span class="badge badge-success menuOfferMarket" style="display:none;background:#485063;color:#e1e1e1;border-radius:1px;padding:3px 6px;"></span>',
                        id: "menu_offerMarket",
                        icon: "",
                        url: "offerRequests"
                    },
                    {
                        text: '<i class="fa fa-list" style="padding-right: 11px;font-size: 13px;color: #999;"></i>My Requests',
                        id: "menu_myRequests",
                        icon: "",
                        url: "offerRequests/myRequests"
                    }
                ]
            };

            menuItems.campaigns = {
                text: "Campaigns <span class='badge badge-success' style='background:none;border-radius:2px;padding:0;border:0;color:lightgreen;font-size:12px;margin:0 0 2px 2px;display:inline-block;'><span class='menuCampaignRevenue' style='display:none'>$0</span></span><span style='color: #ccc;font-size:8px;display:inline-block;text-transform:uppercase;padding:0;margin:-5px 0 0 5px;vertical-align:middle;height: 8px;'>Today</span>",
                id: "menu_campaigns",
                icon: "dashboard",
                children: []
            };
            menuItems.campaigns.children.push({
                text: '<i class="fa fa-table" style="padding-right: 12px;font-size: 13px;color: #999;"></i>Leagues',
                id: "menu_leagues",
                url: "leagues"
            });
            menuItems.campaigns.children.push({
                text: '<i class="fa fa-plus" style="padding-right: 13px;font-size: 13px;color: #999;"></i>Create Link',
                id: "menu_createLink",
                url: "links/new"
            });
            menuItems.campaigns.children.push({
                text: '<i class="fa fa-sliders" style="padding-right: 12px;font-size: 13px;color: #999;"></i>Manage Links',
                id: "menu_linksButton",
                icon: "link",
                url: "links"
            });
            menuItems.campaigns.children.push({
                text: '<i class="fa fa-cog" style="padding-right: 12px;font-size: 13px;color: #999;"></i>Manage Domains',
                id: "menu_addDomain",
                url: "domains"
            });
            menuItems.campaigns.children.push({
                text: '<span style="padding: 0 14px 0 1px;font-size: 13px;color: #999;font-weight: bold;">P</span>Domain Parking',
                id: "menu_domainParking",
                url: "domainParking"
            });

            if(registry.user.allowMessages === 1) {
                menuItems.campaigns.children.push({
                    text: '<i class="fa fa-comments-o" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Messenger',
                    id: "menu_inboxButton",
                    icon: "link",
                    url: "inbox"
                });
            }

            menuItems.push = {
                text: "Push <span class='badge badge-success' style='background: none;border-radius: 2px;padding: 0;border: 0;color: lightgreen;font-size: 12px;margin: 0 0 2px 2px;display: inline-block;'><span class='menuPushRevenue' style='display:none'>$0</span></span><span style='color: #ccc;font-size:8px;display:inline-block;text-transform:uppercase;padding:0;margin:-5px 0 0 5px;vertical-align:middle;height: 8px;'>Today</span>",
                icon: "chrome",
                children: []
            };
            menuItems.push.children.push({
                text: '<i class="fa fa-area-chart" style="padding-right: 9px;font-size: 13px;color: #999;"></i>Push Stats',
                id: "menu_push",
                icon: "",
                url: "push"
            });
            menuItems.push.children.push({
                text: '<i class="fa fa-user-plus" style="padding-right: 9px;font-size: 13px;color: #999;"></i>Collect Subscribers',
                id: "menu_pushCollection",
                icon: "",
                url: "pushCollection"
            });
            menuItems.push.children.push({
                text: '<i class="fa fa-globe" style="padding-right: 13px;font-size: 14px;color: #999;"></i>Add a Domain',
                id: "menu_addDomain",
                url: "domains"
            });

            const allowedIds = [10, 13, 131, 1703, 3150, 16856, 20186, 24072];
            if (allowedIds.includes(+registry.user.id)) {
                menuItems.apps = {
                    text: "Apps",
                    icon: "tablet",
                    children: [],
                    collapse: true
                };
                menuItems.apps.children.push({
                    text:  '<i class="fa fa-link" style="padding-right: 9px;font-size: 13px;color: #999;"></i>Links',
                    id: "menu_appLinks",
                    icon: "",
                    url: "apps/links"
                });
                menuItems.apps.children.push({
                    text:  '<i class="fa fa-table" style="padding-right: 9px;font-size: 13px;color: #999;"></i>Stats',
                    id: "menu_appStats",
                    icon: "",
                    url: "apps/stats"
                });
            }

            menuItems.insights = {
                text: "Insights",
                icon: "search-plus",
                children: [],
                collapse: true
            };

            if (registry.user.getAccessLevel() !== "basic") {
                menuItems.insights.children.push({
                    text: '<i class="fa fa-line-chart" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Trending',
                    id: "menu_trendingButton",
                    icon: "pie-chart",
                    url: "trends"
                });
            }

            if (registry.user.getAccessLevel() !== "basic") {
                menuItems.insights.children.push({
                    text: '<i class="fa fa-bar-chart" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Snapshot',
                    id: "menu_homeButton",
                    icon: "camera",
                    url: "snapshot"
                });
            } else {
                menuItems.insights.children.push({
                    text: '<i class="fa fa-camera" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Snapshot',
                    id: "menu_homeBasicButton",
                    icon: "camera",
                    url: "snapshotBasic"
                });
            }

            menuItems.insights.children.push({
                text: '<i class="fa fa-spinner" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Live Leads',
                id: "menu_liveleadsButton",
                icon: "feed",
                url: "liveLeads"
            });

            if (registry.user.getAccessLevel() !== "basic") {
                menuItems.insights.children.push({
                    text: '<i class="fa fa-bolt" style="padding: 0 14px 0 3px;font-size: 13px;color: #999;"></i>Inspire',
                    id: "menu_verticalOsCarrierButton",
                    icon: "bolt",
                    url: "inspire"
                });
            }
            menuItems.insights.children.push({
                text: '<i class="fa fa-exchange" style="padding-right: 11px;font-size: 13px;color: #999;"></i>PID to Keyword',
                id: "menu_pidKwtool",
                url: "pidKeywordTool"
            });

            menuItems.insights.children.push({
                text: '<i class="fa fa-info-circle" style="padding-right: 13px;font-size: 13px;color: #999;"></i>Subsource Analysis',
                id: "menu_subsourceAnalysis",
                url: "subsourceAnalysis"
            });

            menuItems.banking = {
                text: "Banking",
                icon: "credit-card",
                children: [],
                collapse: true
            };

            menuItems.banking.children.push({
                text: '<i class="fa fa-pencil-square-o" style="padding: 0 10px 0 0;font-size: 13px;color: #999;"></i>Bank Details',
                id: "menu_bankingButton",
                icon: "vcard",
                url: "banking"
            });
            menuItems.banking.children.push({
                text: '<i class="fa fa-money" style="padding: 0 10px 0 0;font-size: 13px;color: #999;"></i>Earnings',
                id: "menu_earningsButton",
                icon: "money",
                url: "earnings"
            });

            menuItems.account = {
                text: "Account",
                icon: "user-circle-o",
                children: [],
                collapse: true
            };

            menuItems.account.children.push({
                text: '<i class="fa fa-pencil-square-o" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Profile',
                id: "menu_settingsButton",
                icon: "cog",
                url: "profile"
            });

            menuItems.account.children.push({
                text: '<i class="fa fa-bell-o" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Offer Subscriptions',
                id: "menu_linksButton",
                icon: "link",
                url: "subscriptions"
            });

            menuItems.account.children.push({
                text: '<i class="fa fa-bell" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Advertiser Subscriptions',
                id: "menu_AdvertiserSubscriptionButton",
                icon: "link",
                url: "advertiserSubscriptions"
            });

            menuItems.account.children.push({
                text: '<i class="fa fa-link" style="padding-right: 11px;font-size: 13px;color: #999;padding-left:1px;"></i>Referral Link',
                id: "menu_referralButton",
                icon: "link",
                url: "javascript:;"
            });

            menuItems.support = {
                text: "Support",
                icon: "question-circle",
                children: [],
                collapse: true
            };
            menuItems.support.children.push({
                text: '<i class="fa fa-info-circle" style="padding-right: 13px;font-size: 13px;color: #999;"></i>Academy',
                id: "menu_faqs",
                icon: "money",
                url: "academy"
            });

            menuItems.advertiser = {
                text: "Advertizer",
                icon: "adn",
                children: [],
                collapse: true
            };
            menuItems.advertiser.children.push({
                text: '<i class="fa fa-arrow-right" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Login',
                id: "menu_advertizerLogin",
                icon: "money",
                url: "https://app.advertizer.com/"
            });
            menuItems.advertiser.children.push({
                text: '<i class="fa fa-plus" style="padding-right: 11px;font-size: 13px;color: #999;"></i>Add Campaign',
                id: "menu_signUp",
                icon: "",
                url: "https://app.advertizer.com/#offers/add"
            });


            this.$el.html(this.menuTemplate({ items: menuItems }));

            this.$notificationButton = $(".updateBadge");

            this._serverTime();
        },

        clickLogout: function() {
            $.ajax({
                url: API_URL + "data/user-logout.php",
                crossDomain: true,
                headers: {
                    X_AFFLOW_USER_API_TOKEN: localStorage.apiToken,
                    X_AFFLOW_CLIENT_VERSION: registry.versionStr,
                    X_AFFLOW_API_TOKEN: registry.user.token
                }
            }).always(function(res) {
                localStorage.removeItem("apiToken");
                localStorage.removeItem("remember");
                location.reload();
            });
        },

        updateUnread: function(num) {
            if (num === 0) {
                num = "";
            }
            this.$notificationButton.text(num);
        },

        _showReferralLinkModal: function() {

            this.referralLinkModal.show();
        },


        _serverTime: function () {
            var self = this;

            setInterval(function() {
                var time = moment.tz('America/New_York').format('HH:mm:ss');
                $('.serverTime').html(time);
            }, 1000);
        }

    });

    return new MenuView();
});
