define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/referralLinkModal.html"
], function (Backbone, registry, ApiRequest, mainTemplate) {

    return Backbone.View.extend({

        events: {},

        initialize: function () {
            this.$el.append(_.template(mainTemplate));
            $('#appContainer').append(this.$el);

            this.$overlay = this.$el.find('.referralLinkModal');
            this.$linkInput = this.$el.find('.referralLinkInput');
        },

        show: function() {
            this.$overlay.fadeIn('fast');
            this.$linkInput.val('https://www.monetizer.com/?pid=' + registry.user.id);
            $('html, body').addClass('overflow-hidden');
        },
    });
});
