/*jslint nomen:true, indent:4*/
/*global define*/

define(["Backbone"], function (Backbone) {
    return Backbone.View.extend({
        classifyRow: function (row) {
            var classes = [],
                clicks = parseInt(row.clicks),
                epc = parseFloat(row.epc);

            if (clicks > 500 && epc > 0.01) {
                classes.push('shine');
            }

            if (/No Click Through/.test(row.keyword)) {
                classes.push('no-click-through');
            }

            return classes.join(' ');
        }
    });
});