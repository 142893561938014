define([
    'collections/offerMarket/publishersRequests',
    'views/offerMarket/baseRequests.js',
    'templates/offerMarket/publishersRequests.html',
    'templates/offerMarket/publisherRequestRow.html',
], function (
    PublishersRequestsCollection,
    BaseRequests,
    template,
    rowTemplate,
) {
    return BaseRequests.extend({
        template: template,
        rowTemplate: _.template(rowTemplate),
        Collection: PublishersRequestsCollection,
        routeFragment: 'publisherRequest',

        events: function() {
            return _.extend({}, BaseRequests.prototype.events, {
            });
        },
    });
});
