define([
    "Backbone",
    "moment-timezone",
    "registry",
    "workers/apirequest",
    "templates/requests/requests.html",
    "templates/requests/myRequestRow.html",
    "templates/requests/helpContent.html",
    "views/requests/requestForm",
    "libs/offerMarketUtils",
    'templates/offerMarket/publisherRequestDetails.html',
    'views/baseModal',
], function(
    Backbone,
    moment,
    registry,
    ApiRequest,
    template,
    myRequestRowTemplate,
    helpContentTemplate,
    RequestFormView,
    Utils,
    publisherRequestDetailsTemplate,
    BaseModalView,
) {
    var requestsView = Backbone.View.extend({
        events: {
            'click #createRequestBtn': 'createRequest',
            'click .viewRequestBtn': 'viewRequest',
            'click .editRequestBtn': 'editRequest',
            'click .mrPageBtn': 'pageChanged',
            'click .deleteRequestBtn': 'showDeleteConfirmationModal',
            'click #requestDeleteModal__confirmBtn': 'deleteRequest',
            'click .selectMyRequest': 'selectMyRequest',
            'click #selectAllMyRequests': 'selectAllMyRequests',
            'click .deleteSelectedRequestsBtn': 'showDeleteSelectedConfirmationModal',
            'click #requestDeleteMultiModal__confirmBtn': 'deleteSelectedRequests',
            'click #mr_helpBtn': 'showHelpModal',
        },

        initialize: function() {
            this.myRequests = [];
            this.page = 1;
            this.perPage = 25;
            this.myRequestRowTemplate = _.template(myRequestRowTemplate);
            this.requestDetailsTemplate = _.template(publisherRequestDetailsTemplate);
        },

        wakeUp: function() {
            this.off();
            // custom events
            this.on('myRequests:new', this.onRequestCreated.bind(this));
            this.on('myRequest:update', this.onRequestUpdated.bind(this));
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "requestsContainer");
            this.$el.append(template);

            // setup element references
            this.$mr_multiActionBtnGroup = this.$el.find('#mr_multiActionBtnGroup');
            this.$mrPrevBtns = this.$el.find('.mrPageBtn[data-id="prev"]');
            this.$mrNextBtns = this.$el.find('.mrPageBtn[data-id="next"]');
            this.$mrPageNumbers = this.$el.find('.paginationPageNumber');
            this.$selectAllMyRequestsCheckbox = this.$el.find('#selectAllMyRequests');

            this.updateMyRequestsPagination();

            $("#appContainer").append(this.$el);

            // load data and render rows
            this.loadMyRequests();
        },

        loadMyRequests: function () {
            var self = this;
            var $loading = this.$el.find('#myRequestsLoading');
            var api = new ApiRequest();
            var params = {
                action: 'getRequests',
                page: this.page,
                perPage: this.perPage
            };

            $loading.show();
            api.request('v3/publisher-requests.php?' + $.param(params))
                .then(function (response) {
                    self.myRequests = _.map(response, self.castPublisherRequest.bind(self));

                    self.updateMyRequestsPagination();
                    self.renderMyRequests();
                })
                .always(function () {
                    $loading.hide();
                });
        },

        castPublisherRequest: function (record) {
            if (!record) {
                return record;
            }

            record.id = +record.id;
            record.trafficType = Utils.trafficTypeTitlesMap[record.traffic_type] || 'N/A';
            record.flowVertical = Utils.flowTitlesMap[record.conversion_flow] || 'N/A';
            record.minPayoutFormatted = record.min_payout ? '$' + parseFloat(record.min_payout).toPrecision() : '0';
            record.minCapFormatted = record.min_cap ? '$' + parseFloat(record.min_cap).toFixed(2) : '0';
            Utils.processGeo(record);
            record.carrier_data = JSON.parse(record.carrier_data);
            record.os_data = JSON.parse(record.os_data);
            record.lp_wanted = +record.lp_wanted;
            record.partner_id = +record.partner_id;
            record.createdAtFormatted = moment.tz(record.created_at, 'America/New_York').format('MMM Do YYYY');
            record.updatedAtFormatted = moment.tz(record.updated_at, 'America/New_York').format('MMM Do YYYY');
            record.archived = !!+record.archived;
            record.notify = !!+record.notify;
            record.isMyRequest = true;

            return record;
        },

        renderMyRequests: function () {
            var $container = this.$el.find('#myRequests');
            var $noDataMsg = this.$el.find('#myRequestsNoData');
            this.renderRows(this.myRequests, $container, this.myRequestRowTemplate, $noDataMsg);



            // unselect all checkbox and hide batch/multi action buttons
            this.selectedIds = [];
            this.$mr_multiActionBtnGroup.hide();
            this.$selectAllMyRequestsCheckbox.prop('checked', false);
            if (!this.myRequests || this.myRequests.length === 0) {
                this.$selectAllMyRequestsCheckbox.prop('disabled', true);
            } else {
                this.$selectAllMyRequestsCheckbox.prop('disabled', false);
            }
        },

        renderRows: function(rows, $el, rowTemplate, $noDataMsg) {
            $el.empty();
            $noDataMsg.hide();
            if (rows && rows.length > 0) {
                _.each(rows, function (row) {
                    $el.append(rowTemplate(row))
                });
            } else {
                $noDataMsg.show();
            }
        },

        updateMyRequestsPagination: function(ev) {
            if (this.page <= 1) {
                this.$mrPrevBtns.prop('disabled', true);
            } else {
                this.$mrPrevBtns.prop('disabled', false);
            }
            if (this.myRequests && this.myRequests.length < this.perPage) {
                this.$mrNextBtns.prop('disabled', true);
            } else {
                this.$mrNextBtns.prop('disabled', false);
            }
            this.$mrPageNumbers.text('Page ' + this.page);
        },

        pageChanged: function(ev) {
            var $btn = $(ev.currentTarget);
            var id = $btn.data('id');
            if (id === 'next') {
                this.page += 1;
            } else if (id === 'prev' && this.page > 1) {
                this.page -= 1
            }
            this.updateMyRequestsPagination()
            this.loadMyRequests();
        },

        sleep: function() {
            // clean up
        },

        createRequest: function() {
            new RequestFormView({parent: this});
        },

        onRequestCreated: function(request) {
            if (!request) {
                return;
            }
            this.myRequests.unshift(this.castPublisherRequest(request));
            this.renderMyRequests();
        },

        viewRequest: function(ev) {
            var id = +$(ev.currentTarget).data('id');
            var request = _.findWhere(this.myRequests, {id: id});
            if (request) {
                new BaseModalView({
                    modalType: 'modal-fluid',
                    modalHtml: this.requestDetailsTemplate(_.extend({type: 'internal'}, request)),
                });
            }
        },

        editRequest: function(ev) {
            var id = +$(ev.currentTarget).data('id');
            var request = _.findWhere(this.myRequests, {id: id});

            new RequestFormView({parent: this, request: request});
        },

        onRequestUpdated: function(request) {
            if (!request) {
                return;
            }
            request = this.castPublisherRequest(request);
            var index = this.myRequests.findIndex(function (r) {
                return r.id === request.id;
            });
            if (index !== -1) {
                this.myRequests.splice(index, 1, request);
                this.renderMyRequests();
            }
        },

        showDeleteConfirmationModal: function (ev) {
            this.currentId = +$(ev.currentTarget).data('id');
            this.$el.find('#requestDeleteModal').show();
        },

        hideDeleteConfirmationModal: function () {
            this.$el.find('#requestDeleteModal').hide();
        },

        showDeleteSelectedConfirmationModal: function () {
            this.$el.find('#requestDeleteMultiModal').show();
        },

        hideDeleteSelectedConfirmationModal: function () {
            this.$el.find('#requestDeleteMultiModal').hide();
        },

        deleteRequest: function(ev) {
            var self = this;
            var $btn = $(ev.currentTarget);
            var api = new ApiRequest();

            if (!this.currentId) {
                self.hideDeleteConfirmationModal();
                return;
            }

            $btn.prop('disabled', true);
            api.request('v3/publisher-requests.php', {
                action: 'delete',
                ids: [this.currentId]
            })
                .then(function (response) {
                    self.myRequests = _.filter(self.myRequests, function(request) {
                        return request.id !== +self.currentId;
                    });
                    self.hideDeleteConfirmationModal();
                    self.currentId = undefined;
                    self.renderMyRequests();
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        deleteSelectedRequests: function (ev) {
            var self = this;
            var $btn = $(ev.currentTarget);
            var api = new ApiRequest();

            if (!this.selectedIds || this.selectedIds.length === 0) {
                self.hideDeleteSelectedConfirmationModal();
                self.$mr_multiActionBtnGroup.hide();
                return;
            }

            $btn.prop('disabled', true);
            api.request('v3/publisher-requests.php', {
                action: 'delete',
                ids: this.selectedIds
            })
                .then(function (response) {
                    self.myRequests = _.filter(self.myRequests, function(request) {
                        return !self.selectedIds.includes(request.id);
                    });
                    self.renderMyRequests();
                    self.hideDeleteSelectedConfirmationModal();
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        selectMyRequest: function() {
            var selectedIds = [];
            this.$el.find('.selectMyRequest:checked').each(function(i, el) {
                selectedIds.push(+$(el).val());
            });
            this.selectedIds = selectedIds;
            if (this.selectedIds.length === this.myRequests.length) {
                this.$selectAllMyRequestsCheckbox.prop('checked', true);
            } else {
                this.$selectAllMyRequestsCheckbox.prop('checked', false);
            }
            if (this.selectedIds.length > 0) {
                this.$mr_multiActionBtnGroup.show();
            } else {
                this.$mr_multiActionBtnGroup.hide();
            }
        },

        selectAllMyRequests: function(ev) {
            var checked = ev.currentTarget.checked;
            var $checkboxes = this.$el.find('.selectMyRequest');
            var selectedIds = [];
            if (checked) {
                $checkboxes.each(function() {
                    selectedIds.push(+$(this).prop('checked', true).val());
                });
                this.$mr_multiActionBtnGroup.show();
            } else {
                $checkboxes.each(function() {
                    $(this).prop('checked', false);
                });
                this.$mr_multiActionBtnGroup.hide();
            }
            this.selectedIds = selectedIds;
        },

        showHelpModal: function() {
           new BaseModalView({
               modalType: "modal-lg",
               header: "Help",
               contentHtml: helpContentTemplate,
           });
        },
    });

    return new requestsView();
});
