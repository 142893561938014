define([
    'backbone',
    'registry',
    'views/register/monetizer',
    'views/register/advertizer',
], function (
    Backbone,
    registry,
    MonetizerRegisterView,
    AdvertizerRegisterView,
) {
    return Backbone.View.extend({
        msgTemplate: _.template('<div class="messageRow"><%= message %></div>'),

        events: {
            'click .pageBtn': 'changePage',
            'click .perPageDropdown .dropdown-item': 'changePerPage',
            'click .requestDetailsBtn': 'showDetails',
            'click .messageRegisterBtn': 'register',
        },

        initialize: function(inputs) {
            this.data = new this.Collection();
            registry[this.data.name] = this.data;
            this.parent = inputs.parent;
            this.reset();
        },

        reset: function(inputs = {}) {
            this.page = inputs.page || 1;
            this.perPage = inputs.perPage || 20;
        },

        wakeUp: function() {
            // prevent double subscribing to the events
            this.stopListening();
            this.undelegateEvents();
            // subscribe to events
            this.delegateEvents();
            $(document).on('click.baseRequests', this.documentClickHandler.bind(this));
            this.listenTo(this.parent, 'render', this.render);
            this.listenTo(this.parent, 'state:changed', this.onParentStateChanged);
            this.listenTo(this.parent, 'perPage:changed', this.updatePerPage)
            this.listenTo(this.data, 'reset', this.updateUI);
            this.listenTo(this.data, 'request', this.showLoading);
            this.listenTo(this.data, 'error', this.showLoadingError);
        },

        remove: function() {
            $(document).off('click.baseRequests');
            Backbone.View.prototype.remove.apply(this, arguments);
        },

        documentClickHandler: function(ev) {
            if (!$(ev.target).closest('.dropdown').length) {
                this.$el.find('.dropdown').removeClass('over');
            }
        },

        render: function() {
            this.$el.html(this.template);

            this.$prevBtns = this.$el.find('.pageBtn[data-id="prev"]');
            this.$nextBtns = this.$el.find('.pageBtn[data-id="next"]');
            this.$pageNumbers = this.$el.find('.paginationPageNumber');
            this.$dataContainer = this.$el.find('.listing');

            if (this.data.hasData(this.getState())) {
                this.updateUI();
            } else {
                this.refreshData();
            }
        },

        updateUI: function () {
            this.updatePagination();
            this.displayData();
            this.onShow();
        },

        updatePagination: function() {
            if (this.page <= 1) {
                this.$prevBtns.prop('disabled', true);
            } else {
                this.$prevBtns.prop('disabled', false);
            }
            if (this.data && this.data.length < this.perPage) {
                this.$nextBtns.prop('disabled', true);
            } else {
                this.$nextBtns.prop('disabled', false);
            }
            this.$pageNumbers.text('Page ' + this.page);
            this.$el.find('.perPageDropdown .dropdown-value').text(this.perPage);
        },

        displayData: function() {
            this.$dataContainer.empty();
            if (this.data && this.data.length > 0) {
                this.data.each(function (model) {
                    this.$dataContainer.append(
                        this.rowTemplate(_.extend({type: this.parent.type}, model.toJSON()))
                    );
                }, this);
            } else {
                this.showMessage('No data.');
            }
        },

        onShow: function() {
        },

        refreshData: function (parentState) {
            var params = this.getState(parentState);
            this.data.refresh({params: params});
        },

        getState: function(parentState) {
            parentState = parentState || this.parent.getState();
            return _.extend({
                page: this.page,
                perPage: this.perPage
            }, parentState);
        },

        // show some text message instead of data
        showMessage: function(message) {
            this.$dataContainer.html(this.msgTemplate({message: message}));
        },

        showLoading: function() {
            this.showMessage('Loading...');
        },

        showLoadingError: function() {
            this.showMessage('Loading error.');
        },

        changePage: function(ev) {
            var $btn = $(ev.currentTarget);
            var id = $btn.data('id');
            if (id === 'next') {
                this.page += 1;
            } else if (id === 'prev' && this.page > 1) {
                this.page -= 1;
            }
            this.updatePagination();
            this.refreshData();
            this.scrollToTop();
        },

        changePerPage: function(ev) {
            var $target = $(ev.currentTarget);
            $target.parents('.dropdown').removeClass('over');
            var value = $target.data('value');
            this.updatePerPage(value);
        },

        updatePerPage: function(value) {
            if (+value === this.perPage) { // prevents loop
                return;
            }
            this.perPage = +value;
            this.page = 1;
            this.updatePagination();
            this.refreshData();
            this.scrollToTop();
            // synchronize perPage values for advertisersRequests and publishersRequests listings
            // communicate with other parent subview through parent
            this.parent.trigger('perPage:changed', this.perPage);
        },

        onParentStateChanged: function(state) {
            this.page = 1;
            this.refreshData(state);
        },

        showDetails: function(ev) {
            var id = $(ev.currentTarget).data('id');
            var route = 'offerRequests';
            if (this.parent.type && this.parent.type === 'public') {
               route = 'offerRequests/main';
            }
            Backbone.history.navigate(route + '/' + this.routeFragment + '/' + id, {trigger: true});
        },

        scrollToTop: function() {
            $(window).scrollTop(0);
        },

        register: function(ev) {
            var $currentTarget = $(ev.currentTarget);
            var site = $currentTarget.data('site');
            var id = $currentTarget.data('id');
            if (site === 'monetizer') {
                new MonetizerRegisterView({
                    helpText: 'In order to message an advertizer you must have an account on <a href="https://www.monetizer.com/">monetizer.com</a>. Complete the form below to sign up.',
                    successHelpContent: '<p>After your application has been approved, you will be able to message selected advertiser on <a href="https://app.monetizer.com/offerRequests/advertiserRequest/'+ id +'">this</a> page.</p>',
                });
            } else {
                new AdvertizerRegisterView({
                    helpText: 'In order to message a publisher you must have an account on <a href="https://www.advertizer.com">advertizer.com</a>. Complete the form below to sign up.',
                    successHelpContent: '<p>After your application has been approved, you will be able to message selected publisher on <a href="https://app.advertizer.com/offerRequests/publisherRequest/'+ id +'">this</a> page.</p>',
                });
            }
        },
    });
});
