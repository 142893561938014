define([
        "Backbone",
        "libs/copyToClipboard",
        "templates/directLink.html",
    ], function (
        Backbone,
        copyToClipboard,
        directLinkTemplate
    ) {
        return Backbone.View.extend({
            events: {
                'click .closeBtn': 'close',
                'click .overlay': 'close',
                'click .modal': 'stopPropagation',
                'change .domainSelectInput': 'domainChanged',
                'focus .focusOnSelect': 'focusOnSelect',
                'mouseup .focusOnSelect': 'blockMouseUp',
                'click .copyBtn': 'copyDirectLink'
            },

            initialize: function (options) {
                this.setElement($('<div>', { id: 'directLinkWrapper' }));
                this.link = options.link;
                this.parent = options.parent;
                this.domains = options.domains;
            },

            render: function () {
                this.$el
                    .html(_.template(directLinkTemplate)())
                    .appendTo(this.parent.$el);

                this.$domainInput = this.$el.find('.domainSelectInput');
                _.each(this.domains, function (domain) {
                    this.$domainInput.append($('<option>', {
                        text: domain.domain,
                        value: domain.domain,
                        selected: +domain.preferred === 1
                    }));
                }, this);
                this.$directLink = this.$el.find('.directLink');

                if (this.link.tag === 'push') {
                    this.$el.find('.noPushTip').hide();
                }

                this.renderUrl();

                return this;
            },

            renderUrl: function () {
                var url = this.buildUrl();
                this.$directLink.val(url);
            },

            buildUrl: function () {
                var optionsData = this.link.options_data;
                if (typeof optionsData === 'string') {
                    optionsData = JSON.parse(optionsData);
                }
                var baseUrl = 'https://' + this.$domainInput.val() + '/';
                var map = [
                    ['utm_campaign', 'rcid'],
                    ['1', 'kw1'],
                    ['2', 'kw2'],
                    ['3', 'kw3'],
                    ['4', 'kw4'],
                    ['5', 'kw5'],
                    ['cid', 's4'],
                ];

                var pairs = ['utm_medium=' + this.link.hash];
                _.each(map, function (pair) {
                    var key = pair[0];
                    var prop = pair[1];
                    if (optionsData[prop]) {
                        var val = optionsData[prop].trim().replace(/ /g, "%20");
                        pairs.push(key + '=' + val);
                    }
                });

                var qs = pairs.join('&');

                return baseUrl + '?' + qs;
            },

            domainChanged: function (ev) {
                this.renderUrl();
            },

            focusOnSelect: function(ev) {
                $(ev.currentTarget).select();
            },

            blockMouseUp: function () {
                return false;
            },

            close: function() {
                this.trigger('close');
                this.remove();
            },

            stopPropagation: function (ev) {
                ev.stopImmediatePropagation();
            },

            copyDirectLink: function (ev) {
               copyToClipboard(this.$directLink.val());
            }
        });
    }
);
