define([
    "views/apps/tableView",
    "moment-timezone",
    "libs/copyToClipboard",
    "workers/apirequest",
    "templates/apps/links.html",
    "templates/apps/linkRow.html",
    "views/apps/linkForm",
    "views/apps/linkDetails"
], function(
    TableView,
    moment,
    copyToClipboard,
    ApiRequest,
    template,
    rowTemplate,
    LinkFormView,
    LinkDetailsView
) {
    var appsLinksView = TableView.extend({
        id: 'appsLinksContainer',
        collectionName: 'links',
        template: template,
        rowTemplate: _.template(rowTemplate),
        dataEndpointUrl: 'v3/publisherAppsLinks.php',

        events: function() {
            return _.extend({}, TableView.prototype.events, {
                'click #createLinkBtn': 'createLink',
                'click .viewBtn': 'viewLink',
                'click .editBtn': 'editLink',
                'click .deleteBtn': 'showDeleteConfirmationModal',
                'click #appLinkDeleteModal__confirmBtn': 'deleteLink',
                'click .urlCopyBtn': 'copyUrl',
            });
        },

        initialize: function() {
            TableView.prototype.initialize.apply(this, arguments);
            this.apps = null;
            this.sortBy = 'created_at';
        },

        wakeUp: function() {
            TableView.prototype.wakeUp.apply(this, arguments);
            this.on('appLink:new', this.onLinkCreated.bind(this));
            this.on('appLink:update', this.onLinkUpdated.bind(this));
        },

        onLinkCreated: function(link) {
            this.page = 1;
            this.loadData();
        },

        onLinkUpdated: function(link) {
            this.loadData();
        },

        getLoadDataParams: function () {
            return {
                action: 'getLinks',
            };
        },

        castDataRecord: function (link) {
            if (!link) {
                return link;
            }

            link.id = +link.id;
            link.createdAtFormatted = moment.tz(link.created_at, 'America/New_York').format('MMM Do YYYY');

            return link;
        },

        viewLink: function (ev) {
            const id = $(ev.currentTarget).data('id');
            const link = _.findWhere(this.links, {id});
            new LinkDetailsView({parent: this, link});
        },

        createLink: function () {
            new LinkFormView({parent: this});
        },

        editLink: function (ev) {
            const id = $(ev.currentTarget).data('id');
            const link = _.findWhere(this.links, {id});
            new LinkFormView({parent: this, link});
        },

        showDeleteConfirmationModal: function (ev) {
            this.currentId = +$(ev.currentTarget).data('id');
            this.$el.find('#appLinkDeleteModal').show();
        },

        hideDeleteConfirmationModal: function () {
            this.$el.find('#appLinkDeleteModal').hide();
        },

        deleteLink: function (ev) {
            const self = this;
            const $btn = $(ev.currentTarget);
            const api = new ApiRequest();

            if (!this.currentId) {
                self.hideDeleteConfirmationModal();
                return;
            }

            $btn.prop('disabled', true);
            api.request(this.dataEndpointUrl, {
                action: 'delete',
                id: this.currentId
            })
                .then(function (response) {
                    self.hideDeleteConfirmationModal();
                    self.currentId = undefined;
                    self.loadData();
                })
                .fail(function (error) {
                    if (error.responseJSON && error.responseJSON.message) {
                        alert(error.responseJSON.message);
                    } else {
                        alert('Server error');
                    }
                })
                .always(function () {
                    $btn.prop('disabled', false);
                });
        },

        getApps: function() {
            const deferred = $.Deferred();
            const self = this;
            const api = new ApiRequest();

            if (!this.apps) {
                api.request("v3/publisherAppsOffers.php").then(function(apps) {
                    self.apps = apps;
                    deferred.resolve(apps);
                });
            } else {
                deferred.resolve(this.apps);
            }

            return deferred.promise();
        },

        copyUrl: function (ev) {
            const url = $(ev.currentTarget).data('url');
            copyToClipboard(url);
        },
    });

    return new appsLinksView();
});
