define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "moment-timezone",
    'libs/Chart.min',
    "dict/isocountries.json",
    "templates/pushStats.html",
    "templates/pushStatsLeaderboardRow.html",
    "templates/pushStatsGeoSubs.html",
    "templates/pushStatsBreakdownRow.html",
    "templates/geoPushStatsBreakdownRow.html",
    "templates/tagPushStatsBreakdownRow.html",
    "templates/pushStatsGeoCpcRow.html",
    "templates/tagGeoPushStatsBreakdownRow.html"
], function(Backbone, registry, ApiRequest, moment, Chart, isoCountries, mainTemplate, leaderboardRowTemplate, geoSubsRowTemplate, breakdownRowTemplate , geoBreakdownRowTemplate, tagBreakdownRowTemplate, geoCpcRowTemplate, tagGeoBreakdownRowTemplate) {
    var pushStatsView = Backbone.View.extend({
        events: {
            "click .prevMonthButton": "_updateBreakdownFilter",
            "click .nextMonthButton": "_updateBreakdownFilter",
            "click .graphPlotBtn": "_updateGraphPlot",
            "click .reportTypeBtn": "_selectReportType",
            "click .geoPrevMonthButton": "_updateGeoBreakdownFilter",
            "click .geoNextMonthButton": "_updateGeoBreakdownFilter",
            "click .geoGraphPlotBtn": "_updateGeoGraphPlot",
            "click .geoGraphPlotInput": "_updateGeoGraphPlot",
            "click .geoSortCol": "_sortGeoBreakdown",
            "click .tagPrevMonthButton": "_updateTagBreakdownFilter",
            "click .tagNextMonthButton": "_updateTagBreakdownFilter",
            "click .tagGraphPlotBtn": "_updateTagGraphPlot",
            "click .tagGraphPlotInput": "_updateTagGraphPlot",
            "click .tagSortCol": "_sortTagBreakdown",
            "click .setCooldownBtn": "_setCooldown",
            "click .tagGeoPrevMonthButton": "_updateTagGeoBreakdownFilter",
            "click .tagGeoNextMonthButton": "_updateTagGeoBreakdownFilter",
            "click .tagGeoGraphPlotBtn": "_updateTagGeoGraphPlot",
            "click .tagGeoGraphPlotInput": "_updateTagGeoChartFilter",
            "click .tagGeoPageBtn": "_tagGeoChangePage",
            "click .tagGeoSortCol": "_sortTagGeoBreakdown",
            "click #pstg_filterBtn": "tagGeoFilterChanged",
            "click #pstg_resetFiltersBtn": "resetFilters",
        },

        month: moment(),
        geoSortReverse: true,
        geoSort: 'rev',
        tagSortReverse: true,
        tagSort: 'rev',
        tagGeoSortReverse: true,
        tagGeoPage: 1,
        tagGeoRowsPerPage: 25,
        tagGeoSort: 'rev',
        DATE_FORMAT: "ddd, MMMM Do YYYY",
        chartKey: 'subs',
        PLOT_PALLETE: [
            "#3366CC",
            "#DC3912",
            "#FF9900",
            "#109618",
            "#990099",
            "#3B3EAC",
            "#0099C6",
            "#DD4477",
            "#66AA00",
            "#B82E2E",
            "#316395",
            "#994499",
            "#22AA99",
            "#AAAA11",
            "#6633CC",
            "#E67300",
            "#8B0707",
            "#329262",
            "#5574A6",
            "#3B3EAC"
        ],

        initialize: function () {
            this.countryByCode = _.groupBy(isoCountries, 'alpha_2_code');
            this.countryByCode[''] = {
                'alpha_2_code': '',
                'country': 'not set'
            };
        },

        addDay: function (date) {
            var parts = date.split('-');
            var nextDay = (++parts[2]).toString();
            parts[2] = nextDay.length === 2 ? nextDay : '0' + nextDay;
            return parts.join('-');
        },


        render: function() {
            var self = this,
                api = new ApiRequest();

            this.template = _.template(mainTemplate);
            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("pushStatsPane")
                .addClass("mainPane")
                .attr("id", "pushStatsContainer");

            this.$el.append(this.template);
            $("#appContainer").append(this.$el);

            this.$activeSubs = this.$el.find('.activeSubs');
            this.$totalSubs = this.$el.find('.totalSubs');
            this.$totalPushes = this.$el.find('.totalPushes');
            this.$totalRevenue = this.$el.find('.totalRevenue');
            this.$todaySubs = this.$el.find('.todaySubs');
            this.$todayPushes = this.$el.find('.todayPushes');
            this.$todayRevenue = this.$el.find('.todayRevenue');
            this.$monthBreakdownPanel = this.$el.find('.breakdownPanel');
            this.$monthBreakdownLabel = this.$el.find('.monthBreakdownLabel');
            this.$monthBreakdownTableBody = this.$el.find('.monthBreakdownTable > tbody');
            this.$geoSubsTableBody = this.$el.find('.geoSubsTable > tbody');
            this.$geoCpcTableBody = this.$el.find('.geoCpcTable > tbody');
            this.$leaderboardTableBody = this.$el.find('.leaderboardTable > tbody');
            this.$monthEarningsContainer = this.$el.find('.monthEarningsContainer');

            this.$reportSummary = this.$el.find('.reportSummary');
            this.$reportGeos = this.$el.find('.reportGeos');
            this.$reportTags = this.$el.find('.reportTags');
            this.$reportTagGeos = this.$el.find('.reportTagGeos');

            this.$geoMonthBreakdownPanel = this.$el.find('.geoBreakdownPanel');
            this.$geoMonthBreakdownLabel = this.$el.find('.geoMonthBreakdownLabel');
            this.$geoMonthBreakdownTableBody = this.$el.find('.geoMonthBreakdownTable > tbody');

            this.$tagMonthBreakdownPanel = this.$el.find('.tagBreakdownPanel');
            this.$tagMonthBreakdownLabel = this.$el.find('.tagMonthBreakdownLabel');
            this.$tagMonthBreakdownTableBody = this.$el.find('.tagMonthBreakdownTable > tbody');

            this.$tagGeoMonthBreakdownPanel = this.$el.find('.tagGeoBreakdownPanel');
            this.$tagGeoMonthBreakdownLabel = this.$el.find('.tagGeoMonthBreakdownLabel');
            this.$tagGeoMonthBreakdownTableBody = this.$el.find('.tagGeoMonthBreakdownTable > tbody');

            this.$delayInput = this.$el.find('.delayInput');

            $.when(api.request("v3/publisherPushCooldown.php"))
                .then(function (cooldown) {
                    self.$delayInput.val(cooldown.first_push_delay);
                });

            this._selectReportType();

        },

        _selectReportType: function(ev) {
            var reportType = 'summary';

            if(ev) {
                reportType = $(ev.currentTarget).data('type');
                this.$el.find('.reportTypeBtn').removeClass('active');
                $(ev.currentTarget).addClass('active');
            }

            switch(reportType) {
                case 'summary':
                    this._updateSummaryGraphPlotButtons();
                    this._getSummaryData();
                    this._getBreakdownData();
                    this._getGeoSubsData();
                    this._getLeaderboardData();
                    this.$reportSummary.show();
                    this.$reportGeos.hide();
                    this.$reportTags.hide();
                    this.$reportTagGeos.hide();
                    break;

                case 'geos':
                    this._updateGeoGraphPlotButtons();
                    this.$reportSummary.hide();
                    this.$reportGeos.show();
                    this.$reportTags.hide();
                    this.$reportTagGeos.hide();
                    this._getGeoBreakdownData();

                    break;

                case 'tags':
                    this._updateTagGraphPlotButtons();
                    this.$reportSummary.hide();
                    this.$reportGeos.hide();
                    this.$reportTags.show();
                    this.$reportTagGeos.hide();
                    this._getTagBreakdownData();

                    break;

                case 'taggeo':
                    this._updateTagGraphPlotButtons();
                    this.$reportSummary.hide();
                    this.$reportGeos.hide();
                    this.$reportTags.hide();
                    this.$reportTagGeos.show();
                    this._getTagGeoBreakdownData();
                    break;

            }

        },

        _updateSummaryGraphPlotButtons: function() {
            this.$el.find('.graphPlotBtn').removeClass('active');
            this.$el.find('.graphPlotBtn[data-type="' + this.chartKey + '"]').addClass('active');
        },

        _updateGeoGraphPlotButtons: function() {
            this.$el.find('.geoGraphPlotBtn').removeClass('active');
            this.$el.find('.geoGraphPlotBtn[data-type="' + this.chartKey + '"]').addClass('active');
        },

        _updateTagGraphPlotButtons: function() {
            this.$el.find('.tagGraphPlotBtn').removeClass('active');
            this.$el.find('.tagGraphPlotBtn[data-type="' + this.chartKey + '"]').addClass('active');
        },

        _getSummaryData: function () {
            var self = this,
                requestSubs = new ApiRequest(),
                requestPush = new ApiRequest(),
                requestRev = new ApiRequest();

            $.when(requestSubs.request("v3/pushPubActiveSubscriptionsSnapshot.php?summary=true"))
                .then(function (activeResults) {
                    self.$activeSubs.text(self.nFormatter(parseInt(activeResults.subscriptions),1));
                });

            $.when(requestSubs.request("v2/PublisherPushSubs?a=summary"))
                .then(function (subResults) {
                    self.$todaySubs.text(parseInt(subResults.today).toLocaleString('en-US'));
                    self.$totalSubs.text(self.nFormatter(parseInt(subResults.total),1));
                });

            $.when(requestPush.request("v2/PublisherPush?a=summary"))
                .then(function (pushResults) {
                    self.$todayPushes.text(parseFloat(pushResults.today).toLocaleString('en-US'));
                    self.$totalPushes.text(self.nFormatter(parseInt(pushResults.total),1));
                });

            $.when(requestRev.request("v2/PublisherPushRevenue?a=summary"))
                .then(function (revResults) {
                    if(revResults.today === null) {
                        revResults.today = 0;
                    }
                    if(revResults.total === null) {
                        revResults.total = 0;
                    }
                    if(revResults.month === null || typeof revResults.month === 'undefined') {
                        revResults.month = 0;
                    }

                    self.$todayRevenue.text(parseFloat(revResults.today).toLocaleString('en-US',{style:'currency',currency:'USD'}));
                    self.$totalRevenue.text('$'+self.nFormatter(parseFloat(revResults.total),1));
                    self.$monthEarningsContainer.find('span').text(parseFloat(revResults.month).toLocaleString('en-US',{style:'currency',currency:'USD'}));

                });

        },


        _getBreakdownData: function () {

            var self = this,
                startMoment = this.month.clone(),
                endMoment = this.month.clone(),
                nowMoment = moment().tz("America/New_York");

            startMoment.tz("America/New_York").startOf("month");
            endMoment.tz("America/New_York").endOf("month");

            startMoment.format('X');
            var from = startMoment.format('YYYY-MM-DD');

            var to;

            if (startMoment.isSame(nowMoment, "month"))  {
                to = nowMoment.format("YYYY-MM-DD");
            }
            else {
                endMoment.endOf("month");
                to = endMoment.format('YYYY-MM-DD');
            }


            var apiData = {
                    a: 'dateRange',
                    from: from,
                    to: to
                };

            var requestSubs = new ApiRequest(),
                requestPush = new ApiRequest(),
                requestRev = new ApiRequest();


            this.$monthBreakdownPanel.addClass('panel-disabled');
            this.$monthBreakdownLabel.text('Loading...');

            $.when(
                requestSubs.request("v2/PublisherPushSubs?"+$.param(apiData)),
                requestPush.request("v2/PublisherPush?"+$.param(apiData)),
                requestRev.request("v2/PublisherPushRevenue?"+$.param(apiData)),
                requestRev.request("v3/pushPubActiveSubscriptionsSnapshot.php?"+$.param(apiData))
            )
                .then(function (responseSubs, responsePush, responseRev, responseActive) {
                    var resultsSubs = responseSubs[0],
                        resultsPush = responsePush[0],
                        resultsRev = responseRev[0],
                        resultsActive = responseActive[0];


                    if(typeof previousObj !== 'undefined') {
                        delete previousObj;

                    }

                    self.breakdownData = [];
                    while(from <= to) {


                        var date = from,
                            subsRow = _.findWhere(resultsSubs,{date:date}),
                            pushRow = _.findWhere(resultsPush,{date:date}),
                            revRow = _.findWhere(resultsRev,{date:date}),
                            activeRow = _.findWhere(resultsActive,{date:date}),
                            obj = {
                                'date': startMoment.clone().format(self.DATE_FORMAT),
                                'ts': startMoment.clone().format('MMM Do'),
                                'subs': 0,
                                'pushes': 0,
                                'clicks': 0,
                                'rev': 0,
                                'ltvNew': 0,
                                'active': 0,
                                'pushesFormatted': 0,
                                'ctr': 0,
                                'cpm': 0,
                                'growth': 0

                            };



                        if(typeof subsRow !== 'undefined') {
                            obj.subs = parseInt(subsRow.subscriptions);
                        }
                        if(typeof pushRow !== 'undefined') {
                            obj.pushes = parseInt(pushRow.pushes);
                            obj.clicks = parseInt(pushRow.clicks);
                            if (obj.pushes > 0) {
                                obj.ctr = obj.clicks / obj.pushes * 100;
                            }
                        }
                        if(typeof revRow !== 'undefined') {
                            obj.rev = parseFloat(revRow.revenue);
                            if(obj.pushes > 0) {
                                obj.cpm = obj.rev / obj.pushes * 1000;
                            }
                        }
                        if(typeof activeRow !== 'undefined') {
                            obj.active =  parseInt(activeRow.subscriptions);
                            if (obj.active > 0) {
                                obj.ltvNew = obj.rev / obj.active;
                            }
                        }
                        obj.pushesFormatted = self.nFormatter(obj.pushes, 2);

                        if(typeof previousObj !== 'undefined') {
                            obj.growth = self._calculateGrowth(previousObj.active, obj.active);
                        }

                        previousObj = obj;

                        self.breakdownData.push(obj);

                        startMoment.add(1,'day');
                        from = startMoment.format('YYYY-MM-DD');

                    }

                    // hack for DST
                    self.breakdownData = _.uniq(self.breakdownData,'date');

                    self._displayBreakdownData();
                });

        },

        _displayBreakdownData: function() {
            var self = this,
                rowTemplate = _.template(breakdownRowTemplate),
                tableData = _.clone(this.breakdownData);

            tableData.reverse();

            this.$monthBreakdownLabel.text(this.month.format('MMMM YYYY'));
            self.$monthBreakdownTableBody.empty();
            _.each(tableData, function(row) {
                self.$monthBreakdownTableBody.append(rowTemplate(row));
            });

            if (this.pushStatsGraph) {
                this.pushStatsGraph.destroy();
            }
            this.$monthBreakdownPanel.removeClass('panel-disabled');
            this.pushStatsGraph = this._plotBreakdownChart();

        },


        _updateBreakdownFilter: function(ev) {
            var $btn = $(ev.currentTarget);

            if($btn.hasClass('prevMonthButton')) {
                this.month.subtract('1', 'month');

            } else if ($btn.hasClass('nextMonthButton')) {
                this.month.add('1', 'month');

            }

            this._getBreakdownData();
        },

        _plotBreakdownChart: function () {

            var self = this,
                label = null,
                labelData = [],
                chartData = [];

            _.each(this.breakdownData, function(row) {
                labelData.push(row.ts);
                chartData.push(row[self.chartKey]);
            });

            label = this._getChartLabelByChartKey(this.chartKey);

            var datasets = [
                {
                    label: label,
                    data: chartData,
                    lineTension: 0,
                    borderWidth: 3,
                    backgroundColor: 'transparent',
                    borderColor: '#34a475',
                    pointRadius: 0,
                    pointHitRadius: 10
                }
            ];

            return this._displayChart(this.$el.find('#pushChart'), labelData, datasets);
        },

        _displayChart: function ($el, labelData, datasets) {
            var self = this;
            return new Chart($el, {
                type: 'line',
                data: {
                    labels: labelData,
                    datasets: datasets
                },
                options: {
                    tooltips: {
                        callbacks: {
                            label: function(tooltipItems) {
                                switch(self.chartKey) {
                                    case 'subs':
                                    case 'pushes':
                                    case 'clicks':
                                    case 'active':
                                        return parseInt(tooltipItems.yLabel).toLocaleString('en-US');
                                        break;
                                    case 'rev':
                                        return parseFloat(tooltipItems.yLabel).toLocaleString('en-US',{style:'currency','currency':'USD'});
                                        break;
                                    case 'ltvNew':
                                        return '$'+parseFloat(tooltipItems.yLabel).toFixed(4);
                                        break;
                                    case 'growth':
                                        return parseFloat(tooltipItems.yLabel).toFixed(2)+'%';
                                        break;
                                }
                            }
                        }
                    },
                    scales: {
                        xAxes: [{
                            position: 'bottom'
                        }],
                        yAxes: [{
                            ticks: {
                                callback: function (value) {
                                    switch(self.chartKey) {
                                        case 'subs':
                                        case 'pushes':
                                        case 'clicks':
                                        case 'active':
                                            return parseInt(value).toLocaleString('en-US');
                                            break;
                                        case 'rev':
                                            return parseFloat(value).toLocaleString('en-US',{style:'currency','currency':'USD'});
                                            break;
                                        case 'ltvNew':
                                            return '$'+parseFloat(value).toFixed(4);
                                            break;
                                        case 'growth':
                                            return parseFloat(value).toFixed(2)+'%';
                                            break;
                                    }
                                }
                            }
                        }]
                    }
                }
            });
        },

        _updateGraphPlot: function(ev) {
            var $btn = $(ev.currentTarget);

            this.$el.find('.graphPlotBtn').removeClass('active');
            this.chartKey = $btn.data('type');
            $btn.addClass('active');
            if (this.pushStatsGraph) {
                this.pushStatsGraph.destroy();
            }

            this.pushStatsGraph = this._plotBreakdownChart();

        },

        _getGeoSubsData: function () {
            var self = this,
                request = new ApiRequest();

            $.when(
                request.request("v2/PublisherPushRevenue?a=subsByGeo"),
                request.request("v3/publisherPushGeoAverageCpc.php"),
                request.request("v2/PublisherPushRevenue?a=earningsByGeo"),
                request.request("v3/publisherPushGeoAverageLtv.php")
        )
                .then(function (subsResponse, averagesResponse, earningsResponse, ltvResponse) {
                    var results = subsResponse[0],
                        averages = averagesResponse[0],
                        earnings = earningsResponse[0],
                        ltv = ltvResponse[0];

                    results = _.map(results, function(row) {
                        row.total = parseInt(row.total);
                        return row;
                    });

                    self.geoSubsData = _.sortBy(results,'total');
                    self.geoSubsData.reverse();
                    self.geoSubsAveragesData = averages;
                    self.geoSubsEarningsData = earnings;
                    self.geoSubsLtvData = ltv;


                    self._displayGeoSubsData();
                });

        },

        _displayGeoSubsData: function() {
            var self = this,
                rowTemplate = _.template(geoSubsRowTemplate),
                geoRowTemplate = _.template(geoCpcRowTemplate),
                geoCpcData = _.clone(this.geoSubsAveragesData);

            this.$geoSubsTableBody.empty();
            this.$geoCpcTableBody.empty();

            _.each(self.geoSubsData, function(row) {
                row.flagPath = require('./../../images/flags/'+row.geo+'.png').default;

                self.$geoSubsTableBody.append(rowTemplate(row));
            });

            geoCpcData = _.sortBy(geoCpcData,'cpc');
            geoCpcData.reverse();
            _.each(geoCpcData, function(row) {
                var ltvRow = _.findWhere(self.geoSubsLtvData,{geo: row.geo});


                row.ltv = 0;
                if(ltvRow) {
                    row.ltv = parseFloat(ltvRow.ltv);
                }

                row.flagPath = require('./../../images/flags/'+row.geo+'.png').default;

                self.$geoCpcTableBody.append(geoRowTemplate(row));

            });

        },

        _getLeaderboardData: function () {
            var self = this,
                request = new ApiRequest();

            $.when(request.request("v2/PublisherPushRevenue?a=leaderboard"))
                .then(function (response) {
                    self.leaderboardData = response;

                    self._displayLeaderboardData();
                });

        },

        _displayLeaderboardData: function() {
            var self = this,
                rowTemplate = _.template(leaderboardRowTemplate);

            this.$leaderboardTableBody.empty();

            _.each(this.leaderboardData, function(row) {
                row.nick_img = row.nick_img.replace('http:','https:');
                self.$leaderboardTableBody.append(rowTemplate(row));
            });

        },

        _getGeoBreakdownData: function () {
            var self = this,
                api = new ApiRequest(),
                startMoment = this.month.clone().tz("America/New_York").startOf("month"),
                endMoment = this.month.clone().tz("America/New_York").endOf("month"),
                nowMoment = moment().tz("America/New_York");

            this.geoBreakdownData = {};
            this.geoBreakdownTotalData = {};
            this.geoChartFilter = [];

            var to, from = startMoment.format('YYYY-MM-DD');
            if (startMoment.isSame(nowMoment, "month"))  {
                to = nowMoment.format("YYYY-MM-DD");
            } else {
                to = endMoment.format('YYYY-MM-DD');
            }

            this.$geoMonthBreakdownPanel.addClass('panel-disabled');
            this.$geoMonthBreakdownLabel.text('Loading...');
            api.request("v3/publisherPushStats.php?groupBy=geo&from="+from+"&to="+to)
                .then(function (response) {
                    _.each(response, self.addRowToGeoBreakdownData.bind(self));
                    self._processGeoBreakdownData(from, to);
                    self._displayGeoBreakdownData();
                });
        },

        addRowToGeoBreakdownData: function (row) {
            var geo = row.geo;
            var date = row.date;
            var flagGeo = geo ? geo : 'INTL';
            var countryName = this.countryByCode[geo] ? this.countryByCode[geo].country : 'unknown country';

            if ('undefined' === typeof this.geoBreakdownData[geo]) {
                this.geoBreakdownData[geo] = {
                    geo: geo,
                    name: countryName,
                    flagPath: require('./../../images/flags/'+flagGeo+'.png').default,
                    subs: 0,
                    impressions: 0,
                    pushes: 0,
                    clicks: 0,
                    rev: 0,
                    active: 0,
                    ltvNew: 0,
                    ltvSubsNew: 0,
                    ltvRevNew: 0,
                    statsByDate: {},
                    ir: 0,
                    ctr: 0,
                    cpm: 0,
                    cpc: 0,
                    growth: 0
                };
            }

            var obj = this.geoBreakdownData[geo];
            this._addRowToBreakdownData(obj, row);

            if ('undefined' === typeof this.geoBreakdownTotalData[date]) {
                this.geoBreakdownTotalData[date] = this._getDefaultDateSummaryRecord(date);
            }

            var dateSummary = this.geoBreakdownTotalData[date];
            this._addRowToTotalBreakdownData(dateSummary, row);

        },

        _addRowToTotalBreakdownData: function (dateSummary, row) {
            dateSummary.subs += parseInt(row.subscriptions);
            dateSummary.clicks += parseInt(row.clicks);
            dateSummary.pushes += parseInt(row.pushes);
            dateSummary.rev += parseFloat(row.revenue);
            dateSummary.active += parseInt(row.active_subscriptions);
            dateSummary.ltvSubsNew += parseInt(row.ltv_count);
            dateSummary.ltvRevNew += parseFloat(row.ltv_revenue);
            if (dateSummary.ltvSubsNew > 0) {
                dateSummary.ltvNew = dateSummary.ltvRevNew / dateSummary.ltvSubsNew;
            }
        },

        _addRowToBreakdownData: function(obj, row) {
            var date = row.date;

            obj.subs += parseInt(row.subscriptions);
            obj.impressions += parseInt(row.impressions);
            obj.pushes += parseInt(row.pushes);
            obj.clicks += parseInt(row.clicks);
            obj.rev += parseFloat(row.revenue);
            obj.active += parseInt(row.active_subscriptions);
            obj.ltvSubsNew += parseInt(row.ltv_count);
            obj.ltvRevNew += parseFloat(row.ltv_revenue);
            if (obj.ltvSubsNew > 0) {
                obj.ltvNew = obj.ltvRevNew / obj.ltvSubsNew;
            }
            if(obj.pushes > 0) {
                obj.ir = 100 * obj.impressions / obj.pushes;
                obj.ctr = 100 * obj.clicks / obj.pushes;
                obj.cpm = 1000 * parseFloat(obj.rev) / parseInt(obj.pushes);
            }
            if (obj.clicks > 0) {
                obj.cpc = obj.rev / obj.clicks;
            }

            if ('undefined' === typeof obj.statsByDate[date]) {
                obj.statsByDate[date] = this._getDefaultDateRecord(date);
            }

            obj.statsByDate[date].subs = parseInt(row.subscriptions);
            obj.statsByDate[date].impressions = parseInt(row.impressions);
            obj.statsByDate[date].pushes = parseInt(row.pushes);
            obj.statsByDate[date].clicks = parseInt(row.clicks);
            obj.statsByDate[date].rev = parseFloat(row.revenue);
            obj.statsByDate[date].active = parseInt(row.active_subscriptions);
            obj.statsByDate[date].ltvNew = parseFloat(row.ltv);
        },

        _getDefaultDateRecord: function (date) {
            return {
                'date': date,
                'subs': 0,
                'pushes': 0,
                'clicks': 0,
                'rev': 0,
                'active': 0,
                'ltvNew': 0,
                'growth': 0
            };
        },

        _getDefaultDateSummaryRecord: function (date) {
            return {
                'date': date,
                'subs': 0,
                'pushes': 0,
                'clicks': 0,
                'rev': 0,
                'active': 0,
                'ltvNew': 0,
                'ltvSubsNew': 0,
                'ltvRevNew': 0,
                'growth': 0
            };
        },

        _processGeoBreakdownData: function (from, to) {
            var self = this;
            var dates = this._getDates(from, to);

            var prev = null;
            _.each(dates, function (date) {
                if ('undefined' === typeof self.geoBreakdownTotalData[date]) {
                    self.geoBreakdownTotalData[date] = self._getDefaultDateSummaryRecord(date);
                }
                var curr = self.geoBreakdownTotalData[date].active;
                if (prev !== null) {
                    self.geoBreakdownTotalData[date].growth = self._calculateGrowth(prev, curr);
                }
                prev = curr;
            });

            _.each(self.geoBreakdownData, function (stats, geo) {
                stats.pushesFormatted = self.nFormatter(stats.pushes, 2);
                stats.data = [];
                var prev = null;
                _.each(dates, function (date) {
                    var record = stats.statsByDate[date] || self._getDefaultDateRecord(date);
                    var curr = record.active;
                    if (prev !== null) {
                        record.growth = self._calculateGrowth(prev, curr);
                    }
                    prev = curr;
                    stats.data.push(record);
                });

                stats.growth = self._calculateGrowth(stats.data[0].active, stats.data[stats.data.length - 1].active);
            });
        },

        _getDates: function (from, to) {
            var result = [];
            var curr = from;

            while (curr <= to) {
               result.push(curr);
               curr = this.addDay(curr);
            }
            return result;
        },

        _calculateGrowth: function (prev, curr) {
            if (prev === 0) {
                return curr === 0 ? 0 : 100;
            } else {
                return 100 * (curr - prev) / prev;
            }
        },

        _displayGeoBreakdownData: function() {
            var self = this,
                rowTemplate = _.template(geoBreakdownRowTemplate),
                tableData = _.clone(this.geoBreakdownData);

            tableData = _.sortBy(tableData,this.geoSort);
            if(this.geoSortReverse) {
                tableData.reverse();
            }

            this.$geoMonthBreakdownLabel.text(this.month.format('MMMM YYYY'));
            self.$geoMonthBreakdownTableBody.empty();
            _.each(tableData, function(row) {
                self.$geoMonthBreakdownTableBody.append(rowTemplate(row));
            });


            if (this.geoPushStatsGraph) {
                this.geoPushStatsGraph.destroy();
            }
            this.$geoMonthBreakdownPanel.removeClass('panel-disabled');
            this.geoPushStatsGraph = this._plotGeoBreakdownChart();

        },

        _updateGeoGraphPlot: function(ev) {
            var $btn = $(ev.currentTarget);

            if($btn.hasClass('geoGraphPlotInput')) {
                // select/deselect geo

                if($btn.is(':checked')) {
                    this.geoChartFilter.push($btn.data('geo'));
                } else {
                    this.geoChartFilter = _.without(this.geoChartFilter,$btn.data('geo'));
                }

                if(this.geoChartFilter.length === 20) {
                    this.$el.find('.geoGraphPlotInput:not(:checked)').attr('disabled','disabled');

                } else {
                    this.$el.find('.geoGraphPlotInput').removeAttr('disabled');

                }


            } else {
                // switching analytic
                this.$el.find('.geoGraphPlotBtn').removeClass('active');
                this.chartKey = $btn.data('type');
                $btn.addClass('active');

            }


            if (this.geoPushStatsGraph) {
                this.geoPushStatsGraph.destroy();
            }


            this.geoPushStatsGraph = this._plotGeoBreakdownChart();

        },

        _updateGeoBreakdownFilter: function(ev) {
            var $btn = $(ev.currentTarget);

            if($btn.hasClass('geoPrevMonthButton')) {
                this.month.subtract('1', 'month');

            } else if ($btn.hasClass('geoNextMonthButton')) {
                this.month.add('1', 'month');

            }

            this._getGeoBreakdownData();
        },

        _sortGeoBreakdown: function(ev) {
            var $btn = $(ev.currentTarget);

            this.$el.find('.geoSortCol').removeClass('active');

            $btn.addClass('active');

            if(this.geoSort === $btn.data('field')) {
                this.geoSortReverse = !this.geoSortReverse;
            } else {
                this.geoSort = $btn.data('field');
                this.geoSortReverse = true;

            }
            this._displayGeoBreakdownData();

        },

        _plotGeoBreakdownChart: function () {

            var self = this,
                label = null,
                labelData = [],
                chartData = [],
                datasets = [];


            if(this.geoChartFilter.length === 0) {
                _.each(_.sortBy(this.geoBreakdownTotalData, 'date'), function(row) {
                    labelData.push(moment.tz(row.date, 'America/New_York').format('MMM Do'));
                    chartData.push(row[self.chartKey]);
                });

                label = this._getChartLabelByChartKey(this.chartKey);

                datasets.push(
                    {
                        label: label,
                        data: chartData,
                        lineTension: 0,
                        borderWidth: 3,
                        backgroundColor: 'transparent',
                        borderColor: '#34a475',
                        pointRadius: 0,
                        pointHitRadius: 10
                    }
                );
            } else {
                _.each(this.geoChartFilter, function(geo, k) {
                    var geoData = _.findWhere(self.geoBreakdownData,{geo:geo}),
                        chartData = [];




                    _.each(geoData.data, function(row) {

                        if(datasets.length === 0) {
                            labelData.push(moment.tz(row.date, 'America/New_York').format('MMM Do'));
                        }

                        chartData.push(row[self.chartKey]);
                    });

                    datasets.push(
                        {
                            label: geo,
                            data: chartData,
                            lineTension: 0,
                            borderWidth: 3,
                            backgroundColor: 'transparent',
                            borderColor: self.PLOT_PALLETE[k],
                            pointRadius: 0,
                            pointHitRadius: 10
                        }
                    );



                });

            }

            return this._displayChart(this.$el.find('#geoPushChart'), labelData, datasets);
        },

        _getChartLabelByChartKey: function(chartKey) {
            var label = '';

            switch(chartKey) {
                case 'subs':
                    label = 'Subscriptions';
                    break;
                case 'pushes':
                    label = 'Pushes';
                    break;
                case 'clicks':
                    label = 'Clicks';
                    break;
                case 'rev':
                    label = 'Revenue';
                    break;
                case 'ltvNew':
                    label = 'Lifetime Value';
                    break;
                case 'growth':
                    label = 'Growth';
                    break;
                case 'active':
                    label = 'Active Subscriptions';
                    break;
            }

            return label;
        },

        _getTagBreakdownData: function () {
            var self = this,
                api = new ApiRequest(),
                startMoment = this.month.clone().tz("America/New_York").startOf("month"),
                endMoment = this.month.clone().tz("America/New_York").endOf("month"),
                nowMoment = moment().tz("America/New_York");

            this.tagBreakdownData = {};
            this.tagBreakdownTotalData = {};
            this.tagChartFilter = [];

            var to, from = startMoment.format('YYYY-MM-DD');
            if (startMoment.isSame(nowMoment, "month"))  {
                to = nowMoment.format("YYYY-MM-DD");
            } else {
                to = endMoment.format('YYYY-MM-DD');
            }

            this.$tagMonthBreakdownPanel.addClass('panel-disabled');
            this.$tagMonthBreakdownLabel.text('Loading...');
            api.request("v3/publisherPushStats.php?groupBy=tag&from="+from+"&to="+to)
                .then(function (response) {
                    _.each(response, self._addRowToTagBreakdownData.bind(self));
                    self._processTagBreakdownData(from, to);
                    self._displayTagBreakdownData();
                });
        },

        _addRowToTagBreakdownData: function (row) {
            var tag = row.tag;
            var date = row.date;
            var name = tag === '' ? 'not set' : tag;

            if ('undefined' === typeof this.tagBreakdownData[tag]) {
                this.tagBreakdownData[tag] = {
                    tag: tag,
                    name: name,
                    subs: 0,
                    impressions: 0,
                    pushes: 0,
                    clicks: 0,
                    rev: 0,
                    active: 0,
                    ltvNew: 0,
                    ltvSubsNew: 0,
                    ltvRevNew: 0,
                    statsByDate: {},
                    ir: 0,
                    ctr: 0,
                    cpm: 0,
                    cpc: 0,
                    growth: 0
                }
            }

            var obj = this.tagBreakdownData[tag];
            this._addRowToBreakdownData(obj, row);

            if ('undefined' === typeof this.tagBreakdownTotalData[date]) {
                this.tagBreakdownTotalData[date] = this._getDefaultDateSummaryRecord(date);
            }

            var dateSummary = this.tagBreakdownTotalData[date];
            this._addRowToTotalBreakdownData(dateSummary, row);
        },

        _processTagBreakdownData: function (from, to) {
            var self = this;
            var dates = this._getDates(from, to);

            var prev = null;
            _.each(dates, function (date) {
                if ('undefined' === typeof self.tagBreakdownTotalData[date]) {
                    self.tagBreakdownTotalData[date] = self._getDefaultDateSummaryRecord(date);
                }
                var curr = self.tagBreakdownTotalData[date].active;
                if (prev !== null) {
                    self.tagBreakdownTotalData[date].growth = self._calculateGrowth(prev, curr);
                }
                prev = curr;
            });

            _.each(self.tagBreakdownData, function (stats, tag) {
                stats.pushesFormatted = self.nFormatter(stats.pushes, 2);
                stats.data = [];
                var prev = null;
                _.each(dates, function (date) {
                    var record = stats.statsByDate[date] || self._getDefaultDateRecord(date);
                    var curr = record.active;
                    if (prev !== null) {
                        record.growth = self._calculateGrowth(prev, curr);
                    }
                    prev = curr;
                    stats.data.push(record);
                });

                stats.growth = self._calculateGrowth(stats.data[0].active, stats.data[stats.data.length - 1].active);
            });
        },

        _displayTagBreakdownData: function() {
            var self = this,
                rowTemplate = _.template(tagBreakdownRowTemplate),
                tableData = _.clone(this.tagBreakdownData);

            tableData = _.sortBy(tableData,this.tagSort);
            if(this.tagSortReverse) {
                tableData.reverse();
            }

            this.$tagMonthBreakdownLabel.text(this.month.format('MMMM YYYY'));
            self.$tagMonthBreakdownTableBody.empty();
            _.each(tableData, function(row) {
                self.$tagMonthBreakdownTableBody.append(rowTemplate(row));
            });


            if (this.tagPushStatsGraph) {
                this.tagPushStatsGraph.destroy();
            }
            this.$tagMonthBreakdownPanel.removeClass('panel-disabled');
            this.tagPushStatsGraph = this._plotTagBreakdownChart();

        },

        _updateTagGraphPlot: function(ev) {
            var $btn = $(ev.currentTarget);

            if($btn.hasClass('tagGraphPlotInput')) {
                // select/deselect tag

                if($btn.is(':checked')) {
                    this.tagChartFilter.push($btn.data('tag'));
                } else {
                    this.tagChartFilter = _.without(this.tagChartFilter,$btn.data('tag'));
                }

                if(this.tagChartFilter.length === 20) {
                    this.$el.find('.tagGraphPlotInput:not(:checked)').attr('disabled','disabled');

                } else {
                    this.$el.find('.tagGraphPlotInput').removeAttr('disabled');

                }


            } else {
                // switching analytic
                this.$el.find('.tagGraphPlotBtn').removeClass('active');
                this.chartKey = $btn.data('type');
                $btn.addClass('active');

            }


            if (this.tagPushStatsGraph) {
                this.tagPushStatsGraph.destroy();
            }


            this.tagPushStatsGraph = this._plotTagBreakdownChart();

        },

        _updateTagBreakdownFilter: function(ev) {
            var $btn = $(ev.currentTarget);

            if($btn.hasClass('tagPrevMonthButton')) {
                this.month.subtract('1', 'month');

            } else if ($btn.hasClass('tagNextMonthButton')) {
                this.month.add('1', 'month');

            }

            this._getTagBreakdownData();
        },

        _sortTagBreakdown: function(ev) {
            var $btn = $(ev.currentTarget);
            this.$el.find('.tagSortCol').removeClass('active');

            $btn.addClass('active');

            if(this.tagSort === $btn.data('field')) {
                this.tagSortReverse = !this.tagSortReverse;
            } else {
                this.tagSort = $btn.data('field');
                this.tagSortReverse = true;

            }
            this._displayTagBreakdownData();

        },

        _plotTagBreakdownChart: function () {

            var self = this,
                label = null,
                labelData = [],
                chartData = [],
                datasets = [];


            if(this.tagChartFilter.length === 0) {
                _.each(_.sortBy(this.tagBreakdownTotalData, 'date'), function(row) {
                    labelData.push(moment.tz(row.date, 'America/New_York').format('MMM Do'));
                    chartData.push(row[self.chartKey]);
                });

                label = this._getChartLabelByChartKey(this.chartKey);

                datasets.push(
                    {
                        label: label,
                        data: chartData,
                        lineTension: 0,
                        borderWidth: 3,
                        backgroundColor: 'transparent',
                        borderColor: '#34a475',
                        pointRadius: 0,
                        pointHitRadius: 10
                    }
                );
            } else {
                _.each(this.tagChartFilter, function(tag, k) {
                    var tagData = _.findWhere(self.tagBreakdownData,{tag:tag.toString()}),
                        chartData = [];




                    _.each(tagData.data, function(row) {

                        if(datasets.length === 0) {
                            labelData.push(moment.tz(row.date, 'America/New_York').format('MMM Do'));
                        }

                        chartData.push(row[self.chartKey]);
                    });

                    datasets.push(
                        {
                            label: tag,
                            data: chartData,
                            lineTension: 0,
                            borderWidth: 3,
                            backgroundColor: 'transparent',
                            borderColor: self.PLOT_PALLETE[k],
                            pointRadius: 0,
                            pointHitRadius: 10
                        }
                    );



                });

            }

            return this._displayChart(this.$el.find('#tagPushChart'), labelData, datasets);
        },

        _getTagGeoBreakdownData: function () {
            var self = this,
                api = new ApiRequest(),
                startMoment = this.month.clone().tz("America/New_York").startOf("month"),
                endMoment = this.month.clone().tz("America/New_York").endOf("month"),
                nowMoment = moment().tz("America/New_York");


            this.tagGeoBreakdownData = [];
            this.tagGeoBreakdownTotalData = {};
            this.tagGeoChartFilter = [];
            this.tagGeoSortedData = [];
            this.tagGeoDatesData = {};
            this.tagGeoPage = 1;
            this.initTagGeoFilters();

            var to, from = startMoment.format('YYYY-MM-DD');
            if (startMoment.isSame(nowMoment, "month"))  {
                to = nowMoment.format("YYYY-MM-DD");
            } else {
                to = endMoment.format('YYYY-MM-DD');
            }

            this.$tagGeoMonthBreakdownPanel.addClass('panel-disabled');
            this.$tagGeoMonthBreakdownLabel.text('Loading...');
            $.when(
                api.request("v3/publisherPushStats.php?groupBy=date&from="+from+"&to="+to),
                api.request("v3/publisherPushStats.php?groupBy=tagGeo&from="+from+"&to="+to),
            )
                .then(function (dateBreakdownResponse, tagGeoBreakdownResponse) {
                    self.tagGeoBreakdownTotalData = _.indexBy(
                        _.map(dateBreakdownResponse[0], self._castDateSummaryRecord.bind(self)),
                        'date'
                    );

                    var dates = self._getDates(from, to);
                    var prev = null;
                    _.each(dates, function (date) {
                       if ('undefined' === typeof self.tagGeoBreakdownTotalData[date]) {
                           self.tagGeoBreakdownTotalData[date] = self._getDefaultDateSummaryRecord(date);
                       }
                        var curr = self.tagGeoBreakdownTotalData[date].active;
                        if (prev !== null) {
                            self.tagGeoBreakdownTotalData[date].growth = self._calculateGrowth(prev, curr);
                        }
                        prev = curr;
                    });

                    self.tagGeoBreakdownData = _.map(tagGeoBreakdownResponse[0], self._processTagGeoRecord.bind(self))

                    self._displayTagGeoBreakdownData();
                    self._plotTagGeoBreakdownChart();
                    self.$tagGeoMonthBreakdownPanel.removeClass('panel-disabled');
                })
                .always(function () {
                    self.$tagGeoMonthBreakdownLabel.text(self.month.format('MMMM YYYY'));
                });
        },

        _castDateSummaryRecord: function (record) {
            var result = {};

            result.date = record.date;
            result.subs = parseInt(record.subscriptions);
            result.pushes = parseInt(record.pushes);
            result.clicks = parseInt(record.clicks);
            result.rev = parseFloat(record.revenue)
            result.active = parseInt(record.active_subscriptions);
            result.ltvNew = parseFloat(record.ltv);
            result.ltvSubsNew = parseInt(record.ltv_count);
            result.ltvRevNew = parseFloat(record.ltv_revenue);
            result.growth = 0;

            return result;
        },

        _processTagGeoRecord: function (record) {
            var result = {};

            var flagGeo = record.geo ? record.geo : 'INTL';
            result.tag = record.tag;
            result.geo = record.geo;
            result.flagPath = require('./../../images/flags/'+flagGeo+'.png').default;
            result.subs = parseInt(record.subscriptions);
            result.impressions = parseInt(record.impressions);
            result.pushes = parseInt(record.pushes);
            result.pushesFormatted = this.nFormatter(result.pushes, 2);
            result.clicks = parseInt(record.clicks);
            result.rev = parseFloat(record.revenue);
            result.active = parseInt(record.active_subscriptions);
            result.ltvNew = parseFloat(record.ltv);
            result.ltvSubsNew = parseInt(record.ltv_count);
            result.ltvRevNew = parseFloat(record.ltv_revenue);
            if (result.pushes > 0) {
                result.ir = 100 * result.impressions / result.pushes;
                result.ctr = 100 * result.clicks / result.pushes;
                result.cpm = 1000 * result.rev / result.pushes;
            } else {
                result.ir = 0;
                result.ctr = 0;
                result.cpm = 0;
            }
            if (result.clicks > 0) {
                result.cpc = result.rev / result.clicks;
            } else {
                result.cpc = 0;
            }

            return result;
        },

        _displayTagGeoBreakdownData: function () {
            var filteredData = this._getTagGeoFilteredData();

            this.tagGeoSortedData = _.sortBy(filteredData, this.tagGeoSort);
            if(this.tagGeoSortReverse) {
                this.tagGeoSortedData.reverse();
            }

            this._displayTagGeoPage();
        },

        _getTagGeoFilteredData: function () {
            var self = this;
            var result = [];

           if (!_.isEmpty(this.tagGeoBreakdownData)) {
               result = _.filter(this.tagGeoBreakdownData, function (record) {
                   return _.reduce(self.tagGeofilters, function (includeInResult, value, key) {
                       return includeInResult && record[key] == value;
                   }, true);
               });
           }

           return result;
        },

        _displayTagGeoPage: function () {
            var self = this;
            var rowTemplate = _.template(tagGeoBreakdownRowTemplate);
            var start = this.tagGeoRowsPerPage * (this.tagGeoPage - 1);
            var end = start + this.tagGeoRowsPerPage;

            var pageData = [];
            if (!_.isEmpty(this.tagGeoSortedData)) {
                pageData = this.tagGeoSortedData.slice(start, end);
            }

            //this.$el.find('#paginationScrollTarget')[0].scrollIntoView(true);
            this._updateTagGeoPagination();
            this.$tagGeoMonthBreakdownTableBody.empty();
            _.each(pageData, function(row) {
                self.$tagGeoMonthBreakdownTableBody.append(rowTemplate(row));
            });

            this._updateTagGeoPlotCheckboxes();
        },

        _tagGeoChangePage: function (ev) {
            var $btn = $(ev.currentTarget);
            var id = $btn.data('id');
            if (id === 'next') {
                this.tagGeoPage += 1;
            } else if (id === 'prev' && this.tagGeoPage > 1) {
                this.tagGeoPage -= 1;
            }
            this._displayTagGeoPage();
        },

        _updateTagGeoPagination: function () {
            var $prevBtns = this.$el.find('.tagGeoPageBtn[data-id="prev"]');
            var $nextBtns = this.$el.find('.tagGeoPageBtn[data-id="next"]');

            var pages = Math.max(1, Math.ceil(this.tagGeoSortedData.length/this.tagGeoRowsPerPage));
            if (this.tagGeoPage <= 1) {
                $prevBtns.prop('disabled', true);
            } else {
                $prevBtns.prop('disabled', false);
            }
            if (this.tagGeoPage >= pages) {
                $nextBtns.prop('disabled', true);
            } else {
                $nextBtns.prop('disabled', false);
            }
            this.$el.find('.tagGeoPageNumber').text('Page ' + this.tagGeoPage);
        },

        _updateTagGeoPlotCheckboxes: function () {
            if (this.tagGeoChartFilter.length === 0) {
                return;
            }
            this.$el.find('.tagGeoGraphPlotInput:checked').prop('checked', false);
            _.each(this.tagGeoChartFilter, function (v) {
                this.$el.find('.tagGeoGraphPlotInput[data-tag="'+v.tag+'"][data-geo="'+v.geo+'"]')
                .prop('checked', true);
            }, this);
        },

        _updateTagGeoChartFilter: function (ev) {
            var $checkbox = $(ev.currentTarget);
            var tag = $checkbox.data('tag');
            var geo = $checkbox.data('geo');

            if ($checkbox.is(':checked')) {
                this.tagGeoChartFilter.push({tag: tag, geo: geo});
                this._getDateBreakdownDataByTagGeo(tag, geo);
            } else {
               this.tagGeoChartFilter = _.filter(this.tagGeoChartFilter, function (v) {
                   return v.geo !== geo || v.tag !== tag;
               });
               this._plotTagGeoBreakdownChart();
            }

            if (this.tagGeoChartFilter.length >= 10) {
                this.$el.find('.tagGeoGraphPlotInput:not(:checked)').prop('disabled', true);
            } else {
                this.$el.find('.tagGeoGraphPlotInput:not(:checked)').prop('disabled', false);
            }
        },

        _getDateBreakdownDataByTagGeo: function (tag, geo) {
            var self = this,
                api = new ApiRequest(),
                startMoment = this.month.clone().tz("America/New_York").startOf("month"),
                endMoment = this.month.clone().tz("America/New_York").endOf("month"),
                nowMoment = moment().tz("America/New_York");

            var to, from = startMoment.format('YYYY-MM-DD');
            if (startMoment.isSame(nowMoment, "month"))  {
                to = nowMoment.format("YYYY-MM-DD");
            } else {
                to = endMoment.format('YYYY-MM-DD');
            }

            this.$tagGeoMonthBreakdownPanel.addClass('panel-disabled');
            this.$tagGeoMonthBreakdownLabel.text('Loading...');
            api.request("v3/publisherPushStats.php?" + $.param({from: from, to: to, tag: tag, geo: geo}))
                .then(function (response) {
                    var statsByDate = _.indexBy(
                        _.map(response, self._castDateSummaryRecord.bind(self)),
                        'date'
                    );
                    var dates = self._getDates(from, to);
                    var data = [];
                    var prev = null;
                    _.each(dates, function (date) {
                        if ('undefined' === typeof statsByDate[date]) {
                            statsByDate[date] = self._getDefaultDateSummaryRecord(date);
                        }
                        var curr = statsByDate[date].active;
                        if (prev !== null) {
                            statsByDate[date].growth = self._calculateGrowth(prev, curr);
                        }
                        prev = curr;
                    });

                    self.tagGeoDatesData[tag+geo] = _.sortBy(statsByDate, 'date');

                    self._plotTagGeoBreakdownChart();
                })
                .always(function () {
                    self.$tagGeoMonthBreakdownPanel.removeClass('panel-disabled');
                    self.$tagGeoMonthBreakdownLabel.text(self.month.format('MMMM YYYY'));
                });
        },

        _updateTagGeoGraphPlot: function (ev) {
            $btn = $(ev.currentTarget);
            this.$el.find('.tagGeoGraphPlotBtn').removeClass('active');
            this.chartKey = $btn.data('type');
            $btn.addClass('active');
            this._plotTagGeoBreakdownChart();
        },

        _plotTagGeoBreakdownChart: function () {
            var self = this,
                label = null,
                labelData = [],
                chartData = [],
                datasets = [];

            if(this.tagGeoChartFilter.length === 0) {
                _.each(_.sortBy(this.tagGeoBreakdownTotalData, 'date'), function(row) {
                    labelData.push(moment.tz(row.date, 'America/New_York').format('MMM Do'));
                    chartData.push(row[self.chartKey]);
                });

                label = this._getChartLabelByChartKey(this.chartKey);

                datasets.push(
                    {
                        label: label,
                        data: chartData,
                        lineTension: 0,
                        borderWidth: 3,
                        backgroundColor: 'transparent',
                        borderColor: '#34a475',
                        pointRadius: 0,
                        pointHitRadius: 10
                    }
                );
            } else {
                _.each(this.tagGeoChartFilter, function(val, k) {
                    var chartData = [];
                    var data = [];
                    if (self.tagGeoDatesData && self.tagGeoDatesData[val.tag + val.geo]) {
                        data = self.tagGeoDatesData[val.tag + val.geo];
                    }

                    _.each(data, function(row) {
                        if(datasets.length === 0) {
                            labelData.push(moment.tz(row.date, 'America/New_York').format('MMM Do'));
                        }
                        chartData.push(row[self.chartKey]);
                    });

                    datasets.push(
                        {
                            label: val.tag + ' + ' + val.geo,
                            data: chartData,
                            lineTension: 0,
                            borderWidth: 3,
                            backgroundColor: 'transparent',
                            borderColor: self.PLOT_PALLETE[k],
                            pointRadius: 0,
                            pointHitRadius: 10
                        }
                    );

                });
            }

            if (this.tagGeoPushStatsGraph) {
                this.tagGeoPushStatsGraph.destroy();
            }
            this.tagGeoPushStatsGraph = this._displayChart(this.$el.find('#tagGeoPushChart'), labelData, datasets);
        },

        _updateTagGeoBreakdownFilter: function(ev) {
            var $btn = $(ev.currentTarget);

            if($btn.hasClass('tagGeoPrevMonthButton')) {
                this.month.subtract('1', 'month');

            } else if ($btn.hasClass('tagGeoNextMonthButton')) {
                this.month.add('1', 'month');
            }

            this._getTagGeoBreakdownData();
        },

        _sortTagGeoBreakdown: function (ev) {
            var $btn = $(ev.currentTarget);
            this.$el.find('.tagGeoSortCol').removeClass('active');

            $btn.addClass('active');

            if(this.tagGeoSort === $btn.data('field')) {
                this.tagGeoSortReverse = !this.tagGeoSortReverse;
            } else {
                this.tagGeoSort = $btn.data('field');
                this.tagSortReverse = true;
            }

            this.tagGeoPage = 1;
            this._displayTagGeoBreakdownData();
        },

        _setCooldown: function() {
            var self = this,
                api = new ApiRequest();

            $.when(api.request("v3/publisherPushCooldown.php",{
                'first_push_delay': this.$delayInput.val()
            }))
                .then(function () {
                    alert('Push Frequency and Delay Saved');
                });


        },

        initTagGeoFilters: function () {
            this.tagGeofilters = {};
            this.tagGeoFilterInputs = {};

            _.each (['tag', 'geo'], function (key) {
                this.tagGeoFilterInputs[key] = this.$el.find('#pstg_' + key + 'FilterInput');
            }, this);

            _.each(isoCountries, function (country) {
                this.tagGeoFilterInputs.geo.append(
                    $("<option>", {
                        value: country.alpha_2_code,
                        text: country.alpha_2_code + " - " + country.country
                    })
                );
            }, this);

            this.setTagGeoFilterInputValues();
        },

        setTagGeoFilterInputValues: function () {
            _.each(this.tagGeoFilterInputs, function($input, key) {
                if (this.tagGeofilters[key] !== undefined) {
                    $input.val(this.tagGeofilters[key]);
                } else {
                    $input.val('');
                }
            }, this);
        },

        tagGeoFilterChanged: function(ev) {
            this.tagGeoPage = 1;
            this.setTagGeoFilters();
            this._displayTagGeoBreakdownData();
        },

        setTagGeoFilters: function() {
            this.tagGeofilters =  _.reduce(this.tagGeoFilterInputs, function (result, $input, key) {
                if ($input.val() !== '') {
                    result[key] = $input.val().trim();
                }
                return result;
            }, {}, this);
        },

        resetFilters: function(ev) {
            this.tagGeofilters = {};
            this.tagGeoPage = 1;
            this.setTagGeoFilterInputValues();
            this._displayTagGeoBreakdownData();
        },

        nFormatter: function(num, digits) {
            var si = [
                { value: 1, symbol: "" },
                { value: 1E3, symbol: "k" },
                { value: 1E6, symbol: "M" },
                { value: 1E9, symbol: "G" },
                { value: 1E12, symbol: "T" },
                { value: 1E15, symbol: "P" },
                { value: 1E18, symbol: "E" }
            ];
            var rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
            var i;
            for (i = si.length - 1; i > 0; i--) {
                if (num >= si[i].value) {
                    break;
                }
            }
            return (num / si[i].value).toFixed(digits).replace(rx, "$1") + si[i].symbol;
        },

        sleep: function() {
            this.isAsleep = true;
        },
        wakeUp: function() {
            this.isAsleep = false;
        }
    });

    return new pushStatsView();
});
