/*jslint nomen:true, indent:4*/
/*jshint -W079 */
/*global define, _, console */

define([
    "Backbone",
    "moment-timezone",
    "workers/apirequest",
    "templates/banking.html",
    "views/pureSelect",
    "dict/isocountries.json",
    "views/fundsTransfer",
    "views/manualIdVerification",
    "registry"
], function (
    Backbone,
    moment,
    ApiRequest,
    pageTemplate,
    PureSelect,
    isoCountries,
    fundsTransferView,
    manualVerificationView,
    app
) {

    var BankingView = Backbone.View.extend({

        paymentMethods: ['wire', 'paypal', 'paxum', 'bitcoin', 'litecoin', 'usdt', 'usdt'],

        _subViews: [],


        // Constants
        SAVE_BUTTON_TEXT: "Save Payment Details",
        SAVING_TEXT: "Saving...",
        SAVED_TEXT: "Saved!",
        SAVE_FAILED_TEXT: "Save Failed!",
        paymentType: null,
        thresholdReached: false,
        threshold: 50,

        // Properties

        // Events
        events: {
            "click #banking_saveButton": "saveWithPreview",
            'click .confirmSave': 'clickSave',
            "click .paymentMethod": "_togglePaymentMethod",
            'click .transferFundsBtn': '_showTransferFundsModal',
            'click .paymentDetailsConfirmBtn': 'confirmUpdate',
            "click .continueIdVerifyBtn": "_proceedSaveBankDetails",
            "click .showPendingDetailsBtn": "showPendingDetails",
            "click .signIOBtn": "signIO",
            "click .downloadIOBtn": "downloadIO",
            "click .langToggleBtn": "_toggleLang",
        },


        // Methods
        initialize: function () {
        },

        _isPaymentTime: function () {
            var today = moment.tz("Europe/London");
            return (today.day() === 1 && today.hour() >= 7 && today.hour() < 18);
        },

        render: function(queryString) {
            var self = this,
                request = new ApiRequest(),
                countries = null,
                countriesFlat = {};

            this.isLocked = false;
            this.queryString = queryString;
            this.template = _.template(pageTemplate);

            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("mainPane")
                .attr("id", "trendingContainer");


            this.$el.append(pageTemplate);
            $('#appContainer').append(this.$el);

            this.$minPayoutInput = this.$el.find("#banking_minPayoutInput");
            this.$accountNameInput = this.$el.find("#banking_accountNameInput");
            this.$accountAddress1Input = this.$el.find("#banking_accountAddress1Input");
            this.$accountAddress2Input = this.$el.find("#banking_accountAddress2Input");
            this.$accountCityInput = this.$el.find("#banking_accountCityInput");
            this.$accountZipInput = this.$el.find("#banking_accountZipInput");
            this.$bankNameInput = this.$el.find("#banking_bankNameInput");
            this.$bankStreetInput = this.$el.find("#banking_bankStreetInput");
            this.$bankCityInput = this.$el.find("#banking_bankCityInput");
            this.$bankZipInput = this.$el.find("#banking_bankZipInput");
            this.$ibanInput = this.$el.find("#banking_IbanInput");
            this.$bicInput = this.$el.find("#banking_bicInput");
            this.$vatNoInput = this.$el.find("#banking_vatNoInput");
            this.$paymentEmailInput = this.$el.find("#banking_paymentEmailInput");
            this.$paymentHoldInput = this.$el.find("#banking_paymentHoldInput");
            this.$saveButton = this.$el.find("#banking_saveButton");

            this.$payPalForm = this.$el.find('.payPalForm');
            this.$paypalInput = this.$el.find('#banking_payPalInput');
            this.$payPalBtn = this.$el.find('.paymentMethod[data-type="paypal"]');
            this.$paypalNameInput = this.$el.find("#banking_paypalNameInput");
            this.$paypalAddress1Input = this.$el.find("#banking_paypalAddress1Input");
            this.$paypalAddress2Input = this.$el.find("#banking_paypalAddress2Input");
            this.$paypalCityInput = this.$el.find("#banking_paypalCityInput");
            this.$paypalZipInput = this.$el.find("#banking_paypalZipInput");

            this.$paxumForm = this.$el.find('.paxumForm');
            this.$paxumInput = this.$el.find('#banking_paxumInput');
            this.$paxumBtn = this.$el.find('.paymentMethod[data-type="paxum"]');
            this.$paxumNameInput = this.$el.find("#banking_paxumNameInput");
            this.$paxumAddress1Input = this.$el.find("#banking_paxumAddress1Input");
            this.$paxumAddress2Input = this.$el.find("#banking_paxumAddress2Input");
            this.$paxumCityInput = this.$el.find("#banking_paxumCityInput");
            this.$paxumZipInput = this.$el.find("#banking_paxumZipInput");

            this.$wireTransferForm = this.$el.find('.wireTransferForm');
            this.$wireTransferBtn = this.$el.find('.paymentMethod[data-type="wiretransfer"]');

            this.$bitcoinForm = this.$el.find('.bitcoinForm');
            this.$bitcoinInput = this.$el.find('#banking_bitcoinInput');
            this.$bitcoinBtn = this.$el.find('.paymentMethod[data-type="bitcoin"]');
            this.$bitcoinNameInput = this.$el.find("#banking_bitcoinNameInput");
            this.$bitcoinAddress1Input = this.$el.find("#banking_bitcoinAddress1Input");
            this.$bitcoinAddress2Input = this.$el.find("#banking_bitcoinAddress2Input");
            this.$bitcoinCityInput = this.$el.find("#banking_bitcoinCityInput");
            this.$bitcoinZipInput = this.$el.find("#banking_bitcoinZipInput");
            this.$bitcoinWalletProviderInput = this.$el.find('#banking_bitcoinWalletProviderInput');

            this.$litecoinForm = this.$el.find('.litecoinForm');
            this.$litecoinInput = this.$el.find('#banking_litecoinInput');
            this.$litecoinBtn = this.$el.find('.paymentMethod[data-type="litecoin"]');
            this.$litecoinNameInput = this.$el.find("#banking_litecoinNameInput");
            this.$litecoinAddress1Input = this.$el.find("#banking_litecoinAddress1Input");
            this.$litecoinAddress2Input = this.$el.find("#banking_litecoinAddress2Input");
            this.$litecoinCityInput = this.$el.find("#banking_litecoinCityInput");
            this.$litecoinZipInput = this.$el.find("#banking_litecoinZipInput");
            this.$litecoinWalletProviderInput = this.$el.find('#banking_litecoinWalletProviderInput');

            this.$rejectAlert = this.$el.find('#banking_RejectAlert');
            this.$rejectionReason = this.$el.find('#banking_rejectionReason');
            this.$pendingAlert = this.$el.find('#banking_PendingAlert');
            this.$blockingAlert = this.$el.find('#banking_BlockingAlert');
            this.$signIOAlert = this.$el.find('#banking_signIOAlert');

            this.$confirmationModal = this.$el.find('#paymentDetailsUpdateConfirmModal');
            this.$verificationCodeInput = this.$el.find('input.verificationCode');
            this.$paymentDetailsPanel = this.$el.find('.paymentDetailsPanel');
            this.$paymentSetupPanel = this.$el.find('.paymentSetupPanel');
            this.$profileDetailsActionBtn = this.$el.find('.profileDetailsActionBtn');
            this.$profileDetailsCompleteBtn = this.$el.find('.profileDetailsCompleteBtn');
            this.$thresholdIncompleteBtn = this.$el.find('.thresholdIncompleteBtn');
            this.$thresholdCompleteBtn = this.$el.find('.thresholdCompleteBtn');


            this.$saveBankDetailsButton = this.$el.find('#banking_saveButton');
            this.$bankDetailsError = this.$el.find('.bankDetailsError');
            this.$bankDetailsErrorModal = this.$el.find('.bankDetailsErrorModal');
            this.$bankDetailsSuccessModal = this.$el.find('.bankDetailsSuccessModal');
            this.$bankDetailsIntroModal = this.$el.find('#bankDetailsIntroModal');
            this.$idVerifyModal = this.$el.find('.idVerifyModal');
            this.$idVerifyIFrame = this.$el.find('.idVerifyIFrame');
            this.$idVerifyManualModal = this.$el.find('.idVerifyManualModal');
            this.$signIOModal = this.$el.find('.bankDetailsSignIOModal');

            this.$usdtForm = this.$el.find('.usdtForm');
            this.$usdtBtn = this.$el.find('.paymentMethod[data-type="usdt"]');
            this.$usdtWalletInput = this.$el.find('#banking_usdtWalletInput');
            this.$usdtNameInput = this.$el.find("#banking_usdtNameInput");
            this.$usdtAddress1Input = this.$el.find("#banking_usdtAddress1Input");
            this.$usdtAddress2Input = this.$el.find("#banking_usdtAddress2Input");
            this.$usdtCityInput = this.$el.find("#banking_usdtCityInput");
            this.$usdtZipInput = this.$el.find("#banking_usdtZipInput");

            this.$usdtTrc20Form = this.$el.find('.usdtTrc20Form');
            this.$usdtTrc20Btn = this.$el.find('.paymentMethod[data-type="usdtTrc20"]');
            this.$usdtTrc20WalletInput = this.$el.find('#banking_usdtTrc20WalletInput');
            this.$usdtTrc20NameInput = this.$el.find("#banking_usdtTrc20NameInput");
            this.$usdtTrc20Address1Input = this.$el.find("#banking_usdtTrc20Address1Input");
            this.$usdtTrc20Address2Input = this.$el.find("#banking_usdtTrc20Address2Input");
            this.$usdtTrc20CityInput = this.$el.find("#banking_usdtTrc20CityInput");
            this.$usdtTrc20ZipInput = this.$el.find("#banking_usdtTrc20ZipInput");



            // Sort out the country array
            countries = isoCountries;
            countriesFlat[''] = 'Select a Country';
            _.each(countries, function (row) {
                countriesFlat[row.alpha_2_code] = row.country;
            });
            this.countriesFlat = countriesFlat;

            this.$bankCountryInputContainer = this.$el.find("#banking_bankCountryInput");
            this.$bankCountryInput = new PureSelect()
                .attr("id", "bank_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$bankCountryInputContainer);

            this._subViews.push(this.$bankCountryInput);

            this.$accountCountryInputContainer = this.$el.find("#banking_accountCountryInput");
            this.$accountCountryInput = new PureSelect()
                .attr("id", "account_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$accountCountryInputContainer);
            this._subViews.push(this.$accountCountryInput);

            this.$paypalCountryInputContainer = this.$el.find("#banking_paypalCountryInput");
            this.$paypalCountryInput = new PureSelect()
                .attr("id", "paypal_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$paypalCountryInputContainer);
            this._subViews.push(this.$paypalCountryInput);

            this.$paxumCountryInputContainer = this.$el.find("#banking_paxumCountryInput");
            this.$paxumCountryInput = new PureSelect()
                .attr("id", "paxum_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$paxumCountryInputContainer);
            this._subViews.push(this.$paxumCountryInput);

            this.$bitcoinCountryInputContainer = this.$el.find("#banking_bitcoinCountryInput");
            this.$bitcoinCountryInput = new PureSelect()
                .attr("id", "bitcoin_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$bitcoinCountryInputContainer);
            this._subViews.push(this.$bitcoinCountryInput);

            this.$litecoinCountryInputContainer = this.$el.find("#banking_litecoinCountryInput");
            this.$litecoinCountryInput = new PureSelect()
                .attr("id", "litecoin_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$litecoinCountryInputContainer);
            this._subViews.push(this.$litecoinCountryInput);

            this.$usdtCountryInputContainer = this.$el.find("#banking_usdtCountryInput");
            this.$usdtCountryInput = new PureSelect()
                .attr("id", "usdt_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$usdtCountryInputContainer);
            this._subViews.push(this.$usdtCountryInput);

            this.$usdtTrc20CountryInputContainer = this.$el.find("#banking_usdtTrc20CountryInput");
            this.$usdtTrc20CountryInput = new PureSelect()
                .attr("id", "usdtTrc20_countrySelect")
                .addClass("white")
                .updateData(countriesFlat)
                .appendTo(this.$usdtTrc20CountryInputContainer);
            this._subViews.push(this.$usdtTrc20CountryInput);

            this.transferView = new fundsTransferView();
            this._subViews = [];
            this._subViews.push(this.transferView);

            this.$saveButton.text(this.SAVE_BUTTON_TEXT);
            $.when(
                app.ready,
                request.request("data/partner-revenue.php"),
                request.request('data/partner.php?action=getProfileDetailsStatus'),
                request.request('data/partner.php')
            )
                .then(function(readyResponse, revenueResponse, statusResponse, partnerResponse) {
                    var totalEarnings = revenueResponse[0].amount,
                        partnerData = partnerResponse[0];

                    self.statusData = statusResponse[0];
                    self.partnerData = partnerData;

                    if (self.partnerData['docusign_io_id']) {
                        self.$el.find('.downloadSignedIO').show();
                    } else {
                        self.$el.find('.downloadSignedIO').hide();
                    }

                    _.each(self.paymentMethods, function (method) {
                        if (parseInt(self.partnerData['allow_' + method]) === 1) {
                            self.$el.find('.'+ method + 'BtnContainer').show();
                        }
                    });


                    if(parseFloat(totalEarnings) >= self.threshold) {
                        self.thresholdReached = true;
                    }



                    if(self.statusData.bypassVerification || (self.thresholdReached && partnerData.profileComplete)) {
                        self.$paymentDetailsPanel.show();
                    } else {
                        if(!partnerData.profileComplete) {
                            self.$profileDetailsActionBtn.show();
                        } else {
                            self.$profileDetailsCompleteBtn.show();
                        }

                        if (!self.thresholdReached) {
                            self.$thresholdIncompleteBtn.show();
                        } else {
                            self.$thresholdCompleteBtn.show();
                        }
                        self.$paymentSetupPanel.show();
                    }

                    self.getData();

                    return self.$el;
                });

        },

        isPaymentMethodAllowed: function (method) {
            var columnName = 'allow_' + this.paymentMethods[+method];
            return parseInt(this.partnerData[columnName]) === 1;
        },

        getData: function () {
            var self = this;

            new ApiRequest().request("data/partner-banking.php")
                .done(function (res) {
                    self.populate(res);
                })
            .fail(function (err) {
                if (err.status !== undefined && err.status === 404) {
                    self.$paymentHoldInput.prop("checked", true);
                } else {
                    console.error("error", err); // eslint-disable-line no-console
                    if (err.status && err.status === 500 && err.responseJSON && err.responseJSON.message) {
                        alert(err.responseJSON.message);
                    }
                }
            });

        },

        populate: function(response) {
            var data = response.actual || {};
            this.notApprovedData = response.not_approved || {};
            this.isLocked = data.info_id
                && (+this.partnerData.io_required === 1)
                && (+this.partnerData.unlock_payment_info === 0)
            this.$minPayoutInput.val(data.min_payout || this.partnerData.min_payout_limit);
            this.$paymentEmailInput.val(data.payment_email);
            this.$vatNoInput.val(data.vat_no);
            if (parseInt(data.payment_hold, 10) === 1 || data.method === null) {
                this.$paymentHoldInput.prop("checked", true);
            }

            this.fetchedData = data;

            this.showHideAlerts(this.notApprovedData.status, this.notApprovedData.rejection_reason);

            var method = +data.method;

            if (this._isPaymentTime()) {
                this.$saveButton.prop('disabled', true);
                this.$saveButton.prop('title', 'Updating payment details is blocked on Mondays from 7am to 6pm London time.')
                this.$blockingAlert.show();
            } else {
                this.$saveButton.prop('disabled', false);
                this.$saveButton.prop('title', '');
                this.$blockingAlert.hide();
            }

            if (!this.isPaymentMethodAllowed(method)) {
                return;
            }
            if(method === 1) {
                this.$paypalInput.val(data.bank_name);
                this.$paypalNameInput.val(data.account_name);
                this.$paypalAddress1Input.val(data.account_address1);
                this.$paypalAddress2Input.val(data.account_address2);
                this.$paypalCityInput.val(data.account_city);
                this.$paypalZipInput.val(data.account_zip);
                this.$paypalCountryInput.val(data.account_country);
                this.$payPalBtn.click();
            } else if(method === 2) {
                this.$paxumInput.val(data.bank_name);
                this.$paxumNameInput.val(data.account_name);
                this.$paxumAddress1Input.val(data.account_address1);
                this.$paxumAddress2Input.val(data.account_address2);
                this.$paxumCityInput.val(data.account_city);
                this.$paxumZipInput.val(data.account_zip);
                this.$paxumCountryInput.val(data.account_country);
                this.$paxumBtn.click();
            } else if(method === 3) {
                this.$bitcoinInput.val(data.bank_name);
                this.$bitcoinNameInput.val(data.account_name);
                this.$bitcoinAddress1Input.val(data.account_address1);
                this.$bitcoinAddress2Input.val(data.account_address2);
                this.$bitcoinCityInput.val(data.account_city);
                this.$bitcoinZipInput.val(data.account_zip);
                this.$bitcoinCountryInput.val(data.account_country);
                this.$bitcoinWalletProviderInput.val(data.wallet_provider);
                this.$bitcoinBtn.click();
            } else if(method === 4) {
                this.$litecoinInput.val(data.bank_name);
                this.$litecoinNameInput.val(data.account_name);
                this.$litecoinAddress1Input.val(data.account_address1);
                this.$litecoinAddress2Input.val(data.account_address2);
                this.$litecoinCityInput.val(data.account_city);
                this.$litecoinZipInput.val(data.account_zip);
                this.$litecoinCountryInput.val(data.account_country);
                this.$litecoinWalletProviderInput.val(data.wallet_provider);
                this.$litecoinBtn.click();
            } else if(method === 5) {
                this.$usdtWalletInput.val(data.bank_name);
                this.$usdtNameInput.val(data.account_name);
                this.$usdtAddress1Input.val(data.account_address1);
                this.$usdtAddress2Input.val(data.account_address2);
                this.$usdtCityInput.val(data.account_city);
                this.$usdtZipInput.val(data.account_zip);
                this.$usdtCountryInput.val(data.account_country);
                this.$usdtBtn.click();
            } else if(method === 6) {
                this.$usdtTrc20WalletInput.val(data.bank_name);
                this.$usdtTrc20NameInput.val(data.account_name);
                this.$usdtTrc20Address1Input.val(data.account_address1);
                this.$usdtTrc20Address2Input.val(data.account_address2);
                this.$usdtTrc20CityInput.val(data.account_city);
                this.$usdtTrc20ZipInput.val(data.account_zip);
                this.$usdtTrc20CountryInput.val(data.account_country);
                this.$usdtTrc20Btn.click();
            } else {
                this.$accountNameInput.val(data.account_name);
                this.$accountAddress1Input.val(data.account_address1);
                this.$accountAddress2Input.val(data.account_address2);
                this.$accountCityInput.val(data.account_city);
                this.$accountZipInput.val(data.account_zip);
                this.$accountCountryInput.val(data.account_country);
                this.$bankNameInput.val(data.bank_name);
                this.$bankStreetInput.val(data.bank_street);
                this.$bankCityInput.val(data.bank_city);
                this.$bankZipInput.val(data.bank_zip);
                this.$bankCountryInput.val(data.bank_country);
                this.$ibanInput.val(data.iban);
                this.$bicInput.val(data.swift);
                this.$wireTransferBtn.click();
            }

            // lock banking details inputs
            if (this.isLocked) {
                this.$el.find('.bankingInput').prop('disabled', true);
                this.$el.find('.paymentMethod').prop('disabled', true);
                this.$minPayoutInput.prop('disabled', false);
                this.$accountCountryInput.disable();
                this.$bankCountryInput.disable();
                this.$paypalCountryInput.disable();
                this.$paxumCountryInput.disable();
                this.$bitcoinCountryInput.disable();
                this.$litecoinCountryInput.disable();
                this.$usdtCountryInput.disable();
                this.$usdtTrc20CountryInput.disable();
                this.$el.find('.bankingDetailsLockedMessage').show();
                this.$el.find('.beforeSaveWarning').hide();

                // if IO have not been signed show notification with Sign IO Btn
                this.showHideSignIOAlert();
            }
        },

        showHideSignIOAlert: function()
        {
            var self = this;

            if (!this.isLocked || this.partnerData.docusign_io_id) {
                this.$signIOAlert.hide();
                return;
            }

            if (this.queryString) {
                var params = new URLSearchParams(this.queryString);
                if (params.has('hash') && params.has('event')) {
                    var url = "v3/publisherInsertionOrder.php?action=checkUrl"
                        + '&hash=' + params.get('hash')
                        + '&status=' + params.get('event');
                    new ApiRequest().request(url)
                        .done(function (response) {
                            if (response.valid && params.get('event') === 'signing_complete') {
                                self.$signIOAlert.hide();
                            } else {
                                self.$signIOAlert.show();
                            }
                        })
                        .fail(function (error) {
                            self.$signIOAlert.show();
                        })
                } else {
                    this.$signIOAlert.show();
                }
            } else {
                this.$signIOAlert.show();
            }
        },

        showHideAlerts: function (status, rejection_reason) {
            if (status === 'rejected' && rejection_reason) {
                this.$rejectionReason.text(rejection_reason);
                this.$rejectAlert.show();
                this.$pendingAlert.hide();
            } else if (status === 'pending' || status === 'jumio') {
                this.$rejectAlert.hide();
                if (this.notApprovedData && this.notApprovedData.status === 'pending') {
                    this.$pendingAlert.find('.showPendingDetailsBtn').show();
                } else {
                    this.$pendingAlert.find('.showPendingDetailsBtn').hide();
                }
                this.$pendingAlert.show();
            } else {
                this.$rejectAlert.hide();
                this.$pendingAlert.hide();
            }
        },

        saveWithPreview: function () {
            if (!this.isLocked) {
                var dataToSubmit = this._generateApiData();
                this.showPreviewData(dataToSubmit);
            } else {
                this.clickSave();
            }
        },

        clickSave: function () {
            this._proceedSaveBankDetails();
        },

        _dataHasChanged: function (oldData, newData) {
            var changed = false;

            _.each(newData, function(value,key) {
                if(oldData[key] == null) {
                    oldData[key] = '';
                }
                if(key === 'min_payout' || key === 'payment_email' || key === 'payment_hold') {
                    return;
                }

                if(oldData[key] !== value) {
                    changed = true;
                }
            });
            return changed;


        },

        _proceedSaveBankDetails: function() {
            var self = this;
            var data = this._generateApiData();
            data.action = 'createVerify';

            this.$el.find(".bankingInput").removeClass("error");
            this.$el.find(".bankingInputErrorText").text('');
            this.$el.find(".pureSelectContainer").removeClass("error");

            this.$saveButton.text(this.SAVING_TEXT);

            new ApiRequest().request("data/partner-banking.php", data)
                .done(function (response) {
                    self.$saveButton.text(self.SAVED_TEXT);

                    setTimeout(function () {
                        self.$saveButton.text(self.SAVE_BUTTON_TEXT);
                    }, 2000);

                    if(response.status === 'verification') {
                        self.$idVerifyIFrame.attr('src', response.verification.redirectUrl);
                        self.$idVerifyModal.show();
                    } else if (response.status === 'manualIdVerification') {
                        self._initManualIdVerification(response.verificationId);
                    } else if (response.status === 'ok') {

                        if (response.pending) {
                            self.showHideAlerts('pending');
                        } else {
                            self.showHideAlerts('approved');
                        }
                    } else if (response.status === 'not confirmed') {
                        self.$confirmationModal.show();
                    }
                })
                .fail(function (err) {
                    self._handleSubmissionFail(err);
                });

        },

        _initManualIdVerification(verificationId) {

            this.$manualView = new manualVerificationView({
                'verificationId': verificationId
            });
            this.$manualView.show();
            this.$manualView.on('complete', this._completeManualIdVerification, this);

        },

        _completeManualIdVerification(verificationId) {
            var self = this,
                req = new ApiRequest(),
                apiData = this._generateApiData();

            apiData.action = 'confirmVerify';
            apiData.verification_id = verificationId;

            req.post('data/partner-banking.php', apiData)
                .done(function (response) {
                    self.$manualView.remove();
                    $('html, body').removeClass('overflow-hidden');
                    self.$saveButton.text(self.SAVED_TEXT);

                    setTimeout(function () {
                        self.$saveButton.text(self.SAVE_BUTTON_TEXT);
                    }, 2000);

                    if (response.status === 'ok') {
                        if (response.pending) {
                            self.showHideAlerts('pending');
                        } else {
                            self.showHideAlerts('approved');
                        }
                        self.$bankDetailsSuccessModal.show();
                    } else if (response.status === 'not confirmed') {
                        self.$confirmationModal.show();
                    }
                })
                .fail(function (err) {
                    self._handleSubmissionFail(err);
                });
        },


        _handleSubmissionFail: function(err) {
            var self = this,
                method = +this.$el.find('.paymentMethod:checked').val(),
                jsonData = err.responseJSON;

            if (err.status && err.responseJSON && err.responseJSON.message) {
                alert('ERROR: ' + err.responseJSON.message);
            }

            self.$saveButton.text(self.SAVE_FAILED_TEXT);

            setTimeout(function () {
                self.$saveButton.text(self.SAVE_BUTTON_TEXT);
            }, 2000);

            if(jsonData.method !== undefined) {
                alert('ERROR: ' + jsonData.method);
            }

            if (jsonData.min_payout !== undefined) {
                self.$minPayoutInput.val(self.partnerData.min_payout_limit);
                self.$minPayoutInput.addClass("error");
            }
            if (jsonData.vat_no !== undefined) {
                self.$vatNoInput.addClass("error");
            }
            if (jsonData.payment_email !== undefined) {
                self.$paymentEmailInput.addClass("error");
            }
            if (jsonData.payment_hold !== undefined) {
                self.$paymentHoldInput.addClass("error");
            }

            if (method === 0) {
                if (jsonData.account_name !== undefined) {
                    self.$accountNameInput.addClass("error");
                }
                if (jsonData.account_address1 !== undefined) {
                    self.$accountAddress1Input.addClass("error");
                }
                if (jsonData.account_address2 !== undefined) {
                    self.$accountAddress2Input.addClass("error");
                }
                if (jsonData.account_city !== undefined) {
                    self.$accountCityInput.addClass("error");
                }
                if (jsonData.account_zip !== undefined) {
                    self.$accountZipInput.addClass("error");
                }
                if (jsonData.account_country !== undefined) {
                    self.$accountCountryInput.addClass("error");
                }
                if (jsonData.bank_name !== undefined) {
                    self.$bankNameInput.addClass("error");
                }
                if (jsonData.bank_street !== undefined) {
                    self.$bankStreetInput.addClass("error");
                }
                if (jsonData.bank_city !== undefined) {
                    self.$bankCityInput.addClass("error");
                }
                if (jsonData.bank_zip !== undefined) {
                    self.$bankZipInput.addClass("error");
                }
                if (jsonData.bank_country !== undefined) {
                    self.$bankCountryInput.addClass("error");
                }
                if (jsonData.iban !== undefined) {
                    self.$ibanInput.addClass("error");
                }
                if (jsonData.swift !== undefined) {
                    self.$bicInput.addClass("error");
                }
            } else if (method === 1) {
                if (jsonData.bank_name !== undefined) {
                    self.$paypalInput.addClass("error");
                }
                if (jsonData.account_name !== undefined) {
                    self.$paypalNameInput.addClass("error");
                }
                if (jsonData.account_address1 !== undefined) {
                    self.$paypalAddress1Input.addClass("error");
                }
                if (jsonData.account_address2 !== undefined) {
                    self.$paypalAddress2Input.addClass("error");
                }
                if (jsonData.account_city !== undefined) {
                    self.$paypalCityInput.addClass("error");
                }
                if (jsonData.account_zip !== undefined) {
                    self.$paypalZipInput.addClass("error");
                }
                if (jsonData.account_country !== undefined) {
                    self.$paypalCountryInput.addClass("error");
                }
            } else if (method === 2) {
                if (jsonData.bank_name !== undefined) {
                    self.$paxumInput.addClass("error");
                }
                if (jsonData.account_name !== undefined) {
                    self.$paxumNameInput.addClass("error");
                }
                if (jsonData.account_address1 !== undefined) {
                    self.$paxumAddress1Input.addClass("error");
                }
                if (jsonData.account_address2 !== undefined) {
                    self.$paxumAddress2Input.addClass("error");
                }
                if (jsonData.account_city !== undefined) {
                    self.$paxumCityInput.addClass("error");
                }
                if (jsonData.account_zip !== undefined) {
                    self.$paxumZipInput.addClass("error");
                }
                if (jsonData.account_country !== undefined) {
                    self.$paxumCountryInput.addClass("error");
                }
            } else if (method === 3) {
                if (jsonData.bank_name !== undefined) {
                    self.$bitcoinInput.addClass("error");
                }
                if (jsonData.account_name !== undefined) {
                    self.$bitcoinNameInput.addClass("error");
                }
                if (jsonData.account_address1 !== undefined) {
                    self.$bitcoinAddress1Input.addClass("error");
                }
                if (jsonData.account_address2 !== undefined) {
                    self.$bitcoinAddress2Input.addClass("error");
                }
                if (jsonData.account_city !== undefined) {
                    self.$bitcoinCityInput.addClass("error");
                }
                if (jsonData.account_zip !== undefined) {
                    self.$bitcoinZipInput.addClass("error");
                }
                if (jsonData.account_country !== undefined) {
                    self.$bitcoinCountryInput.addClass("error");
                }
                if (jsonData.wallet_provider !== undefined) {
                    self.$bitcoinWalletProviderInput.addClass("error");
                }
            } else if (method === 4) {
                if (jsonData.bank_name !== undefined) {
                    self.$litecoinInput.addClass("error");
                }
                if (jsonData.account_name !== undefined) {
                    self.$litecoinNameInput.addClass("error");
                }
                if (jsonData.account_address1 !== undefined) {
                    self.$litecoinAddress1Input.addClass("error");
                }
                if (jsonData.account_address2 !== undefined) {
                    self.$litecoinAddress2Input.addClass("error");
                }
                if (jsonData.account_city !== undefined) {
                    self.$litecoinCityInput.addClass("error");
                }
                if (jsonData.account_zip !== undefined) {
                    self.$litecoinZipInput.addClass("error");
                }
                if (jsonData.account_country !== undefined) {
                    self.$litecoinCountryInput.addClass("error");
                }
                if (jsonData.wallet_provider !== undefined) {
                    self.$litecoinWalletProviderInput.addClass("error");
                }
            } else if (method === 5) {
                if (jsonData.bank_name !== undefined) {
                    self.$usdtWalletInput.addClass("error");
                }
                if (jsonData.account_name !== undefined) {
                    self.$usdtNameInput.addClass("error");
                }
                if (jsonData.account_address1 !== undefined) {
                    self.$usdtAddress1Input.addClass("error");
                }
                if (jsonData.account_address2 !== undefined) {
                    self.$usdtAddress2Input.addClass("error");
                }
                if (jsonData.account_city !== undefined) {
                    self.$usdtCityInput.addClass("error");
                }
                if (jsonData.account_zip !== undefined) {
                    self.$usdtZipInput.addClass("error");
                }
                if (jsonData.account_country !== undefined) {
                    self.$usdtCountryInput.addClass("error");
                }
            } else if (method === 6) {
                if (jsonData.bank_name !== undefined) {
                    self.$usdtTrc20WalletInput.addClass("error");
                }
                if (jsonData.account_name !== undefined) {
                    self.$usdtTrc20NameInput.addClass("error");
                }
                if (jsonData.account_address1 !== undefined) {
                    self.$usdtTrc20Address1Input.addClass("error");
                }
                if (jsonData.account_address2 !== undefined) {
                    self.$usdtTrc20Address2Input.addClass("error");
                }
                if (jsonData.account_city !== undefined) {
                    self.$usdtTrc20CityInput.addClass("error");
                }
                if (jsonData.account_zip !== undefined) {
                    self.$usdtTrc20ZipInput.addClass("error");
                }
                if (jsonData.account_country !== undefined) {
                    self.$usdtTrc20CountryInput.addClass("error");
                }
            }

    },

    confirmUpdate: function(ev) {
        var self = this;
        var verificationCode = this.$verificationCodeInput.val();

        var data = {
                action: 'confirm',
                verification_code: verificationCode
            };

            new ApiRequest().request("data/partner-banking.php", data)
                .done(function (response) {
                    $(window).scrollTop(0);
                    if (response.status === 'ok') {
                        if (response.pending) {
                            self.$bankDetailsSuccessModal.show();
                            self.showHideAlerts('pending');
                        } else {
                            self.showHideAlerts('approved');
                        }
                    }
                    self.$confirmationModal.hide();
                })
                .fail(function (err) {
                    var jsonData = err.responseJSON;
                    if (jsonData.message) {
                        alert(jsonData.message);
                    }
                });
        },

        _togglePaymentMethod: function (ev) {
            var $radio = $(ev.currentTarget);


            if($radio.val() == '0') {
                this.$wireTransferForm.show();
                this.$payPalForm.hide();
                this.$paxumForm.hide();
                this.$bitcoinForm.hide();
                this.$litecoinForm.hide();
                this.$usdtForm.hide();
                this.$usdtTrc20Form.hide();
                this.paymentType = 'wiretransfer';
            } else if($radio.val() == '1') {
                this.$wireTransferForm.hide();
                this.$payPalForm.show();
                this.$paxumForm.hide();
                this.$bitcoinForm.hide();
                this.$litecoinForm.hide();
                this.$usdtForm.hide();
                this.$usdtTrc20Form.hide();
                this.paymentType = 'paypal';
            } else if($radio.val() == '2') {
                this.$wireTransferForm.hide();
                this.$payPalForm.hide();
                this.$paxumForm.show();
                this.$bitcoinForm.hide();
                this.$litecoinForm.hide();
                this.$usdtForm.hide();
                this.$usdtTrc20Form.hide();
                this.paymentType = 'paxum';
            } else if($radio.val() == '3') {
                this.$wireTransferForm.hide();
                this.$payPalForm.hide();
                this.$paxumForm.hide();
                this.$bitcoinForm.show();
                this.$litecoinForm.hide();
                this.$usdtForm.hide();
                this.$usdtTrc20Form.hide();
                this.paymentType = 'bitcoin';
            } else if($radio.val() == '4') {
                this.$wireTransferForm.hide();
                this.$payPalForm.hide();
                this.$paxumForm.hide();
                this.$bitcoinForm.hide();
                this.$litecoinForm.show();
                this.$usdtForm.hide();
                this.$usdtTrc20Form.hide();
                this.paymentType = 'litecoin';
            } else if($radio.val() == '5') {
                this.$wireTransferForm.hide();
                this.$payPalForm.hide();
                this.$paxumForm.hide();
                this.$bitcoinForm.hide();
                this.$litecoinForm.hide();
                this.$usdtForm.show();
                this.$usdtTrc20Form.hide();
                this.paymentType = 'usdt';
            } else if($radio.val() == '6') {
                this.$wireTransferForm.hide();
                this.$payPalForm.hide();
                this.$paxumForm.hide();
                this.$bitcoinForm.hide();
                this.$litecoinForm.hide();
                this.$usdtForm.hide();
                this.$usdtTrc20Form.show();
                this.paymentType = 'usdtTrc20';
            }



    },

        _showTransferFundsModal: function() {

            this.transferView.show();
        },


        _generateApiData() {
            var method = +this.$el.find('.paymentMethod:checked').val();
            var paymentHold = this.$paymentHoldInput.is(":checked") ? 1 : 0;
            var data = {
                "method": method,
                "min_payout": this.$minPayoutInput.val(),
                "payment_email": this.$paymentEmailInput.val(),
                "vat_no": this.$vatNoInput.val(),
                "payment_hold": paymentHold,
            };

            if (method === 1) {
                data['bank_name'] = this.$paypalInput.val();
                data['account_name'] = this.$paypalNameInput.val();
                data['account_address1'] = this.$paypalAddress1Input.val();
                data['account_address2'] = this.$paypalAddress2Input.val();
                data['account_city'] = this.$paypalCityInput.val();
                data['account_zip'] = this.$paypalZipInput.val();
                data['account_country'] = this.$paypalCountryInput.val();
            } else if (method === 2) {
                data['bank_name'] = this.$paxumInput.val();
                data['account_name'] = this.$paxumNameInput.val();
                data['account_address1'] = this.$paxumAddress1Input.val();
                data['account_address2'] = this.$paxumAddress2Input.val();
                data['account_city'] = this.$paxumCityInput.val();
                data['account_zip'] = this.$paxumZipInput.val();
                data['account_country'] = this.$paxumCountryInput.val();
            } else if (method === 3) {
                data['bank_name'] = this.$bitcoinInput.val();
                data['account_name'] = this.$bitcoinNameInput.val();
                data['account_address1'] = this.$bitcoinAddress1Input.val();
                data['account_address2'] = this.$bitcoinAddress2Input.val();
                data['account_city'] = this.$bitcoinCityInput.val();
                data['account_zip'] = this.$bitcoinZipInput.val();
                data['account_country'] = this.$bitcoinCountryInput.val();
                data['wallet_provider'] = this.$bitcoinWalletProviderInput.val();
            } else if (method === 4) {
                data['bank_name'] = this.$litecoinInput.val();
                data['account_name'] = this.$litecoinNameInput.val();
                data['account_address1'] = this.$litecoinAddress1Input.val();
                data['account_address2'] = this.$litecoinAddress2Input.val();
                data['account_city'] = this.$litecoinCityInput.val();
                data['account_zip'] = this.$litecoinZipInput.val();
                data['account_country'] = this.$litecoinCountryInput.val();
                data['wallet_provider'] = this.$litecoinWalletProviderInput.val();
            } else if (method === 5) {
                data['bank_name'] = this.$usdtWalletInput.val();
                data['account_name'] = this.$usdtNameInput.val();
                data['account_address1'] = this.$usdtAddress1Input.val();
                data['account_address2'] = this.$usdtAddress2Input.val();
                data['account_city'] = this.$usdtCityInput.val();
                data['account_zip'] = this.$usdtZipInput.val();
                data['account_country'] = this.$usdtCountryInput.val();
            } else if (method === 6) {
                data['bank_name'] = this.$usdtTrc20WalletInput.val();
                data['account_name'] = this.$usdtTrc20NameInput.val();
                data['account_address1'] = this.$usdtTrc20Address1Input.val();
                data['account_address2'] = this.$usdtTrc20Address2Input.val();
                data['account_city'] = this.$usdtTrc20CityInput.val();
                data['account_zip'] = this.$usdtTrc20ZipInput.val();
                data['account_country'] = this.$usdtTrc20CountryInput.val();
            } else {
                data['account_name'] = this.$accountNameInput.val();
                data['account_address1'] = this.$accountAddress1Input.val();
                data['account_address2'] = this.$accountAddress2Input.val();
                data['account_city'] = this.$accountCityInput.val();
                data['account_zip'] = this.$accountZipInput.val();
                data['account_country'] = this.$accountCountryInput.val();
                data['bank_name'] = this.$bankNameInput.val();
                data['bank_street'] = this.$bankStreetInput.val();
                data['bank_city'] = this.$bankCityInput.val();
                data['bank_zip'] = this.$bankZipInput.val();
                data['bank_country'] = this.$bankCountryInput.val();
                data['iban'] = this.$ibanInput.val();
                data['swift'] = this.$bicInput.val();
            }
            return data;
        },

        showPreviewData: function(data, confirm = true) {
            if (!data || data.method === undefined) {
                return '';
            }

            var map = {
                method: ['Wire Transfer', 'PayPal', 'Paxum', 'Bitcoin', 'Litecoin', 'USDT (ERC-20)', 'USDT (TRC-20)'],
                payment_hold: ['No', 'Yes'],
                all: {
                    min_payout: 'Minimum Payout (USD)',
                    payment_email: 'Payment Advice Email',
                    vat_no: 'VAT NO (Europe Only)',
                    account_name: 'Full Name of Beneficiary',
                    account_address1: 'Address (Line 1)',
                    account_address2: 'Address (Line 2)',
                    account_city: 'City',
                    account_zip: 'Zip/Postcode',
                    account_country: 'Country',
                },
                0: {
                    bank_name: 'Bank Name',
                    bank_street: 'Bank Street',
                    bank_city: 'Bank City',
                    bank_zip: 'Bank ZIP/Postcode',
                    bank_country: 'Bank Country',
                    iban: 'IBAN/Account Number',
                    swift: 'BIC/SWIFT'
                },
                1: {
                    bank_name: 'PayPal Email Address',
                },
                2: {
                    bank_name: 'Paxum Email Address',
                },
                3: {
                    bank_name: 'Bitcoin Wallet Address',
                },
                4: {
                    bank_name: 'Litecoin Wallet Address',
                },
                5: {
                    bank_name: 'USDT ERC20 Wallet',
                },
                6: {
                    bank_name: 'USDT TRC20 Wallet',
                },
            };

            var rows = [
                '<strong>Payment method:</strong> ' + map.method[data.method],
                '<strong>Hold payments:</strong> ' + map.payment_hold[data.payment_hold],
            ];
            _.each(map.all, function (val, key) {
                rows.push('<strong>' + val + ':</strong> ' + (data[key] || ''));
            });

            _.each(map[data.method], function (val, key) {
                rows.push('<strong>' + val + ':</strong> ' + (data[key] || ''));
            });

            var html = rows.join("<br>");
            var modal = this.$el.find('.bankDetailsPreviewModal');
            modal.find('.previewDetails').html(html);
            if (confirm) {
                modal.find('.alert-warning').show();
                modal.find('.confirmSave').show();
            } else {
                modal.find('.alert-warning').hide();
                modal.find('.confirmSave').hide();
            }
            modal.show();
        },

        showPendingDetails: function () {
            if (this.notApprovedData && this.notApprovedData.status === 'pending') {
                this.showPreviewData(this.notApprovedData, false);
            }
        },

        signIO: function (ev) {
            var self = this;
            this.showSignIOModal();
            new ApiRequest().request("v3/publisherInsertionOrder.php?action=getSigningUrl")
                .done(function (response) {
                    var url = response.url;
                    self.showSignIOSuccess('URL generated. Redirecting...', url); // just in case redirect below will not work
                    window.location.href = url;
                })
                .fail(function (error) {
                    var msg = 'Some error happened.'
                    if (error.responseJSON && error.responseJSON.message) {
                        msg = error.responseJSON.message;
                    }
                    self.showSignIOError(msg);
                });
        },

        showSignIOModal: function () {
            this.$signIOModal.find('.success').hide();
            this.$signIOModal.find('.error').hide();
            this.$signIOModal.find('.panel-footer').hide();
            this.$signIOModal.find('.loading').show();
            this.$signIOModal.show();
        },

        showSignIOError: function (msg) {
            this.$signIOModal.find('.errorMsg').text(msg);
            this.$signIOModal.find('.loading').hide();
            this.$signIOModal.find('.error').show();
            this.$signIOModal.find('.panel-footer').show();
        },

        showSignIOSuccess: function (msg, url) {
            this.$signIOModal.find('.successMsg').text(msg);
            this.$signIOModal.find('.goToSignURL').prop('href', url);
            this.$signIOModal.find('.loading').hide();
            this.$signIOModal.find('.success').show();
        },

        downloadIO: function (ev) {
            var api = new ApiRequest();
            var $btn = $(ev.currentTarget);
            $btn.prop('disabled', true);
            var url = API_URL + 'v3/publisherInsertionOrder.php?action=downloadIO';
            var req = new XMLHttpRequest();
            req.open('GET', url);
            req.setRequestHeader('X_AFFLOW_API_TOKEN', api.getToken());
            req.responseType = "blob";
            req.onreadystatechange = function (ev) {
                var blob;
                if (req.readyState === 4) {
                    if (req.status === 200) {
                        blob = new Blob(
                            [ req.response ],
                            { type: 'application/pdf' }
                        );

                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'IO.pdf';
                        link.click();
                        URL.revokeObjectURL(link.href);
                    } else {
                        blob = new Blob(
                            [ req.response ],
                            { type: 'application/json' }
                        );

                        blob.text().then(function (responseText) {
                            var error = JSON.parse(responseText);
                            if (error.message) {
                                alert(error.message)
                            } else {
                                alert('File not found.');
                            }
                        });
                    }
                    $btn.prop('disabled', false);
                }
            };
            req.send();
        },

        _toggleLang: function (ev) {

            this.$el.find('.langContainer').hide();
            this.$el.find('.langContainer[data-id="'+ev.currentTarget.dataset.id+'"]').show();

        },

        sleep: function() {
            // nothing to sleep
        },

        wakeUp: function() {
        }

    });

    return new BankingView();

});

