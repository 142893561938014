/*jslint nomen:true, bitwise: true, indent:4*/
/*global define, _, $*/

define([
    "Backbone",
    "moment-timezone",
    "workers/apirequest",
    "registry",
    "polyfills/formatk",
    "polyfills/addcomma",
    "views/pureSelect",
    "templates/leaderboard.html",
    "templates/leaderboardRow.html"
], function (Backbone, moment, ApiRequest, registry, formatThousands, addCommas, PureSelect, template, tableRowTemplate) {
    var View = Backbone.View.extend({

        // Constants
        REFRESH_INTERVAL: (30000),
        TIMEZONE: 'America/New_York',

        // Properties
        _subViews: [],
        _namePrefixes: [],
        _names: [],
        data: [],
        totals: null,
        hasInitialData: null,
        supplimentalData: null,
        $sortingSelect: null,

        $tbody: null,

        // Events
        events: {
        },

        // Methods
        initialize: function () {
            var self = this;

            self._names = [
                "Talonflame",
                "Buzzwole",
                "Kartana",
                "Vaporeon",
                "Ditto",
                "Entei",
                "Mega Mawile",
                "Mega Kangaskhan",
                "Lugia",
                "Zekrom",
                "Mew",
                "Mewtwo",
                "Xerneas",
                "Reshiram",
                "Giratina",
                "Ho-Oh",
                "Kyurem Black",
                "Kyurem White",
                "Primal Kyogre",
                "Dialga",
                "Rayquaza",
                "Mega Mewtwo X",
                "Primal Groudon",
                "Arceus",
                "Ultra Necrozma",
                "Cubone",
                "Jigglypuff",
                "Crobat",
                "Heracross",
                "Politoad",
                "Weavile",
                "Ludicolo",
                "Samurott",
                "Blissey",
                "Empoleon",
                "Gardevoir",
                "Spiritomb",
                "Slaking",
                "Blaziken",
                "Rayquaza",
                "Hydreigon",
                "Sceptile",
                "Gengar",
                "Ninetales",
                "Lugia"
            ];

        },

        getRandomName: function() {
            return name = this._names[Math.floor(
                Math.random() * this._names.length)];
        },

        getData: function () {
            var dfd = $.Deferred(),
                self = this,
                preset = this.$sortingSelect.val(),
                label = this.$sortingSelect.currentLabel() || 'Current';

            $.when(this.makeLeaderboardRequest(preset),
                   this.makeSummaryRequest(preset),
                   this.makeTotalsRequest())
                .done(function (leaderboardArgs, summaryArgs, totalsArgs) {
                    var data = leaderboardArgs[0],
                        summary = summaryArgs[0],
                        totals = totalsArgs[0];

                    self.data = data;
                    self.summary = summary[0];
                    self.totals = totals;
                    self.label = label;
                    self.trigger("update:data");
                    dfd.resolve();
                })
                .fail(function () {
                    dfd.reject();
                });

            return dfd.promise();
        },

        render: function () {
            var self = this,
                docFragment = $(document.createDocumentFragment()),
                table = null;

            if (registry.user.getAccessLevel() === 'basic') {
                return;
            }

            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("mainPane")
                .attr("id", "leaderboardContainer");

            this.$el.append(_.template(template)());
            $('#appContainer').append(this.$el);

            table = this.$el.find('.leaderboardTable');


            this.$sortingSelect = new PureSelect()
                .addClass("leaderSelect")
                .updateData({
                    "month": "Last 30 Days"}, "month")
                .appendTo(this.$el.find('.dateSelect'))
                .on("change", this.timeChanged, this);
            this._subViews.push(this.$sortingSelect);





            if (registry.user.private) {
                this.$el.find('.totals').show();
                this.$totalClicksVal = this.$el.find('.totalClicks .value');
                this.$totalLeadsVal = this.$el.find('.totalLeads .value');
                this.$totalRevVal = this.$el.find('.totalRev .value');
                this.$totalRevAfflowVal = this.$el.find('.totalRevAfflow .value');
                this.$totalRevMonetizerVal = this.$el.find('.totalRevMonetizer .value');

            }



            this.$el.append(docFragment);
            this.$tbody = table.find("tbody");

            self.hasInitialData = this.getData();
            self.hasInitialData.done(function () {
                self.startPoll.call(self);
            });
            this.on("update:data", this.update, this);

            this.getData();
            this.startPoll();

            $.when(this.hasInitialData).done(function () {
                self.$el.transition({
                    opacity: 1
                });
            });

            return this.$el;
        },

        update: function () {
            var self = this,
                revenue = 0.00,
                affector = null,
                yearText = null,
                totalText = null,
                onlineClass = null,
                onlineText = null,
                isCurrentUser = false,
                nowTs = Math.round(Date.now() / 1000),
                rLastLogin,
                rTemplateData,
                mainFragment = $(document.createDocumentFragment());

            if (registry.user.private) {
                this.updateTotals();
            }

            this.$tbody.empty();
            this.$el.find('.currentHeading').text(this.label);

            this.data.forEach(function (row, index) {
                if (parseFloat(row.revenue) < 0.01) {
                    return false;
                }

                yearText = addCommas(row.year_revenue.toFixed(2));
                totalText = addCommas(row.total_revenue.toFixed(2));

                rLastLogin = row.lastLogin || 0;
                if (Math.abs(rLastLogin - nowTs) > (15*60)) {
                    onlineClass = "u_offline";
                    onlineText = "Offline";
                } else {
                    onlineClass = "u_online";
                    onlineText = "Online";
                }

                revenue = row.revenue.toFixed(2);

                if (index > 9) {
                    affector = " class='faded'";
                }

                if (revenue < 0.01) {
                    affector = " class='dead'";
                }

                if (row.nick === registry.user.nick) {
                    isCurrentUser = true;
                    affector = " class='currentUser " + index + "th'";
                } else {
                    isCurrentUser = false;
                    affector = " class='tr" + index + "th'";
                }

                rTemplateData = {
                    rowClass: affector,
                    image: row.nick_img.replace('http:','https:'),
                    nick: self.getRandomName(),
                    id: row.id,
                    onlineClass: onlineClass,
                    onlineText: onlineText,
                    rankClass: "afflowRank" + (index + 1) + self.ordsfx(index + 1),
                    rankText: (index + 1) + self.ordsfx(index + 1),
                    revenue: "$" + addCommas(revenue),
                    yearText: "$" + yearText,
                    totalText: "$" + totalText
                };

                if(revenue > 0.01) {
                    if (mainFragment.children().length < 30 && revenue > 0.01) {

                        self.$tbody.append(_.template(tableRowTemplate)(rTemplateData));

                        if (isCurrentUser === true) {
                            self.trigger("update:rank", (index + 1) + self.ordsfx(index + 1));
                        }
                    }
                }
            });

            self.$tbody.append(mainFragment);
        },

        updateTotals: function () {
            var summary = this.summary,
                totals = this.totals,
                clicks = parseInt(summary.clicks),
                leads = parseInt(summary.leads),
                revenue = parseFloat(summary.revenue),
                afflowRev = parseFloat(totals.revenue),
                monetizerRev = parseFloat(totals.referral_revenue);

            function formatCurrency(amount) {
                return '$' + formatThousands(amount);
            }

            this.$el.find('.totals .currentLabel').text(this.label);

            this.$totalClicksVal.text(formatThousands(clicks));
            this.$totalLeadsVal.text(formatThousands(leads));
            this.$totalRevVal.text(formatCurrency(revenue));

            this.$totalRevAfflowVal.text(formatCurrency(afflowRev));
            this.$totalRevMonetizerVal.text(formatCurrency(monetizerRev));
        },

        ordsfx: function (a){
            return["th","st","nd","rd"][(a=~~(a<0?-a:a)%100)>10&&a<14||(a%=10)>3?0:a];
        },

        startPoll: function () {
            var self = this,
                callback = function () {
                    var dfd = null;

                    if (!self.isAsleep && document.hasFocus()) {
                        dfd = self.getData.call(self);
                    }
                    self.timeoutInstance = window.setTimeout(callback, self.REFRESH_INTERVAL);
                };

            window.clearTimeout(this.timeoutInstance);
            this.timeoutInstance = window.setTimeout(callback, this.REFRESH_INTERVAL);
        },

        timeChanged: function () {
            this.getData();
        },

        makeLeaderboardRequest: function (preset) {
            var req = new ApiRequest(),
                url;

            url = 'data/partner-leaderboard-new.php'
                + '?mode=' + preset;

            return req.request(url);
        },

        makeSummaryRequest: function (preset) {
            var req = new ApiRequest(),
                range = this.presetToRange(preset),
                url;

            if (registry.user.private) {
                url = 'data/report.php'
                    + '?keyword=summary'
                    + '&fast=1'
                    + '&start_ts=' + range.start_ts
                    + '&end_ts=' + range.end_ts;

                return req.request(url);
            }

            return [[ { clicks: 0, leads: 0, revenue: 0 } ]];
        },

        makeTotalsRequest: function () {
            var req = new ApiRequest(),
                url;

            if (registry.user.private) {
                url = 'data/revenue.php';
                return req.request(url);
            }

            return [{ revenue: 0, referral_revenue: 0 }];
        },

        presetToRange: function (preset) {
            var start_ts = moment.tz(this.TIMEZONE).startOf('day'),
                end_ts = moment.tz(this.TIMEZONE);

            switch (preset) {
            case 'yesterday':
                end_ts = start_ts.clone().subtract(1, 'second');
                start_ts.subtract(1, 'day');
                break;
            case 'week':
                start_ts.subtract(6, 'days');
                break;
            case 'month':
                start_ts.subtract(29, 'days');
                break;
            }

            return {
                start_ts: start_ts.unix(),
                end_ts: end_ts.unix()
            };
        },

        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        }
    });


    return new View();
});
