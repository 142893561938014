/*jslint nomen:true, indent:4*/
/*global define, console*/

define(["Backbone", "workers/apirequest", "moment-timezone", "registry", "polyfills/addcomma"], function (Backbone, ApiRequest, moment, registry, addCommas) {

	var View = Backbone.View.extend({

		// Constants

		// Properties
		$gravatar: null,
		$earningsText: null,
		$yesterEarningsText: null,
		$weekEarningsText: null,
		$monthEarningsText: null,
		$rankText: null,

		_timeoutInstance: null,
		REFRESH_INTERVAL: 10000,
		DEFAULT_AVATAR: 'https://api.afflow.rocks/202-img/default.png',



		// Methods
		initialize: function () {
            var self = this;

            this.gravatarContainer = $('.userAvatar');
            this.earningsContainer = $('.todayEarnings');
            this.pushEarningsContainer = $('.todayPushEarnings');
            this.$menuPushRevenue = $('.menuPushRevenue');
            this.yesterdayContainer = $('.yesterdayEarnings');
            this.weekContainer = $('.weekEarnings');
            this.monthContainer = $('.monthEarnings');
            this.allTimeContainer = $('.allEarnings');
            this.nameContainer = $('.userName');

			this.$gravatar = this.getGravatar();


			this.gravatarContainer
				.addClass("gravatarContainer")
				.css('background-image','url("'+this.$gravatar+'")');

            this.nameContainer.text(registry.user.name);




			this.on("postrender", function () {
				this.getData();
				this.startPoll();
			}, this);




			this.on("update", this.updateData, this);

			this._hasInitialData = false;
			this.hasData = $.Deferred();
		},

		startPoll: function () {
            var self = this,
                callback = function () {
					if (document.hasFocus()) {
						self.getData.call(self, true, true);
					}
					self._timeoutInstance = window.setTimeout(callback, self.REFRESH_INTERVAL);
                };

            window.clearTimeout(this._timeoutInstance);
            this._timeoutInstance = window.setTimeout(callback, this.REFRESH_INTERVAL);
        },

		getData: function() {
			var self = this,
				dfd = $.Deferred(),
				endMoment = moment().tz("America/New_York"),
				startMoment = moment().tz("America/New_York").startOf("month").subtract(1, 'day'),
				startStr = startMoment.format("YYYY-MM-DD"),
				endStr = endMoment.format("YYYY-MM-DD"),
				request = new ApiRequest(),
				requestAll = new ApiRequest(),
                requestPush = new ApiRequest(),
                requestDirect = new ApiRequest(),
                directOffersRead = registry.user.getLastReadDirectOffers();

			if(registry.user.id === null) {
                dfd.reject();
                return;
            }

                $.when(
                    request.request("data/earnings.php?start_date=" + startStr + "&end_date=" + endStr),
                    requestAll.request("data/partner-revenue.php"),
                    requestPush.request("v2/PublisherPushRevenue"),
                    requestDirect.request("v3/publisherUnreadDirectOffers.php?readDate="+directOffersRead),
                    requestDirect.request("v3/publisherUnreadOfferMarketTotal.php")

        )
                .then(function (resultsResponse, allResponse, pushResponse, directResponse, offerMarketResponse) {
                    var results = resultsResponse[0],
                        allTime = allResponse[0],
                        push = pushResponse[0],
                        direct = directResponse[0],
                        offerMarket = offerMarketResponse[0],
                        pushRevenue = parseFloat(push.push_revenue || 0).toFixed(2),
                        amount = 0,
                        display = 0;

                    if (results === false) {
                        console.log("Error getting earnings data");
                        dfd.reject();
                    } else {
                        self.trigger("update", results);

                        if (allTime.amount !== null) {
                            amount = parseFloat(allTime.amount);
                            display = amount > 1000000 ? (amount / 1000000).toFixed(3) + 'M' : amount > 1000 ? (amount / 1000).toFixed(1) + 'K' : amount.toFixed(1);
                        } else {
                            amount = '0.00';
                        }

                        self.allTimeContainer.text('$'+display);
                        self.pushEarningsContainer.text('$'+pushRevenue);

                        $('.menuPushRevenue').text('$'+parseFloat(pushRevenue).toFixed(0)).show();

                        if (results[0] !== undefined && results[0].amount !== undefined && moment.tz(results[0].date, "America/New_York").isSame(moment.tz('America/New_York'), "day")) {
                            var today = Math.abs(parseFloat(results[0].amount) + parseFloat(results[0].referral_amount)).toFixed(2);
                            today = today - pushRevenue;
                            $('.menuCampaignRevenue').text('$'+parseFloat(today).toFixed(0)).show();
                        }
                        else {
                            $('.menuCampaignRevenue').text('$0').show();
                        }



                        if(directOffersRead !== false && parseInt(direct.total) > 0) {
                            $('.menuDirectOffers').text(parseInt(direct.total) + " NEW").show();
                        } else {
                            $('.menuDirectOffers').hide();
                        }

                        if(registry.user.unreadOfferMarket === null) {
                            registry.user.unreadOfferMarket = parseInt(offerMarket.total);
                            if(parseInt(offerMarket.total) > 0) {
                                $('.menuOfferMarket').text(parseInt(offerMarket.total)).show();
                            } else {
                                $('.menuOfferMarket').hide();
                            }

                        }

                        dfd.resolve();
                    }
                });

			return dfd.promise();
		},


		render: function () {
			this.trigger("postrender");
		},

		getGravatar: function () {
            if ((registry.user.nick_img === null || registry.user.nick_img === '') && registry.user.logo_img === '') {
                return 'https://api.afflow.rocks/202-img/default.png';

            }
            else if (registry.user.logo_img !== '') {
            	return 'https://api.afflow.rocks/202-img/company_logos/' + registry.user.logo_img;
            }
            else {
                return registry.user.nick_img.replace('http:','https:');

            }
		},


		setEarningsText: function (text, yesterday, week, month) {
		    var api = new ApiRequest();

            month = month > 1000000 ? (month / 1000000).toFixed(3) + 'M' : month > 1000 ? (month / 1000).toFixed(1) + 'K' : month.toFixed(2);

            registry.user.earnings.today = text;
            registry.user.earnings.yesterday = yesterday;
			this.earningsContainer.text(text);
			this.yesterdayContainer.text(yesterday);
			this.weekContainer.text(week);
			this.monthContainer.text('$'+month);
            if(registry.user.walkytalkyUserHash) {
                api.request("data/earnings.php", {
                    'walkytalky': true,
                    'earnings_yesterday': yesterday,
                    'earnings_week': week,
                    'earnings_month': month,


                })
            }
		},

		setRankText: function(text) {
			//this.$rankText.text(text);
		},

		updateData: function (data) {
			var rRow = null,
				rWorkingMoment = null,
				today = 0.00,
				yesterday = 0.00,
				weekTotal = 0.00,
				monthTotal = 0.00,
				todayDate = moment.tz("America/New_York"),
				yesterdayDate = moment.tz("America/New_York").subtract(1, "day"),
				startMonth = moment().tz("America/New_York").startOf("month"),
				startWeek = moment().tz("America/New_York").startOf("week");


			if (data[0] !== undefined &&
				data[0].amount !== undefined &&
				moment.tz(data[0].date, "America/New_York").isSame(todayDate, "day"))
			{
				today = "$" + Math.abs(parseFloat(data[0].amount) + parseFloat(data[0].referral_amount)).toFixed(2);
			} else {
				today = "$0.00";
			}

			if (data[1] !== undefined &&
				data[1].amount !== undefined &&
				moment.tz(data[1].date, "America/New_York").isSame(yesterdayDate, "day"))
			{
				yesterday = "$" + Math.abs(parseFloat(data[1].amount) + parseFloat(data[1].referral_amount)).toFixed(2);
			} else if (data[0] !== undefined &&
				data[0].amount !== undefined &&
				moment.tz(data[0].date, "America/New_York").isSame(yesterdayDate, "day"))
			{
				yesterday = "$" + Math.abs(parseFloat(data[0].amount) + parseFloat(data[0].referral_amount)).toFixed(2);
			} else {
				yesterday = "$0.00";
			}

			for (var i = 0; i < data.length; i++) {
				rRow = data[i];
				rWorkingMoment = moment.tz(rRow.date, "America/New_York");

				if (rWorkingMoment.isAfter(startMonth, "day") || rWorkingMoment.isSame(startMonth, "day")) {
					monthTotal += Math.abs(parseFloat(rRow.amount) + parseFloat(rRow.referral_amount));

					if (rWorkingMoment.isAfter(startWeek, "day") || rWorkingMoment.isSame(startWeek, "day")) {
						weekTotal += Math.abs(parseFloat(rRow.amount) + parseFloat(rRow.referral_amount));
					}
				}


			}

			weekTotal = weekTotal.toFixed(2);
			monthTotal = monthTotal.toFixed(2);
			this.setEarningsText(today, yesterday, "$" + addCommas(weekTotal), parseFloat(monthTotal));

			// We can boot the support system now we have the month rev figure, pass it back
			if (!this._hasInitialData) {
				this._hasInitialData = true;
				this.hasData.resolve(monthTotal);
			}
		},

	});

	return new View();

});

