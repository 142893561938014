define([
    "Backbone",
    "workers/apirequest",
    "dict/isocountries.json",
    "registry",
    "views/bigselect",
    "libs/offerMarketUtils",
    "templates/requests/requestForm.html",
], function (
    Backbone,
    Api,
    countryData,
    app,
    bigSelect,
    Utils,
    template
) {
    return Backbone.View.extend({
        events: {
            'click #rf_saveRequestBtn': 'save',
            'click .view-close': 'destroy',
            'change #rf_deviceInput': 'deviceChanged',
            'change #rf_connectionTypeInput': 'connectionTypeChanged',
            'keyup #rf_commentsInput': 'commentsChanged',
        },

        initialize: function(inputs) {
            this.parent = inputs.parent || null;
            this._subViews = [];
            if (inputs && inputs.request) {
                this.request = inputs.request;
                this.isEditMode = true;
            } else {
                this.request = {};
                this.isEditMode = false;
            }
            this.osData = [];
            this.carrierData = [];
            // render immediately without waiting of loaded data
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "requestsForm");
            this.$el.append(template);
            if (this.isEditMode) {
                this.$el.find('#headerTitleType').text('Edit')
            }
            $("#appContainer").append(this.$el);
            this.initInputs();
            this.$el.find('.overlay').fadeIn('fast');
        },

        initInputs: function() {
            var self = this;

            this.$trafficTypeInput = this.$el.find('#rf_trafficTypeInput');
            _.each(Utils.trafficTypeTitlesMap, function(title, value) {
                if (value === 'other') title = "Other (Please write in comment section)";
                this.$trafficTypeInput.append($("<option>", {value: value, text: title}));
            }, this);
            if (this.request.traffic_type) {
                this.$trafficTypeInput.val(this.request.traffic_type);
            }

            this.$conversionFlowInput = this.$el.find('#rf_conversionFlowInput');
            _.each(Utils.flowTitlesMap, function(title, value) {
                if (value === 'other') title = "Other (Please write in comment section)";
                this.$conversionFlowInput.append($("<option>", {value: value, text: title}));
            }, this);
            if (this.request.conversion_flow) {
                this.$conversionFlowInput.val(this.request.conversion_flow);
            }

            this.$minPayoutInput = this.$el.find('#rf_minPayoutInput');
            if (this.request.min_payout) {
                this.$minPayoutInput.val(this.request.min_payout);
            }

            this.$minCapInput = this.$el.find('#rf_minCap');
            if (this.request.min_cap) {
                this.$minCapInput.val(this.request.min_cap);
            }

            this.$lpWantedInputGroup = this.$el.find('input[name="lp_wanted"]');
            if (this.request.lp_wanted !== undefined) {
                this.$lpWantedInputGroup.each(function() {
                    if (+this.value === +self.request.lp_wanted) {
                        this.checked = true;
                    } else {
                        this.checked = false;
                    }
                });
            }

            this.$geoInputContainer = this.$el.find('#rm_geoInputContainer');
            this.geoInput = new bigSelect({
                searchLabel: "Countries",
                allText: "All Countries",
                placeholder: "Select offer targeting by countries"
            });
            this._subViews.push(this.geoInput);
            this.$geoInputContainer.append(this.geoInput.render());
            this.populateGeoInput();
            this.listenTo(this.geoInput, 'change', this.geoChanged);
            if (this.request.geo) {
                this.geoInput.val(this.request.geo);
            }

            this.$deviceInput = this.$el.find('#rf_deviceInput');
            if (this.request.device) {
                this.$deviceInput.val(this.request.device);
            }

            this.$connectionTypeInput = this.$el.find('#rf_connectionTypeInput');
            if (this.request.connection_type) {
                this.$connectionTypeInput.val(this.request.connection_type);
            }

            this.$carrierDataInputContainer = this.$el.find('#rf_carrierDataInputContainer');
            this.carrierDataInput = new bigSelect({
                searchLabel: "Carriers",
                allText: "All Carriers",
                placeholder: "Connection & country required"
            });
            this._subViews.push(this.carrierDataInput);
            this.$carrierDataInputContainer.append(this.carrierDataInput.render());
            if (this.request.geo && this.request.geo.length > 0) {
                this.loadCarrierData().then(function () {
                    self.populateCarrierDataInput();
                    if (self.request.carrier_data) {
                        self.carrierDataInput.val(self.request.carrier_data);
                    }
                });
            }

            this.$osDataInputContainer = this.$el.find('#rf_osDataInputContainer');
            this.osDataInput = new bigSelect({
                searchLabel: "Operating Systems",
                allText: "All Operating Systems",
                placeholder: "Device Type Required"
            });
            this._subViews.push(this.osDataInput);
            this.$osDataInputContainer.append(this.osDataInput.render());
            this.loadOSData().then(function() {
                self.populateOsDataInput();
                if (self.request.os_data) {
                    self.osDataInput.val(self.request.os_data);
                }
            });

            this.$commentsInput = this.$el.find('#rf_commentsInput');
            if (this.request.comments) {
                this.$commentsInput.val(this.request.comments);
            }

            this.$notifyInput = this.$el.find('#rf_notifyInput');
            if (this.request.notify === false) {
                this.$notifyInput.prop('checked', false);
            }
        },

        populateGeoInput: function() {
            if (!this.geoInput) {
                return;
            }
            var data = [];
            _.each(countryData, function(country) {
                data.push({
                    value: country.alpha_2_code,
                    text: country.alpha_2_code + " - " + country.country
                });
            });
            this.geoInput.updateData(data);
        },

        loadOSData: function() {
            var deferred = $.Deferred();
            var self = this;
            var api = new Api();

            if (!app.osData) {
                api.request("data/partner-ls-os.php").then(function(osData) {
                    self.osData = osData;
                    app.osData = osData; // cache value
                    deferred.resolve(self.osData);
                });
            } else {
                this.osData = app.osData;
                deferred.resolve(this.osData);
            }

            return deferred.promise();
        },

        populateOsDataInput: function() {
            if (!this.osDataInput) {
                return;
            }

            this.osDataInput.val([]);
            this.osDataInput.updateData([]);

            var deviceType = this.$deviceInput.val();
            if (deviceType && this.osData.length > 0) {
                var data = [];
                _.each(this.osData, function(os) {
                    var osType = os.os_name.indexOf("-Desktop") === -1 ? "mobile" : "desktop";
                    if (deviceType === 'all' || osType === deviceType) {
                        data.push({
                            value: os.os_name,
                            text: os.os_name
                        });
                    }
                }, this);
                this.osDataInput.updateData(data);
            }
        },

        loadCarrierData: function() {
            var deferred = $.Deferred();
            var self = this;
            var geo = this.geoInput.val();
            var api = new Api();

            if (!geo || geo.length === 0) {
                self.carrierData = [];
                deferred.resolve([]);
            } else {
                var geoParam = encodeURIComponent(JSON.stringify(geo));
                api
                    .request("data/partner-ls-carrier.php?geo_arr=" + geoParam)
                    .then(function(carrierData) {
                        self.carrierData = carrierData;
                        deferred.resolve(carrierData);
                    })
                    .fail(function() {
                        console.error("LS Carrier failed!!");
                    });
            }

            return deferred.promise();
        },

        populateCarrierDataInput: function() {
            if (!this.carrierDataInput) {
                return;
            }

            this.carrierDataInput.val([]);
            this.carrierDataInput.updateData([]);

            var geo = this.geoInput.val() || [];
            var connectionType = this.$connectionTypeInput.val();
            if (geo.length > 0 && connectionType && this.carrierData.length > 0) {
                var data = [];
                _.each(this.carrierData, function(carrier) {
                    if (connectionType === 'wifi') {
                        if (carrier.carrier.indexOf('WiFi') !== -1) {
                            data.push({
                                value: carrier.carrier,
                                text: carrier.carrier
                            });
                        }
                    } else if (connectionType === 'cellular') {
                        if (carrier.carrier.indexOf('WiFi') === -1) {
                            data.push({
                                value: carrier.carrier,
                                text: carrier.carrier
                            });
                        }
                    } else {
                        data.push({
                            value: carrier.carrier,
                            text: carrier.carrier
                        });
                    }
                }, this);
                this.carrierDataInput.updateData(data);
            }
        },

        destroy: function() {
            if (this._subViews.length > 0) {
                _.each(this._subViews, function(subView) {
                    subView.remove();
                }, this);

                this._subViews = [];
            }
            this.$el.find('.overlay').fadeOut('fast');
            this.remove();
        },

        save: function(ev) {
            var self = this;
            var api = new Api();
            var $btn = $(ev.currentTarget);
            var data = this.getFormData();

            if (this.isEditMode) {
                data.action = 'update';
                data.id = this.request.id;
            } else {
                data.action = 'create';
            }

            $btn.prop('disabled', true);
            api.request('v3/publisher-requests.php', data)
                .then(function(response) {
                    if (response.status === 'ok' && response.data) {
                        if (self.isEditMode) {
                            self.parent.trigger('myRequest:update', response.data);
                        } else {
                            self.parent.trigger('myRequests:new', response.data);
                        }
                    }
                    self.destroy();
                })
                .fail(function (error) {
                    if (error.responseJSON && error.responseJSON.message) {
                        alert(error.responseJSON.message);
                    } else {
                        alert('Server error');
                    }
                })
                .always(function() {
                    $btn.prop('disabled', false);
                });
        },

        getFormData: function() {
            var data = {};

            data.traffic_type = this.$trafficTypeInput.val();
            data.conversion_flow = this.$conversionFlowInput.val();
            data.min_payout = this.$minPayoutInput.val();
            data.min_cap = this.$minCapInput.val();
            data.lp_wanted = this.$el.find('input[name="lp_wanted"]:checked').val();
            data.geo = this.geoInput.val();
            data.device = this.$deviceInput.val();
            data.connection_type = this.$connectionTypeInput.val();
            data.carrier_data = this.carrierDataInput.val();
            data.os_data = this.osDataInput.val();
            data.comments = this.$commentsInput.val();
            if (this.$notifyInput.prop('checked')) {
                data.notify = '1';
            } else {
                data.notify = '0';
            }
            data.name = Utils.generateName(_.extend(this.request, data), 'publisher');

            return data;
        },

        deviceChanged: function () {
            this.populateOsDataInput();
        },

        connectionTypeChanged: function () {
            this.populateCarrierDataInput();
        },

        geoChanged: function () {
            this.loadCarrierData().then(this.populateCarrierDataInput.bind(this));
        },

        commentsChanged: function (ev) {
            if (this.isEditMode) {
                this.$el.find('.commentsChangeWarning').show();
            }
        }
    });
});

