/*jslint nomen:true, indent:4, bitwise:true*/
/*global define, _*/

define(["Backbone",
    "workers/apirequest",
    "registry",
    "moment-timezone",
    "templates/liveLeads.html",
    "templates/buzzLeadTemplate.html",
    ], function (Backbone, ApiRequest, registry, moment, template, leadTemplate) {

    var View = Backbone.View.extend({

        //CONSTANTS
        REFRESH_INTERVAL: (5000), // MS
        NEW_DURATION: 60, // S

        // Properties
        hasInitialData: null,
        timeoutInstance: null,
        _leadList: null,
        _platformLeadList: null,
        isAsleep: false,
        _nidNameArr: null,

        $_header: null,
        $_lastLeadContainer: null,
        $_platformLeadContainer: null,

        events: {
            "click a": "clickLink"
        },

        // Methods
        initialize: function () {

        },

        getData: function() {
            var self = this,
                leadReq = new ApiRequest(),
                platformLeadReq = new ApiRequest(),
                dfd = $.Deferred();

            // Populate NID name array
            if (this._nidNameArr === null) {
                this._nidNameArr = {};
                _.each(registry.user.nidInfoArr, function (nidRow) {
                    self._nidNameArr[nidRow.nid] = nidRow.name;
                });
            }

            leadReq.request("data/partner-lead-update.php?owner=1&limit=200").done(function (data) {
                if(data !== false) {
                    self._leadList = data;
                    self.trigger("update_leads");
                }
            });

            platformLeadReq.request("data/partner-lead-update.php?limit=200").done(function (data) {
                if(data !== false) {
                    self._platformLeadList = data;
                    self.trigger("update_platform_leads");
                }
            });

            $.when(leadReq, platformLeadReq).done(function () {
                dfd.resolve();
            }).fail(function () {
                dfd.reject();
            });

            return dfd.promise();
        },


        updateLeads: function () {
            var self = this,
                rParent = null,
                rNewRow = null,
                rMoment = null,
                rLeagueName = null;

            rParent = self.$_lastLeadContainer.parent();
            self.$_lastLeadContainer.detach();
            self.$_lastLeadContainer.empty();

            _.each(this._leadList, function (row) {
                rMoment = moment.unix(row.lead_time);
                rLeagueName = this._nidNameArr[row.nid];
                // bla

                if(row.offer_id === 'PushRevShare' && self.$el.find('.hidePushRevShareInput').is(':checked')) {
                    return;
                }

                rNewRow = $(_.template(leadTemplate)({
                    theDate: moment.unix(row.lead_time),
                    flagPath: require('./../../images/flags/'+row.country_code+'.png').default,
                    theAmount: row.amount,
                    theHash: row.offer_id,
                    theLeague: rLeagueName !== undefined ? rLeagueName : "",
                    theGeo: row.country_code === null ? "INT" : row.country_code,
                    theSplit: "" + row.country_code + " #" + row.vertical,
                    theVertical: row.vertical,
                    theOs: this._toClassName(row.os_name),
                    theBrowser: this._toClassName(row.browser),
                    theRcid: row.rcid,
                    clickClass: this._clickClass(row),
                    access: registry.user.getAccessLevel()
                }));

                self.$_lastLeadContainer.append(rNewRow);
            }, this);

            rParent.append(self.$_lastLeadContainer);
        },


        updatePlatformLeads: function () {
            var self = this,
                rParent = null,
                rNewRow = null,
                rMoment = null;

            rParent = self.$_platformLeadContainer.parent();
            self.$_platformLeadContainer.detach();
            self.$_platformLeadContainer.empty();

            _.each(this._platformLeadList, function (row) {
                rMoment = moment.unix(row.lead_time);
                if (registry.user.hasVerticalAccess(row.vertical)) {


                    rNewRow = $(_.template(leadTemplate)({
                        theDate: moment.unix(row.lead_time),
                        theAmount: row.amount,
                        theHash: row.offer_id,
                        theLeague: "",
                        theGeo: row.country_code === null ? "INT" : row.country_code,
                        flagPath: require('./../../images/flags/'+row.country_code+'.png').default,
                        theSplit: "" + row.country_code + " #" + row.vertical,
                        theVertical: row.vertical,
                        theOs: this._toClassName(row.os_name),
                        theBrowser: this._toClassName(row.browser),
                        theRcid: '',
                        clickClass: this._clickClass(row),
                        access: registry.user.getAccessLevel()
                    }));

                    self.$_platformLeadContainer.append(rNewRow);
                }
            }, this);

            rParent.append(self.$_platformLeadContainer);
        },


        _clickClass: function (row) {
            if (row.is_omega & 1) {
                return "omega";
            }

            if (row.is_vauto & 1) {
                return "vauto";
            }

            return "manual";
        },


        render: function () {
            var self = this;

            this.setElement($(document.createElement("div")));
            this.$el
                .addClass("mainPane")
                .attr("id", "liveLeadsContainer");


            this.$el.append(template);
            $('#appContainer').append(this.$el);

            this.$_lastLeadContainer = this.$el.find("#recentLeadList");
            this.$_platformLeadContainer = this.$el.find("#recentLeadPlatformList");

            self.hasInitialData = this.getData();
            self.on("update_leads", this.updateLeads, this);
            self.on("update_platform_leads", this.updatePlatformLeads, this);

            $.when(self.hasInitialData).done(function () {
                self.$el.css('opacity',1);
            }).always(function () {
                self.startPoll.call(self, true);
            });

            return this.$el;
        },

        startPoll: function () {
            var self = this,
                callback = function () {
                    var dfd = null;

                    if (!self.isAsleep && document.hasFocus()) {
                        dfd = self.getData.call(self);
                    }

                    self.timeoutInstance = window.setTimeout(callback, self.REFRESH_INTERVAL);
                };

            window.clearTimeout(this.timeoutInstance);
            this.timeoutInstance = window.setTimeout(callback, this.REFRESH_INTERVAL);
        },


        sleep: function() {
            this.isAsleep = true;
        },

        wakeUp: function() {
            this.isAsleep = false;
        },

        _toClassName: function (raw) {
            return (raw + '').toLowerCase().replace(/[^a-z0-9]+/gi, '-');
        }
    });


    return new View();
});
