/*jslint nomen:true, indent:4*/
/*global define, _, alert, console */

define([
    "Backbone",
    "registry",
    "workers/apirequest",
    "templates/notifications.html",
    "templates/notification.html",
    "templates/nonotification.html"
], function (Backbone, registry, ApiWorker, template, itemTemplate,
    emptyTemplate)
{
    var View = Backbone.View.extend({

        limit: 25,
        page: 0,
        total: 0,
        notificationList: [],
        visible: false,


        events: {
            "click #notificationHide": "hide",
            "click #notificationArchiveAll": "clickArchiveAll",
            "click .archiveNotification": "clickNotification",
            "click .notificationLoadMore": "_loadMore",
        },

        // Methods
        initialize: function () {
            var self = this;

            this.setElement(_.template(template)());


            // PROPERTIES
            this.$el = $(this.el);
            this.$listContainer = this.$el.find("#notificationList");
            this.$loadMoreBtn = this.$el.find('.notificationLoadMore');
            this.notificationTemplate = _.template(itemTemplate);
            this.emptyTemplate = _.template(emptyTemplate);
            this.notificationsTimeout = null;

            $("body").append(this.el);
            this.hide();

            registry.on("updated:notifications", function (notifications) {
                var unreadRows = _.where(notifications,{is_read:"0"}),
                    unread  = unreadRows.length;

                if(unread > 10) {
                    $("#notificationsShowLink").find(".dot").html('10+');
                } else if(unread > 0) {
                    $("#notificationsShowLink").find(".dot").html(unread);
                } else {
                    $("#notificationsShowLink").find(".dot").empty();
                }

            });

            $("#notificationsShowLink").bind("click", function () {
                if (self.$el.is(":visible")) {
                    self.hide();
                } else {
                    self.show();
                }
            });

            this.pollNotifications();
        },

        pollNotifications: function () {
            if (this.notificationsTimeout) {
                clearTimeout(this.notificationsTimeout);
            }
            var self = this,
                doPoll = function() {
                    if(registry.user.id === null || !document.hasFocus()) {
                        self.notificationsTimeout = setTimeout(function () {
                            doPoll();
                        }, 5000);

                    } else {
                        var apiRequest = new ApiWorker();
                        apiRequest.request("v3/notifications.php")
                            .done(function (data) {
                                try {
                                    registry.trigger("updated:notifications", data);
                                } catch (e) {
                                    console.error("Notifications API failed", e);
                                }
                            })
                            .fail(function (err) {
                                if(err.status === 404) {
                                    console.error("Notifications API missing", e);
                                }
                            })
                            .always(function () {
                                self.notificationsTimeout = setTimeout(function () {
                                    doPoll();
                                }, 5000);
                            });

                    }
                };

            doPoll();
        },

        nNotificationsShown: function () {
            return this.$listContainer.children(".notification").length;
        },


        clickArchiveAll: function (ev) {
            var self = this,
                $btn = $(ev.currentTarget),
                btnText = $btn.html(),
                req = new ApiWorker();

            $btn.html('<i class="fa fa-cog fa-spin fa-fw" style="font-size:14px;"></i> Archiving..');


            req.request("v3/notifications.php", {
                "action": "archive"
            }).done(function () {
                $btn.html(btnText);
                self.$el.find(".notification").remove();
                $("#notificationArchiveAll").hide();
                self.$listContainer.append(self.emptyTemplate());
                $('#notificationsShowLink .dot').html('');
            });
        },

        clickNotification: function (el) {
            var self = this,
                req = new ApiWorker(),
                cid = $(el.currentTarget).data("cid");

            req.request("v3/notifications.php", {
                "action": "archive",
                "notification": cid
            }).done(function () {
                $(el.currentTarget).closest(".notification").remove();
                if (self.nNotificationsShown() == 0) {
                    $("#notificationArchiveAll").hide();
                    self.$listContainer.append(self.emptyTemplate());
                }
            });
        },

        show: function () {
            var self = this,
                $appContainer = $("#appContainer");

            this.visible = true;

            this.page = 0;

            if ($appContainer) {
                this.$el.css("left", $appContainer.position().left);
                this.$el.css("top", $appContainer.position().top);
            }

            this.$el.show();

            self.hideOnClickOutside('#notificationsContainer');

            this.$listContainer.empty();

            this._getRows();
        },

        _getRows: function() {
            var self = this,
                req = new ApiWorker(),
                params = new URLSearchParams({
                    limit: this.limit,
                    page: this.page
                });


            req.request("v3/notifications.php?"+params)
                .then(function(response) {
                    var idArr = [];

                    if(response.length > 0) {
                        self.$el.find(".emptyMessage").hide();
                        $("#notificationArchiveAll").show();

                        _.each(response, function(row) {
                            row.mo = moment(row.notification_ts);

                            idArr.push(row.id);

                            self.$listContainer.append(self.notificationTemplate(row));
                        });

                        if(response.length < self.limit) {
                            self.$loadMoreBtn.hide();
                        } else {
                            self.$loadMoreBtn.show();
                        }

                        req.request("v3/notifications.php", {
                            "action": "read",
                            "notifications": idArr.join()
                        });


                    } else {
                        $("#notificationArchiveAll").hide();
                        self.$loadMoreBtn.hide();
                        self.$el.find(".emptyMessage").show();
                    }

                });

        },

        _loadMore: function() {
            this.page++;
            this._getRows();
        },


        hide: function () {
            this.visible = false;
            this.$el.hide();
            this.page = 0;
        },
        hideOnClickOutside: function (selector) {
            var self = this;
            function outsideClickListener(event) {
                var $target = $(event.target);
                if(!$target.closest(selector).length && !$target.closest('#notificationsShowLink').length && $(selector).is(':visible')) {
                    self.hide();
                }
            }
            document.addEventListener('click', outsideClickListener)
        },
    });

    return new View();
});
