/*jshint nomen:true, indent:4*/
/*global define, window, document, $, console, _, requirejs*/

define(["Backbone",
    "moment-timezone",
    "workers/apirequest",
    "registry",
    "modules/leaguerow",
    "views/pureSelect",
    "views/daterange",
    "templates/league.html",
    "templates/leagueRow.html",
    "templates/leagueFooter.html"
], function (Backbone, moment, ApiRequest, registry, LeagueRow, PureSelect, DateRange, leagueTemplate, leagueRowTemplate, leagueFooterTemplate) {

    var LeagueView = Backbone.View.extend({

        _subViews: [],
        data: null,
        providerData: null,
        timezone: null,
        tzPromise: null,
        asleep: 1,

        hasData: null,
        timeoutInstance: null,
        nid: null,
        showMenuButton: null,
        info: null,
        currentRequest: null,
        tfooterTemplate: null,
        showPaused: true,

        lastFCheck: null,

        sortMethod: null,
        sortDirection: false,

        $infoContainer: null,
        $infoText: null,
        $loadingText: null,
        $optionBar: null,
        $tableBody: null,
        $tableFooter: null,
        $createButton: null,
        $filterButton: null,
        $timeButton: null,
        $showHidePausedButton: null,
        $profitButton: null,
        $profitText: null,

        $rowOptionsEl: null,
        $rowOptionsRowRef: null,

        $bogeyNotifier: null,
        $leagueLegend: null,

        REFRESH_INTERVAL: 5000,
        HIDING_TEXT: "Show Paused",
        SHOWING_TEXT: "Hide Paused",
        NA_TEXT: "-",
        LOADER_SHOWN_OPACITY: 0.85,

        events: {
            "click #leagueRefreshButton": "clickRefresh",
            "click #filterLeagueButton": "clickFilterLeagues",
            //"click #notesLeagueButton": "clicknotesLeagues",
            "click #leaguesCustomButton": "clickCustom",
            "click .reportBtn": "clickRow",
            "click .hidePausedButton": "clickShowHidePaused",
            "click .leagueBogeyNotifier": "clickBogeyText",
            "click .leagueHide": "clickFilter",
            "click th": "clickTh",
            "click .leagueSpend.editable": "clickLeagueSpend",
            "mouseover .leagueRow": "overRow",
            "mouseout .leagueRow": "outRow"
        },

        initialize: function (options) {
            var self = this,
                infoRequest = new ApiRequest(),
                docFragment = $(document.createDocumentFragment()),
                table = null;

            this.$el.html(_.template(leagueTemplate)({
                access: registry.user.getAccessLevel()

            }));

            this.tfooterTemplate = _.template(leagueFooterTemplate);
            table = this.$el.find('.leagueTable');
            var panelContent = this.$el.find('.panel-content');
            var $panelHeader = this.$el.find('.panel-header');

            this.nid = options.nid;
            this.info = options.info;

            this.$el.addClass('league-row');
            this.$el.attr('data-name', this.info.name || '');

            this.dateRange = new DateRange({
                element: $panelHeader.find('.datetimepicker')

            });
            this.dateRange.on('change', this.getData, this);
            this.dateRange.on('timezoneChange', this.updateNidTimezone, this);
            this._subViews.push(this.dateRange);

            // Set up the external stats
            try {
                // All timezone default to NY time
                if(this.info.timezone !== null) {
                    self.timezone = this.info.timezone;

                } else {
                    self.timezone = "America/New_York";

                }
                self.dateRange.setTimezone(self.timezone);
            } catch (except) {
                console.log(except); //eslint-disable-line no-console
            }

            // Init
            this.sortMethod = this.sortByProfit;
            this.sortDirection = false;

            if (options.multiple !== undefined && (options.multiple === true || options.multiple === false)) {
                this.showMenuButton = options.multiple;
            } else {
                this.showMenuButton = false;
            }



            this.$infoText =  this.$el.find('.leaguePanel > .panel-header  .title-wrapper')
                .attr("id", "leaguesInfoText")
                .addClass("leaguesInfoText");

            // this.$miniSelectWrapper = $(document.createElement("div"))
            //     .addClass("league-")
            //     .appendTo(this.$infoContainer);

            this.$miniSelect = new PureSelect()
                .placeholder("Loading")
                .addClass("leagueSelect")
                .appendTo(this.$infoContainer)
                .on("change", this.selectedLeague, this);

            this._subViews.push(this.$miniSelect);

            // if theres more than one league we need a league select container
            this.updateMiniSelect();

            this.$loadingText = $(document.createElement("div"))
                .addClass("loadingText")
                .html(" ")
                .css({"opacity": 0})
                .attr("id", "leagueAfflowLoadingText")
                .appendTo(docFragment);

            this.$providerLoadingText = $(document.createElement("div"))
                .addClass("providerLoadingText")
                .addClass(function () {
                    if (self.info.network === null) {
                        return "null";
                    }

                    return self.info.network;

                })
                .html("Downloading Spend...")
                .css({"opacity": 0})
                .attr("id", "leagueProviderLoadingText")
                .appendTo(docFragment);

            this.$optionBar = $(document.createElement("div"))
                .addClass("optionBar")
                .appendTo(docFragment);

            //this.$timeButton = $(document.createElement("div"))
            //     .text("timeSince")
            //     .attr("id", "timeLeagueButton")
            //     .addClass("textButton")
            //    .appendTo(this.$optionBar);


            this.$profitText = this.$el.find('.profitLoad');


            this.$showHidePausedButton = this.$el.find('.hidePausedButton');
            this.$showHidePausedButton
                .html(this.SHOWING_TEXT)
                .addClass("showing");

            /*
            this.$filterButton = $(document.createElement("div"))
                 .text("Manage Leagues")
                 .attr("id", "filterLeagueButton")
                 .addClass("textButton")
                 .appendTo(this.$optionBar);
                 */

            /*
            this.$createButton = $(document.createElement("div"))
                 .text("New League")
                 .attr("id", "addLeagueButton")
                 .addClass("textButton")
                 .appendTo(this.$optionBar);
                 */

            this.$bogeyNotifier = this.$el.find('.leagueBogeyNotifier');
            this.$bogeyNotifier
                .addClass("textButton")
                .text('0 Filtered')

            this.dateRange.render();


            this.$tableBody = table.find('tbody');

            this.$tableFooter =  table.find('tfoot');

            this.$leagueLegend = $(document.createElement("div"))
                .attr("id", "leagueLegend")
                .appendTo(docFragment);

            this.$rowOptionsEl = $(document.createElement("div"))
                .text("")
                .appendTo(docFragment)
                .addClass("rowOptionsBar")
                .css({
                    position: "absolute"
                })
                .hide();

            this.$el
                .append(docFragment);

            this.hasData = this.getData();
            this.on("update:data", this.render, this);
            this.on("repop", this.render, this);
            this.on("refresh:data", this.render, this);
            this.on("refresh:data", this.updateRefreshMoment, this);
            this.on("refresh:data", this.hideAfflowLoading, this);
            this.on("update:data", this.updateInfoText, this);
            this.on("update:data", this.emitTodayData, this);
            this.on("update:data", this.hideAfflowLoading, this);
            this.on("post:render", this.updateBogeyNotifier, this);
            this.on("post:render", this.startPoll, this);

            registry.ready.done(function () {
                registry.filteredView.on("update", self.updateBogeyNotifier, self);
                registry.filteredLeaguesView.on("update", self.updateMiniSelect, self);
                registry.createLeagueView.on("update:leagues", self.updateMiniSelect, self);
            });

        },



        getData: function (forceRefresh, update) {
            var dfd = $.Deferred(),
                self = this,
                position = this.$el[0].getBoundingClientRect();
            if (
                (position.top <= (window.innerHeight || document.documentElement.clientHeight) &&
                position.bottom > 0) || forceRefresh
            ) {
                // get data only if league is fully/partially in viewport.

                if (!this.timezone) {
                    console.log("Waiting for timezone"); //eslint-disable-line no-console
                }

                $.when(self.tzPromise).then(function () {
                    var args = "",
                        tsRange = self.dateRange.get(),
                        doUpdate = false;

                    if (self.currentRequest !== null) {
                        self.currentRequest.abort();
                    }


                    if (update !== undefined && update === true) {
                        doUpdate = true;
                    }

                    // if (today !== targetDate) {
                    args += "&start_ts=" + tsRange.start + "&end_ts=" + tsRange.end;
                    // }

                    self.centerLoading();

                    if (self.$tableBody.children().length > 0 && doUpdate === false) {
                        self.$tableBody.children().remove();
                    }


                    self.currentRequest = new ApiRequest();

                    self.getProviderData();

                    if (doUpdate === false) {
                        self.$tableBody.children().remove();
                    }

                    self.showAfflowLoading();
                    self.currentRequest.request("data/report.php?nid=" + self.nid + args).done(function (data) {
                        if (data !== false) {
                            self.data = data;
                            if (!doUpdate) {
                                self.trigger("update:data");
                            } else {
                                self.trigger("refresh:data");
                            }
                            dfd.resolve();
                        }
                    });

                });


            }

            return dfd.promise();
        },

        getProviderData: function () {
            this._getProviderDataFallback();
        },

        _getProviderDataFallback: function () {
            var self = this,
                range = self.dateRange.getSql(),
                apiRequest = new ApiRequest(),
                params = {
                    action: 'get',
                    nid: self.nid,
                    start: range.start,
                    end: range.end
                };

            apiRequest.request('data/partner-spend.php?' + $.param(params))
            .then(function (data) {
                if (data.message) {
                    return alert(data.message); //eslint-disable-line no-alert
                }
                if (!data.success) {
                    return alert('Error getting spend data'); //eslint-disable-line no-alert
                }

                function buildRow(amount, campaignId) {
                    return {
                        "name": campaignId,
                        "native_day_spend": amount,
                        "diff_spend": amount,
                        "bid": null,
                        "active": 1
                    };
                }

                self.providerData = _.mapObject(data.campaigns, buildRow);

                return undefined;
            })
            .fail(function (jqXHR, status, error) {
                console.log(error); //eslint-disable-line no-alert
            });
        },

        prepareData: function () {
            var self = this,
                hasProviderData = self.providerData !== null,
                rIsPaused = null,
                rNewRow = null,
                rProviderRow = null,
                spendColumn = "native_day_spend",
                data = {
                    totals: new LeagueRow(),
                    rows: []
                };
            this._subViews.push(data.totals);

            _.extend(data.totals, {
                "clicks": 0,
                "filtered_clicks": 0,
                "leads": 0,
                "revenue": 0.00,
                "spend": hasProviderData ? 0.00 : null
            });

            if (!self.dateRange.isTodaySelected()) {
                console.log("Switching to diff_spend"); //eslint-disable-line no-console
                spendColumn = "diff_spend";
            }

            _.each(this.data, function (row) {

                if (hasProviderData && self.providerData[row.keyword] !== undefined) {
                    if (parseInt(self.providerData[row.keyword].active, 10) === 0) {
                        rIsPaused = true;
                    } else {
                        rIsPaused = false;
                    }
                }

                // Is it filtered out?
                if ((parseInt(row.clicks, 10) < 2 && parseFloat(row.revenue) < 0.01) || (self.showPaused === false && rIsPaused) || registry.user.hasFilteredCampaign(self.nid, row.keyword)) {
                    return;
                }

                rNewRow = new LeagueRow();
                self._subViews.push(rNewRow);

                // Do we have provider data?
                if (hasProviderData && self.providerData[row.keyword] !== undefined) {
                    rProviderRow = self.providerData[row.keyword];
                } else {
                    // console.error("No Provider Row for ", row.keyword);
                    rProviderRow = null;
                }

                // Title
                if (!_.isNull(rProviderRow)) {
                    rNewRow.title = rProviderRow.name;
                } else {
                    try {
                        if (row.keyword === "") {
                            rNewRow.title = 'unnamed_campaign';
                        } else {
                            rNewRow.title = decodeURIComponent(row.keyword);
                        }
                    } catch (URIError) {
                        rNewRow.title = row.keyword.replace("/%20/g", " ");
                    }
                }

                // Provided
                if (rProviderRow !== null) {
                    rNewRow.bid = parseFloat(rProviderRow.bid);
                }

                if (rProviderRow !== null) {
                    rNewRow.spend = parseFloat(rProviderRow[spendColumn]);
                }

                rNewRow.paused = rIsPaused;

                // Easy ones
                rNewRow.campaign_id = row.keyword; // eslint-disable-line camelcase
                rNewRow.clicks = parseInt(row.clicks, 10);
                rNewRow.filtered_clicks = parseInt(row.filtered_clicks, 10); // eslint-disable-line camelcase
                rNewRow.leads = parseInt(row.leads, 10);
                rNewRow.revenue = parseFloat(row.revenue);
                rNewRow.profit = parseFloat(row.revenue) - (row.spend || 0);
                rNewRow.epc = row.epc;

                data.rows.push(rNewRow);

                // Update Totals
                data.totals.clicks += rNewRow.clicks;
                data.totals.filtered_clicks += rNewRow.filtered_clicks;
                data.totals.leads += rNewRow.leads;
                if (rNewRow.revenue) {
                    data.totals.revenue += rNewRow.revenue;
                }
                if (rNewRow.spend) {
                    data.totals.spend += rNewRow.spend;
                }
            });

            return data;
        },

        render: function () {
            var self = this,
                docFragment = $(document.createDocumentFragment()),
                toRender = null,
                rNewRow = null,
                range = this.dateRange.getSql();

            toRender = this.prepareData();

            if (toRender === null) {
                console.error("No data for render in league view"); // eslint-disable-line no-console
            } else {

                $.when(true).done(function () {

                    // Empty the table
                    self.$tableBody.children().remove();

                    if (toRender.rows.length === 0) {
                        self.showNoData();
                    } else {

                        // Do the sort
                        toRender.rows.sort(self.sortMethod.bind(self));

                        toRender.rows.forEach(function (row) {

                            rNewRow = $(document.createElement("tr"));
                            rNewRow
                                .addClass("leagueRow")
                                .data("cid", row.campaign_id.replace("/ /g", "%20"))
                                .data("name", row.title);

                            if (row.profit < 0 && row.paused !== true) {
                                rNewRow.addClass("loser");
                            } else if (row.profit > 0.01 && row.paused === true) {
                                rNewRow.addClass("paused_winner");
                            } else if (row.profit > 0.01 && row.paused !== true) {
                                rNewRow.addClass("winner");
                            } else if (row.paused === true) {
                                rNewRow.addClass("paused");
                            }

                            rNewRow.html(_.template(leagueRowTemplate)(row.forDisplay()));

                            if (range.start === range.end) {
                                rNewRow.find('.leagueSpend').addClass('editable');
                            }

                            docFragment.append(rNewRow);
                        });

                        self.$tableBody.append(docFragment);
                    }

                    self.updateFooter(toRender.totals);
                    self.trigger('post:render');
                });
            }
        },

        updateMiniSelect: function() {
            var self = this,
                filterMap = null,
                rNidInfo = null;

            registry.user.hasPreferences.always(function () {
                var options = {};

                filterMap = registry.user.getLeagueFilterMap();

                registry.user.nidAssignmentArr.forEach(function (nid) {
                    if (filterMap[nid] === undefined) {
                        rNidInfo = registry.user.getInfoForNid(nid);
                        options[nid] = rNidInfo.name;
                    }
                });

                options.all = "Show All";

                self.$miniSelect.updateData(options, self.nid);
            });

        },



        // refresh: function () {
        //     var self = this,
        //         rRow = null,
        //         toRender = null;

        //     toRender = this.prepareData();

        //     // TODO THIS FUCKS UP WHY?
        //     if (_.size(toRender.rows) > 0) {
        //         this.$tableBody.find(".faildata").remove();
        //     } else {
        //         if (this.$tableBody.find(".faildata").length === 0) {
        //             self.showNoData();
        //         }
        //         return false;
        //     }

        //     toRender.rows.forEach(function (row) {
        //         var rTheRow = null;

        //         // Find the row! TODO what if its not there?
        //         self.$tableBody.children().each(function () {
        //             rRow = $(this);
        //             if (rRow.data('cid') === row.campaign_id) {
        //                 rTheRow = $(this);
        //                 rTheRow.data("name", row.title);
        //             }
        //         });

        //         if (rTheRow === null) {
        //             rTheRow = $(document.createElement("tr"))
        //                 .addClass("leagueRow")
        //                 .appendTo(self.$tableBody)
        //                 .data("cid", row.campaign_id)
        //                 .data("name", row.title);
        //         }


        //         // Remove the classes
        //         rTheRow.removeClass("loser paused paused_winner winner");
        //         if (row.profit < 0 && row.paused !== true) {
        //             rTheRow.addClass("loser");
        //         } else if (row.profit > 0.00 && row.paused === true) {
        //             rTheRow.addClass("paused_winner");
        //         } else if (row.profit > 0.00 && row.paused !== true) {
        //             rTheRow.addClass("winner");
        //         } else if (row.paused === true) {
        //             rTheRow.addClass("paused");
        //         }

        //         // rProfitLoss = "$" + rProfitLoss;
        //         rTheRow.html(_.template(self.rowTemplate)(row.forDisplay()));


        //     });

        //     // Check if theres anything that needs to be removed
        //     self.$tableBody.children().each(function () {
        //         var found = null;
        //         rRow = $(this);

        //         found = _.find(toRender.rows, function (dataRow) {
        //             return dataRow.campaign_id === rRow.data('cid');
        //         });

        //         if (found === undefined) {
        //             rRow.remove();
        //         }
        //     });

        //     self.updateFooter(toRender.totals);
        // },


        showNoData: function () {
            var rNewRow = $(document.createElement("tr"));
            rNewRow.html('<td colspan="13" class="text-center text-muted">No data for selected timeframe</td>');
            rNewRow.css({"opacity": 0});
            rNewRow.addClass("faildata");
            this.$tableBody.append(rNewRow);
            rNewRow.css({"opacity": 1});
        },



        showAfflowLoading: function() {
            this.$loadingText.css({opacity: this.LOADER_SHOWN_OPACITY});
        },

        hideAfflowLoading: function() {
            this.$loadingText.css({opacity: 0});
        },

        showProviderLoading: function() {
            this.$providerLoadingText.css({left: window.innerWidth});
            this.$providerLoadingText.css({opacity: this.LOADER_SHOWN_OPACITY});
        },

        hideProviderLoading: function() {
            this.$providerLoadingText.css({opacity: 0});
        },

        clickShowAll: function() {
            this.trigger("click:showAll");
        },


        updateRefreshMoment: function () {
            if (this.dateRange.isTodaySelected()) {
                this.updateInfoText();
            }
        },

        updateFooter: function (totals) {
            var totalsRow = null,
                profit = totals.revenue - (totals.spend || 0);

            totalsRow = LeagueRow.prototype.forDisplay.call(totals);
            totalsRow.profitClass = profit < 0 ? 'redTotal' : 'greenTotal';

            this.$tableFooter.html(this.tfooterTemplate(totalsRow));

            if (this.providerData !== null) {
                this.$profitText
                    .text(totalsRow.profit)
                    .removeClass("redProfit greenProfit")
                    .addClass(profit < 0 ? 'redProfit' : 'greenProfit');
            } else {
                this.$profitText.html("<span class='loadingLeagueText'>Loading...</span>");
            }

        },

        updateInfoText: function () {
            var range = this.dateRange.get(),
                rangeStart = moment.tz(range.start * 1000, this.timezone),
                rangeEnd = moment.tz(range.end * 1000, this.timezone),
                today = moment.tz(this.timezone).startOf('day')
                    .add(1, 'day')
                    .subtract(1, 'second'),
                secondsFrom = today.diff(rangeEnd, "seconds"),
                fuzzyDiff = rangeEnd.from(today);

            if (secondsFrom < 300) {
                fuzzyDiff = "Live";
            }

            this.$infoText.html("<span class=\"leagueHeader_date\">" + this.info.name + "</span>");
        },

        updateBogeyNotifier: function () {
            var userFiltered = registry.user.getNumFilteredFor(this.nid);

            if (userFiltered > 0) {
                this.$bogeyNotifier.text(userFiltered + ' Filtered');
            }
        },

        emitTodayData: function () {
            if (this.dateRange.isTodaySelected()) {
                this.trigger('updated:todayData');
            }
        },

        centerLoading: function () {
            this.$loadingText.css({
                "left": (window.innerWidth * 2) - (this.$loadingText.width())
            });
        },

        sortByTitle: function (xo, yo) {
            var xName = String(xo.title).toLowerCase(),
                yName = String(yo.title).toLowerCase(),
                ret = 0;

            if (xName > yName) {
                ret = 1;
            } else if (xName < yName) {
                ret = -1;
            } else {
                ret = 0;
            }

            if (this.sortDirection) {
                return ret * -1;
            }

            return ret;
        },

        sortByClicks: function (xo, yo) {
            if (this.sortDirection) {
                return xo.clicks - yo.clicks;
            }

            return yo.clicks - xo.clicks;
        },

        sortByCPA: function (xo, yo) {
            var xcpa = xo.cpa,
                ycpa = yo.cpa;

            if (xo.cpa === this.NA_TEXT) {
                xcpa = 0;
            } else {
                xcpa = xo.cpa;
            }

            if (yo.cpa === this.NA_TEXT) {
                ycpa = 0;
            } else {
                ycpa = yo.cpa;
            }

            if (this.sortDirection) {
                return xcpa - ycpa;
            }

            return ycpa - xcpa;
        },

        sortByBid: function (xo, yo) {
            var xbid = xo.bid,
                ybid = yo.bid;

            if (xbid === this.NA_TEXT) {
                xbid = 0;
            }

            if (ybid === this.NA_TEXT) {
                ybid = 0;
            }

            if (this.sortDirection) {
                return xbid - ybid;
            }

            return ybid - xbid;
        },

        sortByPay: function (xo, yo) {
            var xpayout = xo.pay,
                ypayout = yo.pay;

            if (isFinite(parseFloat(xpayout))) {
                xpayout = parseFloat(xpayout);
            } else {
                xpayout = 0.00;
            }

            if (isFinite(parseFloat(ypayout))) {
                ypayout = parseFloat(yo.payout);
            } else {
                ypayout = 0.00;
            }

            if (this.sortDirection) {
                return xpayout - ypayout;
            }

            return ypayout - xpayout;
        },

        sortByProfit: function (xo, yo) {
            var xp = parseFloat(xo.profit),
                yp = parseFloat(yo.profit);

            if (!isFinite(xp)) {
                xp = 0.00;
            }

            if (!isFinite(yp)) {
                yp = 0.00;
            }

            if (this.sortDirection) {
                return xp - yp;
            }

            return yp - xp;
        },

        sortByLeads: function (xo, yo) {
            if (this.sortDirection) {
                return xo.leads - yo.leads;
            }

            return yo.leads - xo.leads;
        },

        sortBySU: function (xo, yo) {
            var xsu = parseFloat(xo.su),
                ysu = parseFloat(yo.su);

            if (!isFinite(xsu)) {
                xsu = 0.00;
            }

            if (!isFinite(ysu)) {
                ysu = 0.00;
            }


            if (this.sortDirection) {
                return xsu - ysu;
            }

            return ysu - xsu;
        },

        sortBySpend: function (xo, yo) {
            var xspend = parseFloat(xo.spend),
                yspend = parseFloat(yo.spend);

            if (!isFinite(xspend)) {
                xspend = 0.00;
            }

            if (!isFinite(yspend)) {
                yspend = 0.00;
            }

            if (this.sortDirection) {
                return xspend - yspend;
            }

            return yspend - xspend;
        },

        sortByRevenue: function (xo, yo) {
            var xrevenue = parseFloat(xo.revenue),
                yrevenue = parseFloat(yo.revenue);

            if (!isFinite(xrevenue)) {
                xrevenue = 0.00;
            }

            if (!isFinite(yrevenue)) {
                yrevenue = 0.00;
            }

            if (this.sortDirection) {
                return xrevenue - yrevenue;
            }

            return yrevenue - xrevenue;
        },

        sortByEPC: function (xo, yo) {
            var xepc = parseFloat(xo.epc),
                yepc = parseFloat(yo.epc);

            if (!isFinite(xepc)) {
                xepc = 0.00;
            }

            if (!isFinite(yepc)) {
                yepc = 0.00;
            }

            if (this.sortDirection) {
                return xepc - yepc;
            }

            return yepc - xepc;
        },

        sortByROI: function (xo, yo) {
            var xroi = parseFloat(xo.roi),
                yroi = parseFloat(yo.roi);

            if (!isFinite(xroi)) {
                xroi = 0.00;
            }

            if (!isFinite(yroi)) {
                yroi = 0.00;
            }

            if (this.sortDirection) {
                return xroi - yroi;
            }

            return yroi - xroi;
        },


        clickTh: function(ev) {
            var el = $(ev.currentTarget),
                prop = el.data("method");

            switch (prop) {
            case "name":
                if (this.sortMethod === this.sortByTitle) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByTitle;
                }
                break;

            case "bid":
                if (this.sortMethod === this.sortByBid) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByBid;
                }
                break;

            case "clicks":
                if (this.sortMethod === this.sortByClicks) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByClicks;
                }
                break;

            case "cpa":
                if (this.sortMethod === this.sortByCPA) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByCPA;
                }
                break;

            case "pay":
                if (this.sortMethod === this.sortByPay) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByPay;
                }
                break;

            case "profit":
                if (this.sortMethod === this.sortByProfit) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByProfit;
                }
                break;

            case "leads":
                if (this.sortMethod === this.sortByLeads) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByLeads;
                }
                break;

            case "revenue":
                if (this.sortMethod === this.sortByRevenue) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByRevenue;
                }
                break;

            case "epc":
                if (this.sortMethod === this.sortByEPC) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByEPC;
                }
                break;

            case "roi":
                if (this.sortMethod === this.sortByROI) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortByROI;
                }
                break;

            case "su":
                if (this.sortMethod === this.sortBySU) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortBySU;
                }
                break;

            case "spend":
                if (this.sortMethod === this.sortBySpend) {
                    this.sortDirection = !this.sortDirection;
                } else {
                    this.sortMethod = this.sortBySpend;
                }
                break;

            default:
                return true;
            }

            this.render();

            return true;
        },



        clickShowHidePaused: function () {
            this.showPaused = !this.showPaused;
            this.$showHidePausedButton.removeClass("showing hiding");
            if (this.showPaused) {
                this.$showHidePausedButton.addClass("showing").html(this.SHOWING_TEXT);
            } else {
                this.$showHidePausedButton.addClass("hiding").html(this.HIDING_TEXT);
            }
            this.render();
        },

        clickRefresh: function () {
            this.getData(true);
        },

        clickMenu: function () {
            this.trigger("click:menu");
        },

        clickRow: function (ev) {
            var $element = $(ev.currentTarget),
                $row = $element.closest('tr');



            if (ev.target.tagName === "SPAN") {
                return false; // Prevent the reports from opening on AddNote
            }

            // this.trigger("click:row", {
            //     nid: this.nid,
            //     rcid: $(el).data("cid")
            // });
            registry.reportsView.setTarget({
                nid: this.nid,
                rcid: $row.data("cid"),
                name: $row.data("name")
            });
            registry.reportsView.show();

            return true;
        },

        clickLeagueSpend: function (event) {
            var self = this,
                $el = $(event.target),
                $input = $('<input>', {class: 'leagueSpendEdit'}),
                val = $el.text(),
                newVal = $el.text(),
                rcid = $el.parents('tr').data('cid'),
                dateRange = this.dateRange.getSql();

            function refresh() {
                self.wakeUp();
                self.getData(true, true);
            }

            function save() {
                var data = {
                        nid: self.nid,
                        rcid: rcid,
                        date: dateRange.start,
                        amount: $input.val()
                    },
                    apiRequest = new ApiRequest();

                apiRequest.request('data/partner-spend.php?action=set', data)
                    .then(function (rdata) {
                        if (rdata.message) {
                            alert(rdata.message); // eslint-disable-line no-alert
                        }
                    })
                    .fail(function (jqXHR, status, error) {
                        alert('Error saving: ' + error); // eslint-disable-line no-alert
                    })
                    .always(refresh);
            }

            function onKeyUp(keyEvent) {
                switch (keyEvent.keyCode) {
                case 13: save();
                    break;
                case 27: refresh();
                    break;
                default:
                    break;
                }
            }

            event.stopPropagation();

            if (dateRange.start !== dateRange.end) {
                alert('You can edit spend only when timespan is 1 day'); // eslint-disable-line no-alert

                return;
            }


            if (val === '-') {
                newVal = '0';
            }

            this.sleep();

            $el.text('$').addClass('active');

            $input.on('keyup', onKeyUp)
                .val(newVal)
                .appendTo($el)
                .focus()
                .select();
        },

        selectedLeague: function () {
            this.trigger("click:leagueIcon", this.$miniSelect.val());
        },

        clickFilter: function () {
            var rcid = this.$rowOptionsRowRef.data("cid"),
                nid = this.nid,
                name = this.$rowOptionsRowRef.data("name"),
                self = this;

            this.$rowOptionsRowRef.css({opacity: 0});
            registry.user.filterCampaign(nid, rcid, name);
            this.$rowOptionsRowRef.remove();
            self.updateBogeyNotifier();
        },

        clickBogeyText: function () {
            registry.filteredView.setTarget({
                nid: this.nid,
                name: this.info.name
            });
            registry.filteredView.show();
        },


        clickFilterLeagues: function() {
            registry.filteredLeaguesView.show();
        },


        overRow: function(ev) {
            var el = $(ev.currentTarget).children()
                .eq(0),
                rowEl = $(ev.currentTarget);

            this.$rowOptionsRowRef = rowEl;
            el.addClass("leagueHover");

            if (!this.$rowOptionsEl.is(":visible")) {
                this.$rowOptionsEl.show();
            }

            this.$rowOptionsEl.css({
                top: rowEl.position().top,
                left: rowEl.position().left + rowEl.width()
            });
        },

        outRow: function (ev) {
            var el = $(ev.currentTarget).children()
                .eq(0),
                tableStart = this.$tableBody.offset().top,
                tableHeight = this.$tableBody.outerHeight();
            el.removeClass("leagueHover");

            if (ev.clientY > tableStart + tableHeight || ev.clientY < tableStart) {
                this.$rowOptionsEl.hide();
            }
        },


        startPoll: function () {
            var self = this;

            function callback() {
                if (self.asleep === false && document.hasFocus()) {
                    self.getData(true, true);
                }
                self.timeoutInstance = window.setTimeout(callback, self.REFRESH_INTERVAL);
            }

            window.clearTimeout(this.timeoutInstance);
            this.timeoutInstance = window.setTimeout(callback, this.REFRESH_INTERVAL);
        },

        updateNidTimezone: function() {
            var apiRequest = new ApiRequest(),
                timezoneData = this.dateRange.get(),
                timezone = timezoneData.tz;



            apiRequest.request('data/partner-league.php', {
                'timezone': timezone,
                'nid': this.nid
            })
                .then(function (rdata) {

                })
                .fail(function (jqXHR, status, error) {
                    alert('Error saving: ' + error); // eslint-disable-line no-alert
                });

        },


        sleep: function() {
            this.asleep = true;
        },

        wakeUp: function() {
            this.asleep = false;

            window.clearTimeout(this.timeoutInstance);
            this.getData(true, true);
            this.startPoll();

            this.$miniSelect.val(this.nid);
        }

    });

    return LeagueView;
});
