(function ($) {
    $.fn.imageTooltip = function (options) {

        var defaults = {
            imgWidth: 'initial',
            backgroundColor: '#fff'
        };

        if (typeof (options) === 'object') {
            options = $.extend(defaults, options);
        } else {
            var tempOptions = {};
            tempOptions.imgWidth = arguments[0] || defaults.imgWidth;
            tempOptions.backgroundColor = arguments[1] || defaults.backgroundColor;
            options = tempOptions;
        }

        function calLeft(x, imgWidth) {
            return window.innerWidth - x > imgWidth ? x : x - imgWidth;
        }

        function calTop(y, imgHeight) {
            var top = y - Math.floor(imgHeight/2);
            if (y < window.innerHeight / 2) {
                return top > 0 ? top : 0;
            } else {
                if (top + imgHeight < window.innerHeight) {
                    return top;
                } else if (window.innerHeight - imgHeight >= 0) {
                    return window.innerHeight - imgHeight;
                } else {
                    return 0;
                }
            }
        }

        return this.each(function () {

            var imgContainer = $('<p>', {
                class: 'offerImgTooltip',
                css: {
                    display: 'none',
                    position: 'fixed',
                    zIndex:999999
                }
            });

            var img = $('<img>', {
                src: $(this).data('image-tooltip') || $(this).attr('src'),
                alt: 'Image Not Available',
                maxWidth: '30vw',
            });

            imgContainer.append(img);

            $(this).hover(
                function (e) {
                    imgContainer.css({
                        left: calLeft(e.clientX + 100, imgContainer.outerWidth()) + 'px',
                        top: calTop(e.clientY, imgContainer.outerHeight()) + 'px'
                    });
                    $('body').append(imgContainer);
                    imgContainer.fadeIn('fast');
                },
                function () {
                    imgContainer.remove();
                }
            ).mousemove(function (e) {
                imgContainer.css({
                    left: calLeft(e.clientX + 100, imgContainer.outerWidth()) + 'px',
                    top: calTop(e.clientY, imgContainer.outerHeight()) + 'px'
                });
            });
        });
    };
}(jQuery));
