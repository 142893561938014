define([
    "Backbone",
    "templates/profilePreview.html",
], function (
    Backbone,
    template,
) {
    return Backbone.View.extend({
        events: {
            'click .view-close': 'close',
            'click .confirmSave': 'saveConfirmed',
            'click .editBtn': 'edit'
        },

        initialize: function(profileDetails, mode = 'confirmation') {
            this.template = _.template(template);
            this.profile = profileDetails;
            this.mode = mode;
            // render immediately without waiting of loaded data
            this.render();
        },

        render: function() {
            this.setElement($(document.createElement("div")));
            this.$el.attr("id", "profileDetailsPreview");
            let title;
            if (this.mode === 'pending') {
                title = 'Pending Profile Details Preview';
            } else if (this.mode === 'rejected') {
                title = 'Rejected Profile Details Preview';
            } else {
                title = 'Profile Details Preview';
            }
            this.$el.append(this.template({
                title,
                profile: this.profile,
                showAlert: this.mode === 'confirmation',
                showConfirmBtn: this.mode === 'confirmation'
            }));
            $("#appContainer").append(this.$el);
            this.$el.find('.overlay').fadeIn('fast');
        },

        close: function () {
            this.trigger('profilePreview:confirmed', false);
            this.destroy();
        },

        destroy: function() {
            this.$el.find('.overlay').fadeOut('fast');
            this.remove();
        },

        saveConfirmed: function(ev) {
            this.trigger('profilePreview:confirmed', true);
            this.destroy();
        },

        edit: function (ev) {
            this.trigger('profilePreview:edit');
            this.destroy();
        }
    });
});

