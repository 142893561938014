/*jslint nomen:true, indent:4*/
/*global define */

define(function () {
    var bases = [
        [ 1e9, 'b' ],
        [ 1e6, 'm' ],
        [ 1e3, 'k' ]
    ];

    return function (number) {
        number = parseFloat(number);

        for (var i = 0; i < bases.length; ++i) {
            if (number >= bases[i][0]) {
                var fixnum = (number / bases[i][0]).toFixed(1);

                return fixnum.replace(/\.0$/, '') + bases[i][1];
            }
        }

        return number.toFixed();
    };
});

