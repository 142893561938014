/*jslint nomen:true, indent:4*/
/*global Backbone, define, _, alert*/

define(["registry", "Underscore"], function(registry ) {

  var ApiRequestWorker = function() {
    this.initialize.apply(this, arguments);
  };

  _.extend(ApiRequestWorker.prototype, Backbone.Events, {
    _tempToken: null,
    _headers: {},

    requestObject: null,

    initialize: function(options) {
      if (options !== undefined && options.token !== undefined) {
        this._tempToken = options.token;
      }
    },

    request: function(url, data) {
      var params = {
          url: url,
          data: data
      };
      var type = data !== undefined
        ? 'POST'
        : 'GET';

      return this._buildRequest(params, type);
    },

    post: function(url, data = {}) {
      var params = {
        url: url,
        data: data
      };

      return this._buildRequest(params, 'POST');
    },

    put: function(url, data) {
      var params = {
          url: url,
          data: data
      };

      return this._buildRequest(params, 'PUT');
    },

    deleteRequest: function(url, data) {
      var params = {
          url: url,
          data: data
      };

      return this._buildRequest(params, 'DELETE');
    },

    _buildRequest: function(params, type) {
      var url = params.url;
      var data = params.data;

      var dfd = $.Deferred();
      var options = {};
      var headers = _.extend(
          {
            X_AFFLOW_API_TOKEN: this.getToken()
          },
          this._headers
        );




      if (!/^https?:\/\//.test(url)) {
        url = API_URL + url;
      }

      options.url = url;
      options.headers = headers;
      options.crossDomain = true;
      options.timeout = 60 * 1000;
      options.type = type;
      options.cache = false;

      if (type === 'PUT') {
        options.processData = false;
        options.data = data;
      }

      if (
        type === 'POST' ||
        type === 'DELETE' & data !== undefined
      ) {
        options.data = data;
      }

      this.requestObject = $.ajax(options)
          .done(function(body, status, req) {
            dfd.resolve(body, req);
          })
          .fail(function(jqXHR, textStatus, errorThrown) {
            if (jqXHR.status === 403 && localStorage.apiToken !== undefined) {
              localStorage.removeItem('apiToken');
            } else if (jqXHR.status === 429) {
              $('#tooManyRequestsAlert').show();
              setTimeout(function () {
                $('#tooManyRequestsAlert').hide();
              }, 5000);
            }
            dfd.reject(jqXHR, textStatus, errorThrown);
          });

      dfd.promise.request = this.requestObject;
      return dfd.promise();
    },

    requestHTML: function(url) {
      var dfd = $.Deferred(),
        options = {},
        headers = _.extend(
          {
            X_AFFLOW_API_TOKEN: this.getToken(),
            X_AFFLOW_CLIENT_VERSION: registry.versionStr
          },
          this._headers
        );
      // console.log(url);

      options.url = url;
      options.headers = headers;
      options.crossDomain = true;

      this.requestObject = $.ajax(options).done(function(body) {
        dfd.resolve(body);
      });

      dfd.promise.request = this.requestObject;
      return dfd.promise();
    },

    abort: function() {
      if (
        this.requestObject === null ||
        (this.requestObject.req !== undefined &&
          this.requestObject.req.finished === true)
      ) {
      } else {
        this.requestObject.abort();
      }
    },

    getToken: function() {
        return registry.user.token;
    }

    // started: function () {
    //     return this._startedDfd.promise();
    // },

    // campaignHasList: function (campaignId) {
    //     var returner = this._lists.findWhere({name: this._preString + campaignId});

    //     if (returner) {
    //         return true;
    //     } else {
    //         return false;
    //     }
    // }
  });

  return ApiRequestWorker;
});
